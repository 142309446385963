import React, { createContext, useRef } from "react";

import { BryntumSchedulerPro } from "@bryntum/schedulerpro-react";

export const SchedulerInstanceContext = createContext<React.RefObject<BryntumSchedulerPro>>({ current: null });

export function SchedulerInstanceProvider(props: ChildrenProps) {
    const { children } = props;

    const value = useRef<BryntumSchedulerPro>(null);

    return <SchedulerInstanceContext.Provider value={value}>{children}</SchedulerInstanceContext.Provider>;
}
