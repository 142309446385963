import React, { useEffect, useState } from "react";

import classnames from "classnames/bind";

import { COLOR } from "@CORE/constants";

import { SidebarArrowIcon } from "@VIEW/components/icons";

import styles from "./ArrowButton.module.scss";


const cx: CX = classnames.bind(styles);

function ArrowButton({
    onClick, //
    disabled,
    ...attributes
}: Props) {
    const [hover, serHover] = useState(false);

    useEffect(() => {
        if (disabled) {
            serHover(false);
        }
    }, [disabled]);

    function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
        if (!disabled) {
            onClick(event);
        }
    }

    const iconColor = disabled ? COLOR["grey"] : hover ? COLOR["blue-main"] : COLOR["blue-dark"];

    return (
        <button
            type="button"
            className={cx("arrow-button")}
            disabled={disabled}
            onClick={handleClick}
            onMouseEnter={() => {
                serHover(true);
            }}
            onMouseLeave={() => {
                serHover(false);
            }}
            {...attributes}
        >
            <div className={cx("icon-wrapper")}>
                <SidebarArrowIcon
                    size={15}
                    color={iconColor}
                />
            </div>
        </button>
    );
}

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export default ArrowButton;
