import React from "react";

import classNames from "classnames/bind";

import { COLOR } from "@CORE/constants";

import { CalendarEntryTypeExtended } from "@INTEGRATIONS/scheduler/types";

import Select from "@VIEW/components/basic/inputs/select/BasicSelect";

import styles from "./EventTypeSelect.module.scss";

const cx: CX = classNames.bind(styles);

type EventTypeOption = {
    value: CalendarEntryTypeExtended;
    label: string;
    color: React.CSSProperties["color"];
};

const eventTypesOptions = [
    {
        value: "TRAVEL",
        label: "Travel",
        color: COLOR["entry-travel"],
    },
    {
        value: "RESOURCE_ACTIVITY",
        label: "Resource Activity",
        color: COLOR["entry-resource-activity"],
    },
    {
        value: "TRAINING",
        label: "Training",
        color: COLOR["entry-training-event"],
    },
    {
        value: "OPENINGS",
        label: "Opening",
        color: COLOR["entry-openings"],
    },
    {
        value: "HOLIDAYS",
        label: "Holiday",
        color: COLOR["entry-holidays"],
    },
    {
        value: "RESOURCE_BLOCKED_PERIOD",
        label: "Blocked",
        color: COLOR["entry-resource-blocked-period"],
    },
] satisfies EventTypeOption[];

function CustomOption({
    value, //
}: CustomOptionProps) {
    return (
        <div className={cx("event-type-option")}>
            {value.color && (
                <span
                    style={{ background: value.color }}
                    className={cx("type-square")}
                />
            )}
            <span>{value.label}</span>
        </div>
    );
}

type CustomOptionProps = {
    value: EventTypeOption;
};

function EventTypesSelect({
    selectedEventTypes, //
    onChange,
}: Props) {
    const value = eventTypesOptions.filter((option: EventTypeOption) => {
        return selectedEventTypes.includes(option.value);
    });

    const handleChange = (newValue: EventTypeOption[] | EventTypeOption | null) => {
        if (Array.isArray(newValue)) {
            onChange(
                newValue?.map((item: EventTypeOption) => {
                    return item.value;
                }) || [],
            );

            return;
        }

        onChange(newValue ? [newValue.value] : []);
    };

    return (
        <Select<EventTypeOption>
            isMultiple
            isClearable
            options={eventTypesOptions}
            placeholder="All Entry Types"
            selectLabel="Event Types"
            value={value}
            onChange={handleChange}
            Option={CustomOption}
            getLabel={() => `${selectedEventTypes.length} Event Types`}
        />
    );
}

type Props = {
    selectedEventTypes: CalendarEntryTypeExtended[];
    onChange: (newEventTypes: CalendarEntryTypeExtended[]) => void;
};

export default EventTypesSelect;
