import { TimeSpan, TimeSpanConfig } from "@bryntum/schedulerpro";

export type GlobalBlockedPeriodTimeSpan = {
    name: string;
    startDate: Date;
    endDate: Date;
};

interface ConstructorParams extends Partial<TimeSpanConfig> {
    name: string;
    startDate: Date;
    endDate: Date;
}

export class GlobalBlockedPeriodTimeSpanModel extends TimeSpan {
    name: string;
    cls: string = "blocked-period";
    startDate: Date;
    endDate: Date;

    constructor(params: ConstructorParams) {
        super(params);

        this.name = params.name;
        this.startDate = params.startDate;
        this.endDate = params.endDate;
    }

    static get $name(): string {
        return "GlobalBlockedPeriodTimeSpanModel";
    }

    static get fields(): any {
        return [
            { name: "name", type: "string" },
            { name: "cls", type: "string" },
            { name: "startDate", type: "date" },
            { name: "endDate", type: "date" },
        ];
    }
}
