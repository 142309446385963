import { GlobalBlockedPeriodTimeSpan, GlobalBlockedPeriodTimeSpanModel } from "@INTEGRATIONS/scheduler/models";

export const globalBlockedPeriodMappers = {
    valueToModel: (span: GlobalBlockedPeriodTimeSpan): GlobalBlockedPeriodTimeSpanModel => {
        return new GlobalBlockedPeriodTimeSpanModel(span);
    },
    modelToValue: (spanModel: GlobalBlockedPeriodTimeSpanModel): GlobalBlockedPeriodTimeSpan => {
        return {
            name: spanModel.name,
            startDate: spanModel.startDate,
            endDate: spanModel.endDate,
        };
    },
};
