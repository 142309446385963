import {
    CalendarEntryNonOpeningProject,
    CalendarEntryNonOpeningProjectModel,
    CalendarEntryOpeningProject,
    CalendarEntryOpeningProjectModel,
} from "@INTEGRATIONS/scheduler/models";

export type CalendarEntryType =
    | "TRAVEL" //
    | "HOLIDAYS"
    | "OPENINGS"
    | "RESOURCE_ACTIVITY"
    | "RESOURCE_BLOCKED_PERIOD";

export type CalendarEntryTypeExtended = CalendarEntryType | "TRAINING";

export const ProjectType = {
    OPENING: "OPENING_PROJECT",
    NON_OPENING: "NON_OPENING_PROJECT",
    NOT_PROJECT_RELATED: "NOT_PROJECT_RELATED",
    TRAINING_EVENT: "TRAINING_EVENT",
    HOLIDAY: "HOLIDAY",
} as const;

export type ProjectType = (typeof ProjectType)[keyof typeof ProjectType];

export const TrainingEventType = {
    PMS_CONFIG: "PMS_CONFIG",
    PMS_GOLIVE: "PMS_GOLIVE",
    PMS_TRAINING: "PMS_TRAINING",
    RESERVATION: "RESERVATION",
    RESERVATION_SYSTEM_TESTING: "RESERVATION_SYSTEM_TESTING",
    RESERVATION_TRAINING: "RESERVATION_TRAINING",
    AD_HOC_FIELD: "AD_HOC_FIELD",
    S_C: "S_C",
    CONVERSION: "CONVERSION",
    CONVERSION_SCRIPT: "CONVERSION_SCRIPT",
    DISCOVERY_CALL: "DISCOVERY_CALL",
    OPERA_SPRINT_TRAINING: "OPERA_SPRINT_TRAINING",
    POST_LIVE_CALL: "POST_LIVE_CALL",
} as const;

export type TrainingEventType = (typeof TrainingEventType)[keyof typeof TrainingEventType];

export const TrainingEventSubType = {
    TRAINING: "TRAINING",
    GOLIVE: "GOLIVE",
    CONFIG: "CONFIG",
    KICKOFF: "KICKOFF",
    PHASE_1: "PHASE_1",
    PHASE_2: "PHASE_2",
    SYSTEM_TESTING: "SYSTEM_TESTING",
    SYSTEM_CHECK: "SYSTEM_CHECK",
    EXIT: "EXIT",
    SCRIPT: "SCRIPT",
} as const;

export type TrainingEventSubType = (typeof TrainingEventSubType)[keyof typeof TrainingEventSubType];

export const TrainingTrainerType = {
    INTERNAL: "INTERNAL",
    EXTERNAL: "EXTERNAL",
} as const;

export type TrainingTrainerType = (typeof TrainingTrainerType)[keyof typeof TrainingTrainerType];

export type CalendarEntryProject = CalendarEntryOpeningProject | CalendarEntryNonOpeningProject;
export type CalendarEntryProjectModel = CalendarEntryOpeningProjectModel | CalendarEntryNonOpeningProjectModel;
