import React from "react";

import classnames from "classnames/bind";

import { SidebarView } from "@INTEGRATIONS/scheduler/config/common";

import styles from "./SummaryOpeningsColumnView.module.scss";


const cx: CX = classnames.bind(styles);

function SummaryOpeningsColumnView({
    view, //
}: Props) {
    return (
        <div
            data-summary-openings-view="1"
            data-summary-openings-view-mode={view}
            className={cx("summary-openings-column-view")}
        >
            Openings
        </div>
    );
}

interface Props {
    view: SidebarView;
}

export default SummaryOpeningsColumnView;
