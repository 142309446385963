import React from "react";

import classnames from "classnames/bind";

import { COLOR } from "@CORE/constants";

import { ClockIcon } from "@VIEW/components/icons";

import styles from "./ScheduleMenuTooltipView.module.scss";


const cx: CX = classnames.bind(styles);

function ScheduleMenuTooltip({
    date, //
}: Props) {
    return (
        <div className={cx("schedule-menu-tooltip-view")}>
            <div className={cx("icon-wrapper")}>
                <ClockIcon
                    size={21}
                    color={COLOR["grey"]}
                />
            </div>
            <div className={cx("date")}>{date}</div>
        </div>
    );
}

interface Props {
    date: string;
}

export default ScheduleMenuTooltip;
