import React from "react";

import { IconProps } from "@CORE/interfaces";

function ThrashIcon({
    size = defaultProps.size, //
    color = defaultProps.color,
}: IconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 41 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g>
                <path
                    opacity="0.2"
                    d="M31.75 8.75V32.5C31.75 32.8315 31.6183 33.1495 31.3839 33.3839C31.1495 33.6183 30.8315 33.75 30.5 33.75H10.5C10.1685 33.75 9.85054 33.6183 9.61612 33.3839C9.3817 33.1495 9.25 32.8315 9.25 32.5V8.75H31.75Z"
                    fill={color}
                />
                <path
                    d="M34.25 8.75H6.75"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16.75 16.25V26.25"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M24.25 16.25V26.25"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M31.75 8.75V32.5C31.75 32.8315 31.6183 33.1495 31.3839 33.3839C31.1495 33.6183 30.8315 33.75 30.5 33.75H10.5C10.1685 33.75 9.85054 33.6183 9.61612 33.3839C9.3817 33.1495 9.25 32.8315 9.25 32.5V8.75"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M26.75 8.75V6.25C26.75 5.58696 26.4866 4.95107 26.0178 4.48223C25.5489 4.01339 24.913 3.75 24.25 3.75H16.75C16.087 3.75 15.4511 4.01339 14.9822 4.48223C14.5134 4.95107 14.25 5.58696 14.25 6.25V8.75"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}

const defaultProps = {
    size: "1em",
    color: "currentColor",
};

export default ThrashIcon;
