import React, { useMemo, useRef, useState } from "react";

import classNames from "classnames/bind";

import Select, { CSSObjectWithLabel, MultiValue, OptionProps, StylesConfig } from "react-select";

import { COLOR } from "@CORE/constants";

import { Person } from "@VIEW/components/common";

import { CloseIcon, PlusIcon } from "@VIEW/components/icons";

import { useClickOutside } from "@VIEW/hooks";

import MultipleCustomOption from "../BasicSelect/MultipleCustomOption/MultipleCustomOption.com";
import styles from "./ResourcePicker.module.scss";
import SelectedResource from "./SelectedResource";


const cx: CX = classNames.bind(styles);

function CustomOption<T extends AbstractResource>(optionProps: OptionProps<T>) {
    const { data: optionData, isSelected } = optionProps;

    return (
        <MultipleCustomOption
            onSelect={() => {
                optionProps.selectOption(optionProps.data);
            }}
            isSelected={isSelected}
        >
            <Person
                name={optionData.name}
                role={optionData.role}
                logo={optionData.logo}
            />
        </MultipleCustomOption>
    );
}

const selectStyles = {
    control: (base: CSSObjectWithLabel) => {
        return {
            ...base,
            minHeight: 0,
            border: 0,
            opacity: 0,
        };
    },
    menu: (base: CSSObjectWithLabel) => {
        return {
            ...base,
            margin: "-2px 0 0",
            boxShadow: "0px 2px 8px 0px #27293714",
            border: `1px solid ${COLOR["grey-light"]}`,
            borderRadius: 8,
            right: 0,
            width: "auto",
        };
    },
    menuList: (base: CSSObjectWithLabel) => {
        return {
            ...base,
            paddingTop: "10px",
        };
    },
    container: (base: CSSObjectWithLabel) => {
        return {
            ...base,
            height: 0,
        };
    },
    valueContainer: (base: CSSObjectWithLabel) => {
        return {
            ...base,
            height: 0,
        };
    },
} satisfies StylesConfig<AbstractResource, true>;

function ResourcePicker<T extends AbstractResource>({
    resources, //
    selectedResources,
    onChange,
}: Props<T>) {
    const [pickerOpen, setPickerOpen] = useState(false);

    const containerRef = useRef<HTMLDivElement>(null);

    useClickOutside(containerRef, () => {
        setPickerOpen(false);
    });

    const handleChange = (newValues: MultiValue<T>) => {
        const newResources = resources.filter((item: T) => {
            return newValues.find((option: T) => option.id === item.id);
        });

        onChange(newResources);
    };

    const handleRemove = (id: string | number) => {
        const newResources = resources.filter((item: T) =>
            selectedResources.find((option: T) => option.id === item.id && id !== option.id),
        );

        onChange(newResources);
    };


    const groupedResources = useMemo(() => resources.reduce((acc: { label: string, options: T[]}[], item: T) => {
        const { role } = item;

        const foundGroup = acc.find((group: { label: string, options: T[]}) => group.label === role);

        if(foundGroup) foundGroup.options.push(item);
        else acc.push({ label: role, options: [item]});

        return acc;
    }, []), [resources]);

    return (
        <div ref={containerRef}>
            <div className={cx("selected-resources")}>
                {selectedResources.slice(0, 5).map((resource: T) => (
                    <div
                        key={resource.id}
                        className={cx("selected-resource-wrapper")}
                    >
                        <SelectedResource
                            resource={resource}
                            onRemove={() => handleRemove(resource.id)}
                        />
                    </div>
                ))}
                <div className={cx("selected-resource-wrapper")}>
                    <button
                        type="button"
                        onClick={() => setPickerOpen(!pickerOpen)}
                        className={cx("new-resource")}
                    >
                        <div className={cx("icon-wrapper")}>
                            {pickerOpen ? (
                                <CloseIcon
                                    color="white"
                                    size={14}
                                />
                            ) : (
                                <PlusIcon
                                    color="white"
                                    size={13}
                                />
                            )}
                        </div>
                    </button>
                </div>
            </div>
            <div className={cx("select-wrapper")}>
                {pickerOpen && (
                    <Select<T, true>
                        isMulti
                        components={{ IndicatorSeparator: null, DropdownIndicator: null, Option: CustomOption }}
                        controlShouldRenderValue={false}
                        hideSelectedOptions={false}
                        isClearable={false}
                        isSearchable={false}
                        menuIsOpen
                        onMenuClose={() => setPickerOpen(false)}
                        styles={selectStyles}
                        options={groupedResources}
                        value={selectedResources}
                        tabSelectsValue={false}
                        onChange={handleChange}
                        closeMenuOnSelect={false}
                        getOptionValue={(option: AbstractResource) => option.id.toString()}
                        getOptionLabel={(option: AbstractResource) => option.name}
                    />
                )}
            </div>
        </div>
    );
}

type Props<T extends AbstractResource> = {
    resources: T[];
    selectedResources: T[];
    onChange: (newResources: T[]) => void;
};

type AbstractResource = {
    id: string | number;
    name: string;
    role: string;
    logo: string;
};

export default ResourcePicker;
