import { Model, ModelConfig } from "@bryntum/schedulerpro";

import { ProjectType } from "@INTEGRATIONS/scheduler/types";

export type CalendarEntryOpeningProject = {
    id: string | number;
    name: string;
    marsha?: string;
    gdNumber?: string;
    projectId?: string;
    type?: string;
    projectUrl?: string;
};

interface ConstructorParams extends Partial<ModelConfig> {
    name: string;
    marsha?: string;
    gdNumber?: string;
    projectId?: string;
    type?: string;
    projectUrl?: string;
}

export class CalendarEntryOpeningProjectModel extends Model {
    name: string;
    marsha?: string = "";
    gdNumber?: string = "";
    projectId?: string = "";
    type: string = "";
    projectUrl: string = "";
    projectType = ProjectType.OPENING;

    constructor(params: ConstructorParams) {
        super(params);

        this.name = params.name;
        this.marsha = params.marsha;
        this.gdNumber = params.gdNumber;
        this.projectId = params.projectId;
        this.type = params.type || "";
        this.projectUrl = params.projectUrl || "";
    }

    static get $name(): string {
        return "CalendarEntryOpeningProjectModel";
    }

    static get fields(): any {
        return [
            { name: "name", type: "string" },
            { name: "marsha", type: "string" },
            { name: "gdNumber", type: "string" },
            { name: "type", type: "string" },
            { name: "projectType", type: "string" },
            { name: "projectId", type: "string" },
            { name: "projectUrl", type: "string" },
        ];
    }
}
