import { z } from "zod";

export const DatesSchema = z.object({
    startDate: z.date({
        required_error: "Start date is not allowed to be empty",
        invalid_type_error: "Start date is not allowed to be empty",
    }),
    endDate: z.date({
        required_error: "End date is not allowed to be empty",
        invalid_type_error: "End date is not allowed to be empty",
    }),
});
