import { SchedulerPro } from "@bryntum/schedulerpro";

import { AppProjectModel, CalendarResource, CalendarResourceModel } from "@INTEGRATIONS/scheduler/models";

type ValueToModelParams = {
    resource: CalendarResource;
    source: SchedulerPro;
};

export const calendarResourceMappers = {
    valueToModel(params: ValueToModelParams): CalendarResourceModel {
        const { resource, source } = params;

        if (source.project instanceof AppProjectModel) {
            return new CalendarResourceModel({
                ...resource,
                project: source.project,
            });
        }

        throw new Error("Wrong scheduler project");
    },
    modelToValue(model: CalendarResourceModel): CalendarResource {
        return {
            id: model.id,
            name: model.name,
            role: model.role,
            logo: model.logo,
            abbreviation: model.abbreviation,
            allowToAddHolidays: model.allowToAddHolidays,
        };
    },
} as const;
