import React from "react";

import classnames from "classnames/bind";

import { CalendarEntryModel } from "@INTEGRATIONS/scheduler/models";
import { CalendarEntryProjectModel, CalendarEntryType, ProjectType } from "@INTEGRATIONS/scheduler/types";

import { BlockIcon, LevelUpIcon, LinkIcon2, ScissorsIcon, TentativeIcon } from "@VIEW/components/icons";

import styles from "./EventView.module.scss";

const cx: CX = classnames.bind(styles);

const BASIC_LIMIT = 70;
const OPENING_FIRST_LIMIT = 40;
const OPENING_SECOND_LIMIT = 140;

const entryTypeMap = {
    TRAVEL: "Travel",
    HOLIDAYS: "Holiday",
    OPENINGS: "Opening",
    RESOURCE_ACTIVITY: "",
    RESOURCE_BLOCKED_PERIOD: "Blocked",
} satisfies Record<CalendarEntryType, string>;

const DEFAULT_COLOR_1 = "rgb(0 0 0 / 50%)";
const DEFAULT_COLOR_2 = "rgb(0 0 0 / 40%)";

const DEFAULT_TRAINING_EVENT_COLOR = "#967AB9";
const TENTATIVE_TRAINING_EVENT_COLOR = "#B194D6";

const DEFAULT_RESOURCE_ACTIVITY_COLOR = "#074CA4";
const TENTATIVE_RESOURCE_ACTIVITY_COLOR = "#558CD2";

const RESOURCE_BLOCKED_PERIOD_ICON_COLOR = "#9C4739";

function PseudoLink({
    eventRecord, //
    children,
}: PseudoLinkProps) {
    if (!eventRecord.readOnly || !eventRecord.url) {
        return <>{children}</>;
    }

    return (
        <div className={cx("pseudo-link")}>
            {children}
            <div
                data-link-icon="1"
                className={cx("link-icon-wrapper")}
            >
                <LinkIcon2
                    color={DEFAULT_COLOR_1}
                    size={12}
                />
            </div>
        </div>
    );
}

interface PseudoLinkProps extends ChildrenProps {
    eventRecord: CalendarEntryModel;
}

function OpeningEventView({
    eventRecord, //
    eventWidth,
}: Props) {
    if (eventWidth < OPENING_FIRST_LIMIT) return null;

    const title = eventRecord.projectMarsha || eventRecord.gdProjectId || "";

    return (
        <div
            className={cx("event-view", "opening", {
                centered: eventWidth < OPENING_SECOND_LIMIT,
            })}
        >
            <div className={cx("info")}>
                <ScissorsIcon
                    size={24}
                    color={DEFAULT_COLOR_1}
                />
                {eventWidth >= OPENING_SECOND_LIMIT && title.trim() !== "" && (
                    <PseudoLink eventRecord={eventRecord}>
                        <div
                            data-title="1"
                            style={{
                                color: DEFAULT_COLOR_1,
                            }}
                            className={cx("title")}
                        >
                            {title}
                        </div>
                    </PseudoLink>
                )}
            </div>
        </div>
    );
}

function ResourceActivityEventView({
    eventRecord, //
    eventWidth,
}: Props) {
    if (eventWidth < BASIC_LIMIT) return null;

    const title = (() => {
        const typeName = entryTypeMap[eventRecord.entryType];

        return `${typeName} ${eventRecord.name}`.trim();
    })();

    const projects = (() => {
        return eventRecord.projects
            .map((project: CalendarEntryProjectModel) => {
                if (project.projectType === ProjectType.OPENING) {
                    return project.marsha || project.gdNumber || null;
                }

                if (project.projectType === ProjectType.NON_OPENING) {
                    return project.code || null;
                }

                return null;
            })
            .filter(Boolean);
    })();

    const Icon = (() => {
        if (!eventRecord.isTrainingEvent && eventRecord.isTentative) {
            const iconColor = eventRecord.isUnassigned ? DEFAULT_COLOR_1 : TENTATIVE_RESOURCE_ACTIVITY_COLOR;

            return (
                <div className={cx("icon-wrapper")}>
                    <TentativeIcon
                        size={20}
                        color={iconColor}
                    />
                </div>
            );
        }

        if (eventRecord.isTrainingEvent) {
            const hasProjects = eventRecord.projects.length > 0;

            const iconColor = eventRecord.isUnassigned ? DEFAULT_COLOR_2 : DEFAULT_TRAINING_EVENT_COLOR;

            return (
                <div
                    data-project-link={hasProjects ? "1" : "0"}
                    className={cx("icon-wrapper")}
                >
                    <div data-training-icon="default">
                        {eventRecord.trainingIsStatusConfirmed ? (
                            <LevelUpIcon
                                size={16}
                                color={iconColor}
                            />
                        ) : (
                            <TentativeIcon
                                size={20}
                                color={iconColor}
                            />
                        )}
                    </div>
                    <div data-training-icon="link">
                        <LinkIcon2
                            size={12}
                            color={iconColor}
                        />
                    </div>
                </div>
            );
        }

        return null;
    })();

    const color = (() => {
        if (eventRecord.isTrainingEvent) {
            if (eventRecord.isUnassigned) return DEFAULT_COLOR_2;

            if (eventRecord.trainingIsStatusConfirmed) {
                return DEFAULT_TRAINING_EVENT_COLOR;
            }

            return TENTATIVE_TRAINING_EVENT_COLOR;
        }

        if (eventRecord.isUnassigned) return DEFAULT_COLOR_1;

        if (eventRecord.isTentative) {
            return TENTATIVE_RESOURCE_ACTIVITY_COLOR;
        }

        return DEFAULT_RESOURCE_ACTIVITY_COLOR;
    })();

    return (
        <div
            data-event-view="1"
            data-event-unassigned-view={eventRecord.isUnassigned ? "1" : ""}
            className={cx("event-view", {
                "training-event": eventRecord.isTrainingEvent,
            })}
        >
            {Icon}

            <div className={cx("info")}>
                {title && (
                    <div
                        style={{
                            color,
                        }}
                        className={cx("title")}
                    >
                        {title}
                    </div>
                )}
                {projects.length > 0 && (
                    <div
                        style={{
                            color,
                        }}
                        className={cx("marsha-code")}
                    >
                        {projects.join(", ")}
                    </div>
                )}
            </div>
        </div>
    );
}

function ResourceBlockedPeriodEventView({
    eventWidth, //
}: Omit<Props, "eventRecord">) {
    if (eventWidth < BASIC_LIMIT) return null;

    return (
        <div className={cx("event-view", "resource-blocked-period")}>
            <div className={cx("icon-wrapper")}>
                <BlockIcon
                    size={16}
                    color={RESOURCE_BLOCKED_PERIOD_ICON_COLOR}
                />
            </div>
            <div className={cx("info")}>
                <div className={cx("title")}>Blocked</div>
            </div>
        </div>
    );
}

function BasicEventView({
    eventRecord, //
    eventWidth,
}: Props) {
    if (eventWidth < BASIC_LIMIT) return null;

    const title = (() => {
        const typeName = entryTypeMap[eventRecord.entryType];

        if (eventRecord.entryType === "TRAVEL") {
            return [
                typeName, //
                eventRecord.travelDestination,
            ]
                .filter(Boolean)
                .join(" ");
        }

        if (eventRecord.entryType === "HOLIDAYS") {
            return typeName;
        }

        return `${typeName} ${eventRecord.name}`;
    })();

    return (
        <div className={cx("event-view")}>
            <div className={cx("info")}>
                <PseudoLink eventRecord={eventRecord}>
                    <div
                        data-title="1"
                        style={{
                            color: DEFAULT_COLOR_1,
                        }}
                        className={cx("title")}
                    >
                        {title}
                    </div>
                </PseudoLink>
            </div>
        </div>
    );
}

function EventView(props: Props) {
    if (props.eventRecord.entryType === "RESOURCE_ACTIVITY") {
        return <ResourceActivityEventView {...props} />;
    }

    if (props.eventRecord.entryType === "OPENINGS") {
        return <OpeningEventView {...props} />;
    }

    if (props.eventRecord.entryType === "RESOURCE_BLOCKED_PERIOD") {
        return <ResourceBlockedPeriodEventView {...props} />;
    }

    return <BasicEventView {...props} />;
}

interface Props {
    eventRecord: CalendarEntryModel;
    eventWidth: number;
}

export default EventView;
