import { DateHelper } from "@bryntum/schedulerpro";

export function formatDate(date: Date): string {
    return DateHelper.format(date, "MMM DD, YYYY");
}

export function formatDateTime(date: Date): string {
    return DateHelper.format(date, "DDMMYYYYHHmmss");
}

export function getFirstDayOfWeek(date: Date): Date {
    const cloneDate = new Date(date);
    const day = cloneDate.getDay();

    const diff = cloneDate.getDate() - day + (day === 0 ? -6 : 1);

    return new Date(cloneDate.setDate(diff));
}

export function getStartOfDay(date: Date): Date {
    const newDate = new Date(date);

    newDate.setHours(0, 0, 0, 0);

    return newDate;
}

export function getFirstDateOfMonth(date: Date): Date {
    return DateHelper.getFirstDateOfMonth(date);
}

export function getNextDay(date: Date): Date {
    return DateHelper.getStartOfNextDay(date);
}

export function getPreviousDay(date: Date): Date {
    return DateHelper.add(date, -1, "day");
}

export function removeTimezoneOffset(
    localStartDate: Date,
    localEndDate: Date,
    direction: 1 | -1,
): { startDate: Date; endDate: Date } {
    const startDateOffset = localStartDate.getTimezoneOffset();
    const endDateOffset = localEndDate.getTimezoneOffset();

    const startDate = DateHelper.add(localStartDate, direction * startDateOffset, "minute");
    const endDate = DateHelper.add(localEndDate, direction * endDateOffset, "minute");

    return {
        startDate,
        endDate,
    };
}

type RenderDateParams = {
    dateFormat: string;
    startDate: Date;
    endDate: Date;
};

export function renderDateRange({
    dateFormat, //
    startDate,
    endDate,
}: RenderDateParams): string {
    const start = DateHelper.format(startDate, dateFormat);
    const end = DateHelper.format(getPreviousDay(endDate), dateFormat);

    if (start === end) return start;

    return `${start} - ${end}`;
}
