import { Model, ModelConfig } from "@bryntum/schedulerpro";

import { ProjectType } from "@INTEGRATIONS/scheduler/types";

export type CalendarEntryNonOpeningProject = {
    id: string | number;
    name: string;
    code?: string;
};

interface ConstructorParams extends Partial<ModelConfig> {
    name: string;
    code?: string;
}

export class CalendarEntryNonOpeningProjectModel extends Model {
    name: string;
    code?: string = "";
    projectType = ProjectType.NON_OPENING;

    constructor(params: ConstructorParams) {
        super(params);

        this.name = params.name;
        this.code = params.code;
    }

    static get $name(): string {
        return "CalendarEntryNonOpeningProjectModel";
    }

    static get fields(): any {
        return [
            { name: "name", type: "string" },
            { name: "code", type: "string" },
            { name: "projectType", type: "string" },
        ];
    }
}
