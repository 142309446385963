import { PresetManager, ViewPresetConfig } from "@bryntum/schedulerpro";

import { renderDateRange } from "@INTEGRATIONS/scheduler/utils";

export const CustomViewPreset = {
    YEARS_AND_QUARTERS: "YEARS_AND_QUARTERS",
    YEARS_AND_MONTHS: "YEARS_AND_MONTHS",
    QUARTERS_AND_MONTHS: "QUARTERS_AND_MONTHS",
    QUARTERS_AND_WEEKS: "QUARTERS_AND_WEEKS",
    MONTHS_AND_WEEKS: "MONTHS_AND_WEEKS",
    MONTHS_AND_DAYS: "MONTHS_AND_DAYS",
    WEEKS_AND_DAYS: "WEEKS_AND_DAYS",
} as const;

const customYearsAndQuarters = {
    id: CustomViewPreset.YEARS_AND_QUARTERS,
    mainUnit: "day",
    tickWidth: 250,
    timeResolution: {
        unit: "day",
        increment: 1,
    },
    headers: [
        {
            unit: "year",
            dateFormat: "YYYY",
        },
        {
            unit: "quarter",
            dateFormat: "{Quarter} Q",
        },
    ],
} satisfies Partial<ViewPresetConfig>;

const customYearsAndMonths = {
    id: CustomViewPreset.YEARS_AND_MONTHS,
    mainUnit: "day",
    tickWidth: 150,
    timeResolution: {
        unit: "day",
        increment: 1,
    },
    headers: [
        {
            unit: "year",
            dateFormat: "YYYY",
        },
        {
            unit: "month",
            dateFormat: "MMMM",
        },
    ],
} satisfies Partial<ViewPresetConfig>;

const customQuartersAndMonths = {
    id: CustomViewPreset.QUARTERS_AND_MONTHS,
    mainUnit: "day",
    tickWidth: 250,
    timeResolution: {
        unit: "day",
        increment: 1,
    },
    headers: [
        {
            unit: "quarter",
            dateFormat: "{Quarter} Q YYYY",
        },
        {
            unit: "month",
            dateFormat: "MMMM",
        },
    ],
} satisfies Partial<ViewPresetConfig>;

const customQuartersAndWeeks = {
    id: CustomViewPreset.QUARTERS_AND_WEEKS,
    mainUnit: "day",
    tickWidth: 100,
    timeResolution: {
        unit: "day",
        increment: 1,
    },
    headers: [
        {
            unit: "quarter",
            dateFormat: "{Quarter} Q YYYY",
        },
        {
            unit: "week",
            dateFormat: "Wp",
        },
    ],
} satisfies Partial<ViewPresetConfig>;

const customMonthsAndWeeks = {
    id: CustomViewPreset.MONTHS_AND_WEEKS,
    mainUnit: "day",
    tickWidth: 150,
    timeResolution: {
        unit: "day",
        increment: 1,
    },
    headers: [
        {
            unit: "month",
            dateFormat: "MMMM YYYY",
        },
        {
            unit: "week",
            dateFormat: "Wp",
        },
    ],
} satisfies Partial<ViewPresetConfig>;

const customMonthsAndDays = {
    id: CustomViewPreset.MONTHS_AND_DAYS,
    mainUnit: "day",
    defaultSpan: 31,
    tickWidth: 50,
    timeResolution: {
        unit: "day",
        increment: 1,
    },
    headers: [
        {
            unit: "month",
            dateFormat: "MMMM YYYY",
        },
        {
            unit: "day",
            dateFormat: "DD",
        },
    ],
} satisfies Partial<ViewPresetConfig>;

const customWeeksAndDays = {
    id: CustomViewPreset.WEEKS_AND_DAYS,
    mainUnit: "day",
    defaultSpan: 6,
    tickWidth: 100,
    timeResolution: {
        unit: "day",
        increment: 1,
    },
    headers: [
        {
            unit: "week",
            renderer(startDate: Date, endDate: Date) {
                return renderDateRange({
                    dateFormat: "MMMM DD, YYYY",
                    startDate,
                    endDate,
                });
            },
        },
        {
            unit: "day",
            dateFormat: "ddd DD",
        },
    ],
} satisfies Partial<ViewPresetConfig>;

// Default presets

// "manyYears",
// "manyYears-80by50",
// "year-30by100",
// "year-50by100",
// "year",
// "year-200by100",
// "monthAndYear",
// "weekDateAndMonth",
// "weekAndMonth",
// "weekAndDayLetter",
// "weekAndDay-54by80",
// "dayAndMonth",
// "weekAndDay",
// "hourAndDay-64by40",
// "hourAndDay-100by40",
// "hourAndDay-64by40-2",
// "hourAndDay",
// "minuteAndHour",
// "minuteAndHour-60by60",
// "minuteAndHour-130by60",
// "minuteAndHour-60by60-5",
// "minuteAndHour-100by60",
// "secondAndMinute",
// "secondAndMinute-60by40",
// "secondAndMinute-130by40",

export const presets = [
    PresetManager.registerPreset(customYearsAndQuarters.id, customYearsAndQuarters),
    PresetManager.registerPreset(customYearsAndMonths.id, customYearsAndMonths),
    PresetManager.registerPreset(customQuartersAndMonths.id, customQuartersAndMonths),
    PresetManager.registerPreset(customQuartersAndWeeks.id, customQuartersAndWeeks),
    PresetManager.registerPreset(customMonthsAndWeeks.id, customMonthsAndWeeks),
    PresetManager.registerPreset(customMonthsAndDays.id, customMonthsAndDays),
    PresetManager.registerPreset(customWeeksAndDays.id, customWeeksAndDays),
] satisfies Partial<ViewPresetConfig>[];
