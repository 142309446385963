import React from "react";

import classnames from "classnames/bind";

import { BasicButton, GhostButton } from "@VIEW/components/basic/buttons";

import styles from "./EventFormButtons.module.scss";

const cx: CX = classnames.bind(styles);

function EventFormButtons({
    isCreating, //
    disabled,
    onSave,
    onDelete,
    onCancel,
}: Props) {
    return (
        <div className={cx("event-form-buttons")}>
            <div className={cx("save-button-wrapper", "button-wrapper")}>
                <BasicButton
                    label="Save"
                    disabled={disabled}
                    onClick={onSave}
                />
            </div>
            {!isCreating && (
                <div className={cx("delete-button-wrapper", "button-wrapper")}>
                    <GhostButton
                        label="Delete"
                        disabled={disabled}
                        onClick={onDelete}
                    />
                </div>
            )}
            <div className={cx("cancel-button-wrapper", "button-wrapper")}>
                <GhostButton
                    label="Cancel"
                    disabled={disabled}
                    onClick={onCancel}
                />
            </div>
        </div>
    );
}

interface Props {
    isCreating: boolean;
    disabled: boolean;
    onSave: () => void;
    onDelete: () => void;
    onCancel: () => void;
}

export default EventFormButtons;
