import { CalendarEntryLocationApiModel } from "@API/services";

type Location = {
    custom: string | null;
    city: string | null;
    country: string | null;
};

export function locationToString(location: Location | null): string | null {
    if (location === null) return null;

    if (location.custom !== null) return location.custom;

    if (typeof location.city === "string" && typeof location.country === "string") {
        return `${location.city}, ${location.country}`;
    }

    return null;
}

export function stringToLocation(stringLocation: string | null): CalendarEntryLocationApiModel | null {
    if (stringLocation === null || stringLocation.trim() === "") return null;

    const [city, country] = stringLocation.split(",");

    if (city && country) {
        return {
            custom: null,
            city: city.trim(),
            country: country.trim(),
        };
    }

    return {
        custom: stringLocation.trim(),
        city: null,
        country: null,
    };
}
