import { z } from "zod";

export const ProjectSchema = z.object({
    id: z.union([z.string(), z.number()]),
    name: z.string(),
    marsha: z
        .string()
        .nullish()
        .transform((val: string | null | undefined) => val || undefined),
    gdNumber: z
        .string()
        .nullish()
        .transform((val: string | null | undefined) => val || undefined),
    projectId: z
        .string()
        .nullish()
        .transform((val: string | null | undefined) => val || undefined),
    projectUrl: z
        .string()
        .nullish()
        .transform((val: string | null | undefined) => val || undefined),
});
