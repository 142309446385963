import { ResourceModel, ResourceModelConfig, Store } from "@bryntum/schedulerpro";

import { AppProjectModel } from "@INTEGRATIONS/scheduler/models/AppProjectModel";

export const UNASSIGNED_RESOURCE_ID = "UNASSIGNED_RESOURCE_ID";
export const UNASSIGNED_RESOURCE_ROLE = "UNASSIGNED_RESOURCE_ROLE";

export const EXTERNAL_TRAINER_RESOURCE_ID = "EXTERNAL_TRAINER_RESOURCE_ID";
export const EXTERNAL_TRAINER_RESOURCE_ROLE = "EXTERNAL_TRAINER_RESOURCE_ROLE";

export type CalendarResource = {
    id: string | number;
    name: string;
    role: string;
    logo: string;
    abbreviation: string;
    allowToAddHolidays: boolean;
};

interface ConstructorParams extends Partial<ResourceModelConfig> {
    role: string;
    logo: string;
    abbreviation: string;
    project: AppProjectModel;
    allowToAddHolidays: boolean;
}

export class CalendarResourceModel extends ResourceModel {
    role: string;
    logo: string;
    abbreviation: string;
    project: AppProjectModel;
    allowToAddHolidays: boolean;

    constructor(params: ConstructorParams, store?: Store, meta?: object) {
        super(params, store, meta);

        this.role = params.role;
        this.logo = params.logo;
        this.abbreviation = params.abbreviation;
        this.project = params.project;
        this.allowToAddHolidays = params.allowToAddHolidays;
    }

    static get $name(): string {
        return "CalendarResourceModel";
    }

    static get fields(): any {
        return [
            { name: "role", type: "string" }, //
            { name: "logo", type: "string" },
            { name: "abbreviation", type: "string" },
            { name: "allowToAddHolidays", type: "boolean" },
        ];
    }
}
