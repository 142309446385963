import { useMemo } from "react";

import { ToastOptions, toast } from "react-toastify";
import { Observer, Subject } from "rxjs";

type Options = Omit<ToastOptions, "onOpen" | "onClose">;

export type NotificationsStateProps = {
    open: boolean;
    count: number;
};

type ObserverOrNext<T> = Partial<Observer<T>> | ((value: T) => void);

function createNotificationsHook() {
    const notification$ = new Subject<NotificationsStateProps>();

    let count = 0;

    return function useNotifications() {
        const notify = useMemo(() => {
            const handlers: ToastOptions = {
                onOpen: () => {
                    notification$.next({
                        open: true,
                        count: ++count,
                    });
                },
                onClose: () => {
                    count = Math.max(0, count - 1);

                    notification$.next({
                        open: count > 0,
                        count,
                    });
                },
            };

            return {
                info: (message: string, options?: Options) => {
                    toast.info(message, {
                        ...(options || {}),
                        ...handlers,
                    });
                },
                warn: (message: string, options?: Options) => {
                    toast.warn(message, {
                        ...(options || {}),
                        ...handlers,
                    });
                },
                error: (message: string, options?: Options) => {
                    toast.error(message, {
                        ...(options || {}),
                        ...handlers,
                    });
                },
            };
        }, []);

        const listeners = useMemo(
            () => ({
                onNotificationStateChange: {
                    next: (props: NotificationsStateProps) => {
                        notification$.next(props);
                    },
                    subscribe: (observerOrNext: ObserverOrNext<NotificationsStateProps>) => {
                        return notification$.subscribe(observerOrNext);
                    },
                },
            }),
            [],
        );

        return {
            notify,
            listeners,
        };
    };
}

export const useNotifications = createNotificationsHook();
