import { mlcs } from "string-comparison";

function trimLowerCase(str: string): string {
    return str.trim().toLowerCase();
}

export function createAbbreviation(name: string): string {
    return name
        .split(" ")
        .map((item: string) => item.charAt(0).toUpperCase())
        .join("");
}

export function isSimilarStrings(target: string, value: string): boolean {
    const includes = trimLowerCase(target).includes(trimLowerCase(value));

    const similarity = mlcs.similarity(target, value);

    return includes || similarity > 0.7;
}
