import { useContext, useMemo } from "react";

import {
    EventDeleteConfirmPopupOpenAction,
    EventEditPopupOpenAction,
    SchedulerPopupsContext,
} from "@INTEGRATIONS/scheduler/context/internal";

function createSchedulerPopupsHook() {
    return function useSchedulerPopups() {
        const [state, dispatch] = useContext(SchedulerPopupsContext);

        const actions = useMemo(
            () => ({
                evenEditPopupActions: {
                    open: (payload: EventEditPopupOpenAction["payload"]) => {
                        return dispatch!({
                            type: "event-edit-popup-open",
                            payload,
                        });
                    },
                    close: () => {
                        return dispatch!({
                            type: "event-edit-popup-close",
                        });
                    },
                },

                evenDeleteConfirmPopupActions: {
                    open: (payload: EventDeleteConfirmPopupOpenAction["payload"]) => {
                        return dispatch!({
                            type: "event-delete-confirm-popup-open",
                            payload,
                        });
                    },
                    close: () => {
                        return dispatch!({
                            type: "event-delete-confirm-popup-close",
                        });
                    },
                },
            }),
            [dispatch],
        );

        return {
            state,
            actions,
        };
    };
}

export const useSchedulerPopups = createSchedulerPopupsHook();
