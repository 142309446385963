import React, { Dispatch, createContext, useReducer } from "react";

import { FiltersAction, FiltersState, filtersReducer } from "@CONTEXT/reducers";

const initialState: FiltersState = {
    dates: {
        from: null,
        to: null,
    },
    resources: [],
    eventTypes: [],
    period: null,
    teamLead: null,
    search: "",
};

export const FiltersContext = createContext<
    [
        FiltersState, //
        Dispatch<FiltersAction> | null,
    ]
>([initialState, null]);

export function FiltersProvider(props: ChildrenProps) {
    const { children } = props;

    const value = useReducer(filtersReducer, initialState);

    return <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>;
}
