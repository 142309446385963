import { CancelledException } from "@CORE/exceptions";
import { AxiosError } from "axios";

export function throwApiError(error: unknown, message: string): never {
    console.error(error);

    if (error instanceof AxiosError) {
        if (error.code === "ERR_CANCELED") {
            throw new CancelledException(error.message);
        }

        const { response } = error;

        if (response?.data && "message" in response.data) {
            if ("source" in response.data) {
                const data = response.data as { message: unknown; source: unknown };

                throw new Error(message + "\n" + String(data.message) + "\nSource [" + String(data.source) + "]");
            }

            const data = response.data as { message: unknown };

            throw new Error(message + "\n" + String(data.message));
        }
    }

    if (error instanceof Error) {
        throw new Error(message + error.message);
    }

    throw new Error(message + "\nUnexpected error");
}
