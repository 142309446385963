import React from "react";

import { COLOR } from "@CORE/constants";

export function renderSlideLabel(checked: boolean) {
    return (
        <span
            style={{
                color: checked ? COLOR["dark-soft"] : COLOR["grey"],
            }}
        >
            {checked ? "Yes" : "No"}
        </span>
    );
}
