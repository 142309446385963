import { useContext, useMemo } from "react";

import { DateHelper, Model, SchedulerPro } from "@bryntum/schedulerpro";
import invariant from "tiny-invariant";
import { write } from "xlsx";

import { useAuthContext } from "@CONTEXT/hooks";

import { Period } from "@CORE/enums";

import { CustomViewPreset } from "@INTEGRATIONS/scheduler/config/presets";
import { AppTimeRangeStore } from "@INTEGRATIONS/scheduler/config/stores";
import { SchedulerInstanceContext } from "@INTEGRATIONS/scheduler/context";
import { useSchedulerListeners } from "@INTEGRATIONS/scheduler/hooks";
import {
    calendarEntryMappers,
    calendarEntryProjectMappers,
    calendarResourceMappers,
    globalBlockedPeriodMappers,
} from "@INTEGRATIONS/scheduler/mappers";
import {
    AppProjectModel,
    CalendarEntry,
    CalendarEntryModel,
    CalendarEntryNonOpeningProject,
    CalendarEntryNonOpeningProjectModel,
    CalendarEntryOpeningProject,
    CalendarEntryOpeningProjectModel,
    CalendarResource,
    CalendarResourceModel,
    EXTERNAL_TRAINER_RESOURCE_ID,
    EXTERNAL_TRAINER_RESOURCE_ROLE,
    GlobalBlockedPeriodTimeSpan,
    UNASSIGNED_RESOURCE_ID,
    UNASSIGNED_RESOURCE_ROLE,
    eventBlockedCalendar,
} from "@INTEGRATIONS/scheduler/models";
import { CalendarEntryProject, CalendarEntryTypeExtended } from "@INTEGRATIONS/scheduler/types";
import {
    formatDateTime,
    getFirstDateOfMonth,
    getFirstDayOfWeek,
    getNextDay,
    getPreviousDay,
    getStartOfDay,
} from "@INTEGRATIONS/scheduler/utils";

import { isSimilarStrings } from "@UTILS/helpers";
import { download } from "@UTILS/helpers/download.helper";

import { useLocations } from "@VIEW/hooks";

import { schedulerProConfig } from "../config";
import { createExcelDataset } from "../utils/export.utils";

function createSchedulerInstanceHook() {
    return function useSchedulerInstance() {
        const ref = useContext(SchedulerInstanceContext);

        const listeners = useSchedulerListeners();

        const getLocations = useLocations();

        const {
            state: { user },
        } = useAuthContext();

        invariant(user, "User must be specified");

        const actions = useMemo(() => {
            function getInstance() {
                return ref.current?.instance;
            }

            function mapEntries(instance: SchedulerPro, entries: CalendarEntry[]) {
                return entries.map((entry: CalendarEntry) => {
                    const formattedEntry = {
                        ...entry,
                        startDate: getStartOfDay(entry.startDate),
                        endDate: getStartOfDay(getNextDay(entry.endDate)),
                    };

                    return calendarEntryMappers.valueToModel(formattedEntry, instance);
                });
            }

            return {
                setCalendarResources: async (resources: CalendarResource[]) => {
                    const instance = getInstance();

                    if (instance) {
                        instance.resourceStore.suspendAutoCommit();
                        instance.suspendRefresh();

                        const unassignedResourceModel = calendarResourceMappers.valueToModel({
                            resource: {
                                id: UNASSIGNED_RESOURCE_ID,
                                name: "Unassigned",
                                role: UNASSIGNED_RESOURCE_ROLE,
                                logo: "",
                                abbreviation: "",
                                allowToAddHolidays: false,
                            },
                            source: instance,
                        });

                        const externalTrainerResourceModel = calendarResourceMappers.valueToModel({
                            resource: {
                                id: EXTERNAL_TRAINER_RESOURCE_ID,
                                name: "External Trainers",
                                role: EXTERNAL_TRAINER_RESOURCE_ROLE,
                                logo: "",
                                abbreviation: "",
                                allowToAddHolidays: false,
                            },
                            source: instance,
                        });

                        const resourceModels = resources.map((resource: CalendarResource) => {
                            return calendarResourceMappers.valueToModel({
                                resource,
                                source: instance,
                            });
                        });

                        instance.resourceStore.removeAll();
                        instance.resourceStore.add([
                            unassignedResourceModel,
                            externalTrainerResourceModel,
                            ...resourceModels,
                        ]);

                        await instance.resourceStore.filter({});

                        instance.resourceStore.resumeAutoCommit();
                        await instance.resumeRefresh(true);

                        await new Promise((resolve: (value: void) => void) => {
                            instance.project.on({
                                once: true,
                                dataReady: () => {
                                    instance.features.group.toggleCollapse(
                                        `group-header-${UNASSIGNED_RESOURCE_ROLE}`,
                                        true,
                                    );

                                    instance.features.group.toggleCollapse(
                                        `group-header-${EXTERNAL_TRAINER_RESOURCE_ROLE}`,
                                        true,
                                    );

                                    resolve();
                                },
                            });
                        });
                    }
                },

                setCalendarEntries: async (entries: CalendarEntry[]) => {
                    const instance = getInstance();

                    if (instance) {
                        instance.eventStore.suspendAutoCommit();
                        instance.suspendRefresh();

                        instance.eventStore.remove(
                            instance.eventStore.allRecords.filter((model: unknown) => {
                                if (model instanceof CalendarEntryModel) {
                                    return !(
                                        model.isTrainingEvent ||
                                        model.isExternalHoliday ||
                                        model.entryType === "RESOURCE_BLOCKED_PERIOD"
                                    );
                                }

                                return false;
                            }),
                        );

                        const entryModels = mapEntries(instance, entries);

                        instance.eventStore.add(entryModels);

                        await instance.eventStore.filter({});

                        instance.eventStore.resumeAutoCommit();
                        await instance.resumeRefresh(true);
                    }
                },

                setBlockedPeriods: async (entries: CalendarEntry[]) => {
                    const instance = getInstance();

                    if (instance) {
                        instance.eventStore.suspendAutoCommit();
                        instance.suspendRefresh();

                        instance.eventStore.remove(
                            instance.eventStore.allRecords.filter((model: unknown) => {
                                if (model instanceof CalendarEntryModel) {
                                    return model.entryType === "RESOURCE_BLOCKED_PERIOD";
                                }

                                return false;
                            }),
                        );

                        const entryModels = mapEntries(instance, entries);

                        instance.eventStore.add(entryModels);

                        await instance.eventStore.filter({});

                        instance.eventStore.resumeAutoCommit();
                        await instance.resumeRefresh(true);
                    }
                },

                setTrainingEvents: async (entries: CalendarEntry[]) => {
                    const instance = getInstance();

                    if (instance) {
                        instance.eventStore.suspendAutoCommit();
                        instance.suspendRefresh();

                        instance.eventStore.remove(
                            instance.eventStore.allRecords.filter((model: unknown) => {
                                if (model instanceof CalendarEntryModel) {
                                    return model.isTrainingEvent;
                                }

                                return false;
                            }),
                        );

                        const entryModels = mapEntries(instance, entries);

                        instance.eventStore.add(entryModels);

                        await instance.eventStore.filter({});

                        instance.eventStore.resumeAutoCommit();
                        await instance.resumeRefresh(true);
                    }
                },

                setPublicHolidays: async (entries: CalendarEntry[]) => {
                    const instance = getInstance();

                    if (instance) {
                        instance.eventStore.suspendAutoCommit();
                        instance.suspendRefresh();

                        instance.eventStore.remove(
                            instance.eventStore.allRecords.filter((model: unknown) => {
                                if (model instanceof CalendarEntryModel) {
                                    return model.isExternalHoliday;
                                }
                            }),
                        );

                        const entryModels = mapEntries(instance, entries);

                        instance.eventStore.add(entryModels);

                        await instance.eventStore.filter({});

                        instance.eventStore.resumeAutoCommit();
                        await instance.resumeRefresh(true);
                    }
                },

                setOpeningProjects: (projects: CalendarEntryOpeningProject[]) => {
                    const instance = getInstance();

                    if (instance && instance.project instanceof AppProjectModel) {
                        const { eventOpeningProjectStore } = instance.project;

                        eventOpeningProjectStore.suspendAutoCommit();

                        eventOpeningProjectStore.removeAll();

                        eventOpeningProjectStore.add(
                            projects.map((project: CalendarEntryOpeningProject) => {
                                return calendarEntryProjectMappers.opening.valueToModel(project);
                            }),
                        );

                        eventOpeningProjectStore.resumeAutoCommit();
                    }
                },

                setNonOpeningProjects: (projects: CalendarEntryNonOpeningProject[]) => {
                    const instance = getInstance();

                    if (instance && instance.project instanceof AppProjectModel) {
                        const { eventNonOpeningProjectStore } = instance.project;

                        eventNonOpeningProjectStore.suspendAutoCommit();

                        eventNonOpeningProjectStore.removeAll();

                        eventNonOpeningProjectStore.add(
                            projects.map((project: CalendarEntryNonOpeningProject) => {
                                return calendarEntryProjectMappers.nonOpening.valueToModel(project);
                            }),
                        );

                        eventNonOpeningProjectStore.resumeAutoCommit();
                    }
                },

                setGlobalBlockedPeriods: (spans: GlobalBlockedPeriodTimeSpan[]) => {
                    const instance = getInstance();

                    if (instance) {
                        const { timeRangeStore, resourceStore } = instance;

                        if (timeRangeStore instanceof AppTimeRangeStore) {
                            eventBlockedCalendar.clearIntervals();

                            eventBlockedCalendar.addIntervals(
                                spans.map((span: GlobalBlockedPeriodTimeSpan) => ({
                                    startDate: getStartOfDay(span.startDate),
                                    endDate: getStartOfDay(getNextDay(span.endDate)),
                                    isWorking: false,
                                    cls: "blocked-period-overlap",
                                })),
                            );

                            instance.suspendRefresh();
                            resourceStore.suspendAutoCommit();
                            timeRangeStore.suspendAutoCommit();

                            resourceStore.allRecords.forEach((model: unknown) => {
                                if (model instanceof CalendarResourceModel) {
                                    // #BLOCKED
                                    if (
                                        model.id !== UNASSIGNED_RESOURCE_ID &&
                                        model.id !== EXTERNAL_TRAINER_RESOURCE_ID
                                    ) {
                                        void model.setCalendar(eventBlockedCalendar);
                                    }
                                }
                            });

                            timeRangeStore.removeAll();

                            timeRangeStore.add(
                                spans.map((span: GlobalBlockedPeriodTimeSpan) => {
                                    const formattedSpan = {
                                        ...span,
                                        startDate: getStartOfDay(span.startDate),
                                        endDate: getStartOfDay(getNextDay(span.endDate)),
                                    };

                                    return globalBlockedPeriodMappers.valueToModel(formattedSpan);
                                }),
                            );

                            resourceStore.resumeAutoCommit();
                            timeRangeStore.resumeAutoCommit();
                            void instance.resumeRefresh(true);
                        }
                    }
                },

                filterEntriesByTypes: (types: CalendarEntryTypeExtended[]) => {
                    const instance = getInstance();

                    if (instance) {
                        void instance.eventStore.filter({
                            filters: {
                                id: "filter-by-entry-types",
                                filterBy: (model: CalendarEntryModel) => {
                                    if (types.length === 0) return true;

                                    if (model.entryType === "RESOURCE_ACTIVITY") {
                                        const { isTrainingEvent } = model;

                                        return [
                                            types.includes("RESOURCE_ACTIVITY") && !isTrainingEvent,
                                            types.includes("TRAINING") && isTrainingEvent,
                                        ].some(Boolean);
                                    }

                                    return types.includes(model.entryType);
                                },
                            },
                            replace: false,
                        });
                    }
                },

                filterByKeywords: async (text: string) => {
                    const instance = getInstance();

                    if (instance) {
                        await instance.eventStore.filter({
                            filters: {
                                id: "filter-events-by-keywords",
                                filterBy: (model: CalendarEntryModel) => {
                                    if (text.trim() === "") return true;

                                    const foundByName = isSimilarStrings(model.name, text);

                                    const foundByProjectName = model.projects.some((project: CalendarEntryProject) => {
                                        return isSimilarStrings(project.name, text);
                                    });

                                    const foundByCodes = model.projects.some((project: CalendarEntryProject) => {
                                        const lowerText = text.trim().toLowerCase();

                                        if (project instanceof CalendarEntryOpeningProjectModel) {
                                            if (project.marsha?.toLowerCase() === lowerText) {
                                                return true;
                                            }

                                            if (project.gdNumber?.toLowerCase() === lowerText) {
                                                return true;
                                            }
                                        }

                                        if (project instanceof CalendarEntryNonOpeningProjectModel) {
                                            if (project.code?.toLowerCase() === lowerText) {
                                                return true;
                                            }
                                        }

                                        return false;
                                    });

                                    const foundByLocation = model.location
                                        ? model.location?.custom !== null
                                            ? isSimilarStrings(model.location.custom, text)
                                            : isSimilarStrings(model.location.city!, text) ||
                                              isSimilarStrings(model.location.country!, text)
                                        : false;

                                    const foundByDescription = isSimilarStrings(model.description, text);

                                    return [
                                        foundByName, //
                                        foundByProjectName,
                                        foundByCodes,
                                        foundByLocation,
                                        foundByDescription,
                                    ].some(Boolean);
                                },
                            },
                            replace: false,
                        });
                    }
                },

                filterResources: async (resourceIds: (string | number)[]) => {
                    const instance = getInstance();

                    if (instance) {
                        instance.resourceStore.suspendAutoCommit();
                        instance.suspendRefresh();

                        await instance.resourceStore.filter({
                            filters: {
                                id: "filter-by-selected-resources",
                                filterBy: (model: CalendarResourceModel) => {
                                    const required =
                                        model.id === UNASSIGNED_RESOURCE_ID ||
                                        model.id === EXTERNAL_TRAINER_RESOURCE_ID;

                                    if (required) return true;

                                    if (user.role === "USER") {
                                        if (!user.departments.includes(model.role)) return false;
                                    }

                                    if (resourceIds.length === 0) return true;

                                    return resourceIds.includes(model.id);
                                },
                            },
                            replace: true,
                        });

                        instance.resourceStore.resumeAutoCommit();
                        void instance.resumeRefresh(true);
                    }
                },

                setDatesRange: (startDate: Date, endDate: Date) => {
                    const instance = getInstance();

                    const nextEndDate = getNextDay(endDate);

                    if (instance) {
                        const diff = DateHelper.diff(startDate, nextEndDate, "year");

                        if (diff > 4) {
                            instance.viewPreset = CustomViewPreset.YEARS_AND_QUARTERS;
                        } else if (diff > 2) {
                            instance.viewPreset = CustomViewPreset.YEARS_AND_MONTHS;
                        } else if (diff > 1) {
                            instance.viewPreset = CustomViewPreset.QUARTERS_AND_MONTHS;
                        } else if (diff > 1 / 2) {
                            instance.viewPreset = CustomViewPreset.QUARTERS_AND_WEEKS;
                        } else if (diff > 1 / 4) {
                            instance.viewPreset = CustomViewPreset.MONTHS_AND_WEEKS;
                        } else if (diff > 1 / 8) {
                            instance.viewPreset = CustomViewPreset.MONTHS_AND_DAYS;
                        } else {
                            instance.viewPreset = CustomViewPreset.WEEKS_AND_DAYS;
                        }

                        instance.setTimeSpan(startDate, nextEndDate);
                    }
                },

                setPeriod: (period: Period) => {
                    const instance = getInstance();

                    if (instance) {
                        instance.suspendRefresh();

                        if (period === Period.TODAY) {
                            const startDate = new Date();
                            const nextEndDate = getNextDay(startDate);

                            instance.viewPreset = CustomViewPreset.WEEKS_AND_DAYS;
                            instance.setTimeSpan(startDate, nextEndDate);
                        }

                        if (period === Period.CURRENT_WEEK) {
                            const startDate = new Date();

                            const startOfWeek = getFirstDayOfWeek(startDate);

                            instance.viewPreset = CustomViewPreset.WEEKS_AND_DAYS;
                            instance.setTimeSpan(startOfWeek);
                        }

                        if (period === Period.CURRENT_MONTH) {
                            const startDate = new Date();

                            const startOfMonth = getFirstDateOfMonth(startDate);

                            instance.viewPreset = CustomViewPreset.MONTHS_AND_DAYS;
                            instance.setTimeSpan(startOfMonth, getNextDay(DateHelper.getLastDateOfMonth(startOfMonth)));
                        }

                        if (period === Period.NEXT_4_WEEKS) {
                            const startDate = new Date();
                            const endDate = DateHelper.add(startDate, 27, "day");

                            instance.viewPreset = CustomViewPreset.MONTHS_AND_DAYS;
                            instance.setTimeSpan(startDate, endDate);
                        }

                        if (period === Period.WEEK) {
                            const { startDate } = instance;

                            const startOfWeek = getFirstDayOfWeek(startDate);
                            const endOfWeek = DateHelper.getNext(startOfWeek, "week", 1, 1);

                            instance.viewPreset = CustomViewPreset.WEEKS_AND_DAYS;
                            instance.setTimeSpan(startOfWeek, endOfWeek);
                        }

                        if (period === Period.MONTH) {
                            const { startDate } = instance;

                            const startOfMonth = getFirstDateOfMonth(startDate);

                            instance.viewPreset = CustomViewPreset.MONTHS_AND_DAYS;
                            instance.setTimeSpan(startOfMonth, getNextDay(DateHelper.getLastDateOfMonth(startOfMonth)));
                        }

                        void instance.resumeRefresh(true);
                    }
                },

                zoomIn: () => {
                    const instance = getInstance();

                    instance?.zoomIn();
                },

                zoomOut: () => {
                    const instance = getInstance();

                    instance?.zoomOut();
                },

                prevRange: (period: Period) => {
                    const instance = getInstance();

                    if (instance) {
                        const { startDate } = instance;

                        if (period === Period.TODAY) {
                            const prevDay = getPreviousDay(startDate);

                            instance.setTimeSpan(prevDay, startDate);

                            listeners.onDatesRangeTranslate.next({
                                startDate: prevDay,
                                endDate: getPreviousDay(startDate),
                            });
                        }

                        if (period === Period.NEXT_4_WEEKS) {
                            const prev4WeeksEnd = startDate;
                            const prev4WeeksStart = DateHelper.add(prev4WeeksEnd, -28, "day");

                            instance.setTimeSpan(prev4WeeksStart, prev4WeeksEnd);

                            listeners.onDatesRangeTranslate.next({
                                startDate: prev4WeeksStart,
                                endDate: getPreviousDay(prev4WeeksEnd),
                            });
                        }

                        if (period === Period.WEEK || period === Period.CURRENT_WEEK) {
                            const startOfPrevWeek = DateHelper.getNext(getFirstDayOfWeek(startDate), "week", -1, 1);
                            const endOfPrevWeek = DateHelper.add(startOfPrevWeek, 1, "week");

                            instance.setTimeSpan(startOfPrevWeek, endOfPrevWeek);

                            listeners.onDatesRangeTranslate.next({
                                startDate: startOfPrevWeek,
                                endDate: getPreviousDay(endOfPrevWeek),
                            });
                        }

                        if (period === Period.MONTH || period === Period.CURRENT_MONTH) {
                            const startOfPrevMonth = DateHelper.getNext(getFirstDateOfMonth(startDate), "month", -1);
                            const endOfPrevMonth = DateHelper.getLastDateOfMonth(startOfPrevMonth);

                            instance.setTimeSpan(startOfPrevMonth, getNextDay(endOfPrevMonth));

                            listeners.onDatesRangeTranslate.next({
                                startDate: startOfPrevMonth,
                                endDate: endOfPrevMonth,
                            });
                        }
                    }
                },

                nextRange: (period: Period) => {
                    const instance = getInstance();

                    if (instance) {
                        const { startDate, endDate } = instance;

                        if (period === Period.TODAY) {
                            const nextDay = getNextDay(startDate);
                            const nextDayAfter = getNextDay(nextDay);

                            instance.setTimeSpan(nextDay, nextDayAfter);

                            listeners.onDatesRangeTranslate.next({
                                startDate: nextDay,
                                endDate: getPreviousDay(nextDayAfter),
                            });
                        }

                        if (period === Period.NEXT_4_WEEKS) {
                            const next4WeeksStart = endDate;
                            const next4WeeksEnd = DateHelper.add(next4WeeksStart, 28, "day");

                            instance.setTimeSpan(next4WeeksStart, next4WeeksEnd);

                            listeners.onDatesRangeTranslate.next({
                                startDate: next4WeeksStart,
                                endDate: getPreviousDay(next4WeeksEnd),
                            });
                        }

                        if (period === Period.WEEK || period === Period.CURRENT_WEEK) {
                            const startOfNextWeek = DateHelper.getNext(getFirstDayOfWeek(startDate), "week", 1, 1);
                            const endOfNextWeek = DateHelper.add(startOfNextWeek, 1, "week");

                            instance.setTimeSpan(startOfNextWeek, endOfNextWeek);

                            listeners.onDatesRangeTranslate.next({
                                startDate: startOfNextWeek,
                                endDate: getPreviousDay(endOfNextWeek),
                            });
                        }

                        if (period === Period.MONTH || period === Period.CURRENT_MONTH) {
                            const startOfNextMonth = DateHelper.getNext(getFirstDateOfMonth(startDate), "month", 1);
                            const endOfNextMonth = DateHelper.getLastDateOfMonth(startOfNextMonth);

                            instance.setTimeSpan(startOfNextMonth, getNextDay(endOfNextMonth));

                            listeners.onDatesRangeTranslate.next({
                                startDate: startOfNextMonth,
                                endDate: endOfNextMonth,
                            });
                        }
                    }
                },
            };
        }, [ref, listeners, user]);

        const services = useMemo(() => {
            function getInstance() {
                return ref.current?.instance;
            }

            return {
                getInstance,

                getDatesRange: () => {
                    const instance = getInstance();

                    return {
                        startDate: instance?.startDate || null,
                        endDate: instance?.endDate ? getPreviousDay(instance.endDate) : null,
                    };
                },

                getOpeningProjects: () => {
                    const instance = getInstance();

                    if (instance && instance.project instanceof AppProjectModel) {
                        const { eventOpeningProjectStore } = instance.project;

                        return eventOpeningProjectStore.allRecords.map((model: Model) => {
                            return calendarEntryProjectMappers.opening.modelToValue(
                                model as CalendarEntryOpeningProjectModel,
                            );
                        });
                    }

                    return [];
                },

                getNonOpeningProjects: () => {
                    const instance = getInstance();

                    if (instance && instance.project instanceof AppProjectModel) {
                        const { eventNonOpeningProjectStore } = instance.project;

                        return eventNonOpeningProjectStore.records.map((model: Model) => {
                            return calendarEntryProjectMappers.nonOpening.modelToValue(
                                model as CalendarEntryNonOpeningProjectModel,
                            );
                        });
                    }

                    return [];
                },

                getLocations: async (query: string) => {
                    const data = await getLocations(query);

                    return data || [];
                },

                getCurrentUser: () => {
                    return user;
                },

                checkZoomEnd: (side: "in" | "out") => {
                    const instance = getInstance();

                    if (instance) {
                        const { zoomLevel, maxZoomLevel, minZoomLevel } = instance;

                        if (side === "in") {
                            return zoomLevel === maxZoomLevel;
                        }

                        if (side === "out") {
                            return zoomLevel === minZoomLevel;
                        }
                    }

                    return true;
                },

                exportPDF: async () => {
                    const instance = getInstance();

                    if (instance) {
                        try {
                            instance.features.pdfExport.setConfig({
                                ...schedulerProConfig.pdfExportFeature,
                                fileName: `ResourceSchedulerData_${formatDateTime(new Date())}`,
                            });

                            await instance.features.pdfExport.export({
                                columns: ["resource-column"],
                            });
                        } catch (e: unknown) {
                            //
                        }
                    }
                },

                exportExcel: () => {
                    const instance = getInstance();

                    if (instance) {
                        const workbook = createExcelDataset({
                            resources: instance.resourceStore.allRecords as CalendarResourceModel[],
                            entries: instance.eventStore.records as CalendarEntryModel[],
                            startDate: instance.startDate,
                            endDate: instance.endDate,
                        });

                        const excelBuffer: BlobPart = write(workbook, { bookType: "xlsx", type: "array" }) as BlobPart;

                        download(
                            excelBuffer,
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                            `ResourceSchedulerData_${formatDateTime(new Date())}.xlsx`,
                        );
                    }
                },
            };
        }, [ref, getLocations, user]);

        return {
            ref,
            actions,
            services,
        };
    };
}

export const useSchedulerInstance = createSchedulerInstanceHook();
