import React from "react";

import { BarLoader } from "react-spinners";

function BarSpinner(props: Props) {
    const { color } = props;

    return (
        <BarLoader
            color={color}
            height={2}
            width="100%"
        />
    );
}

interface Props {
    color: React.CSSProperties["color"];
}

export default React.memo(BarSpinner);
