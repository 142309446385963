import React from "react";

import { IconProps } from "@CORE/interfaces";

function BlockIcon({
    size = defaultProps.size, //
    color = defaultProps.color,
}: IconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.8889 7.5H3.11111C2.49746 7.5 2 7.98842 2 8.59091V12.4091C2 13.0116 2.49746 13.5 3.11111 13.5H10.8889C11.5025 13.5 12 13.0116 12 12.4091V8.59091C12 7.98842 11.5025 7.5 10.8889 7.5Z"
                fill={color}
            />
            <path
                d="M4 7.5V5.27778C4 4.54107 4.31607 3.83453 4.87868 3.31359C5.44129 2.79266 6.20435 2.5 7 2.5C7.79565 2.5 8.55871 2.79266 9.12132 3.31359C9.68393 3.83453 10 4.54107 10 5.27778V7.5"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const defaultProps = {
    size: "1em",
    color: "currentColor",
};

export default BlockIcon;
