import { Model, Store, StoreConfig } from "@bryntum/schedulerpro";

import { CalendarEntryNonOpeningProjectModel } from "@INTEGRATIONS/scheduler/models";

export class AppEventNonOpeningProjectStore extends Store {
    constructor(params: Partial<StoreConfig>) {
        super(params);
    }

    add(
        records: CalendarEntryNonOpeningProjectModel | CalendarEntryNonOpeningProjectModel[], //
        silent?: boolean,
    ): Model[] {
        return super.add(records, silent);
    }
}

export const appEventNonOpeningProjectStore = new AppEventNonOpeningProjectStore({
    modelClass: CalendarEntryNonOpeningProjectModel,
    data: [],
});
