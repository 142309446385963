import { AxiosResponse } from "axios";
import { catchError, from, lastValueFrom, map } from "rxjs";

import { BaseApi } from "@API/base";

import { throwApiError } from "@UTILS/helpers";

type LocationDataItem = {
    city: string;
    country: string;
};

export type LocationApiModel = {
    city: string;
    country: string;
};

export class LocationsApi extends BaseApi {
    async find(params?: { query: string }, signal?: AbortSignal): Promise<LocationApiModel[]> {
        const { query } = params || {};
        const urlParams = new URLSearchParams();

        if (query) {
            urlParams.append("filter", `Contains([City], "${query}") or Contains([Country], "${query}")`);
        }

        return lastValueFrom<LocationApiModel[]>(
            from(
                this.client.get(`/City/select.json?${urlParams.toString()}`, {
                    signal,
                }),
            )
                .pipe(
                    map((response: AxiosResponse<LocationDataItem[]>) =>
                        response.data.map((item: LocationDataItem) => {
                            return {
                                city: item.city,
                                country: item.country,
                            };
                        }),
                    ),
                )
                .pipe(
                    catchError((error: unknown) => {
                        throwApiError(error, "Can't get locations: ");
                    }),
                ),
        );
    }
}
