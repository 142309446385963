import { SchedulerPro, SchedulerResourceModel } from "@bryntum/schedulerpro";

import { locationMappers } from "@INTEGRATIONS/scheduler/mappers";
import {
    AppProjectModel,
    CalendarEntry,
    CalendarEntryModel,
    CalendarResourceModel,
    EXTERNAL_TRAINER_RESOURCE_ID,
    UNASSIGNED_RESOURCE_ID,
} from "@INTEGRATIONS/scheduler/models";
import {
    CalendarEntryProject,
    CalendarEntryType,
    ProjectType,
    TrainingTrainerType,
} from "@INTEGRATIONS/scheduler/types";

type EncodeIdParams = {
    id: string | number;
    entryType: CalendarEntryType;
    projectType: ProjectType;
};

type DecodeIdParams = {
    id: string | number;
};

function encodeId({
    id, //
    entryType,
    projectType,
}: EncodeIdParams) {
    return `${entryType}_${projectType}_##_${id}`;
}

function decodeId({
    id, //
}: DecodeIdParams) {
    return id.toString().replace(/^.+##_/, "");
}

export const calendarEntryMappers = {
    valueToModel(entry: CalendarEntry, source: SchedulerPro): CalendarEntryModel {
        const { resourceIds, projectIds } = entry;

        source.assignmentStore.suspendAutoCommit();
        source.suspendRefresh();

        const isExternal = [
            entry.entryType === "RESOURCE_ACTIVITY", //
            entry.isTrainingEvent,
            entry.trainingTrainerType === TrainingTrainerType.EXTERNAL,
        ].every(Boolean);

        const isUnassigned = [
            resourceIds.length === 0, //
            [
                entry.entryType === "RESOURCE_ACTIVITY",
                entry.entryType === "HOLIDAYS" && entry.isCreatedFromSchedulerHoliday,
            ].some(Boolean),
        ].every(Boolean);

        const entryModel = new CalendarEntryModel({
            id: encodeId({
                id: entry.id,
                entryType: entry.entryType,
                projectType: entry.projectType,
            }),
            name: entry.name,
            startDate: entry.startDate,
            endDate: entry.endDate,
            entryType: entry.entryType,
            projectType: entry.projectType,
            location: entry.location ? locationMappers.valueToModel(entry.location) : null,
            description: entry.description,
            isTentative: entry.isTrainingEvent ? true : entry.isTentative,
            isUnassigned: isExternal ? false : isUnassigned,
            url: entry.url,

            // holiday
            isExternalHoliday: entry.isExternalHoliday,
            isCreatedFromSchedulerHoliday: entry.isCreatedFromSchedulerHoliday,

            // travel
            travelDestination: entry.travelDestination,

            // opening
            projectMarsha: entry.projectMarsha,
            gdProjectId: entry.gdProjectId,

            // training
            isTrainingEvent: entry.isTrainingEvent,
            trainingLocation: entry.trainingLocation,
            trainingTrainerType: entry.trainingTrainerType,
            trainingTrainerName: entry.trainingTrainerName,
            trainingTrainerEmail: entry.trainingTrainerEmail,
            trainingIsStatusConfirmed: entry.trainingIsStatusConfirmed,
            trainingEventType: entry.trainingEventType,
            trainingEventSubType: entry.trainingEventSubType,
            trainingResourceUniqueId: entry.trainingResourceUniqueId,

            // blocked
            blockedDisallowRoles: entry.blockedDisallowRoles,
            blockedDisallowUsers: entry.blockedDisallowUsers,
        });

        if (source.project instanceof AppProjectModel) {
            const { eventOpeningProjectStore, eventNonOpeningProjectStore } = source.project;

            const projects = projectIds
                .map((id: string | number) => {
                    if (entry.projectType === ProjectType.OPENING || entry.projectType === ProjectType.TRAINING_EVENT) {
                        return eventOpeningProjectStore.getById(id);
                    }

                    return eventNonOpeningProjectStore.getById(id);
                })
                .filter(Boolean);

            entryModel.update("projects", projects);
        }

        switch (true) {
            case isExternal: {
                source.eventStore.assignEventToResource(entryModel, EXTERNAL_TRAINER_RESOURCE_ID, true);

                break;
            }

            case isUnassigned: {
                source.eventStore.assignEventToResource(entryModel, UNASSIGNED_RESOURCE_ID, true);

                break;
            }

            default: {
                const resourceModels = resourceIds
                    .map((id: string | number) => {
                        return source.resourceStore.getById(id) as CalendarResourceModel;
                    })
                    .filter(Boolean);

                source.eventStore.assignEventToResource(entryModel, resourceModels, true);
            }
        }

        source.assignmentStore.resumeAutoCommit();
        void source.resumeRefresh(true);

        return entryModel;
    },
    modelToValue(entryModel: CalendarEntryModel): CalendarEntry {
        const resourceIds = entryModel.resources
            .filter((resourceRecord: SchedulerResourceModel) => {
                return ![
                    UNASSIGNED_RESOURCE_ID, //
                    EXTERNAL_TRAINER_RESOURCE_ID,
                ].includes(resourceRecord.id.toString());
            })
            .map((resourceRecord: SchedulerResourceModel) => {
                return resourceRecord.id;
            });

        const projectIds = entryModel.projects.map((project: CalendarEntryProject) => project.id);

        return {
            id: decodeId({
                id: entryModel.id,
            }),
            resourceIds,
            name: entryModel.name,
            startDate: entryModel.startDate,
            endDate: entryModel.endDate,
            entryType: entryModel.entryType,
            projectType: entryModel.projectType,
            projectIds,
            location: entryModel.location ? locationMappers.modelToValue(entryModel.location) : null,
            description: entryModel.description,
            isTentative: entryModel.isTrainingEvent ? true : entryModel.isTentative,
            url: entryModel.url,

            // holiday
            isExternalHoliday: entryModel.isExternalHoliday,
            isCreatedFromSchedulerHoliday: entryModel.isCreatedFromSchedulerHoliday,

            // travel
            travelDestination: entryModel.travelDestination,

            // opening
            projectMarsha: entryModel.projectMarsha,
            gdProjectId: entryModel.gdProjectId,

            // training
            isTrainingEvent: entryModel.isTrainingEvent,
            trainingLocation: entryModel.trainingLocation,
            trainingTrainerType: entryModel.trainingTrainerType,
            trainingTrainerName: entryModel.trainingTrainerName,
            trainingTrainerEmail: entryModel.trainingTrainerEmail,
            trainingIsStatusConfirmed: entryModel.trainingIsStatusConfirmed,
            trainingEventType: entryModel.trainingEventType,
            trainingEventSubType: entryModel.trainingEventSubType,
            trainingResourceUniqueId: entryModel.trainingResourceUniqueId,

            // blocked
            blockedDisallowRoles: entryModel.blockedDisallowRoles,
            blockedDisallowUsers: entryModel.blockedDisallowUsers,
        };
    },
} as const;
