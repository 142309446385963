import { DateArray, EventAttributes, createEvent } from "ics";

function getDateArray(date: Date): DateArray {
    return [date.getFullYear(), date.getMonth() + 1, date.getDate()];
}

type Params = {
    title: string;
    start: Date;
    end: Date;
} & Omit<EventAttributes, "start" | "end">;

export async function generateICSFile(params: Params): Promise<File> {
    const filename = `${params.title || "Event"}.ics`;

    return new Promise<File>((resolve: (value: File) => void, reject: (reason?: any) => void) => {
        const attributes = {
            ...params,
            start: getDateArray(params.start),
            end: getDateArray(params.end),
        };

        createEvent(attributes, (error: Error | undefined, value: string) => {
            if (error) {
                reject(error);
            }

            resolve(new File([value], filename, { type: "text/calendar" }));
        });
    });
}
