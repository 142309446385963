import React from "react";

import { IconProps } from "@CORE/interfaces";

function CutIcon({
    size = defaultProps.size, //
    color = defaultProps.color,
}: IconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g>
                <path
                    d="M5.625 9.75C7.07475 9.75 8.25 8.57475 8.25 7.125C8.25 5.67525 7.07475 4.5 5.625 4.5C4.17525 4.5 3 5.67525 3 7.125C3 8.57475 4.17525 9.75 5.625 9.75Z"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.625 19.5C7.07475 19.5 8.25 18.3247 8.25 16.875C8.25 15.4253 7.07475 14.25 5.625 14.25C4.17525 14.25 3 15.4253 3 16.875C3 18.3247 4.17525 19.5 5.625 19.5Z"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.7499 12L7.7915 15.3928"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M21.7498 5.84253L15.4058 10.1822"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M21.7499 18.1575L7.7915 8.60718"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}

const defaultProps = {
    size: "1em",
    color: "currentColor",
};

export default CutIcon;
