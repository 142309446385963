import { HolidayApiModel, ResourceApiModel } from "@API/services";

import { CalendarEntry } from "@INTEGRATIONS/scheduler/models";
import { ProjectType } from "@INTEGRATIONS/scheduler/types";

export const holidaysApiMappers = {
    apiToValues: (holidays: HolidayApiModel[], provincesResourcesMap: Map<string, ResourceApiModel[]>): CalendarEntry[] => {
        return holidays.map((holiday: HolidayApiModel) => ({
            id: holiday.id,
            name: holiday.name,
            entryType: "HOLIDAYS",
            projectType: ProjectType.NOT_PROJECT_RELATED,
            startDate: holiday.startDate,
            endDate: holiday.endDate,
            resourceIds:
                provincesResourcesMap.get(holiday.provinceCode)?.map((resource: ResourceApiModel) => resource.id) || [],
            projectIds: [],
            location: null,
            url: "",
            description: "",
            isTentative: false,

            // holiday
            isExternalHoliday: true,
            isCreatedFromSchedulerHoliday: false,

            // travel
            travelDestination: null,

            // opening
            projectMarsha: null,
            gdProjectId: null,

            // training
            isTrainingEvent: false,
            trainingLocation: "",
            trainingTrainerType: null,
            trainingTrainerName: "",
            trainingTrainerEmail: "",
            trainingIsStatusConfirmed: false,
            trainingEventType: null,
            trainingEventSubType: null,
            trainingResourceUniqueId: null,

            // blocked
            blockedDisallowRoles: null,
            blockedDisallowUsers: null,
        }));
    },
};
