import { ResourceApiModel, BlockedPeriodApiModel } from "@API/services";

import { CalendarEntry, GlobalBlockedPeriodTimeSpan } from "@INTEGRATIONS/scheduler/models";
import { ProjectType } from "@INTEGRATIONS/scheduler/types";

type EveryoneResourceBlockedPeriod = Omit<BlockedPeriodApiModel, "disallowRoles"> & {
    disallowRoles: "EVERYONE";
};

type NonEveryoneResourceBlockedPeriod = Omit<BlockedPeriodApiModel, "disallowRoles"> & {
    disallowRoles: string[] | null;
};

export const blockedPeriodsApiMappers = {
    apiToValues: (blockedPeriods: BlockedPeriodApiModel[], resources: ResourceApiModel[]): CalendarEntry[] => {
        return blockedPeriods
            .filter((item: BlockedPeriodApiModel): item is NonEveryoneResourceBlockedPeriod => {
                return item.disallowRoles !== "EVERYONE";
            })
            .map((item: NonEveryoneResourceBlockedPeriod) => {
                const { disallowRoles, disallowUsers } = item;

                const resourceIdsSet = new Set<string | number>();

                if (disallowRoles !== null) {
                    resources
                        .filter((resource: ResourceApiModel) => {
                            return disallowRoles.includes(resource.role);
                        })
                        .forEach((resource: ResourceApiModel) => {
                            resourceIdsSet.add(resource.id);
                        });
                }

                if (disallowUsers !== null) {
                    resources
                        .filter((resource: ResourceApiModel) => {
                            return disallowUsers.includes(resource.fullName);
                        })
                        .forEach((resource: ResourceApiModel) => {
                            resourceIdsSet.add(resource.id);
                        });
                }

                const resourceIds = Array.from(resourceIdsSet);

                if (resourceIds.length === 0) return null;

                return {
                    id: item.id,
                    projectType: ProjectType.NOT_PROJECT_RELATED,
                    name: "",
                    resourceIds,
                    startDate: item.startDate,
                    endDate: item.endDate,
                    entryType: "RESOURCE_BLOCKED_PERIOD" as const,
                    description: item.description,
                    projectIds: [],
                    isTentative: false,
                    location: null,
                    url: null,

                    // holiday
                    isExternalHoliday: false,
                    isCreatedFromSchedulerHoliday: false,

                    // travel
                    travelDestination: null,

                    // opening
                    projectMarsha: null,
                    gdProjectId: null,

                    // training
                    isTrainingEvent: false,
                    trainingLocation: "",
                    trainingTrainerType: null,
                    trainingTrainerName: "",
                    trainingTrainerEmail: "",
                    trainingIsStatusConfirmed: false,
                    trainingEventType: null,
                    trainingEventSubType: null,
                    trainingResourceUniqueId: null,

                    // blocked
                    blockedDisallowRoles: disallowRoles,
                    blockedDisallowUsers: disallowUsers,
                } satisfies CalendarEntry;
            })
            .filter(Boolean);
    },
};

export const globalBlockedPeriodsApiMappers = {
    apiToValues: (blockedPeriods: BlockedPeriodApiModel[]): GlobalBlockedPeriodTimeSpan[] => {
        return blockedPeriods
            .filter((item: BlockedPeriodApiModel): item is EveryoneResourceBlockedPeriod => {
                return item.disallowRoles == "EVERYONE";
            })
            .map((item: BlockedPeriodApiModel) => ({
                name: item.description,
                startDate: item.startDate,
                endDate: item.endDate,
            }));
    },
};
