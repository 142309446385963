import React, { RefObject, useMemo, useRef, useState } from "react";

import classnames from "classnames/bind";

import { BasicEventForm, TrainingEventForm } from "@INTEGRATIONS/scheduler/components/forms";
import HolidayEventForm from "@INTEGRATIONS/scheduler/components/forms/forms/HolidayEventForm";
import { BasePopup } from "@INTEGRATIONS/scheduler/components/popups";
import { useSchedulerInstance } from "@INTEGRATIONS/scheduler/hooks";
import { useSchedulerPopups } from "@INTEGRATIONS/scheduler/hooks/internal";
import { EXTERNAL_TRAINER_RESOURCE_ID, UNASSIGNED_RESOURCE_ID } from "@INTEGRATIONS/scheduler/models";
import { ProjectType, TrainingTrainerType } from "@INTEGRATIONS/scheduler/types";

import ProjectTypeSelect from "@VIEW/components/basic/inputs/select/ProjectTypeSelect";

import styles from "./EventEditPopup.module.scss";

const cx: CX = classnames.bind(styles);

type ProjectTypeOption = {
    value: ProjectType;
    label: string;
    disabled: boolean;
};

const NA = "(N/A for current activity)";

const OPENING_____________1 = {
    value: ProjectType.OPENING,
    label: "Opening Project",
    disabled: false,
};

const OPENING_____________0 = {
    value: ProjectType.OPENING,
    label: `Opening Project ${NA}`,
    disabled: true,
};

const NON_OPENING_________1 = {
    value: ProjectType.NON_OPENING,
    label: "Non-Opening Project",
    disabled: false,
};

const NON_OPENING_________0 = {
    value: ProjectType.NON_OPENING,
    label: `Non-Opening Project ${NA}`,
    disabled: true,
};

const NOT_PROJECT_RELATED_1 = {
    value: ProjectType.NOT_PROJECT_RELATED,
    label: "Not Project Related",
    disabled: false,
};

const NOT_PROJECT_RELATED_0 = {
    value: ProjectType.NOT_PROJECT_RELATED,
    label: `Not Project Related ${NA}`,
    disabled: true,
};

const TRAINING_EVENT______1 = {
    value: ProjectType.TRAINING_EVENT,
    label: "Training Event",
    disabled: false,
};

const TRAINING_EVENT______0 = {
    value: ProjectType.TRAINING_EVENT,
    label: `Training Event ${NA}`,
    disabled: true,
};

const HOLIDAY_____________1 = {
    value: ProjectType.HOLIDAY,
    label: "Holiday",
    disabled: false,
};

const HOLIDAY_____________0 = {
    value: ProjectType.HOLIDAY,
    label: `Holiday ${NA}`,
    disabled: true,
};

function EventEditPopup() {
    const {
        state: {
            eventEditPopupState: { data },
        },
        actions: { evenEditPopupActions },
    } = useSchedulerPopups();

    const { services } = useSchedulerInstance();

    const formRef: RefObject<{
        confirmCancel: () => void;
    }> = useRef(null);

    const {
        eventRecord, //
        resourceRecord,
    } = data!;

    const user = services.getCurrentUser();

    const projectTypes = useMemo(() => {
        if (resourceRecord.id === EXTERNAL_TRAINER_RESOURCE_ID) {
            return [
                OPENING_____________0,
                NON_OPENING_________0,
                NOT_PROJECT_RELATED_0,
                TRAINING_EVENT______1,
                HOLIDAY_____________0,
            ];
        }

        if (eventRecord.isCreating) {
            return [
                OPENING_____________1,
                NON_OPENING_________1,
                NOT_PROJECT_RELATED_1,
                TRAINING_EVENT______1,
                HOLIDAY_____________1,
            ];
        }

        if (eventRecord.isTrainingEvent) {
            return [
                OPENING_____________0,
                NON_OPENING_________0,
                NOT_PROJECT_RELATED_0,
                TRAINING_EVENT______1,
                HOLIDAY_____________0,
            ];
        }

        return [
            OPENING_____________1,
            NON_OPENING_________1,
            NOT_PROJECT_RELATED_1,
            TRAINING_EVENT______0,
            HOLIDAY_____________1,
        ];
    }, [eventRecord, resourceRecord]);

    const defaultProjectType = useMemo(() => {
        return (
            projectTypes.find((p: ProjectTypeOption) => p.value === eventRecord.projectType && !p.disabled) ||
            projectTypes.find((p: ProjectTypeOption) => !p.disabled)!
        );
    }, [eventRecord, projectTypes]);

    const defaultTrainerType = useMemo(() => {
        const { trainingTrainerType } = eventRecord;

        if (trainingTrainerType !== null) return trainingTrainerType;

        if (eventRecord.isCreating) {
            if (resourceRecord.id === EXTERNAL_TRAINER_RESOURCE_ID) {
                return TrainingTrainerType.EXTERNAL;
            }

            if (resourceRecord.id !== UNASSIGNED_RESOURCE_ID) {
                return TrainingTrainerType.INTERNAL;
            }
        }

        return null;
    }, [eventRecord, resourceRecord]);

    const [projectType, setProjectType] = useState<ProjectTypeOption>(defaultProjectType);

    return (
        <BasePopup
            onClose={() => {
                formRef.current?.confirmCancel();
            }}
        >
            <div className={cx("event-edit-popup")}>
                <div className={cx("project-type-wrapper")}>
                    <ProjectTypeSelect
                        projectTypeOptions={projectTypes}
                        value={projectType.value}
                        onChange={(option: ProjectTypeOption | null) => {
                            if (option !== null) {
                                setProjectType(option);
                            }
                        }}
                    />
                </div>

                {projectType.value === ProjectType.TRAINING_EVENT ? (
                    <TrainingEventForm
                        ref={formRef}
                        eventRecord={eventRecord}
                        defaultTrainerType={defaultTrainerType}
                        user={user}
                        onClose={() => {
                            evenEditPopupActions.close();
                        }}
                    />
                ) : projectType.value === ProjectType.HOLIDAY ? (
                    <HolidayEventForm
                        ref={formRef}
                        eventRecord={eventRecord}
                        user={user}
                        onClose={() => {
                            evenEditPopupActions.close();
                        }}
                    />
                ) : (
                    <BasicEventForm
                        ref={formRef}
                        eventRecord={eventRecord}
                        projectType={projectType}
                        user={user}
                        onClose={() => {
                            evenEditPopupActions.close();
                        }}
                    />
                )}
            </div>
        </BasePopup>
    );
}

export default EventEditPopup;
