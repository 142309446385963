import { UserApiModel } from "@API/services";

export const AUTH_SET_USER_ACTION_TYPE = "AUTH_SET_USER_ACTION_TYPE";
export const AUTH_CLEAR_USER_ACTION_TYPE = "AUTH_CLEAR_USER_ACTION_TYPE";

export interface AuthSetUserAction {
    type: typeof AUTH_SET_USER_ACTION_TYPE;
    payload: UserApiModel;
}

export interface AuthClearUserAction {
    type: typeof AUTH_CLEAR_USER_ACTION_TYPE;
}

/**
 *  Auth set user action creator
 */
export function authSetUser(payload: AuthSetUserAction["payload"]): AuthSetUserAction {
    return {
        type: AUTH_SET_USER_ACTION_TYPE,
        payload,
    };
}

/**
 *  Auth clear user action creator
 */
export function authClearUser(): AuthClearUserAction {
    return {
        type: AUTH_CLEAR_USER_ACTION_TYPE,
    };
}
