import React from "react";

import ReactDOMServer from "react-dom/server";

import { EventDrag, EventDragConfig } from "@bryntum/schedulerpro";

import { EventTimeRangeTooltipView } from "@INTEGRATIONS/scheduler/components/common";
import { AppTimeRangeStore } from "@INTEGRATIONS/scheduler/config/stores";
import {
    CalendarEntryModel,
    CalendarResourceModel,
    EXTERNAL_TRAINER_RESOURCE_ID,
    UNASSIGNED_RESOURCE_ID,
} from "@INTEGRATIONS/scheduler/models";
import { TrainingTrainerType } from "@INTEGRATIONS/scheduler/types";
import { formatDate, getPreviousDay } from "@INTEGRATIONS/scheduler/utils";

export const eventDragFeature = {
    tip: {
        header: false,
        anchor: false,
        hoverDelay: 0,
        hideDelay: 0,
        margin: -2,
    },
    showExactDropPosition: true,
    tooltipTemplate(original: {
        startDate: Date; //
        endDate: Date;
        valid: boolean;
        message: string;
    }) {
        const startDate = formatDate(original.startDate);
        const endDate = formatDate(getPreviousDay(original.endDate));

        return ReactDOMServer.renderToString(
            <EventTimeRangeTooltipView
                startDate={startDate}
                endDate={endDate}
                isInvalid={!original.valid}
                message={original.message}
            />,
        );
    },
    validatorFn(original: {
        startDate: Date; //
        endDate: Date;
        eventRecord: CalendarEntryModel;
        newResource: CalendarResourceModel;
    }) {
        // #BLOCKED

        // allow specific resources for custom holidays
        if (
            [
                original.eventRecord.entryType === "HOLIDAYS", //
                original.eventRecord.isCreatedFromSchedulerHoliday,
            ].every(Boolean)
        ) {
            if (original.newResource.allowToAddHolidays) {
                return true;
            } else {
                if (original.newResource.id === UNASSIGNED_RESOURCE_ID) {
                    return {
                        valid: false,
                        message: "Holiday is not allowed to be unassigned",
                    };
                }

                if (original.newResource.id !== EXTERNAL_TRAINER_RESOURCE_ID) {
                    return {
                        valid: false,
                        message: "Holiday is not allowed for this user",
                    };
                }
            }
        }

        // allow unassigned
        if (
            [
                UNASSIGNED_RESOURCE_ID, //
            ].includes(original.newResource.id.toString())
        ) {
            return true;
        }

        // allow external trainers only inside "External Trainers" resource
        if (original.newResource.id === EXTERNAL_TRAINER_RESOURCE_ID) {
            if (original.eventRecord.trainingTrainerType !== TrainingTrainerType.EXTERNAL) {
                return {
                    valid: false,
                    message: "Can't move calendar entry to External Trainers",
                };
            } else {
                return true;
            }
        }

        // allow outside blocked periods
        const { timeRangeStore } = original.eventRecord.project;

        if (timeRangeStore instanceof AppTimeRangeStore) {
            const isIntersect = timeRangeStore.isIntersectBlockedPeriods(
                original.startDate, //
                original.endDate,
            );

            if (isIntersect) {
                return {
                    valid: false,
                    message: "Calendar entry overlaps blocked period",
                };
            }
        }
    },
} satisfies Partial<EventDragConfig> | EventDrag;
