import React, { useId } from "react";

import classnames from "classnames/bind";

import { COLOR } from "@CORE/constants";

import styles from "./BasicTextarea.module.scss";


const cx: CX = classnames.bind(styles);

function ResizeHandlerIcon() {
    return (
        <svg
            width="11"
            height="11"
            viewBox="0 0 11 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11 5V1.27324C11 0.40313 9.96568 -0.0518703 9.32428 0.536081L0.895078 8.26285C0.222787 8.87911 0.658793 10 1.5708 10H6C8.76142 10 11 7.76142 11 5Z"
                fill={COLOR["grey-light"]}
            />
        </svg>
    );
}

function BasicTextarea({
    label = defaultProps.label, //
    value,
    disabled,
    minHeight = defaultProps.minHeight,
    maxHeight = defaultProps.maxHeight,
    type = defaultProps.type,
    onChange,
    ...attributes
}: Props) {
    const id = useId();

    function handleChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        if (!disabled) {
            onChange(event.target.value, event);
        }
    }

    return (
        <div className={cx("basic-textarea")}>
            <label htmlFor={id}>
                {label && <div className={cx("input-label")}>{label}</div>}

                <textarea
                    id={id}
                    value={value}
                    disabled={disabled}
                    onChange={handleChange}
                    className={cx({
                        error: type === "error",
                    })}
                    style={{
                        minHeight,
                        maxHeight,
                    }}
                    {...attributes}
                />
                <div className={cx("custom-resizer-container")}>
                    <div className={cx("icon-wrapper")}>
                        <ResizeHandlerIcon />
                    </div>
                </div>
            </label>
        </div>
    );
}

const defaultProps = {
    label: "",
    minHeight: 100,
    maxHeight: 400,
    type: "default",
} as const;

interface Props extends Omit<React.InputHTMLAttributes<HTMLTextAreaElement>, "onChange"> {
    label?: string;
    value: string;
    minHeight?: React.CSSProperties["minHeight"];
    maxHeight?: React.CSSProperties["maxHeight"];
    type?: "default" | "error";
    onChange: (value: string, event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export default BasicTextarea;
