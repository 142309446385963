import { AxiosResponse } from "axios";
import { EMPTY, catchError, expand, from, lastValueFrom, map, reduce } from "rxjs";

import { BaseApi } from "@API/base";

import { ProjectType } from "@INTEGRATIONS/scheduler/types";

import { throwApiError } from "@UTILS/helpers";

type OpeningProjectDataItem = {
    "Name": string;
    "MARSHA"?: string;
    "@row.id": number;
    "GD Project #"?: string;
    "Project Id"?: string;
    "Type"?: string;
    "Project URL"?: string;
};

type NonOpeningProjectDataItem = {
    "@row.id": number;
    "Project Name": string;
    "Project Code": string;
};

export type ProjectApiModel = {
    id: number;
    marsha?: string;
    name: string;
    gdNumber?: string;
    code?: string;
    projectId?: string;
    type?: string;
    projectUrl?: string;
    projectType: ProjectType;
};

const MAX_TOTAL_RECORDS = 500;

export class ProjectsApi extends BaseApi {
    async findNonOpening(params?: { page: number }, signal?: AbortSignal): Promise<ProjectApiModel[]> {
        const { page = 1 } = params || {};
        const skip = MAX_TOTAL_RECORDS * (page - 1);
        const urlParams = new URLSearchParams();

        urlParams.append("skip", skip.toString());

        return lastValueFrom<ProjectApiModel[]>(
            from(
                this.client.get(`/Non-Opening%20Project/select.json?${urlParams.toString()}`, {
                    signal,
                }),
            )
                .pipe(
                    map((response: AxiosResponse<NonOpeningProjectDataItem[]>) =>
                        response.data.map((item: NonOpeningProjectDataItem) => ({
                            id: item["@row.id"],
                            name: item["Project Name"],
                            code: item["Project Code"] || "",
                            projectType: ProjectType.NON_OPENING,
                        })),
                    ),
                )
                .pipe(
                    catchError((error: unknown) => {
                        throwApiError(error, "Can't get non-opening projects: ");
                    }),
                ),
        );
    }

    async findOpening(params?: { page: number }, signal?: AbortSignal): Promise<ProjectApiModel[]> {
        const { page = 1 } = params || {};
        const skip = MAX_TOTAL_RECORDS * (page - 1);
        const urlParams = new URLSearchParams();

        urlParams.append("skip", skip.toString());

        return lastValueFrom<ProjectApiModel[]>(
            from(
                this.client.get(`/Site%20Project%20Lookup/API%20Table%20View/select.json?${urlParams.toString()}`, {
                    signal,
                }),
            )
                .pipe(
                    map((response: AxiosResponse<OpeningProjectDataItem[]>) =>
                        response.data.map((item: OpeningProjectDataItem) => ({
                            id: item["@row.id"],
                            marsha: item.MARSHA,
                            name: item["Name"],
                            gdNumber: item["GD Project #"],
                            projectId: item["Project Id"] || "",
                            type: item["Type"],
                            projectUrl: item["Project URL"],
                            projectType: ProjectType.OPENING,
                        })),
                    ),
                )
                .pipe(
                    catchError((error: unknown) => {
                        throwApiError(error, "Can't get opening projects: ");
                    }),
                ),
        );
    }

    async findAllNonOpening(signal?: AbortSignal): Promise<ProjectApiModel[]> {
        let page = 1;

        return lastValueFrom<ProjectApiModel[]>(
            from(this.findNonOpening({ page: page++ }, signal)).pipe(
                expand((response: ProjectApiModel[]) =>
                    response.length === MAX_TOTAL_RECORDS ? this.findNonOpening({ page: page++ }) : EMPTY,
                ),
                reduce((acc: ProjectApiModel[], current: ProjectApiModel[]) => acc.concat(current), []),
            ),
        );
    }

    async findAllOpening(signal?: AbortSignal): Promise<ProjectApiModel[]> {
        let page = 1;

        return lastValueFrom<ProjectApiModel[]>(
            from(this.findOpening({ page: page++ }, signal)).pipe(
                expand((response: ProjectApiModel[]) =>
                    response.length === MAX_TOTAL_RECORDS ? this.findOpening({ page: page++ }) : EMPTY,
                ),
                reduce((acc: ProjectApiModel[], current: ProjectApiModel[]) => acc.concat(current), []),
            ),
        );
    }
}
