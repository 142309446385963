import React, { useLayoutEffect, useRef, useState } from "react";

import ReactDOM from "react-dom";

import classnames from "classnames/bind";

import { z } from "zod";

import { inIframe } from "@UTILS/helpers";

import styles from "./BasePopup.module.scss";

const cx: CX = classnames.bind(styles);

const portal = document.getElementById("scheduler-popups");

const schema = z.object({
    name: z.literal("scheduler-iframe-info"),
    box: z.instanceof(DOMRect),
    innerHeight: z.number(),
    paddingTop: z.number(),
    paddingBottom: z.number(),
});

const PADDING_TOP = 30;
const PADDING_BOTTOM = 30;
const HEIGHT_LIMIT = 500;

function BasePopup({
    onClose, //
    children,
}: Props) {
    const [isReady, setReady] = useState<boolean>(true);

    const contentRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        function handler(event: MessageEvent<unknown>) {
            const parsed = schema.safeParse(event.data);

            if (parsed.success) {
                const { data } = parsed;

                const computedHeight = getComputedStyle(contentRef.current!)["height"];

                const heightLimit = Math.min(parseInt(computedHeight, 10) || HEIGHT_LIMIT, HEIGHT_LIMIT);

                const PT = PADDING_TOP + data.paddingTop;
                const PB = PADDING_BOTTOM + data.paddingBottom;

                const availableSpace =
                    data.innerHeight -
                    Math.max(data.innerHeight - data.box.bottom, 0) -
                    Math.max(data.box.top, 0) -
                    (PT + PB);

                const height = Math.max(availableSpace, heightLimit);

                const top = Math.max(PT - data.box.top + Math.min(availableSpace - heightLimit, 0), PT);

                contentRef.current!.style.height = `${height}px`;
                contentRef.current!.style.top = `${top}px`;
            }
        }

        const isIframe = inIframe();

        if (isIframe) {
            contentRef.current!.style.position = "absolute";

            window.addEventListener("message", handler, false);
            window.parent.postMessage("scheduler-box-subscribe", {
                targetOrigin: "*",
            });

            setReady(true);

            return () => {
                window.removeEventListener("message", handler, false);
                window.parent.postMessage("scheduler-box-unsubscribe", {
                    targetOrigin: "*",
                });
            };
        } else {
            contentRef.current!.style.display = "contents";

            setReady(true);
        }
    }, []);

    function handleClose() {
        onClose();
    }

    return ReactDOM.createPortal(
        <div className={cx("base-popup")}>
            <div
                role="button"
                tabIndex={0}
                className={cx("bg")}
                onClick={handleClose}
            />
            <div
                className={cx("content-wrapper")}
                style={{
                    visibility: isReady ? "visible" : "hidden",
                }}
            >
                <div
                    ref={contentRef}
                    className={cx("content")}
                >
                    <div
                        id="event-confirm-dialog"
                        className={cx("event-confirm-dialog")}
                    />

                    <div className={cx("popup")}>{children}</div>
                </div>
            </div>
        </div>,
        portal!,
    );
}

interface Props extends ChildrenProps {
    onClose: () => void;
}

export default BasePopup;
