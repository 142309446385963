import React, { useId } from "react";

import classnames from "classnames/bind";

import { COLOR } from "@CORE/constants";

import { CalendarIcon } from "@VIEW/components/icons";

import styles from "./DayNumber.module.scss";

const cx: CX = classnames.bind(styles);

function DayNumber({
    type = defaultProps.type, //
    value,
    disabled,
    onChange,
    min,
    max,
    ...attributes
}: Props) {
    const inputId = useId();

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (!disabled) {
            const val = Number(event.target.value);

            if (!Number.isNaN(val)) {
                if (min !== undefined && val < min) {
                    onChange(min, event);
                } else if (max !== undefined && val > max) {
                    onChange(max, event);
                } else {
                    onChange(val, event);
                }
            }
        }
    }

    return (
        <div className={cx("day-number")}>
            <label htmlFor={inputId}>
                <div className={cx("input")}>
                    <span className={cx("icon")}>
                        <CalendarIcon color={COLOR["blue-dark"]} />
                    </span>
                    <input
                        id={inputId}
                        type="number"
                        className={cx({
                            error: type === "error",
                        })}
                        value={value}
                        disabled={disabled}
                        onChange={handleChange}
                        autoComplete="off"
                        aria-autocomplete="none"
                        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                            if (event.code === "ArrowUp") {
                                if (max !== undefined && value >= max) {
                                    event.preventDefault();
                                }
                            }

                            if (event.code === "ArrowDown") {
                                if (min !== undefined && value <= min) {
                                    event.preventDefault();
                                }
                            }
                        }}
                        {...attributes}
                    />
                </div>
            </label>
        </div>
    );
}

const defaultProps = {
    type: "default",
} as const;

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
    value: number;
    min?: number;
    max?: number;
    onChange: (value: number, event: React.ChangeEvent<HTMLInputElement>) => void;
    type?: "default" | "error";
}

export default DayNumber;
