import {
    CalendarEntryNonOpeningProject,
    CalendarEntryNonOpeningProjectModel,
    CalendarEntryOpeningProject,
    CalendarEntryOpeningProjectModel,
} from "@INTEGRATIONS/scheduler/models";

export const calendarEntryProjectMappers = {
    opening: {
        valueToModel(value: CalendarEntryOpeningProject): CalendarEntryOpeningProjectModel {
            return new CalendarEntryOpeningProjectModel(value);
        },
        modelToValue(model: CalendarEntryOpeningProjectModel): CalendarEntryOpeningProject {
            return {
                id: model.id,
                name: model.name,
                marsha: model.marsha,
                gdNumber: model.gdNumber,
                projectId: model.projectId,
                type: model.type,
                projectUrl: model.projectUrl,
            };
        },
    },
    nonOpening: {
        valueToModel(value: CalendarEntryNonOpeningProject): CalendarEntryNonOpeningProjectModel {
            return new CalendarEntryNonOpeningProjectModel(value);
        },
        modelToValue(model: CalendarEntryNonOpeningProjectModel): CalendarEntryNonOpeningProject {
            return {
                id: model.id,
                name: model.name,
                code: model.code,
            };
        },
    },
};
