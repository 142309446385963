import React, { useEffect, useState } from "react";

import ReactDOM from "react-dom";

import classnames from "classnames/bind";

import { useFiltersContext } from "@CONTEXT/hooks";

import { useSchedulerInstance } from "@INTEGRATIONS/scheduler/hooks";

import { ArrowButton } from "@VIEW/components/basic/buttons";

import styles from "./SchedulerTimeRangeControls.module.scss";

const cx: CX = classnames.bind(styles);

const schedulerHeaderElementId = "b-schedulerpro-1-normalSubgrid-header";
const portalId = "custom-time-range-controls";

function SchedulerTimeRangeControlsView() {
    const { actions: schedulerActions } = useSchedulerInstance();

    const {
        filters: { period },
    } = useFiltersContext();

    if (!period) return null;

    return (
        <div className={cx("scheduler-time-range-controls")}>
            <div className={cx("button-wrapper", "prev")}>
                <ArrowButton
                    onClick={() => {
                        schedulerActions.prevRange(period);
                    }}
                />
            </div>
            <div className={cx("button-wrapper")}>
                <ArrowButton
                    onClick={() => {
                        schedulerActions.nextRange(period);
                    }}
                />
            </div>
        </div>
    );
}

function SchedulerTimeRangeControls() {
    const [ready, setReady] = useState(false);

    useEffect(() => {
        const schedulerHeaderElement = document.getElementById(schedulerHeaderElementId);

        const portal = document.createElement("div");

        portal.setAttribute("id", portalId);

        if (schedulerHeaderElement) {
            schedulerHeaderElement.appendChild(portal);

            setReady(true);
        }

        return () => {
            portal.remove();
        };
    }, []);

    if (!ready) return null;

    return ReactDOM.createPortal(
        <SchedulerTimeRangeControlsView />, //
        document.getElementById(portalId)!,
    );
}

export default React.memo(SchedulerTimeRangeControls);
