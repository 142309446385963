import { EventModel, EventModelConfig, Store } from "@bryntum/schedulerpro";

import { COLOR } from "@CORE/constants";

import { AppLocation, AppLocationModel } from "@INTEGRATIONS/scheduler/models/AppLocationModel";
import {
    CalendarEntryProjectModel,
    CalendarEntryType,
    ProjectType,
    TrainingEventSubType,
    TrainingEventType,
    TrainingTrainerType,
} from "@INTEGRATIONS/scheduler/types";

export type CalendarEntry = {
    id: string | number;
    name: string;
    resourceIds: (string | number)[];
    startDate: Date;
    endDate: Date;
    entryType: CalendarEntryType;
    projectType: ProjectType;
    projectIds: (string | number)[];
    location: AppLocation | null;
    description: string;
    isTentative: boolean;
    url: string | null;

    // holiday
    isExternalHoliday: boolean;
    isCreatedFromSchedulerHoliday: boolean;

    // travel
    travelDestination: string | null;

    // opening
    projectMarsha: string | null;
    gdProjectId: string | null;

    // training
    isTrainingEvent: boolean;
    trainingLocation: string;
    trainingTrainerType: TrainingTrainerType | null;
    trainingTrainerName: string;
    trainingTrainerEmail: string;
    trainingIsStatusConfirmed: boolean;
    trainingEventType: TrainingEventType | null;
    trainingEventSubType: TrainingEventSubType | null;
    trainingResourceUniqueId: string | null;

    // blocked
    blockedDisallowRoles: string[] | null;
    blockedDisallowUsers: string[] | null;
};

const colorMap = {
    TRAVEL: COLOR["entry-travel"],
    HOLIDAYS: COLOR["entry-holidays"],
    OPENINGS: COLOR["entry-openings"],
    RESOURCE_ACTIVITY: COLOR["entry-resource-activity"],
    RESOURCE_BLOCKED_PERIOD: COLOR["entry-resource-blocked-period"],
} satisfies Record<CalendarEntryType, string>;

const classMap = {
    TRAVEL: "calendar-entry-travel",
    HOLIDAYS: "calendar-entry-holidays",
    OPENINGS: "calendar-entry-openings",
    RESOURCE_ACTIVITY: "calendar-entry-resource-activity",
    RESOURCE_BLOCKED_PERIOD: "calendar-entry-resource-blocked-period",
} satisfies Record<CalendarEntryType, string>;

interface ConstructorParams extends Partial<EventModelConfig> {
    startDate: Date;
    endDate: Date;
    entryType: CalendarEntryType;
    projectType?: ProjectType;
    projects?: CalendarEntryProjectModel[];
    location?: AppLocationModel | null;
    description?: string;
    isTentative?: boolean;
    isUnassigned?: boolean;
    url?: string | null;

    // holiday
    isExternalHoliday?: boolean;
    isCreatedFromSchedulerHoliday?: boolean;

    // travel
    travelDestination?: string | null;

    // opening
    projectMarsha?: string | null;
    gdProjectId?: string | null;

    // training
    isTrainingEvent?: boolean;
    trainingLocation?: string;
    trainingTrainerType?: TrainingTrainerType | null;
    trainingTrainerName?: string;
    trainingTrainerEmail?: string;
    trainingIsStatusConfirmed?: boolean;
    trainingEventType?: TrainingEventType | null;
    trainingEventSubType?: TrainingEventSubType | null;
    trainingResourceUniqueId?: string | null;

    // blocked
    blockedDisallowRoles?: string[] | null;
    blockedDisallowUsers?: string[] | null;
}

export class CalendarEntryModel extends EventModel {
    startDate: Date;
    endDate: Date;
    entryType: CalendarEntryType;
    projectType: ProjectType = ProjectType.OPENING;
    projects: CalendarEntryProjectModel[] = [];
    location: AppLocationModel | null = null;
    description: string = "";
    isTentative: boolean = false;
    isUnassigned: boolean = false;
    url: string | null = null;

    // holiday
    isExternalHoliday: boolean = false;
    isCreatedFromSchedulerHoliday: boolean = false;

    // travel
    travelDestination: string | null = null;

    // opening
    projectMarsha: string | null = null;
    gdProjectId: string | null = null;

    // training
    isTrainingEvent: boolean = false;
    trainingLocation: string = "";
    trainingTrainerType: TrainingTrainerType | null = null;
    trainingTrainerName: string = "";
    trainingTrainerEmail: string = "";
    trainingIsStatusConfirmed: boolean = false;
    trainingEventType: TrainingEventType | null = null;
    trainingEventSubType: TrainingEventSubType | null = null;
    trainingResourceUniqueId: string | null = null;

    // blocked
    blockedDisallowRoles: string[] | null = null;
    blockedDisallowUsers: string[] | null = null;

    ignoreResourceCalendar = true;

    generateId(): string {
        const defaultId = super.generateId();

        return defaultId.replace("_generatedCalendarEntryModel_", "").replaceAll("-", "");
    }

    constructor(params: ConstructorParams, store?: Store, meta?: object) {
        super(params, store, meta);

        const editable = [
            params.entryType === "RESOURCE_ACTIVITY",
            [
                params.entryType === "HOLIDAYS", //
                params.isCreatedFromSchedulerHoliday,
            ].every(Boolean),
        ].some(Boolean);

        if (params.style) {
            this.style = params.style;
        }

        if (params.projectType) {
            this.projectType = params.projectType;
        }

        if (params.projects) {
            this.projects = params.projects;
        }

        this.location = params.location || null;

        if (params.url !== undefined) {
            this.url = params.url;
        }

        if (params.description) {
            this.description = params.description;
        }

        if (params.isTentative !== undefined) {
            this.isTentative = params.isTentative;
        }

        if (params.isUnassigned !== undefined) {
            this.isUnassigned = params.isUnassigned;
        }

        this.startDate = params.startDate;
        this.endDate = params.endDate;
        this.entryType = params.entryType;
        this.readOnly = !editable;

        // holiday
        if (params.isExternalHoliday !== undefined) {
            this.isExternalHoliday = params.isExternalHoliday;
        }

        if (params.isCreatedFromSchedulerHoliday !== undefined) {
            this.isCreatedFromSchedulerHoliday = params.isCreatedFromSchedulerHoliday;
        }

        // travel
        if (params.travelDestination !== undefined) {
            this.travelDestination = params.travelDestination;
        }

        // opening
        if (params.projectMarsha !== undefined) {
            this.projectMarsha = params.projectMarsha;
        }

        if (params.gdProjectId !== undefined) {
            this.gdProjectId = params.gdProjectId;
        }

        // training
        if (params.isTrainingEvent !== undefined) {
            this.isTrainingEvent = params.isTrainingEvent;
        }

        if (params.trainingLocation !== undefined) {
            this.trainingLocation = params.trainingLocation;
        }

        if (params.trainingTrainerType !== undefined) {
            this.trainingTrainerType = params.trainingTrainerType;
        }

        if (params.trainingTrainerName !== undefined) {
            this.trainingTrainerName = params.trainingTrainerName;
        }

        if (params.trainingTrainerEmail !== undefined) {
            this.trainingTrainerEmail = params.trainingTrainerEmail;
        }

        if (params.trainingIsStatusConfirmed !== undefined) {
            this.trainingIsStatusConfirmed = params.trainingIsStatusConfirmed;
        }

        if (params.trainingEventType !== undefined) {
            this.trainingEventType = params.trainingEventType;
        }

        if (params.trainingEventSubType !== undefined) {
            this.trainingEventSubType = params.trainingEventSubType;
        }

        if (params.trainingResourceUniqueId !== undefined) {
            this.trainingResourceUniqueId = params.trainingResourceUniqueId;
        }

        // blocked
        if (params.blockedDisallowRoles !== undefined) {
            this.blockedDisallowRoles = params.blockedDisallowRoles;
        }

        if (params.blockedDisallowUsers !== undefined) {
            this.blockedDisallowUsers = params.blockedDisallowUsers;
        }

        this.updateView(params.isUnassigned);
    }

    update(field: string | Partial<ConstructorParams>, value?: unknown, silent?: boolean): void {
        this.set(field, value, silent);

        if (typeof field !== "string") {
            this.updateView(field.isUnassigned);
        } else if (field === "isUnassigned" && typeof value === "boolean") {
            this.updateView(value);
        }
    }

    private updateView(isUnassigned?: boolean) {
        this.eventColor = this.getEventColor(isUnassigned);
        this.isTentative = isUnassigned === true ? true : this.isTentative;
        this.cls = this.getEventClass(isUnassigned);
    }

    private getEventColor(isUnassigned?: boolean) {
        const { entryType, isTrainingEvent, isTentative, isExternalHoliday, trainingIsStatusConfirmed } = this;

        // Unassigned
        if (isUnassigned === true) {
            return COLOR["entry-unassigned"];
        }

        // External Holiday
        if (entryType === "HOLIDAYS" && isExternalHoliday) {
            return COLOR["entry-holidays-external"];
        }

        // Resource Activity
        if (entryType === "RESOURCE_ACTIVITY") {
            if (!isTrainingEvent) {
                return !isTentative ? colorMap[entryType] : "#C0DAFA";
            } else {
                return trainingIsStatusConfirmed ? COLOR["entry-training-event"] : "#EDE6FE";
            }
        }

        return colorMap[entryType];
    }

    private getEventClass(isUnassigned?: boolean) {
        const {
            entryType, //
            isTrainingEvent,
            isTentative,
            url,
            isExternalHoliday,
            trainingIsStatusConfirmed,
            readOnly,
        } = this;

        const base = new Set<string | false>([
            "custom-calendar-entry", //
            classMap[entryType],
        ]);

        // Unassigned
        if (isUnassigned === true) {
            base.add("unassigned");
        }

        // Resource Activity
        if (entryType === "RESOURCE_ACTIVITY") {
            if (!isTrainingEvent && isTentative) {
                base.add("tentative");
            }

            if (isTrainingEvent) {
                base.add("training-event");

                if (!trainingIsStatusConfirmed) {
                    base.add("tentative");
                }
            }
        }

        // External Holiday
        if (isExternalHoliday) {
            base.add("external-holiday");
        }

        // With url
        if (url && readOnly) {
            base.add("with-url");
        }

        return Array.from(base).filter(Boolean).join(" ");
    }

    static get $name(): string {
        return "CalendarEntryModel";
    }

    static get fields(): any {
        return [
            { name: "startDate", type: "date" },
            { name: "endDate", type: "date" },
            { name: "entryType", type: "string" },
            { name: "projectType", type: "string" },
            { name: "projects", type: "array" },
            { name: "location", type: "model" },
            { name: "description", type: "string" },
            { name: "isTentative", type: "boolean" },
            { name: "isUnassigned", type: "boolean" },
            { name: "url", type: "string" },

            // holiday
            { name: "isExternalHoliday", type: "boolean" },
            { name: "isCreatedFromSchedulerHoliday", type: "boolean" },

            // travel
            { name: "travelDestination", type: "string" },

            // opening
            { name: "projectMarsha", type: "string" },
            { name: "gdProjectId", type: "string" },

            // training
            { name: "isTrainingEvent", type: "boolean" },
            { name: "trainingLocation", type: "string" },
            { name: "trainingTrainerType", type: "string" },
            { name: "trainingTrainerName", type: "string" },
            { name: "trainingTrainerEmail", type: "string" },
            { name: "trainingIsStatusConfirmed", type: "boolean" },
            { name: "trainingEventType", type: "string" },
            { name: "trainingEventSubType", type: "string" },
            { name: "trainingResourceUniqueId", type: "string" },

            // blocked
            { name: "blockedDisallowRoles" },
            { name: "blockedDisallowUsers" },
        ];
    }
}
