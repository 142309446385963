import React, { useEffect, useState } from "react";

import classnames from "classnames/bind";

import { COLOR } from "@CORE/constants";

import { IconProps } from "@CORE/interfaces";

import { ZoomInIcon, ZoomOutIcon } from "@VIEW/components/icons";

import styles from "./IconButton.module.scss";


const cx: CX = classnames.bind(styles);

const icons = {
    "zoom-in": (props: IconProps) => (
        <ZoomInIcon
            color={props.color}
            size={24}
        />
    ),
    "zoom-out": (props: IconProps) => (
        <ZoomOutIcon
            color={props.color}
            size={24}
        />
    ),
} satisfies Record<Icon, React.ElementType>;

function IconButton({
    icon, //
    disabled,
    onClick,
    ...attributes
}: Props) {
    const [hover, serHover] = useState(false);

    useEffect(() => {
        if (disabled) {
            serHover(false);
        }
    }, [disabled]);

    function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
        if (!disabled) {
            onClick(event);
        }
    }

    const IconComponent = icons[icon];

    const iconColor = disabled ? COLOR["grey"] : hover ? COLOR["blue-main"] : COLOR["blue-dark"];

    return (
        <button
            type="button"
            className={cx("icon-button")}
            disabled={disabled}
            onClick={handleClick}
            onMouseEnter={() => {
                serHover(true);
            }}
            onMouseLeave={() => {
                serHover(false);
            }}
            {...attributes}
        >
            <IconComponent color={iconColor} />
        </button>
    );
}

type Icon = "zoom-in" | "zoom-out";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    icon: Icon;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export default IconButton;
