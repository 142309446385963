import React from "react";

import { IconProps } from "@CORE/interfaces";

function DownloadIcon({
    size = "1.5rem",
    color = "currentColor",
}: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
            <g clipPath="url(#clip0_2538_2405)">
                <path d="M12.5 14H3.5C3.36739 14 3.24021 13.9473 3.14645 13.8536C3.05268 13.7598 3 13.6326 3 13.5V2.5C3 2.36739 3.05268 2.24021 3.14645 2.14645C3.24021 2.05268 3.36739 2 3.5 2H9.5L13 5.5V13.5C13 13.6326 12.9473 13.7598 12.8536 13.8536C12.7598 13.9473 12.6326 14 12.5 14Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M9.5 2V5.5H13" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 7.5V11.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6.5 10L8 11.5L9.5 10" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_2538_2405">
                    <rect width={size} height={size} fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default DownloadIcon;