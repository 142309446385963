import React from "react";

import classNames from "classnames/bind";

import { BasicCheckbox } from "@VIEW/components/basic/inputs/checkbox";

import styles from "./MultipleCustomOption.module.scss";


const cx: CX = classNames.bind(styles);

function MultipleCustomOption({
    children, //
    onSelect,
    isSelected,
    halfSelected = false,
    style = {},
}: Props) {
    const checked = isSelected || halfSelected;

    return (
        <div
            className={cx("wrapper", {
                selected: isSelected,
            })}
            onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                e.preventDefault();
                e.stopPropagation();

                onSelect();
            }}
            style={style}
            role="button"
            tabIndex={0}
        >
            <BasicCheckbox
                className={cx("checkbox", {
                    "half-selected": halfSelected && !isSelected,
                })}
                checked={checked}
                onChange={() => null}
                renderLabel={() => null}
            />
            {children}
        </div>
    );
}

interface Props extends ChildrenProps {
    onSelect: () => void;
    isSelected: boolean;
    halfSelected?: boolean;
    style?: React.CSSProperties;
}

export default MultipleCustomOption;
