import React from "react";

import { ProjectType } from "@INTEGRATIONS/scheduler/types";

import Select from "@VIEW/components/basic/inputs/select/BasicSelect";

function ProjectTypeSelect({ projectTypeOptions, value, onChange }: Props) {
    const handleChange = (newValue: ProjectTypeOption | ProjectTypeOption[] | null) => {
        if (newValue !== null && !Array.isArray(newValue)) {
            onChange(newValue);
        }
    };

    const selectedValue = projectTypeOptions.find((option: ProjectTypeOption) => option.value === value);
    return (
        <Select<ProjectTypeOption>
            placeholder="Project Type"
            isMultiple={false}
            options={projectTypeOptions}
            value={selectedValue || ""}
            onChange={handleChange}
            isClearable={false}
            isOptionDisabled={(newValue: ProjectTypeOption) => {
                return newValue.disabled;
            }}
        />
    );
}

type Props = {
    projectTypeOptions: ProjectTypeOption[];
    onChange: (newValue: ProjectTypeOption) => void;
    value: ProjectType;
};

type ProjectTypeOption = {
    value: ProjectType;
    label: string;
    disabled: boolean;
};

export default ProjectTypeSelect;
