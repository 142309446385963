import React from "react";

import classnames from "classnames/bind";

import styles from "./BlockedPeriodTooltipView.module.scss";


const cx: CX = classnames.bind(styles);

function BlockedPeriodTooltipView({
    name, //
}: Props) {
    const title = `Blocked ${name ? `- ${name}` : ""}`;

    return (
        <div className={cx("blocked-period-tooltip-view")}>
            <div className={cx("title")}>{title}</div>
        </div>
    );
}

interface Props {
    name: string;
}

export default BlockedPeriodTooltipView;
