import { TrainingEventSubType, TrainingEventType } from "@INTEGRATIONS/scheduler/types";

export const trainingDisplayTypeMapper = {
    types: {
        [TrainingEventType.PMS_CONFIG]: "PMS Config",
        [TrainingEventType.PMS_GOLIVE]: "PMS Golive",
        [TrainingEventType.PMS_TRAINING]: "PMS Training",
        [TrainingEventType.RESERVATION]: "Reservation",
        [TrainingEventType.RESERVATION_SYSTEM_TESTING]: "Reservation System Testing",
        [TrainingEventType.RESERVATION_TRAINING]: "Reservation Training",
        [TrainingEventType.AD_HOC_FIELD]: "Ad-hoc field",
        [TrainingEventType.S_C]: "S&C (Config- Training & Go-live)",
        [TrainingEventType.CONVERSION]: "Conversion",
        [TrainingEventType.CONVERSION_SCRIPT]: "Conversion Script",
        [TrainingEventType.DISCOVERY_CALL]: "Discovery Call",
        [TrainingEventType.OPERA_SPRINT_TRAINING]: "OPERA Sprint Training",
        [TrainingEventType.POST_LIVE_CALL]: "Post Live Call",
    } satisfies Record<TrainingEventType, string>,

    subTypes: {
        [TrainingEventSubType.TRAINING]: "Training",
        [TrainingEventSubType.GOLIVE]: "Golive",
        [TrainingEventSubType.CONFIG]: "Config",
        [TrainingEventSubType.KICKOFF]: "Kickoff",
        [TrainingEventSubType.PHASE_1]: "Phase 1",
        [TrainingEventSubType.PHASE_2]: "Phase 2",
        [TrainingEventSubType.SYSTEM_TESTING]: "System Testing",
        [TrainingEventSubType.SYSTEM_CHECK]: "System Check",
        [TrainingEventSubType.EXIT]: "Exit",
        [TrainingEventSubType.SCRIPT]: "Script",
    } satisfies Record<TrainingEventSubType, string>,
};
