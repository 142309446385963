import { ProjectModel, ProjectModelConfig } from "@bryntum/schedulerpro";

import { AppEventNonOpeningProjectStore, AppEventOpeningProjectStore } from "@INTEGRATIONS/scheduler/config/stores";

interface ConstructorParams extends Partial<ProjectModelConfig> {
    eventOpeningProjectStore: AppEventOpeningProjectStore;
    eventNonOpeningProjectStore: AppEventNonOpeningProjectStore;
}

export class AppProjectModel extends ProjectModel {
    eventOpeningProjectStore: AppEventOpeningProjectStore;
    eventNonOpeningProjectStore: AppEventNonOpeningProjectStore;

    constructor(params: ConstructorParams) {
        super(params);

        this.eventOpeningProjectStore = params.eventOpeningProjectStore;
        this.eventNonOpeningProjectStore = params.eventNonOpeningProjectStore;
    }

    static get $name(): string {
        return "AppProjectModel";
    }
}
