import React from "react";

import classnames from "classnames/bind";

import { COLOR } from "@CORE/constants";

import { SidebarView } from "@INTEGRATIONS/scheduler/config/common";

import { ArrowIcon } from "@VIEW/components/icons";

import styles from "./CollapsibleResourceGroupView.module.scss";

const cx: CX = classnames.bind(styles);

function CollapsibleResourceGroupView({
    view, //
    title,
    collapsed,
}: Props) {
    const collapsedVertical = view === "COLLAPSED";

    return (
        <div
            className={cx("collapsible-resource-group-view", {
                "collapsed-vertical": collapsedVertical,
                "collapsed-horizontal": collapsed,
            })}
        >
            <div className={cx("title")}>{title}</div>
            <div className={cx("collapse-button")}>
                <ArrowIcon
                    color={COLOR["blue-dark"]}
                    size={collapsedVertical ? 14 : 18}
                    fillOpacity={1}
                />
            </div>
        </div>
    );
}

interface Props {
    view: SidebarView;
    title: string;
    collapsed: boolean;
}

export default CollapsibleResourceGroupView;
