import React from "react";

import classnames from "classnames/bind";

import { COLOR } from "@CORE/constants";

import { ClockIcon, WarningIcon } from "@VIEW/components/icons";

import styles from "./EventTimeRangeTooltipView.module.scss";


const cx: CX = classnames.bind(styles);

function EventTimeRangeTooltipView({
    startDate, //
    endDate,
    isInvalid = defaultProps.inInvalid,
    message = defaultProps.message,
}: Props) {
    return (
        <div className={cx("event-time-range-tooltip-view")}>
            <div className={cx("date-wrapper")}>
                <div className={cx("icon-wrapper")}>
                    <ClockIcon
                        size={21}
                        color={COLOR["grey"]}
                    />
                </div>
                <div className={cx("date")}>{startDate}</div>
            </div>
            {startDate !== endDate && (
                <div className={cx("date-wrapper")}>
                    <div className={cx("icon-wrapper")}>
                        <ClockIcon
                            size={21}
                            color={COLOR["grey"]}
                        />
                    </div>
                    <div className={cx("date")}>{endDate}</div>
                </div>
            )}
            {isInvalid && message && (
                <div className={cx("warning")}>
                    <div className={cx("icon-wrapper")}>
                        <WarningIcon
                            size={21}
                            color={COLOR["orange"]}
                        />
                    </div>
                    <div className={cx("message")}>{message}</div>
                </div>
            )}
        </div>
    );
}

const defaultProps = {
    inInvalid: false,
    message: "",
} as const;

interface Props {
    startDate: string;
    endDate: string;
    isInvalid?: boolean;
    message?: string;
}

export default EventTimeRangeTooltipView;
