import { useMemo } from "react";

import { CancelledException } from "@CORE/exceptions";

import { useApiContext } from "@CONTEXT/hooks";

import { useNotifications } from "@VIEW/hooks";

function createHook() {
    return function useCalendarEntriesProjectsApi() {
        const { api } = useApiContext();

        const { notify } = useNotifications();

        const queries = useMemo(
            () => ({
                openings: {
                    findAll: async (signal?: AbortSignal) => {
                        try {
                            return await api.projects.findAllOpening(signal);
                        } catch (error: unknown) {
                            if (error instanceof Error && !(error instanceof CancelledException)) {
                                notify.error(error.message);
                            }

                            return [];
                        }
                    },
                },

                nonOpenings: {
                    findAll: async (signal?: AbortSignal) => {
                        try {
                            return await api.projects.findAllNonOpening(signal);
                        } catch (error: unknown) {
                            if (error instanceof Error && !(error instanceof CancelledException)) {
                                notify.error(error.message);
                            }

                            return [];
                        }
                    },
                },
            }),
            [api, notify],
        );

        return {
            queries,
        };
    };
}

export const useCalendarEntriesProjectsApi = createHook();
