import { AssignmentModel, AssignmentModelConfig, Store } from "@bryntum/schedulerpro";

import { CalendarEntryModel } from "@INTEGRATIONS/scheduler/models/CalendarEntryModel";
import { CalendarResourceModel } from "@INTEGRATIONS/scheduler/models/CalendarResourceModel";

interface ConstructorParams extends Partial<AssignmentModelConfig> {
    event: CalendarEntryModel;
    resource: CalendarResourceModel;
}

export class CalendarAssignmentModel extends AssignmentModel {
    event: CalendarEntryModel;
    resource: CalendarResourceModel;

    constructor(params: ConstructorParams, store?: Store, meta?: object) {
        super(params, store, meta);

        this.event = params.event;
        this.resource = params.resource;
    }

    static get $name(): string {
        return "CalendarAssignmentModel";
    }
}
