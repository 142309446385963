import React, { useEffect, useLayoutEffect, useState } from "react";

import {
    blockedPeriodsApiMappers,
    calendarEntriesApiMappers,
    globalBlockedPeriodsApiMappers,
    holidaysApiMappers,
    trainingEventsApiMappers,
} from "@UTILS/mappers";
import { DateHelper } from "@bryntum/schedulerpro";
import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import invariant from "tiny-invariant";

import {
    BlockedPeriodApiModel,
    CalendarEntryApiModel,
    HolidayApiModel,
    ProjectApiModel,
    ResourceApiModel,
    TrainingEventApiModel,
} from "@API/services";

import { useFiltersContext } from "@CONTEXT/hooks";

import { useSchedulerInstance } from "@INTEGRATIONS/scheduler/hooks";

import { createAbbreviation } from "@UTILS/helpers";

import { SchedulerDataLoadingBar } from "@VIEW/components/common";

import {
    useBlockedPeriodsApi,
    useCalendarEntriesApi,
    useCalendarEntriesProjectsApi,
    useCalendarResourcesApi,
    useHolidaysApi,
    useTrainingEventsApi,
} from "@VIEW/hooks/api";

// TODO move away
function getProvincesResourcesMap(resources: ResourceApiModel[]) {
    const map = new Map<string, ResourceApiModel[]>();

    resources.map((resource: ResourceApiModel) => {
        if (map.has(resource.province)) {
            map.get(resource.province)?.push(resource);
        } else {
            map.set(resource.province, [resource]);
        }
    });

    return map;
}

const DEFAULT_CALENDAR_RESOURCES: ResourceApiModel[] = [];
const DEFAULT_PROJECTS: ProjectApiModel[] = [];
const DEFAULT_CALENDAR_ENTRIES: CalendarEntryApiModel[] = [];
const DEFAULT_TRAINING_EVENTS: TrainingEventApiModel[] = [];
const DEFAULT_HOLIDAYS: HolidayApiModel[] = [];
const DEFAULT_BLOCKED_PERIODS: BlockedPeriodApiModel[] = [];

function SchedulerDataLoader({
    onLoad, //
}: Props) {
    const [provinceCodes, setProvinceCodes] = useState<string[]>([]);
    const [selectedDates, setSelectedDates] = useState<{
        from: string;
        to: string;
    } | null>(null);

    const {
        filters: {
            resources: selectedResources, //
            search,
            dates,
        },
    } = useFiltersContext();

    const {
        actions: schedulerActions, //
    } = useSchedulerInstance();

    const { queries: calendarResourcesQueries } = useCalendarResourcesApi();
    const { queries: calendarEntriesProjectsQueries } = useCalendarEntriesProjectsApi();
    const { queries: blockedPeriodsQueries } = useBlockedPeriodsApi();
    const { queries: calendarEntriesQueries } = useCalendarEntriesApi();
    const { queries: trainingEventsQueries } = useTrainingEventsApi();
    const { queries: holidaysQueries } = useHolidaysApi();

    const {
        data: calendarResources = DEFAULT_CALENDAR_RESOURCES, //
        isSuccess: isCalendarResourcesSuccess,
    } = useQuery({
        queryKey: ["resources"],
        queryFn: async (ctx: QueryFunctionContext) => {
            return calendarResourcesQueries.findAll(ctx.signal);
        },
    });

    const { data: openingProjects = DEFAULT_PROJECTS, isSuccess: isOpeningProjectsSuccess } = useQuery({
        queryKey: ["projects-opening"],
        queryFn: async (ctx: QueryFunctionContext) => {
            return calendarEntriesProjectsQueries.openings.findAll(ctx.signal);
        },
    });

    const { data: nonOpeningProjects = DEFAULT_PROJECTS, isSuccess: isNonOpeningProjectsSuccess } = useQuery({
        queryKey: ["projects-non-opening"],
        queryFn: async (ctx: QueryFunctionContext) => {
            return calendarEntriesProjectsQueries.nonOpenings.findAll(ctx.signal);
        },
    });

    const {
        data: blockedPeriods = DEFAULT_BLOCKED_PERIODS,
        isSuccess: isBlockedPeriodsSuccess,
        isLoading: isBlockedPeriodsLoading,
    } = useQuery({
        queryKey: ["blocked-periods", selectedDates],
        queryFn: async (ctx: QueryFunctionContext) => {
            invariant(selectedDates, "selectedDates must be specified");

            return blockedPeriodsQueries.findAll(
                {
                    dates: selectedDates,
                },
                ctx.signal,
            );
        },
        enabled: isCalendarResourcesSuccess && selectedDates !== null,
    });

    const {
        data: calendarEntries = DEFAULT_CALENDAR_ENTRIES,
        isSuccess: isCalendarEntriesSuccess,
        isLoading: isCalendarEntriesLoading,
    } = useQuery({
        queryKey: ["calendar-entries", selectedDates],
        queryFn: async (ctx: QueryFunctionContext) => {
            invariant(selectedDates, "selectedDates must be specified");

            return calendarEntriesQueries.findAll(
                {
                    dates: selectedDates,
                },
                ctx.signal,
            );
        },
        cacheTime: 0,
        staleTime: 0,
        enabled:
            isCalendarResourcesSuccess &&
            isOpeningProjectsSuccess &&
            isNonOpeningProjectsSuccess &&
            selectedDates !== null,
    });

    const {
        data: trainingEvents = DEFAULT_TRAINING_EVENTS,
        isSuccess: isTrainingEventsSuccess,
        isLoading: isTrainingEventsLoading,
    } = useQuery({
        queryKey: ["training-events", selectedDates],
        queryFn: async (ctx: QueryFunctionContext) => {
            invariant(selectedDates, "selectedDates must be specified");

            return trainingEventsQueries.findAll(
                {
                    dates: selectedDates,
                },
                ctx.signal,
            );
        },
        cacheTime: 0,
        staleTime: 0,
        enabled: isCalendarResourcesSuccess && isOpeningProjectsSuccess && selectedDates !== null,
    });

    const {
        data: holidays = DEFAULT_HOLIDAYS,
        isSuccess: isHolidaysSuccess,
        isLoading: isHolidaysLoading,
    } = useQuery({
        queryKey: ["holidays", provinceCodes, selectedDates],
        queryFn: async (ctx: QueryFunctionContext) => {
            invariant(selectedDates, "selectedDates must be specified");

            return holidaysQueries.findAll(
                {
                    provinceCodes,
                    dates: selectedDates,
                },
                ctx.signal,
            );
        },
        enabled: isCalendarResourcesSuccess && provinceCodes.length > 0 && selectedDates !== null,
    });

    /**
     * Set resources to Scheduler
     */
    useEffect(() => {
        async function exec() {
            await schedulerActions.setCalendarResources(
                calendarResources.map((resource: ResourceApiModel) => ({
                    id: resource.id,
                    name: resource.fullName,
                    role: resource.role,
                    logo: resource.logo,
                    abbreviation: createAbbreviation(resource.fullName || ""),
                    allowToAddHolidays: resource.allowToAddHolidays,
                })),
            );

            const provincesResourcesMap = getProvincesResourcesMap(calendarResources);

            setProvinceCodes(Array.from(provincesResourcesMap.keys()));
        }

        if (isCalendarResourcesSuccess && calendarResources.length > 0) {
            void exec();
        }
    }, [calendarResources, isCalendarResourcesSuccess, schedulerActions]);

    /**
     * Set projects to Scheduler
     */
    useEffect(() => {
        if (isOpeningProjectsSuccess && openingProjects.length > 0) {
            schedulerActions.setOpeningProjects(openingProjects);
        }

        if (isNonOpeningProjectsSuccess && nonOpeningProjects.length > 0) {
            schedulerActions.setNonOpeningProjects(nonOpeningProjects);
        }
    }, [openingProjects, nonOpeningProjects, isOpeningProjectsSuccess, isNonOpeningProjectsSuccess, schedulerActions]);

    /**
     * Set global blocked periods to Scheduler
     */
    useEffect(() => {
        if (blockedPeriods.length > 0) {
            schedulerActions.setGlobalBlockedPeriods(globalBlockedPeriodsApiMappers.apiToValues(blockedPeriods));
        }
    }, [blockedPeriods, isBlockedPeriodsSuccess, schedulerActions]);

    /**
     * Set calendar entries to Scheduler
     */
    useEffect(() => {
        async function exec() {
            const entries = calendarEntriesApiMappers.apiToValues(calendarEntries, calendarResources);

            await schedulerActions.setCalendarEntries(entries);
        }

        if (isCalendarEntriesSuccess) {
            void exec();
        }
    }, [calendarEntries, isCalendarEntriesSuccess, calendarResources, schedulerActions]);

    /**
     * Set individual resources blocked periods to Scheduler
     */
    useEffect(() => {
        async function exec() {
            const blocked = blockedPeriodsApiMappers.apiToValues(blockedPeriods, calendarResources);

            await schedulerActions.setBlockedPeriods(blocked);
        }

        if (isBlockedPeriodsSuccess) {
            void exec();
        }
    }, [blockedPeriods, isBlockedPeriodsSuccess, calendarResources, schedulerActions]);

    /**
     * Set training events to Scheduler
     */
    useEffect(() => {
        async function exec() {
            const trainings = trainingEventsApiMappers.apiToValues(trainingEvents, calendarResources, openingProjects);

            await schedulerActions.setTrainingEvents(trainings);
        }

        if (isTrainingEventsSuccess) {
            void exec();
        }
    }, [trainingEvents, isTrainingEventsSuccess, calendarResources, openingProjects, schedulerActions]);

    /**
     * Set public holidays to Scheduler
     */
    useEffect(() => {
        async function exec() {
            const provincesResourcesMap = getProvincesResourcesMap(calendarResources);

            const entries = holidaysApiMappers.apiToValues(holidays, provincesResourcesMap);

            await schedulerActions.setPublicHolidays(entries);
        }

        if (isHolidaysSuccess) {
            void exec();
        }
    }, [holidays, isHolidaysSuccess, calendarResources, schedulerActions]);

    /**
     * Trigger data loading end
     */
    useEffect(() => {
        if (
            isCalendarResourcesSuccess &&
            isOpeningProjectsSuccess &&
            isNonOpeningProjectsSuccess &&
            isBlockedPeriodsSuccess &&
            isCalendarEntriesSuccess &&
            isTrainingEventsSuccess &&
            isHolidaysSuccess
        ) {
            onLoad();
        }
    }, [
        isCalendarResourcesSuccess,
        isOpeningProjectsSuccess,
        isNonOpeningProjectsSuccess,
        isBlockedPeriodsSuccess,
        isCalendarEntriesSuccess,
        isTrainingEventsSuccess,
        isHolidaysSuccess,
        onLoad,
    ]);

    /**
     * Filter resources in Scheduler by selected roles or resources
     */
    useEffect(() => {
        if (calendarResources.length > 0) {
            void schedulerActions.filterResources(selectedResources.map((resource: ResourceApiModel) => resource.id));
        }
    }, [calendarResources, selectedResources, schedulerActions]);

    /**
     * Filter calendar entries in Scheduler by keywords
     */
    useEffect(() => {
        const timer = setTimeout(() => {
            void schedulerActions.filterByKeywords(search);
        }, 250);

        return () => {
            clearTimeout(timer);
        };
    }, [search, schedulerActions]);

    useLayoutEffect(() => {
        function format(date: Date) {
            return DateHelper.format(date, "YYYY-MM-DD");
        }

        const timer = setTimeout(() => {
            if (dates.from && dates.to) {
                setSelectedDates({
                    from: format(dates.from),
                    to: format(dates.to),
                });
            }
        }, 200);

        return () => {
            clearTimeout(timer);
        };
    }, [dates]);

    return (
        <SchedulerDataLoadingBar
            visible={
                isBlockedPeriodsLoading || isCalendarEntriesLoading || isTrainingEventsLoading || isHolidaysLoading
            }
        />
    );
}

interface Props {
    onLoad: () => void;
}

export default React.memo(SchedulerDataLoader);
