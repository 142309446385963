import { RefObject, useEffect } from "react";

function createClickOutsideHook() {
    return function useClickOutside(ref: RefObject<HTMLElement>, callback: () => void) {
        useEffect(() => {
            const handleClick = (e: MouseEvent) => {
                if (ref.current && !ref.current.contains(e.target as Node)) {
                    callback();
                }
            };

            document.addEventListener("click", handleClick);

            return () => {
                document.removeEventListener("click", handleClick);
            };
        }, [ref, callback]);
    };
}

export const useClickOutside = createClickOutsideHook();
