import { z } from "zod";

export const UserSchema = z.discriminatedUnion("role", [
    z.object({
        role: z.literal("ADMIN"),
    }),
    z.object({
        role: z.literal("USER"),
        email: z.string(),
        fullName: z.string().nullable(),
        departments: z.array(z.string()),
    }),
]);
