import React from "react";

import ReactDOMServer from "react-dom/server";

import { DateHelper, ScheduleTooltip, ScheduleTooltipConfig } from "@bryntum/schedulerpro";

import { BlockedPeriodTooltipView, ScheduleMenuTooltipView } from "@INTEGRATIONS/scheduler/components/common";
import { AppTimeRangeStore } from "@INTEGRATIONS/scheduler/config/stores";
import {
    CalendarResourceModel,
    EXTERNAL_TRAINER_RESOURCE_ID,
    GlobalBlockedPeriodTimeSpanModel,
    UNASSIGNED_RESOURCE_ID,
} from "@INTEGRATIONS/scheduler/models";
import { formatDate } from "@INTEGRATIONS/scheduler/utils";

export const scheduleTooltipFeature = {
    hideForNonWorkingTime: false,
    generateTipContent(original: { date: Date; resourceRecord: unknown }) {
        // #BLOCKED

        if (
            original.resourceRecord instanceof CalendarResourceModel &&
            original.resourceRecord.project &&
            original.resourceRecord.id !== UNASSIGNED_RESOURCE_ID &&
            original.resourceRecord.id !== EXTERNAL_TRAINER_RESOURCE_ID
        ) {
            const { timeRangeStore } = original.resourceRecord.project;

            if (timeRangeStore instanceof AppTimeRangeStore) {
                const foundBlockedPeriod = timeRangeStore
                    .getBlockedPeriods()
                    .find((model: GlobalBlockedPeriodTimeSpanModel) => {
                        return DateHelper.intersectSpans(
                            original.date, //
                            original.date,
                            model.startDate,
                            model.endDate,
                        );
                    });

                if (foundBlockedPeriod) {
                    return ReactDOMServer.renderToString(
                        <BlockedPeriodTooltipView name={foundBlockedPeriod.name} />, //
                    );
                }
            }
        }

        const stringDate = formatDate(original.date);

        return ReactDOMServer.renderToString(<ScheduleMenuTooltipView date={stringDate} />);
    },
} satisfies ScheduleTooltip | Partial<ScheduleTooltipConfig>;
