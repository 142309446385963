import { SchedulerPro } from "@bryntum/schedulerpro";

import { AppTimeRangeStore } from "@INTEGRATIONS/scheduler/config/stores";

export function getTimeRangeStore(source: SchedulerPro): AppTimeRangeStore | null {
    if (!source) return null;

    const { timeRangeStore } = source;

    if (timeRangeStore instanceof AppTimeRangeStore) return timeRangeStore;

    return null;
}
