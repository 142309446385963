/*eslint-disable*/
// @ts-nocheck TODO: find a way to reuse some of the functions from the base exporter class. Though it does not provide it in the interface
import { MultiPageVerticalExporter, PdfExportConfig, SchedulerPro } from "@bryntum/schedulerpro";
// declare class MultiPageVerticalExporterExtended extends MultiPageVerticalExporter {
//     beforeExport(): void;
//     saveState(config: PdfExportConfig & { client: SchedulerPro }): void;
//     prepareComponent(config: PdfExportConfig & { client: SchedulerPro }): Promise<void>;
//     restoreComponent(config: PdfExportConfig & { client: SchedulerPro }): Promise<void>;
//     getPages(config: PdfExportConfig & { client: SchedulerPro }): Promise<string[]>;
//     requestAnimationFrame: ((value: unknown) => void);
// }

var RowsRange = {
  all: "all",
  visible: "visible"
};
export class PdfCustomExporter extends MultiPageVerticalExporter {
    static get type(): string {
        return "customPdf";
    }

    async export(config: PdfExportConfig & { client: SchedulerPro }): Promise<string[]> {
        const formattedConfig = config;
        this.beforeExport();
        this.saveState(config);
        await this.prepareComponent(config);

        const totalPixels = config.client.timeAxisViewModel.getDistanceBetweenDates(config.rangeStart, config.rangeEnd);

        const totalPages = Math.floor(totalPixels / 1500);

        const startDate = new Date(config.rangeStart);
        const endDate = new Date(config.rangeEnd);

        const totalMilliseconds = endDate.getTime() - startDate.getTime();

        const subRangeMilliseconds = totalMilliseconds / totalPages;

        let pages: string[] = [];

        formattedConfig.repeatHeader = true;

        config.totalParts = totalPages;

        for (let i = 0; i < totalPages; i++) {
            const subRangeStart = new Date(startDate.getTime() + i * subRangeMilliseconds);
            const subRangeEnd = new Date(startDate.getTime() + (i + 1) * subRangeMilliseconds);

            config.currentPart = i + 1;
            formattedConfig.scheduleRange = "daterange";

            if (i === totalPages - 1) {
                subRangeEnd.setTime(endDate.getTime());
            }

            formattedConfig.rangeStart = subRangeStart;
            formattedConfig.rangeEnd = subRangeEnd;
            let page;
            this.beforeExport();
            this.saveState(config);

            await this.prepareComponent(config);

            try {
                page = await this.getPages(config);
                pages = pages.concat(page);
            } finally {
                await this.restoreComponent(config);
                await new Promise((resolve: (value: unknown) => void) => this.requestAnimationFrame(resolve));
            }
        }

        formattedConfig.rangeStart = startDate;
        formattedConfig.rangeEnd = endDate;
        this.restoreState(config);

        return pages;
    }

    async buildPage(config) {
        const me = this, { exportMeta } = me, {
            client,
            headerTpl,
            footerTpl,
            enableDirectRendering,
            totalParts,
            currentPart
        } = config, {
            totalWidth,
            totalPages,
            currentPage,
            subGrids
        } = exportMeta;
        Object.values(subGrids).forEach((subGrid) => subGrid.rows = []);
        if (config.rowsRange === RowsRange.all) {
            exportMeta.totalHeight = client.headerHeight + client.footerHeight + client.scrollable.scrollHeight;
            if (!enableDirectRendering) {
                exportMeta.totalHeight -= me.getVirtualScrollerHeight(client);
            }
        }
        let header, footer, offset;
        if (headerTpl) {
            header = me.prepareHTML(headerTpl({
                totalWidth,
                totalPages,
                currentPage,
                totalParts,
                currentPart
            }));
        }
        if (footerTpl) {
            footer = me.prepareHTML(footerTpl({
                totalWidth,
                totalPages,
                currentPage
            }));
        }
        if (enableDirectRendering) {
            offset = await me.renderRows(config);
        } else {
            offset = await me.collectRows(config);
        }
        const html = me.buildPageHtml(config);
        return { html, header, footer, offset };
    }

    restoreState(config: PdfExportConfig & { client: SchedulerPro }): void {
        const formattedConfig = config;

        if (config.rangeStart.getTime() !== config.client.startDate.getTime()) {
            formattedConfig.client.startDate = config.rangeStart;
        }

        if (config.rangeEnd.getTime() !== config.client.endDate.getTime()) {
            formattedConfig.client.endDate = config.rangeEnd;
        }
    }
}