import { FiltersState } from "@CONTEXT/reducers";

export const FILTERS_APPLY_ACTION_TYPE = "FILTERS_APPLY_ACTION_TYPE";

export interface FiltersApplyAction {
    type: typeof FILTERS_APPLY_ACTION_TYPE;
    payload: {
        [K in keyof FiltersState]?: FiltersState[K];
    };
}

/**
 *  Filters apply action creator
 */
export function filtersApply(payload: FiltersApplyAction["payload"]): FiltersApplyAction {
    return {
        type: FILTERS_APPLY_ACTION_TYPE,
        payload,
    };
}
