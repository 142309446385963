import React, { TouchEvent, useMemo, useRef, useState } from "react";

import classNames from "classnames/bind";

import ReactSelect, {
    CSSObjectWithLabel,
    ClearIndicatorProps,
    ControlProps,
    GroupBase,
    InputActionMeta,
    MenuListProps,
    MultiValue,
    MultiValueProps,
    OptionProps,
    SelectInstance,
    components,
} from "react-select";

import { COLOR } from "@CORE/constants";

import { BasicInput } from "@VIEW/components/basic/inputs/input";
import { DropdownIndicator } from "@VIEW/components/basic/inputs/select/BasicSelect/Select.com";

import { ClearIcon } from "@VIEW/components/icons";

import { useClickOutside } from "@VIEW/hooks";

import MultipleCustomOption from "../BasicSelect/MultipleCustomOption/MultipleCustomOption.com";
import styles from "./ProjectsSelect.module.scss";

const cx: CX = classNames.bind(styles);

interface FilterOptionOption<T> {
    readonly label: string;
    readonly value: string;
    readonly data: T;
}

function CustomOption<T extends AbstractProject>(optionProps: OptionProps<T>) {
    const { isSelected, data: optionData } = optionProps;
    return (
        <MultipleCustomOption
            onSelect={() => {
                optionProps.selectOption(optionProps.data);
            }}
            isSelected={isSelected}
            style={{
                padding: "12px 14px 12px 0",
            }}
        >
            <span>{optionData.name}</span>
        </MultipleCustomOption>
    );
}

function ClearIndicator<T extends AbstractProject>(clearIndicatorProps: ClearIndicatorProps<T, boolean, GroupBase<T>>) {
    return (
        <components.ClearIndicator {...clearIndicatorProps}>
            {clearIndicatorProps.children}
            <button
                style={{ display: "flex" }}
                type="button"
            >
                <ClearIcon color={COLOR["blue-dark"]} />
            </button>
        </components.ClearIndicator>
    );
}

function getCustomMenuList<T extends AbstractProject>(
    selectRef: React.MutableRefObject<SelectInstance<T, true, GroupBase<T>> | null>,
) {
    return function CustomMenuList({ selectProps, children, ...rest }: MenuListProps<T>) {
        const { onInputChange, inputValue } = selectProps;

        return (
            <div className={cx("menu-wrapper")}>
                <div className={cx("menu-input-wrapper")}>
                    <BasicInput
                        autoCorrect="off"
                        autoComplete="off"
                        spellCheck="false"
                        value={inputValue}
                        onChange={(value: string) => {
                            onInputChange(value, {
                                prevInputValue: "",
                                action: "input-change",
                            });
                        }}
                        onBlur={() => {
                            selectRef?.current?.blurInput();
                        }}
                        onMouseDown={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
                            e.stopPropagation();
                            selectRef?.current?.focus();
                        }}
                        onTouchEnd={(e: TouchEvent<HTMLInputElement>) => {
                            e.stopPropagation();
                            selectRef?.current?.focus();
                        }}
                        placeholder="Search..."
                    />
                </div>
                <div className={cx("menu-list-wrapper")}>
                    <components.MenuList
                        selectProps={selectProps}
                        {...rest}
                    >
                        {children}
                    </components.MenuList>
                </div>
            </div>
        );
    };
}

function getStyles(error: boolean, isOpen: boolean) {
    return {
        menuList: (base: CSSObjectWithLabel) => ({
            ...base,
            marginTop: 0,
            boxShadow: "none",
            transition: "all .2s cubic-bezier(.5,0,0,1.25),opacity .15s ease-out",
            transformOrigin: "50% 0",
        }),
        placeholder: (base: CSSObjectWithLabel) => ({
            ...base,
            color: "#ADB4BD",
            fontWeight: 400,
        }),
        control: (baseStyles: CSSObjectWithLabel) => ({
            ...baseStyles,
            "borderColor": error ? "#DF715F" : isOpen ? "#ADB4BD" : "#D9DDE2",
            "outline": "none",
            "borderRadius": "8px",
            "fontSize": "0.875rem",
            "boxShadow": isOpen ? "none" : "none",
            "height": "40px",
            "zIndex": 11,
            "paddingLeft": "calc(0.5rem - 1px)",

            "&:hover": {
                borderColor: error ? "#DF715F" : isOpen ? "#ADB4BD" : "#D9DDE2",
                boxShadow: isOpen ? "none" : "none",
            },
        }),
        menu: (base: CSSObjectWithLabel) => {
            return {
                ...base,
                marginTop: 4,
                zIndex: 10,
                width: "auto",
                minWidth: "100%",
                borderRadius: 8,
                border: `1px solid ${COLOR["grey"]}`,
                boxShadow: "none",
            };
        },
        valueContainer: (base: CSSObjectWithLabel) => ({
            ...base,
            input: { height: 0 },
        }),
    };
}

function MultiValueComponent<T extends AbstractProject>(props: MultiValueProps<T, boolean, GroupBase<T>>) {
    let label; // TODO ???

    if (props.getValue().length === 0 && !label) return <components.MultiValue {...props} />;
    if (props.getValue().length === 1) return <components.SingleValue {...props} />;
    return <span>{!props.index && `${props.getValue().length} projects selected`}</span>;
}

function ProjectsSelect<T extends AbstractProject>({
    projects, //
    selectedProjects,
    onChange,
    type = "default",
}: Props<T>) {
    const selectRef = useRef<SelectInstance<T, true, GroupBase<T>> | null>(null);
    const [inputValue, setInputValue] = useState("");
    const [isOpen, setOpen] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    useClickOutside(containerRef, () => {
        setOpen(false);
        setInputValue("");
    });

    const handleChange = (newValues: MultiValue<T>) => {
        const newProjects = projects.filter((item: T) => {
            return newValues.find((option: T) => option.id === item.id);
        });

        onChange(newProjects);
    };

    const CustomMenuListInstance = useMemo(() => getCustomMenuList(selectRef), [selectRef]);

    return (
        <div ref={containerRef}>
            <ReactSelect<T, true>
                isMulti
                ref={selectRef}
                isSearchable={false}
                placeholder="Select Projects"
                getOptionValue={(option: AbstractProject) => option.id.toString()}
                getOptionLabel={(option: AbstractProject) => option.name}
                options={projects}
                value={selectedProjects}
                onChange={handleChange}
                backspaceRemovesValue={false}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                styles={getStyles(type === "error", isOpen)}
                noOptionsMessage={() => "No projects found"}
                onFocus={() => setOpen(true)}
                menuIsOpen={isOpen}
                filterOption={(opt: FilterOptionOption<T>, val: string) => {
                    const trimmedInput = val.trim().toLowerCase();

                    if (opt.data.marsha?.trim()?.toLowerCase().includes(trimmedInput)) {
                        return true;
                    }

                    return opt.label.toLowerCase().includes(trimmedInput);
                }}
                onInputChange={(query: string, action: InputActionMeta) => {
                    if (
                        action.action !== "set-value" &&
                        action.action !== "input-blur" &&
                        action.action !== "menu-close"
                    ) {
                        setInputValue(query);
                    }

                    selectRef.current?.blur();
                }}
                inputValue={inputValue}
                components={{
                    Control: (props: ControlProps<T, true, GroupBase<T>>) => {
                        return (
                            <div
                                tabIndex={0}
                                role="button"
                                onClick={() => {
                                    if (isOpen) {
                                        setOpen(false);
                                        selectRef.current?.blur();
                                    }
                                }}
                            >
                                <components.Control {...props} />
                            </div>
                        );
                    },
                    DropdownIndicator,
                    MultiValue: MultiValueComponent,
                    IndicatorSeparator: () => null,
                    Option: CustomOption,
                    ClearIndicator,
                    MenuList: CustomMenuListInstance,
                    Input: () => null,
                }}
            />
        </div>
    );
}

type Props<T extends AbstractProject> = {
    projects: T[];
    selectedProjects: T[];
    onChange: (newProjects: T[]) => void;
    type?: "default" | "error";
};

type AbstractProject = {
    id: string | number;
    name: string;
    marsha?: string;
    gdNumber?: string;
};

export default ProjectsSelect;
