import React from "react";

import classNames from "classnames/bind";

import { createAbbreviation } from "@UTILS/helpers";

import { UserLogo } from "@VIEW/components/common";

import styles from "./Person.module.scss";


const cx: CX = classNames.bind(styles);

function Person({
    name, //
    logo,
    role,
}: Props) {
    const abbreviation = createAbbreviation(name);

    return (
        <div className={cx("person")}>
            <UserLogo
                src={logo}
                title={abbreviation}
            />
            <div className={cx("name-wrapper")}>
                <div className={cx("name")}>{name.trim() || "Unknown"}</div>
                <div className={cx("position")}>{role}</div>
            </div>
        </div>
    );
}

type Props = {
    name: string;
    logo: string;
    role: string;
};

export default Person;
