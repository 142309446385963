import React, { RefObject, useLayoutEffect, useRef, useState } from "react";

import classnames from "classnames/bind";

import { ToastContainer } from "react-toastify";
import { z } from "zod";

import { inIframe } from "@UTILS/helpers";

import { NotificationsStateProps, useNotifications } from "@VIEW/hooks";

import styles from "./Notificator.module.scss";

const cx: CX = classnames.bind(styles);

const schema = z.object({
    name: z.literal("scheduler-iframe-info"),
    box: z.instanceof(DOMRect),
    innerHeight: z.number(),
});

const HEIGHT_LIMIT = 200;

function Notificator() {
    const [open, setOpen] = useState(false);
    const ref: RefObject<HTMLDivElement> = useRef(null);

    const { listeners } = useNotifications();

    useLayoutEffect(() => {
        const isIframe = inIframe();

        if (isIframe) {
            const notificationStateChange = listeners.onNotificationStateChange.subscribe(
                (data: NotificationsStateProps) => {
                    setOpen(data.open);
                },
            );

            return () => {
                notificationStateChange.unsubscribe();
            };
        }

        setOpen(true);
    }, [listeners]);

    useLayoutEffect(() => {
        function handler(event: MessageEvent<unknown>) {
            const parsed = schema.safeParse(event.data);

            if (parsed.success) {
                const { data } = parsed;

                const availableSpace =
                    data.innerHeight - data.box.top - Math.max(data.innerHeight - data.box.bottom, 0);

                const height = Math.max(availableSpace, HEIGHT_LIMIT);

                ref.current!.style.height = `${height}px`;
            }
        }

        const isIframe = inIframe();

        if (isIframe) {
            if (open) {
                window.addEventListener("message", handler, false);
                window.parent.postMessage("scheduler-notifications-box-subscribe", {
                    targetOrigin: "*",
                });
            } else {
                window.removeEventListener("message", handler, false);
                window.parent.postMessage("scheduler-notifications-box-unsubscribe", {
                    targetOrigin: "*",
                });
            }
        }
    }, [open]);

    return (
        <div
            ref={ref}
            style={{
                visibility: open ? "visible" : "hidden",
            }}
            className={cx("notificator")}
        >
            <ToastContainer
                className={cx("toast-container", {
                    "in-iframe": inIframe(),
                })}
                position="bottom-right"
                autoClose={10000}
                hideProgressBar
                closeOnClick
                pauseOnFocusLoss
                draggable
                bodyStyle={{
                    fontSize: 12,
                    lineHeight: 1.5,
                    whiteSpace: "pre-line",
                }}
                pauseOnHover
            />
        </div>
    );
}

export default Notificator;
