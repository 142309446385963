import { useContext } from "react";

import { ApiContext } from "@CONTEXT/providers";

function createApiHook() {
    return function useApiContext() {
        return useContext(ApiContext);
    };
}

export const useApiContext = createApiHook();
