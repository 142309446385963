import React from "react";

import { IconProps } from "@CORE/interfaces";

function CheckIcon({
    size = defaultProps.size, //
    color = defaultProps.color,
}: IconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 10 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.69842 5.61903L8.12141 0.993487L8.12141 0.993486M3.69842 5.61903L8.26506 1.13085L8.12141 0.993486M3.69842 5.61903L1.87849 3.71668L1.87849 3.71667C1.63063 3.45762 1.22579 3.45911 0.979684 3.71976C0.739014 3.97465 0.740242 4.38259 0.982574 4.63594L0.982585 4.63595L3.25058 7.00663C3.4973 7.26452 3.89996 7.26444 4.1466 7.00651L9.01753 1.91251C9.25982 1.65912 9.26092 1.25114 9.02022 0.99632L9.0202 0.996302C8.77401 0.73573 8.3692 0.734349 8.12141 0.993486M3.69842 5.61903L8.12141 0.993486"
                fill={color}
                strokeWidth="0"
            />
        </svg>
    );
}

const defaultProps = {
    size: "1em",
    color: "currentColor",
};

export default CheckIcon;
