import React from "react";

import { IconProps } from "@CORE/interfaces";

function PasteIcon({
    size = defaultProps.size, //
    color = defaultProps.color,
}: IconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g>
                <path
                    d="M15.25 3H19C19.1989 3 19.3897 3.07902 19.5303 3.21967C19.671 3.36032 19.75 3.55109 19.75 3.75V10.875M9.25 3H5.5C5.30109 3 5.11032 3.07902 4.96967 3.21967C4.82902 3.36032 4.75 3.55109 4.75 3.75V19.5C4.75 19.6989 4.82902 19.8897 4.96967 20.0303C5.11032 20.171 5.30109 20.25 5.5 20.25H12.25"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.5 6V5.25C8.5 4.25544 8.89509 3.30161 9.59835 2.59835C10.3016 1.89509 11.2554 1.5 12.25 1.5C13.2446 1.5 14.1984 1.89509 14.9017 2.59835C15.6049 3.30161 16 4.25544 16 5.25V6H8.5Z"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M21.4375 21.75H13.5625C13.4133 21.75 13.2702 21.6907 13.1648 21.5852C13.0593 21.4798 13 21.3367 13 21.1875V11.0625C13 10.9133 13.0593 10.7702 13.1648 10.6648C13.2702 10.5593 13.4133 10.5 13.5625 10.5H19.1875L22 13.3125V21.1875C22 21.3367 21.9407 21.4798 21.8352 21.5852C21.7298 21.6907 21.5867 21.75 21.4375 21.75Z"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16 16.5H19"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16 18.75H19"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}

const defaultProps = {
    size: "1em",
    color: "currentColor",
};

export default PasteIcon;
