import React from "react";

import { IconProps } from "@CORE/interfaces";

function ClearIcon({
    size = defaultProps.size, //
    color = defaultProps.color,
}: IconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
        >
            <g clipPath="url(#clip0_2203_151)">
                <path
                    d="M10 6L6 10"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6 6L10 10"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_2203_151">
                    <rect
                        width="16"
                        height="16"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}

const defaultProps = {
    size: "1em",
    color: "currentColor",
};

export default ClearIcon;
