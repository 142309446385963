import React from "react";

import classnames from "classnames/bind";

import styles from "./GhostButton.module.scss";

const cx: CX = classnames.bind(styles);

function GhostButton({
    label, //
    disabled,
    onClick,
    ...attributes
}: Props) {
    function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
        if (!disabled) {
            onClick(event);
        }
    }

    return (
        <button
            type="button"
            className={cx("ghost-button")}
            disabled={disabled}
            onClick={handleClick}
            {...attributes}
        >
            {label}
        </button>
    );
}

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    label: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export default GhostButton;
