import { z } from "zod";

export const ResourceSchema = z.object({
    id: z.union([z.string(), z.number()]),
    name: z.string(),
    role: z.string(),
    logo: z.string(),
    abbreviation: z.string(),
    allowToAddHolidays: z.boolean(),
});
