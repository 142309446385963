import React, { useMemo } from "react";

import classNames from "classnames/bind";

import { ResourceApiModel } from "@API/services";

import Select from "@VIEW/components/basic/inputs/select/BasicSelect";
import { Option } from "@VIEW/components/basic/inputs/select/BasicSelect/Select.com";

import { Person } from "@VIEW/components/common";

import styles from "./TeamLeadSelect.module.scss";


const cx: CX = classNames.bind(styles);

function TeamLeadSelect({ resources, selectedTeamLead, onChange }: Props) {
    const uniqueTeamLeads = useMemo(
        () =>
            Object.values(
                resources.reduce((acc: Record<string, ResourceApiModel>, item: ResourceApiModel) => {
                    const newAcc = { ...acc };

                    if (item.teamLeadId && !newAcc[item.teamLeadId]) {
                        const teamLead = resources.find((resource: ResourceApiModel) => resource.id === item.teamLeadId);
                        if (teamLead) {
                            newAcc[item.teamLeadId] = teamLead;
                        }
                    }
                    return newAcc;
                }, {}),
            ),
        [resources],
    );

    const teamLeadOptions = useMemo(
        () =>
            uniqueTeamLeads.map((item: ResourceApiModel) => ({
                value: item?.id.toString(),
                label: item?.fullName,
                logoSrc: item.logo,
            })),
        [uniqueTeamLeads],
    );

    const value = selectedTeamLead ? { value: selectedTeamLead.id.toString(), label: selectedTeamLead.fullName } : null;

    const handleChange = (newValue: PersonOption | PersonOption[] | null) => {
        const newTeamLead = uniqueTeamLeads.find(
            (item: ResourceApiModel) => item.id.toString() === (newValue as PersonOption)?.value,
        );

        onChange(newTeamLead || null);
    };

    return (
        <Select<PersonOption>
            placeholder="Team Lead"
            isMultiple={false}
            options={teamLeadOptions}
            value={value || ""}
            onChange={handleChange}
            Option={CustomOption}
        />
    );
}

function CustomOption({ value: optionValue, isSelected }: { value: PersonOption; isSelected?: boolean }) {
    const role = "Team Lead";

    return (
        <div className={cx("team-lead-option", { selected: isSelected })}>
            <Person
                name={optionValue.label}
                logo={optionValue.logoSrc || ""}
                role={role}
            />
        </div>
    );
}

type PersonOption = Option & { logoSrc?: string; role?: string };

type Props = {
    resources: ResourceApiModel[];
    selectedTeamLead: ResourceApiModel | null;
    onChange: (newTeamLead: ResourceApiModel | null) => void;
};

export default TeamLeadSelect;
