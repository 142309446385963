import React from "react";

import classnames from "classnames/bind";

import { COLOR } from "@CORE/constants";

import { SearchIcon } from "@VIEW/components/icons";

import styles from "./SearchInput.module.scss";
import ClearIcon from "@VIEW/components/icons/ClearIcon/ClearIcon.com";


const cx: CX = classnames.bind(styles);

function SearchInput({
    value, //
    type = defaultProps.type,
    disabled,
    onChange,
    ...attributes
}: Props) {
    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (!disabled) {
            onChange(event.target.value, event);
        }
    }

    return (
        <div className={cx("search-input")}>
            <div className={cx("icon-wrapper")}>
                <SearchIcon
                    size={16}
                    color={disabled ? COLOR["grey"] : COLOR["blue-dark"]}
                />
            </div>
            <input
                value={value}
                type="text"
                className={cx({
                    error: type === "error",
                })}
                disabled={disabled}
                onChange={handleChange}
                autoComplete="off"
                aria-autocomplete="none"
                {...attributes}
            />
            {value && (
                <button
                    className={cx("clear-button")}
                    type="button"
                    onClick={() => onChange("")}
                >
                    <ClearIcon color={COLOR["blue-dark"]} />
                </button>
            )}
        </div>
    );
}

const defaultProps = {
    type: "default",
} as const;

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
    value: string;
    type?: "default" | "error";
    onChange: (value: string, event?: React.ChangeEvent<HTMLInputElement>) => void;
}

export default SearchInput;
