import React, { Dispatch, createContext, useReducer } from "react";

import { AuthAction, AuthState, authReducer } from "@CONTEXT/reducers";

const initialState: AuthState = {
    user: null,
};

export const AuthContext = createContext<
    [
        AuthState, //
        Dispatch<AuthAction> | null,
    ]
>([initialState, null]);

export function AuthProvider(props: ChildrenProps) {
    const { children } = props;

    const value = useReducer(authReducer, initialState);

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
