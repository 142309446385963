import React, { CSSProperties } from "react";

import classnames from "classnames/bind";

import { CalendarEntryModel } from "@INTEGRATIONS/scheduler/models";

import styles from "./SummaryItemView.module.scss";

const cx: CX = classnames.bind(styles);

function SummaryItemView({
    eventRecords, //
    dataSummaryItem,
    color,
    hoverColor,
    active,
}: Props) {
    const count = eventRecords.length;

    return (
        <div
            data-summary-item={dataSummaryItem}
            style={
                {
                    "--color": color,
                    "--hover-color": hoverColor,
                    "pointerEvents": active ? "all" : "none",
                } as CSSProperties
            }
            className={cx("summary-item-view")}
        >
            {count}
        </div>
    );
}

interface Props {
    eventRecords: CalendarEntryModel[];
    dataSummaryItem: "opening" | "unassigned" | "external-trainers";
    color: CSSProperties["color"];
    hoverColor: CSSProperties["color"];
    active: boolean;
}

export default SummaryItemView;
