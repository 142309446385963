import { useCallback, useEffect } from "react";

import { ResourceApiModel, TrainingEventApiModel } from "@API/services";

import { useApiContext } from "@CONTEXT/hooks";

import { useSchedulerListeners } from "@INTEGRATIONS/scheduler/hooks";
import { CalendarEntry, CalendarEntryOpeningProject, CalendarResource } from "@INTEGRATIONS/scheduler/models";

import {
    DEPRECATED_useCalendarEntriesProjects,
    DEPRECATED_useCalendarResources,
    useEmailNotifications,
    useNotifications,
} from "@VIEW/hooks";

function createTrainingEventsHook() {
    return function useTrainingEvents() {
        const { api } = useApiContext();

        const { calendarResources } = DEPRECATED_useCalendarResources();

        const { openingProjects } = DEPRECATED_useCalendarEntriesProjects();

        const listeners = useSchedulerListeners();

        const { notifyAssignees } = useEmailNotifications();

        const { notify } = useNotifications();

        const onUpsert = useCallback(
            async ({
                items,
                shouldRemovePrevItems,
            }: {
                items: TrainingEventApiModel[];
                shouldRemovePrevItems: boolean;
            }) => {
                const transformedItems = items.map((item: TrainingEventApiModel) => {
                    const projectIds = item.projectIds
                        .map((id: string) => {
                            return openingProjects.find((project: CalendarEntryOpeningProject) => {
                                return project.id.toString() === id;
                            })?.projectId;
                        })
                        .filter(Boolean);

                    return {
                        ...item,
                        projectIds,
                    };
                });

                try {
                    await api.trainingEvents.upsert({
                        events: transformedItems,
                        shouldRemovePrevItems,
                    });
                } catch (error: unknown) {
                    if (error instanceof Error) {
                        notify.error(error.message);
                    }
                }
            },
            [api, openingProjects, notify],
        );

        const onDelete = useCallback(
            async (entries: CalendarEntry[]) => {
                try {
                    await Promise.all(
                        entries.map((entry: CalendarEntry) => {
                            if (entry.trainingResourceUniqueId) {
                                return api.trainingEvents.deleteByResourceUniqueId(entry.trainingResourceUniqueId);
                            }

                            return api.trainingEvents.deleteById(entry.id);
                        }),
                    );
                } catch (error: unknown) {
                    if (error instanceof Error) {
                        notify.error(error.message);
                    }
                }
            },
            [api, notify],
        );

        const onNotifyAssignees = useCallback(
            (entries: CalendarEntry[]) => {
                entries.forEach((entry: CalendarEntry) => {
                    const assignees = calendarResources.filter((resource: ResourceApiModel) => {
                        return entry.resourceIds.includes(resource.id);
                    }, []);

                    notifyAssignees({
                        entry,
                        assignees,
                        projects: openingProjects,
                    })
                        .then(() => {
                            console.log("Notification success");
                        })
                        .catch((error: unknown) => {
                            if (error instanceof Error) {
                                notify.error(error.message);
                            }

                            console.error("Notification error");
                        });
                });
            },
            [
                calendarResources, //
                notifyAssignees,
                openingProjects,
                notify,
            ],
        );

        useEffect(() => {
            function transformTrainingEvent(items: CalendarEntry[]): TrainingEventApiModel[] {
                return items.map((item: CalendarEntry) => {
                    const resourceEmails = item.resourceIds
                        .map((resourceId: string | number) => {
                            return calendarResources.find((resource: ResourceApiModel) => resource.id === resourceId)
                                ?.email;
                        })
                        .filter((resource: string | undefined): resource is string => !!resource);

                    return {
                        id: item.id,
                        name: item.name,
                        type: item.trainingEventType,
                        subType: item.trainingEventSubType,
                        isStatusConfirmed: item.trainingIsStatusConfirmed,
                        startDate: item.startDate,
                        endDate: item.endDate,
                        location: item.trainingLocation,
                        trainerType: item.trainingTrainerType,
                        trainerName: item.trainingTrainerName,
                        trainerEmail: item.trainingTrainerEmail,
                        description: item.description,
                        projectIds: item.projectIds.map((id: string | number) => id.toString()),
                        resourceEmails,
                        resourceUniqueId: item.trainingResourceUniqueId,
                    };
                });
            }

            const onEventResizeEndSubscriber = listeners.onEventResizeEnd.subscribe((item: CalendarEntry) => {
                const payload = transformTrainingEvent([item]);

                void onUpsert({
                    items: payload,
                    shouldRemovePrevItems: false,
                });
            }, "training-event");

            const onBeforeDeleteSubscriber = listeners.onBeforeEventDelete.subscribe((item: CalendarEntry[]) => {
                if (item[0]?.id) {
                    void onDelete([item[0]]);
                }
            }, "training-event");

            const onAfterEventSaveSubscriber = listeners.onAfterEventSave.subscribe(
                (item: {
                    entry: CalendarEntry; //
                    shouldNotifyAssignees: boolean;
                }) => {
                    const { entry, shouldNotifyAssignees } = item;

                    void onUpsert({
                        items: transformTrainingEvent([entry]),
                        shouldRemovePrevItems: true,
                    });

                    if (shouldNotifyAssignees) {
                        onNotifyAssignees([entry]);
                    }
                },
                "training-event",
            );

            const onEventPasteSubscriber = listeners.onEventPaste.subscribe(
                (item: {
                    entries: CalendarEntry[]; //
                    shouldNotifyAssignees: boolean;
                }) => {
                    const { entries, shouldNotifyAssignees } = item;

                    void onUpsert({
                        items: transformTrainingEvent(entries),
                        shouldRemovePrevItems: false,
                    });

                    if (shouldNotifyAssignees) {
                        onNotifyAssignees(entries);
                    }
                },
                "training-event",
            );

            const onEventDropSubscriber = listeners.onEventDrop.subscribe(
                (item: {
                    entries: CalendarEntry[]; //
                    shouldNotifyAssignees: boolean;
                }) => {
                    const { entries, shouldNotifyAssignees } = item;

                    void onUpsert({
                        items: transformTrainingEvent(entries),
                        shouldRemovePrevItems: false,
                    });

                    if (shouldNotifyAssignees) {
                        onNotifyAssignees(entries);
                    }
                },
                "training-event",
            );

            const onBeforeAssignmentDeleteSubscriber = listeners.onBeforeAssignmentDelete.subscribe(
                (
                    items: {
                        entry: CalendarEntry;
                        resource: CalendarResource;
                    }[],
                ) => {
                    const formattedItems = items.map((item: { entry: CalendarEntry; resource: CalendarResource }) => {
                        return {
                            ...item.entry,
                            resourceIds: item.entry.resourceIds.filter(
                                (id: string | number) => item.resource.id !== id,
                            ),
                        };
                    });

                    const itemsToUpdate = formattedItems.filter((item: CalendarEntry) => item.resourceIds.length);

                    const itemsToDelete = formattedItems.filter((item: CalendarEntry) => !item.resourceIds.length);

                    if (itemsToUpdate.length) {
                        void onUpsert({
                            items: transformTrainingEvent(formattedItems),
                            shouldRemovePrevItems: false,
                        });
                    }

                    if (itemsToDelete.length) {
                        void onDelete(formattedItems);
                    }
                },
                "training-event",
            );

            return () => {
                onEventResizeEndSubscriber.unsubscribe();
                onBeforeDeleteSubscriber.unsubscribe();
                onAfterEventSaveSubscriber.unsubscribe();
                onEventPasteSubscriber.unsubscribe();
                onEventDropSubscriber.unsubscribe();
                onBeforeAssignmentDeleteSubscriber.unsubscribe();
            };
        }, [listeners, calendarResources, onUpsert, onDelete, onNotifyAssignees]);
    };
}

/**
 * @deprecated use useTrainingEventsApi hook instead
 */
export const DEPRECATED_useTrainingEvents = createTrainingEventsHook();
