import React from "react";

import classnames from "classnames/bind";

import { COLOR } from "@CORE/constants";

import { trainingDisplayTypeMapper } from "@INTEGRATIONS/scheduler/mappers";
import { CalendarEntryModel } from "@INTEGRATIONS/scheduler/models";
import { CalendarEntryProjectModel, CalendarEntryType } from "@INTEGRATIONS/scheduler/types";

import { locationToString } from "@UTILS/helpers";

import { CircleIcon, LocationIcon } from "@VIEW/components/icons";

import styles from "./EventTooltipView.module.scss";

const cx: CX = classnames.bind(styles);

const entryTypeMap = {
    TRAVEL: "Travel",
    HOLIDAYS: "Holiday",
    OPENINGS: "Opening",
    RESOURCE_ACTIVITY: "",
    RESOURCE_BLOCKED_PERIOD: "Blocked",
} satisfies Record<CalendarEntryType, string>;

function ResourceActivityTooltipView({
    eventRecord, //
}: Props) {
    const title = (() => {
        if (!eventRecord.isTrainingEvent && eventRecord.isTentative) {
            return [eventRecord.name, "(Tentative)"].join(" ");
        }

        if (eventRecord.isTrainingEvent) {
            const { trainingEventType, trainingEventSubType } = eventRecord;

            const typeName = [
                trainingEventType !== null && trainingDisplayTypeMapper.types[trainingEventType], //
                trainingEventSubType !== null && trainingDisplayTypeMapper.subTypes[trainingEventSubType],
            ]
                .filter(Boolean)
                .join(" - ");

            const name = eventRecord.name || typeName || "Untitled";

            if (eventRecord.trainingIsStatusConfirmed) {
                return [name, "(Status confirmed)"].join(" ");
            }

            return name;
        }

        return eventRecord.name;
    })();

    const location = (() => {
        if (!eventRecord.isTrainingEvent && eventRecord.location !== null) {
            return locationToString(eventRecord.location) || "";
        }

        if (eventRecord.isTrainingEvent && eventRecord.trainingLocation.trim() !== "") {
            return eventRecord.trainingLocation;
        }

        return "";
    })();

    const hasLocation = location !== "";
    const hasProjects = eventRecord.projects.length > 0;

    return (
        <div
            className={cx("event-tooltip-view", "resource-activity", {
                "title-only": !hasLocation && !hasProjects,
            })}
        >
            <div className={cx("title")}>{title}</div>
            {location && (
                <div className={cx("location")}>
                    <div className={cx("location-icon-wrapper")}>
                        <LocationIcon
                            size={20}
                            color={COLOR["grey"]}
                        />
                    </div>
                    <div className={cx("location-name")}>{location}</div>
                </div>
            )}
            {eventRecord.projects.length > 0 && (
                <div className={cx("projects")}>
                    {eventRecord.projects.map((project: CalendarEntryProjectModel) => {
                        const key = project.id;

                        return (
                            <li
                                key={key}
                                className={cx("project-item")}
                            >
                                <div className={cx("project-icon-wrapper")}>
                                    <CircleIcon
                                        color={COLOR["grey"]}
                                        size={14}
                                    />
                                </div>
                                <div className={cx("project-name")}>{project.name}</div>
                            </li>
                        );
                    })}
                </div>
            )}
        </div>
    );
}

function ResourceBlockedPeriodTooltipView({
    eventRecord, //
}: Props) {
    const title = (() => {
        const typeName = entryTypeMap[eventRecord.entryType];

        return [
            typeName, //
            eventRecord.description,
        ]
            .filter(Boolean)
            .join(" - ");
    })();

    const disallowRoles: string[] | null = (() => {
        const { blockedDisallowRoles } = eventRecord;

        if (blockedDisallowRoles === null) return null;

        return blockedDisallowRoles;
    })();

    const disallowUsers: string[] | null = (() => {
        const { blockedDisallowUsers } = eventRecord;

        if (blockedDisallowUsers === null) return null;

        return blockedDisallowUsers;
    })();

    return (
        <div className={cx("event-tooltip-view", "resource-blocked-period")}>
            <div className={cx("title")}>{title}</div>

            {disallowRoles !== null && disallowRoles.length > 0 && (
                <>
                    <div className={cx("disallow-title")}>Disallow Holidays for these Roles:</div>
                    <div className={cx("disallow-list")}>
                        {disallowRoles.map((role: string) => (
                            <div
                                key={role}
                                className={cx("disallow-list-item")}
                            >
                                <div className={cx("disallow-list-icon-wrapper")}>
                                    <CircleIcon
                                        color={COLOR["grey"]}
                                        size={14}
                                    />
                                </div>
                                <div className={cx("disallow-list-item-title")}>{role}</div>
                            </div>
                        ))}
                    </div>
                </>
            )}

            {disallowUsers !== null && disallowUsers.length > 0 && (
                <>
                    <div className={cx("disallow-title")}>Disallow Holidays for these Users:</div>
                    <div className={cx("disallow-list")}>
                        {disallowUsers.map((user: string) => (
                            <div
                                key={user}
                                className={cx("disallow-list-item")}
                            >
                                <div className={cx("disallow-list-icon-wrapper")}>
                                    <CircleIcon
                                        color={COLOR["grey"]}
                                        size={14}
                                    />
                                </div>
                                <div className={cx("disallow-list-item-title")}>{user}</div>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
}

function BasicTooltipView({
    eventRecord, //
}: Props) {
    const title = (() => {
        const typeName = entryTypeMap[eventRecord.entryType];

        if (eventRecord.entryType === "TRAVEL") {
            return [
                typeName, //
                eventRecord.travelDestination,
            ]
                .filter(Boolean)
                .join(" ");
        }

        if (eventRecord.entryType === "OPENINGS") {
            return [
                typeName, //
                eventRecord.projectMarsha || eventRecord.gdProjectId,
            ]
                .filter(Boolean)
                .join(" ");
        }

        return [
            typeName, //
            eventRecord.name,
        ].join(" ");
    })();

    return (
        <div className={cx("event-tooltip-view")}>
            <div className={cx("title")}>{title}</div>
        </div>
    );
}

function EventTooltipView(props: Props) {
    if (props.eventRecord.entryType === "RESOURCE_ACTIVITY") {
        return <ResourceActivityTooltipView {...props} />;
    }

    if (props.eventRecord.entryType === "RESOURCE_BLOCKED_PERIOD") {
        return <ResourceBlockedPeriodTooltipView {...props} />;
    }

    return <BasicTooltipView {...props} />;
}

interface Props {
    eventRecord: CalendarEntryModel;
}

export default EventTooltipView;
