import React from "react";

import classnames from "classnames/bind";

import { CalendarResourceModel } from "@INTEGRATIONS/scheduler/models";

import styles from "./CellTooltipView.module.scss";


const cx: CX = classnames.bind(styles);

function CellTooltipView({
    resourceRecord, //
}: Props) {
    return <div className={cx("cell-tooltip-view")}>{resourceRecord.name.trim() || "Unknown"}</div>;
}

interface Props {
    resourceRecord: CalendarResourceModel;
}

export default CellTooltipView;
