import React from "react";

import ReactDOMServer from "react-dom/server";

import { PdfCustomExporter } from "@CORE/exporters";
import { DateHelper, Model, SchedulerPro } from "@bryntum/schedulerpro";
import { BryntumSchedulerProProps } from "@bryntum/schedulerpro-react";

import { EventView } from "@INTEGRATIONS/scheduler/components/common";
import { columnsConfig } from "@INTEGRATIONS/scheduler/config/common";
import {
    cellTooltipFeature,
    columnResizeFeature,
    eventCopyPasteFeature,
    eventDragCreateFeature,
    eventDragFeature,
    eventEditFeature,
    eventFilterFeature,
    eventMenuFeature,
    eventResizeFeature,
    eventTooltipFeature,
    groupSummaryFeature,
    nonWorkingTimeFeature,
    scheduleContextFeature,
    scheduleMenuFeature,
    scheduleTooltipFeature,
    summaryFeature,
    timeRangesFeature,
} from "@INTEGRATIONS/scheduler/config/features";
import { CustomViewPreset, presets } from "@INTEGRATIONS/scheduler/config/presets";
import {
    AppTimeRangeStore,
    appAssignmentStore,
    appEventNonOpeningProjectStore,
    appEventOpeningProjectStore,
    appEventStore,
    appResourceStore,
    appTimeRangeStore,
} from "@INTEGRATIONS/scheduler/config/stores";
import {
    AppProjectModel,
    CalendarEntryModel,
    CalendarEntryOpeningProjectModel,
    CalendarResourceModel,
    EXTERNAL_TRAINER_RESOURCE_ID,
    EXTERNAL_TRAINER_RESOURCE_ROLE,
    UNASSIGNED_RESOURCE_ID,
    UNASSIGNED_RESOURCE_ROLE,
} from "@INTEGRATIONS/scheduler/models";
import { CalendarEntryProjectModel, TrainingTrainerType } from "@INTEGRATIONS/scheduler/types";

import { getHeaderTemplate } from "../utils/export.utils";

function openUrl(url: string) {
    const a = document.createElement("a");

    a.href = url;
    a.target = "_blank";

    a.click();
    a.remove();
}

export const schedulerProConfig = {
    // common
    emptyText: "",
    rowHeight: 42,
    barMargin: 2,
    startDate: new Date(),
    weekStartDay: 1,
    createEventOnDblClick: false,

    // project
    project: new AppProjectModel({
        eventStore: appEventStore,
        resourceStore: appResourceStore,
        assignmentStore: appAssignmentStore,
        timeRangeStore: appTimeRangeStore,
        eventOpeningProjectStore: appEventOpeningProjectStore,
        eventNonOpeningProjectStore: appEventNonOpeningProjectStore,
    }),
    pdfExportFeature: {
        exportServer: "https://mt-emea-resource-scheduling-export-pdf.apexdigital.online/export-pdf/",
        exporterType: "customPdf",
        alignRows: true,
        repeatHeader: true,
        fileFormat: "pdf",
        paperFormat: "A4",
        orientation: "landscape",
        rowsRange: "all",
        scheduleRange: "completeview",
        enableDirectRendering: true,
        headerTpl: getHeaderTemplate,
        exporters: [PdfCustomExporter],
    },

    // features
    cellTooltipFeature,
    columnResizeFeature,
    eventCopyPasteFeature,
    eventDragFeature,
    eventDragCreateFeature,
    eventEditFeature,
    eventFilterFeature,
    eventMenuFeature,
    eventResizeFeature,
    eventTooltipFeature,
    groupSummaryFeature,
    nonWorkingTimeFeature,
    scheduleContextFeature,
    scheduleMenuFeature,
    scheduleTooltipFeature,
    summaryFeature,
    timeRangesFeature,

    eventNonWorkingTimeFeature: false,
    stripeFeature: false,
    timeAxisHeaderMenuFeature: false,
    regionResizeFeature: false,
    splitFeature: false,
    dependenciesFeature: false,
    taskEditFeature: false,
    columnRenameFeature: false,
    headerZoomFeature: false,

    resourceNonWorkingTimeFeature: {
        maxTimeAxisUnit: "year",
    },

    // presets
    presets,
    viewPreset: CustomViewPreset.MONTHS_AND_DAYS,

    listeners: {
        paint(original: { firstPaint: boolean; source: SchedulerPro }) {
            if (original.firstPaint) {
                columnsConfig.addListeners(original.source);
            }
        },

        beforeEventSave() {
            // console.log(item);
        },

        beforePaste(original: {
            entityName: string; //
            date: Date;
            source: SchedulerPro;
            eventRecords?: CalendarEntryModel[];
            resourceRecord: CalendarResourceModel;
        }) {
            // #BLOCKED

            if (original.entityName === "event" && original.eventRecords) {
                // allow not readonly only calendar entries
                const isEditable = original.eventRecords.every((model: CalendarEntryModel) => {
                    return !model.readOnly;
                });

                if (!isEditable) return false;

                // allow specific resources for custom holidays
                const isCustomHolidays = original.eventRecords.every((model: CalendarEntryModel) => {
                    return [
                        model.entryType === "HOLIDAYS", //
                        model.isCreatedFromSchedulerHoliday,
                    ].every(Boolean);
                });

                if (isCustomHolidays) {
                    return original.resourceRecord.allowToAddHolidays;
                }

                // allow unassigned
                if (
                    [
                        UNASSIGNED_RESOURCE_ID, //
                    ].includes(original.resourceRecord.id.toString())
                ) {
                    return true;
                }

                const isExternalTraining = original.eventRecords.every((model: CalendarEntryModel) => {
                    return model.trainingTrainerType === TrainingTrainerType.EXTERNAL;
                });

                // allow external trainers only inside "External Trainers" resource
                if (isExternalTraining && original.resourceRecord.id === EXTERNAL_TRAINER_RESOURCE_ID) {
                    return true;
                }

                // not allow to external trainers
                if (!isExternalTraining && original.resourceRecord.id === EXTERNAL_TRAINER_RESOURCE_ID) {
                    return false;
                }

                // allow outside blocked periods
                const { timeRangeStore } = original.source;

                if (timeRangeStore instanceof AppTimeRangeStore) {
                    const isIntersectBlockedPeriod = original.eventRecords.every((model: CalendarEntryModel) => {
                        const startDate = original.date;

                        const endDate = DateHelper.add(
                            startDate,
                            DateHelper.diff(model.startDate, model.endDate, "day"),
                            "day",
                        );

                        return timeRangeStore.isIntersectBlockedPeriods(
                            startDate, //
                            endDate,
                        );
                    });

                    if (isIntersectBlockedPeriod) {
                        return false;
                    }
                }
            }

            return true;
        },

        beforeDragCreate(original: {
            date: Date; //
            source: SchedulerPro;
            resourceRecord: CalendarResourceModel;
        }) {
            // #BLOCKED

            // allow unassigned and external trainer
            if (
                [
                    UNASSIGNED_RESOURCE_ID, //
                    EXTERNAL_TRAINER_RESOURCE_ID,
                ].includes(original.resourceRecord.id.toString())
            ) {
                return true;
            }

            // allow outside blocked periods
            const { timeRangeStore } = original.source;

            if (timeRangeStore instanceof AppTimeRangeStore) {
                const isIntersect = timeRangeStore.isIntersectBlockedPeriods(
                    original.date, //
                    original.date,
                );

                return !isIntersect;
            }
        },

        scheduleMenuBeforeShow(original: {
            date: Date; //
            source: SchedulerPro;
            resourceRecord: CalendarResourceModel;
        }) {
            // #BLOCKED

            // allow unassigned and external trainer
            if (
                [
                    UNASSIGNED_RESOURCE_ID, //
                    EXTERNAL_TRAINER_RESOURCE_ID,
                ].includes(original.resourceRecord.id.toString())
            ) {
                return true;
            }

            // allow outside blocked periods
            const { timeRangeStore } = original.source;

            if (timeRangeStore instanceof AppTimeRangeStore) {
                const isIntersect = timeRangeStore.isIntersectBlockedPeriods(
                    original.date, //
                    original.date,
                );

                return !isIntersect;
            }
        },

        beforeToggleGroup(original: { groupRecord: Model }) {
            // console.log(original);

            return [
                `group-header-${UNASSIGNED_RESOURCE_ROLE}`, //
                `group-header-${EXTERNAL_TRAINER_RESOURCE_ROLE}`,
            ].includes(original.groupRecord.id.toString());
        },

        eventClick: (original: { event: PointerEvent; eventRecord: CalendarEntryModel }) => {
            if (original.eventRecord.isTrainingEvent) {
                const target = original.event.target as Element | null;

                if (target) {
                    const link = target.closest("[data-training-icon='link']");

                    if (link) {
                        original.eventRecord.projects.forEach((project: CalendarEntryProjectModel, index: number) => {
                            if (project instanceof CalendarEntryOpeningProjectModel && project.projectUrl) {
                                setTimeout(() => {
                                    openUrl(project.projectUrl);
                                }, 100 * index);
                            }
                        });

                        return false;
                    }
                }
            }

            if (!original.eventRecord.readOnly) {
                original.event.preventDefault();

                return false;
            }

            if (!original.eventRecord.url) return;

            openUrl(original.eventRecord.url);
        },

        // catchAll(original: any) {
        //     console.log(original);
        // },

        // eventPartialResize(ku: any) {
        //     console.log(ku);
        // },

        // eventResizeEnd(ku: any) {
        //     console.log(ku);
        // },
    },

    columns: columnsConfig.columns,

    eventRenderer: (original: { eventRecord: CalendarEntryModel; renderData: { width: number } }) => {
        return ReactDOMServer.renderToString(
            <EventView
                eventRecord={original.eventRecord}
                eventWidth={original.renderData.width}
            />,
        );
    },
} satisfies BryntumSchedulerProProps;
