import { useCallback } from "react";

import { QueryFunctionContext, useQueryClient } from "@tanstack/react-query";

import { useApiContext } from "@CONTEXT/hooks";

function createLocationsHook() {
    return function useLocations() {
        const { api } = useApiContext();

        const queryClient = useQueryClient();

        return useCallback(
            (query: string) =>
                queryClient.ensureQueryData({
                    queryKey: ["locations", query],
                    queryFn: ({ signal }: QueryFunctionContext) => {
                        return api.locations.find({ query }, signal);
                    },
                }),
            [api.locations, queryClient],
        );
    };
}

export const useLocations = createLocationsHook();
