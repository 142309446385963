import React from "react";

import classnames from "classnames/bind";

import { SidebarView } from "@INTEGRATIONS/scheduler/config/common";

import styles from "./ResourceGroupView.module.scss";

const cx: CX = classnames.bind(styles);

function ResourceGroupView({
    title, //
    view,
}: Props) {
    const collapsed = view === "COLLAPSED";

    return (
        <div
            className={cx("resource-group-view", {
                collapsed,
            })}
        >
            {title}
        </div>
    );
}

interface Props {
    title: string;
    view: SidebarView;
}

export default ResourceGroupView;
