import React from "react";

import { EventDeleteConfirmPopup, EventEditPopup } from "@INTEGRATIONS/scheduler/components/popups";
import { useSchedulerPopups } from "@INTEGRATIONS/scheduler/hooks/internal";

function PopupsContainer() {
    const {
        state: {
            eventEditPopupState, //
            eventDeleteConfirmPopupState,
        },
    } = useSchedulerPopups();

    return (
        <>
            {eventEditPopupState.isOpen && <EventEditPopup />}
            {eventDeleteConfirmPopupState.isOpen && <EventDeleteConfirmPopup />}
        </>
    );
}

export default PopupsContainer;
