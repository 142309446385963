import { CalendarEntryApiModel, ResourceApiModel } from "@API/services";

import { CalendarEntry } from "@INTEGRATIONS/scheduler/models";
import { ProjectType } from "@INTEGRATIONS/scheduler/types";

export const calendarEntriesApiMappers = {
    apiToValues: (entries: CalendarEntryApiModel[], resources: ResourceApiModel[]): CalendarEntry[] => {
        return entries.map((item: CalendarEntryApiModel) => {
            const resourceIds = item.resourceEmails
                .map((resourceEmail: string) => {
                    return resources.find((resource: ResourceApiModel) => resource.email === resourceEmail)
                        ?.id as number;
                })
                .filter((id: number | undefined) => !!id);

            let projectType: ProjectType = ProjectType.NOT_PROJECT_RELATED;

            if (item.type === "HOLIDAYS" && item.isCreatedFromSchedulerHoliday) {
                projectType = ProjectType.HOLIDAY;
            } else if (item.openingProjectIds.length) {
                projectType = ProjectType.OPENING;
            } else if (item.nonOpeningProjectIds.length) {
                projectType = ProjectType.NON_OPENING;
            }

            return {
                id: item.id,
                name: item.name,
                resourceIds,
                startDate: item.startDate,
                endDate: item.endDate,
                entryType: item.type,
                description: item.description,
                isTentative: item.isTentative,
                location: item.location || null,
                url: item.url,
                projectType,
                projectIds: item.openingProjectIds.length ? item.openingProjectIds : item.nonOpeningProjectIds,

                // holiday
                isExternalHoliday: false,
                isCreatedFromSchedulerHoliday: item.isCreatedFromSchedulerHoliday,

                // travel
                travelDestination: item.travelDestination,

                // opening
                projectMarsha: item.projectMarsha || null,
                gdProjectId: item.gdProjectId || null,

                // training
                isTrainingEvent: false,
                trainingLocation: "",
                trainingTrainerType: null,
                trainingTrainerName: "",
                trainingTrainerEmail: "",
                trainingIsStatusConfirmed: false,
                trainingEventType: null,
                trainingEventSubType: null,
                trainingResourceUniqueId: null,

                // blocked
                blockedDisallowRoles: null,
                blockedDisallowUsers: null,
            } satisfies CalendarEntry;
        });
    },
    valuesToApi: (entries: CalendarEntry[], resources: ResourceApiModel[]): CalendarEntryApiModel[] => {
        return entries.map((item: CalendarEntry) => {
            const resourceEmails = item.resourceIds
                .map((resourceId: string | number) => {
                    return resources.find((resource: ResourceApiModel) => resource.id === resourceId)?.email;
                })
                .filter((resource: string | undefined): resource is string => !!resource);

            return {
                id: item.id,
                name: item.name,
                resourceEmails,
                startDate: item.startDate,
                endDate: item.endDate,
                type: item.entryType,
                description: item.description,
                isTentative: item.isTentative,
                location: item.location,
                url: item.url,
                travelDestination: item.travelDestination,
                ...(item.projectType === ProjectType.OPENING
                    ? {
                          openingProjectIds: item.projectIds.map((id: string | number) => id.toString()),
                          nonOpeningProjectIds: [],
                      }
                    : {
                          nonOpeningProjectIds: item.projectIds.map((id: string | number) => id.toString()),
                          openingProjectIds: [],
                      }),
                isCreatedFromSchedulerHoliday: item.isCreatedFromSchedulerHoliday,
            };
        });
    },
};
