export function download(data: BlobPart, type: string, name: string): void {
    const blob = new Blob([data], { type });

    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");

    a.setAttribute("href", url);

    a.setAttribute("download", name);

    a.click();
}