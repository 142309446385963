import { SchedulerPro } from "@bryntum/schedulerpro";

import { CalendarResourceModel } from "@INTEGRATIONS/scheduler/models";
import { excludeResourceGroupHeaderModel } from "@INTEGRATIONS/scheduler/utils";

export function getAllResourceRecords(source: SchedulerPro): CalendarResourceModel[] {
    const resourceIds = source.resourceStore.getDistinctValues("id", true);

    return excludeResourceGroupHeaderModel(
        resourceIds.map((id: number) => {
            return source.resourceStore.getById(id);
        }),
    );
}
