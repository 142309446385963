import React from "react";

import ReactDOMServer from "react-dom/server";

import { GroupSummaryConfig } from "@bryntum/schedulerpro";

import { COLOR } from "@CORE/constants";

import { SummaryItemView } from "@INTEGRATIONS/scheduler/components/common";
import {
    CalendarEntryModel,
    EXTERNAL_TRAINER_RESOURCE_ROLE,
    UNASSIGNED_RESOURCE_ROLE,
} from "@INTEGRATIONS/scheduler/models";

function GroupWrapper({ children }: ChildrenProps) {
    return (
        <div
            style={{
                height: "100%",
                padding: "4px 2px 2px 2px",
            }}
        >
            {children}
        </div>
    );
}

export const groupSummaryFeature = {
    target: "header",
    showTooltip: false,
    summaries: [
        {
            label: "Common summary group",
            renderer: (original: { groupValue: string; events: CalendarEntryModel[] }) => {
                if (original.groupValue === UNASSIGNED_RESOURCE_ROLE) {
                    if (original.events.length === 0) return "";

                    return ReactDOMServer.renderToString(
                        <GroupWrapper>
                            <SummaryItemView
                                eventRecords={original.events}
                                dataSummaryItem="unassigned"
                                color={COLOR["entry-unassigned"]}
                                hoverColor="#E8EDF6"
                                active
                            />
                        </GroupWrapper>,
                    );
                }

                if (original.groupValue === EXTERNAL_TRAINER_RESOURCE_ROLE) {
                    if (original.events.length === 0) return "";

                    return ReactDOMServer.renderToString(
                        <GroupWrapper>
                            <SummaryItemView
                                eventRecords={original.events}
                                dataSummaryItem="external-trainers"
                                color={COLOR["entry-training-event"]}
                                hoverColor="#E9D8FF"
                                active
                            />
                        </GroupWrapper>,
                    );
                }

                return "";
            },
        },
    ],
} satisfies Partial<GroupSummaryConfig>;
