import React from "react";

import classNames from "classnames/bind";

import { DotSpinner } from "@VIEW/components/basic/spinners";

import styles from "./PageLoader.module.scss";

const cx = classNames.bind(styles);

function PageLoader() {
    return (
        <div className={cx("loader-wrapper")}>
            <div className={cx("loader-inner")}>
                <DotSpinner size="large" />
            </div>
        </div>
    );
}

export default React.memo(PageLoader);
