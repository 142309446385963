import React, { ForwardedRef, RefObject, useImperativeHandle, useMemo, useRef } from "react";

import classnames from "classnames/bind";

import { zodResolver } from "@hookform/resolvers/zod";
import { FieldErrors, useController, useForm } from "react-hook-form";
import { RefinementCtx, z } from "zod";

import { UserApiModel } from "@API/services";

import { EventFormWrapper } from "@INTEGRATIONS/scheduler/components/forms/elements";
import { DatesSchema, ResourceSchema, UserSchema } from "@INTEGRATIONS/scheduler/components/forms/schemas";
import { useSchedulerInstance } from "@INTEGRATIONS/scheduler/hooks";
import { calendarResourceMappers } from "@INTEGRATIONS/scheduler/mappers";
import {
    CalendarEntryModel,
    CalendarResource,
    CalendarResourceModel,
    EXTERNAL_TRAINER_RESOURCE_ID,
    UNASSIGNED_RESOURCE_ID,
} from "@INTEGRATIONS/scheduler/models";
import { OnAfterEventSaveExtended, OnBeforeEventDelete, ProjectType } from "@INTEGRATIONS/scheduler/types";
import { excludeResourceGroupHeaderModel, getNextDay, getPreviousDay } from "@INTEGRATIONS/scheduler/utils";
import { getAllResourceRecords } from "@INTEGRATIONS/scheduler/utils/helpers";

import { DatePicker } from "@VIEW/components/basic/inputs/date";
import ResourcePicker from "@VIEW/components/basic/inputs/select/ResourcePicker";

import { CustomErrorMessage } from "@VIEW/components/common";

import styles from "./HolidayEventForm.module.scss";

const cx: CX = classnames.bind(styles);

type FormRef = {
    confirmCancel: () => void;
};

const schema = z
    .object({
        resources: z.array(ResourceSchema),
        dates: DatesSchema,
        user: UserSchema,
    })
    .superRefine(
        (
            arg: {
                resources: CalendarResource[];
                user: UserApiModel;
            },
            ctx: RefinementCtx,
        ) => {
            const { resources, user } = arg;

            if (resources.length === 0) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: "At least one assignee must be selected",
                    path: ["resources"],
                });

                return z.NEVER;
            }

            if (resources.length > 8) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: "Max 8 assignees can be selected",
                    path: ["resources"],
                });

                return z.NEVER;
            }

            if (user.role === "USER") {
                const hasCurrentDepartment = resources.some((resource: CalendarResource) => {
                    return user.departments.includes(resource.role);
                });

                if (!hasCurrentDepartment) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: `At least one assignee should be selected from:\n${user.departments.join(", ")}`,
                        path: ["resources"],
                    });

                    return z.NEVER;
                }
            }

            return true;
        },
    )
    .superRefine(
        (
            arg: {
                resources: CalendarResource[];
            },
            ctx: RefinementCtx,
        ) => {
            const { resources } = arg;

            const someNotAllowed = resources.some((resource: CalendarResource) => {
                return !resource.allowToAddHolidays;
            });

            if (someNotAllowed) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: "Can't add Holiday to some assignees",
                    path: ["resources"],
                });

                return z.NEVER;
            }

            return true;
        },
    )
    .superRefine(
        (
            arg: {
                dates: {
                    endDate: Date | null;
                    startDate: Date | null;
                };
            },
            ctx: RefinementCtx,
        ) => {
            const {
                dates: { startDate, endDate }, //
            } = arg;

            if (startDate && endDate) {
                if (endDate < startDate) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: "End date must be greater than or equal start date",
                        path: ["dates.endDate"],
                    });

                    return z.NEVER;
                }
            }

            return true;
        },
    );

type SchemaInput = z.input<typeof schema>;
type SchemaOutput = z.output<typeof schema>;

function resetEvent(eventRecord: CalendarEntryModel) {
    if (eventRecord.isCreating) {
        eventRecord.remove();
    } else {
        eventRecord.clearChanges();
    }
}

function checkUnsavedChanges(
    oldValues: Omit<SchemaInput, "user">, //
    newValues: Omit<SchemaInput, "user">,
) {
    const newResourcesIds = newValues.resources.map((r: CalendarResource) => r.id);
    const oldResourcesIds = oldValues.resources.map((r: CalendarResource) => r.id);

    return [
        newResourcesIds.length !== oldResourcesIds.length,
        !newResourcesIds.every((newResourceId: string | number) => {
            return oldResourcesIds.includes(newResourceId);
        }),

        oldValues.dates?.startDate?.toString() !== newValues.dates.startDate?.toString(),
        oldValues.dates?.endDate?.toString() !== newValues.dates.endDate?.toString(),
    ].some(Boolean);
}

function useHolidayEventFormData({
    eventRecord, //
    user,
}: {
    eventRecord: CalendarEntryModel;
    user: UserApiModel;
}) {
    const { ref } = useSchedulerInstance();

    function getInstance() {
        return ref.current!.instance;
    }

    const allResources = useMemo(() => {
        const instance = getInstance();

        if (!instance) return [];

        const allRecords = getAllResourceRecords(instance);

        return allRecords
            .filter((resourceModel: CalendarResourceModel) => {
                return (
                    ![
                        UNASSIGNED_RESOURCE_ID, //
                        EXTERNAL_TRAINER_RESOURCE_ID,
                    ].includes(resourceModel.id.toString()) && resourceModel.allowToAddHolidays
                );
            })
            .map((resourceModel: CalendarResourceModel) => {
                return calendarResourceMappers.modelToValue(resourceModel);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        defaults: {
            isUnassigned: eventRecord.isUnassigned,
            resources: eventRecord.isUnassigned
                ? []
                : allResources.filter((resource: CalendarResource) => {
                      return excludeResourceGroupHeaderModel(eventRecord.resources)
                          .map((model: CalendarResourceModel) => model.id)
                          .includes(resource.id);
                  }),
            dates: {
                startDate: eventRecord.startDate,
                endDate: getPreviousDay(eventRecord.endDate),
            },
            user,
        },
        extra: {
            allResources,
        },
    };
}

function useHolidayEventForm({
    eventRecord, //
    onClose,
}: {
    eventRecord: CalendarEntryModel;
    onClose: () => void;
}) {
    const { ref } = useSchedulerInstance();

    function getInstance() {
        return ref.current!.instance;
    }

    async function handleSaveAssignedEvent(values: SchemaOutput) {
        const source = getInstance();

        const allRecords = getAllResourceRecords(source);

        const newResources = allRecords.filter((resourceModel: CalendarResourceModel) => {
            return values.resources.map((resource: CalendarResource) => resource.id).includes(resourceModel.id);
        });

        await setEventData(values, newResources);

        const triggerParam = {
            eventRecord,
            shouldNotifyAssignees: false,
        } satisfies OnAfterEventSaveExtended;

        await source.trigger("afterEventSave", triggerParam);

        onClose();
    }

    async function setEventData(values: SchemaOutput, newResources: string | CalendarResourceModel[]) {
        console.log(values);

        const source = getInstance();

        source.assignmentStore.suspendAutoCommit();
        source.suspendRefresh();

        await eventRecord.setStartDate(values.dates.startDate);
        await eventRecord.setEndDate(getNextDay(values.dates.endDate));

        eventRecord.beginBatch();

        eventRecord.update({
            entryType: "HOLIDAYS",
            projectType: ProjectType.HOLIDAY,
            projects: [],
            name: "",
            isUnassigned: false,
            location: null,
            description: "",
            isTentative: true,

            // holiday
            isCreatedFromSchedulerHoliday: true,

            // training
            isTrainingEvent: false,
            trainingLocation: "",
            trainingTrainerType: null,
            trainingTrainerName: "",
            trainingTrainerEmail: "",
            trainingIsStatusConfirmed: false,
            trainingEventType: null,
            trainingEventSubType: null,
            trainingResourceUniqueId: null,
        });

        source.eventStore.assignEventToResource(eventRecord, newResources, true);

        eventRecord.endBatch();

        eventRecord.isCreating = false; // eslint-disable-line no-param-reassign

        source.assignmentStore.resumeAutoCommit();
        await source.resumeRefresh(true);
    }

    async function handleDelete() {
        const source = getInstance();

        const triggerData = {
            eventRecords: [eventRecord],
            isConfirmed: true,
        } satisfies OnBeforeEventDelete;

        await source.trigger("beforeEventDelete", triggerData);

        eventRecord.remove();

        onClose();
    }

    function handleCancel() {
        resetEvent(eventRecord);

        onClose();
    }

    function onValid(values: SchemaOutput) {
        void handleSaveAssignedEvent(values);
    }

    function onInvalid(errors: FieldErrors<SchemaOutput>) {
        console.log("Validation Error", errors);
    }

    return {
        onValid,
        onInvalid,
        handleDelete,
        handleCancel,
    };
}

const HolidayEventForm = React.forwardRef(
    (
        {
            eventRecord, //
            onClose,
            user,
        }: Props,
        ref: ForwardedRef<FormRef>,
    ) => {
        const formRef: RefObject<{
            confirmCancel: (canCancel: () => boolean) => void;
        }> = useRef(null);

        useImperativeHandle(ref, () => ({
            confirmCancel: () =>
                formRef.current?.confirmCancel(() =>
                    checkUnsavedChanges(defaults, {
                        resources: resources.value,
                        dates: {
                            startDate: startDate.value,
                            endDate: endDate.value,
                        },
                    }),
                ),
        }));

        const {
            onValid, //
            onInvalid,
            handleDelete,
            handleCancel,
        } = useHolidayEventForm({
            eventRecord,
            onClose,
        });

        const {
            defaults, //
            extra: {
                allResources, //
            },
        } = useHolidayEventFormData({
            eventRecord,
            user,
        });

        const { handleSubmit, control } = useForm<SchemaInput, undefined, SchemaOutput>({
            defaultValues: defaults,
            resolver: zodResolver(schema),
            mode: "onChange",
        });

        const {
            field: resources, //
            fieldState: { error: resourcesError },
        } = useController({
            name: "resources",
            control,
        });

        const {
            field: startDate, //
            fieldState: { error: startDateError },
        } = useController({
            name: "dates.startDate",
            control,
        });

        const {
            field: endDate, //
            fieldState: { error: endDateError },
        } = useController({
            name: "dates.endDate",
            control,
        });

        return (
            <div className={cx("holiday-event-form")}>
                <EventFormWrapper
                    ref={formRef}
                    onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();

                        void handleSubmit(onValid, onInvalid)(event);
                    }}
                    isCreating={eventRecord.isCreating}
                    onDelete={() => void handleDelete()}
                    onCancel={handleCancel}
                >
                    <div className={cx("assigners-wrapper")}>
                        <div className={cx("dropdown")}>
                            <div className={cx("title")}>Assigned to</div>

                            <ResourcePicker<CalendarResource>
                                resources={allResources}
                                selectedResources={resources.value}
                                onChange={(newResources: CalendarResource[]) => {
                                    resources.onChange(newResources);
                                }}
                            />
                        </div>
                        <CustomErrorMessage
                            error={{
                                name: "resources",
                                errors: {
                                    resources: resourcesError,
                                },
                            }}
                        />
                    </div>
                    <div className={cx("date-range-wrapper")}>
                        <DatePicker
                            startDate={startDate.value}
                            endDate={endDate.value}
                            type={startDateError || endDateError ? "error" : "default"}
                            fixedHeight
                            disabledKeyboardNavigation
                            onChange={([newStartDate, newEndDate]: [Date | null, Date | null]) => {
                                startDate.onChange(newStartDate);
                                endDate.onChange(newEndDate);
                            }}
                            inline
                        />
                        <CustomErrorMessage
                            error={[
                                startDateError && {
                                    name: "dates.startDate",
                                    errors: {
                                        "dates.startDate": startDateError,
                                    },
                                },
                                endDateError && {
                                    name: "dates.endDate",
                                    errors: {
                                        "dates.endDate": endDateError,
                                    },
                                },
                            ]}
                        />
                    </div>
                </EventFormWrapper>
            </div>
        );
    },
);

HolidayEventForm.displayName = "HolidayEventForm";

interface Props {
    eventRecord: CalendarEntryModel;
    onClose: () => void;
    user: UserApiModel;
}

export default HolidayEventForm;
