import { Model, Store, StoreConfig } from "@bryntum/schedulerpro";

import { CalendarEntryOpeningProjectModel } from "@INTEGRATIONS/scheduler/models";

export class AppEventOpeningProjectStore extends Store {
    constructor(params: Partial<StoreConfig>) {
        super(params);
    }

    add(
        records: CalendarEntryOpeningProjectModel | CalendarEntryOpeningProjectModel[], //
        silent?: boolean,
    ): Model[] {
        return super.add(records, silent);
    }
}

export const appEventOpeningProjectStore = new AppEventOpeningProjectStore({
    modelClass: CalendarEntryOpeningProjectModel,
    data: [],
});
