import React from "react";

import classnames from "classnames/bind";

import { COLOR } from "@CORE/constants";

import styles from "./DotSpinner.module.scss";


const cx = classnames.bind(styles);

type SpinnerSize = "large" | "medium" | "small";

function DotSpinner({
    color = defaultProps.color, //
    size,
}: Props) {
    return (
        <div
            className={cx("dot-spinner", {
                large: size === "large",
                medium: size === "medium",
                small: size === "small",
            })}
        >
            {[1, 2, 3, 4, 5, 6, 7, 8].map((dot: number) => {
                return (
                    <div
                        key={dot}
                        style={
                            {
                                "--color": color,
                            } as React.CSSProperties
                        }
                        className={cx("dot")}
                    />
                );
            })}
        </div>
    );
}

const defaultProps = {
    color: COLOR["blue-main"],
};

interface Props {
    color?: React.CSSProperties["color"];
    size: SpinnerSize;
}

export default DotSpinner;
