import { useMemo } from "react";

import { CancelledException } from "@CORE/exceptions";

import { CalendarEntryApiModel } from "@API/services";

import { useApiContext } from "@CONTEXT/hooks";

import { useNotifications } from "@VIEW/hooks";

interface FindAllParams {
    dates: {
        from: string;
        to: string;
    };
}

function createHook() {
    return function useCalendarEntriesApi() {
        const { api } = useApiContext();

        const { notify } = useNotifications();

        const queries = useMemo(
            () => ({
                findAll: async (params: FindAllParams, signal?: AbortSignal): Promise<CalendarEntryApiModel[]> => {
                    try {
                        return await api.calendarEntries.findAll(params, signal);
                    } catch (error: unknown) {
                        if (error instanceof Error && !(error instanceof CancelledException)) {
                            notify.error(error.message);
                        }

                        return [];
                    }
                },
            }),
            [api, notify],
        );

        const mutations = useMemo(
            () => ({
                upsert: async (items: CalendarEntryApiModel[]) => {
                    try {
                        await api.calendarEntries.upsert(items);
                    } catch (error: unknown) {
                        if (error instanceof Error) {
                            notify.error(error.message);
                        }
                    }
                },

                delete: async (ids: (string | number)[]) => {
                    try {
                        await Promise.all(ids.map((id: string | number) => api.calendarEntries.delete(id)));
                    } catch (error: unknown) {
                        if (error instanceof Error) {
                            notify.error(error.message);
                        }
                    }
                },
            }),
            [api, notify],
        );

        return {
            queries,
            mutations,
        };
    };
}

export const useCalendarEntriesApi = createHook();
