import React from "react";

import classnames from "classnames/bind";

import { TrainingEventSubType, TrainingEventType } from "@INTEGRATIONS/scheduler/types";

import BasicSelect from "@VIEW/components/basic/inputs/select/BasicSelect";
import { Option } from "@VIEW/components/basic/inputs/select/BasicSelect/Select.com";

import styles from "./TrainingEventTypeSelect.module.scss";

const cx: CX = classnames.bind(styles);

function TrainingEventTypeSelect({
    value, //
    onChange,
    trainingEventTypeOptions,
    placeholder,
    type,
}: Props) {
    const handleChange = (newValue: Option | Option[] | null) => {
        onChange(newValue as TrainingEventTypeOption);
    };

    const selectedValue = trainingEventTypeOptions.find((option: TrainingEventTypeOption) => option.value === value);

    return (
        <div className={cx("training-event-type-select")}>
            <BasicSelect<Option>
                placeholder={placeholder}
                isMultiple={false}
                options={trainingEventTypeOptions}
                value={selectedValue || ""}
                onChange={handleChange}
                isClearable={false}
                type={type}
            />
        </div>
    );
}

export type TrainingEventTypeOption = {
    value: TrainingEventType | TrainingEventSubType;
    label: string;
};

type Props = {
    trainingEventTypeOptions: TrainingEventTypeOption[];
    onChange: (newValue: TrainingEventTypeOption) => void;
    value: string | null;
    placeholder: string;
    type?: "error" | "default";
};

export default TrainingEventTypeSelect;
