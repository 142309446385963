import { DateHelper } from "@bryntum/schedulerpro";
import { AxiosResponse } from "axios";
import { EMPTY, catchError, expand, from, lastValueFrom, map, reduce, tap } from "rxjs";
import { z } from "zod";

import { BaseApi } from "@API/base";

import { TrainingEventSubType, TrainingEventType, TrainingTrainerType } from "@INTEGRATIONS/scheduler/types";
import { removeTimezoneOffset } from "@INTEGRATIONS/scheduler/utils";

import { throwApiError } from "@UTILS/helpers";

import config from "../../config";

const schema = z.object({
    "@row.id": z.number(),
    "Id": z.string(),

    "Type": z.union([
        z.literal("PMS Config"),
        z.literal("PMS Golive"),
        z.literal("PMS Training"),
        z.literal("Reservation"),
        z.literal("Reservation System Testing"),
        z.literal("Reservation Training"),
        z.literal("Ad-hoc field"),
        z.literal("S&C (Config- Training & Go-live)"),
        z.literal("Conversion"),
        z.literal("Conversion Script"),
        z.literal("Discovery Call"),
        z.literal("OPERA Sprint Training"),
        z.literal("Post Live Call"),
    ]),
    "Sub Type": z.union([
        z.literal("Training"),
        z.literal("Golive"),
        z.literal("Config"),
        z.literal("Kickoff"),
        z.literal("Phase 1"),
        z.literal("Phase 2"),
        z.literal("System Testing"),
        z.literal("System Check"),
        z.literal("Exit"),
        z.literal("Script"),
    ]),

    "Status": z.string().nullable(),
    "Start Date": z.coerce.date(),
    "End Date": z.coerce.date(),
    "Duration (days)": z.number(),
    "Location": z.string().nullable(),
    "Title": z.string().nullable(),
    "Description": z.string().nullable(),
    "Project": z.string().nullable(),

    "Unassigned": z.boolean(),
    "Internal": z.boolean(),
    "External": z.boolean(),
    "External Trainer Name": z.string().nullable(),
    "External Trainer Email": z.string().nullable(),
    "Internal Trainer 1": z.string().nullable(),
    "Internal Trainer 2": z.string().nullable(),
    "Internal Trainer 3": z.string().nullable(),
    "Internal Trainer 4": z.string().nullable(),
    "Internal Trainer 5": z.string().nullable(),
    "Internal Trainer 6": z.string().nullable(),
    "Internal Trainer 7": z.string().nullable(),
    "Internal Trainer 8": z.string().nullable(),

    "Resource Unique ID": z.string().nullable(),
});

type SchemaInput = z.input<typeof schema>;
type SchemaOutput = z.output<typeof schema>;

export type TrainingEventApiModel = {
    id: number | string;
    name: string;
    type: TrainingEventType | null;
    subType: TrainingEventSubType | null;
    isStatusConfirmed: boolean;
    startDate: Date;
    endDate: Date;
    location: string;
    trainerType: TrainingTrainerType | null;
    trainerName: string;
    trainerEmail: string;
    description: string;
    projectIds: string[];
    resourceEmails: string[];
    resourceUniqueId: string | null;
};

type TrainingEventUpsertBody = {
    "Id"?: string | number;
    "Type": SchemaOutput["Type"] | "";
    "Sub Type": SchemaOutput["Sub Type"] | "";
    "Status": string;
    "Start Date": string;
    "Duration (days)": number;
    "Location": string;
    "Title": string;
    "Description": string;
    "Project": string | null;

    "Unassigned": boolean;
    "Internal": boolean;
    "External": boolean;
    "External Trainer Name": string;
    "External Trainer Email": string;
    "Internal Trainer 1": string | null;
    "Internal Trainer 2": string | null;
    "Internal Trainer 3": string | null;
    "Internal Trainer 4": string | null;
    "Internal Trainer 5": string | null;
    "Internal Trainer 6": string | null;
    "Internal Trainer 7": string | null;
    "Internal Trainer 8": string | null;

    "Resource Unique ID": string | null;
};

interface FindParams {
    page: number;
    dates: {
        from: string;
        to: string;
    };
}

interface FindAllParams {
    dates: {
        from: string;
        to: string;
    };
}

const MAX_TOTAL_RECORDS = 500;
const DURATION_DIVIDER = 24 * 60 * 60;

const trainingEventTypesMapper = {
    valueToType: (str: SchemaOutput["Type"]): TrainingEventType => {
        const _ = {
            "PMS Config": TrainingEventType.PMS_CONFIG,
            "PMS Golive": TrainingEventType.PMS_GOLIVE,
            "PMS Training": TrainingEventType.PMS_TRAINING,
            "Reservation": TrainingEventType.RESERVATION,
            "Reservation System Testing": TrainingEventType.RESERVATION_SYSTEM_TESTING,
            "Reservation Training": TrainingEventType.RESERVATION_TRAINING,
            "Ad-hoc field": TrainingEventType.AD_HOC_FIELD,
            "S&C (Config- Training & Go-live)": TrainingEventType.S_C,
            "Conversion": TrainingEventType.CONVERSION,
            "Conversion Script": TrainingEventType.CONVERSION_SCRIPT,
            "Discovery Call": TrainingEventType.DISCOVERY_CALL,
            "OPERA Sprint Training": TrainingEventType.OPERA_SPRINT_TRAINING,
            "Post Live Call": TrainingEventType.POST_LIVE_CALL,
        } satisfies Record<SchemaOutput["Type"], TrainingEventType>;

        return _[str];
    },
    valueToSubType: (str: SchemaOutput["Sub Type"]): TrainingEventSubType => {
        const _ = {
            "Training": TrainingEventSubType.TRAINING,
            "Golive": TrainingEventSubType.GOLIVE,
            "Config": TrainingEventSubType.CONFIG,
            "Kickoff": TrainingEventSubType.KICKOFF,
            "Phase 1": TrainingEventSubType.PHASE_1,
            "Phase 2": TrainingEventSubType.PHASE_2,
            "System Testing": TrainingEventSubType.SYSTEM_TESTING,
            "System Check": TrainingEventSubType.SYSTEM_CHECK,
            "Exit": TrainingEventSubType.EXIT,
            "Script": TrainingEventSubType.SCRIPT,
        } satisfies Record<SchemaOutput["Sub Type"], TrainingEventSubType>;

        return _[str];
    },
    typeToValue: (t: TrainingEventType): SchemaOutput["Type"] => {
        const _ = {
            [TrainingEventType.RESERVATION_TRAINING]: "Reservation Training",
            [TrainingEventType.PMS_GOLIVE]: "PMS Golive",
            [TrainingEventType.CONVERSION]: "Conversion",
            [TrainingEventType.CONVERSION_SCRIPT]: "Conversion Script",
            [TrainingEventType.RESERVATION]: "Reservation",
            [TrainingEventType.RESERVATION_SYSTEM_TESTING]: "Reservation System Testing",
            [TrainingEventType.S_C]: "S&C (Config- Training & Go-live)",
            [TrainingEventType.OPERA_SPRINT_TRAINING]: "OPERA Sprint Training",
            [TrainingEventType.PMS_CONFIG]: "PMS Config",
            [TrainingEventType.DISCOVERY_CALL]: "Discovery Call",
            [TrainingEventType.AD_HOC_FIELD]: "Ad-hoc field",
            [TrainingEventType.PMS_TRAINING]: "PMS Training",
            [TrainingEventType.POST_LIVE_CALL]: "Post Live Call",
        } satisfies Record<TrainingEventType, SchemaOutput["Type"]>;

        return _[t];
    },
    subTypeToValue: (t: TrainingEventSubType): SchemaOutput["Sub Type"] => {
        const _ = {
            [TrainingEventSubType.GOLIVE]: "Golive",
            [TrainingEventSubType.CONFIG]: "Config",
            [TrainingEventSubType.KICKOFF]: "Kickoff",
            [TrainingEventSubType.SCRIPT]: "Script",
            [TrainingEventSubType.PHASE_2]: "Phase 2",
            [TrainingEventSubType.PHASE_1]: "Phase 1",
            [TrainingEventSubType.SYSTEM_TESTING]: "System Testing",
            [TrainingEventSubType.SYSTEM_CHECK]: "System Check",
            [TrainingEventSubType.EXIT]: "Exit",
            [TrainingEventSubType.TRAINING]: "Training",
        } satisfies Record<TrainingEventSubType, SchemaOutput["Sub Type"]>;

        return _[t];
    },
};

function createUpsertData(
    event: Omit<TrainingEventApiModel, "id"> & { id?: string | number },
    projectId: string | null,
): TrainingEventUpsertBody {
    const { startDate, endDate } = removeTimezoneOffset(event.startDate, event.endDate, -1);

    const extraCheck = z
        .object({
            startDate: z.date(),
            endDate: z.date(),
        })
        .safeParse({
            startDate,
            endDate,
        });

    if (!extraCheck.success) {
        throw new Error("Can't create/update training event:\n'Start Date' or 'End Date' are missing or invalid");
    }

    return {
        "Id": event.id,
        "Type": event.type ? trainingEventTypesMapper.typeToValue(event.type) : "",
        "Sub Type": event.subType ? trainingEventTypesMapper.subTypeToValue(event.subType) : "",
        "Status": event.isStatusConfirmed ? "Confirmed" : "Tentative",
        "Start Date": startDate.toISOString(),
        "Duration (days)": DateHelper.diff(startDate, endDate, "day") * DURATION_DIVIDER,
        "Location": event.location,
        "Title": event.name,
        "Description": event.description,
        "Project": projectId,

        "Unassigned": event.trainerType === null,
        "Internal": event.trainerType === TrainingTrainerType.INTERNAL,
        "External": event.trainerType === TrainingTrainerType.EXTERNAL,
        "External Trainer Name": event.trainerName,
        "External Trainer Email": event.trainerEmail,
        "Internal Trainer 1": event.resourceEmails[0] || null,
        "Internal Trainer 2": event.resourceEmails[1] || null,
        "Internal Trainer 3": event.resourceEmails[2] || null,
        "Internal Trainer 4": event.resourceEmails[3] || null,
        "Internal Trainer 5": event.resourceEmails[4] || null,
        "Internal Trainer 6": event.resourceEmails[5] || null,
        "Internal Trainer 7": event.resourceEmails[6] || null,
        "Internal Trainer 8": event.resourceEmails[7] || null,

        "Resource Unique ID": event.resourceUniqueId,
    };
}

export class TrainingEventsApi extends BaseApi {
    async findAll(params: FindAllParams, signal?: AbortSignal): Promise<TrainingEventApiModel[]> {
        let page = 1;

        return lastValueFrom<TrainingEventApiModel[]>(
            from(this.find({ page: page++, dates: params.dates }, signal)).pipe(
                expand((response: { data: TrainingEventApiModel[]; totalCount: number }) => {
                    return response.totalCount === MAX_TOTAL_RECORDS
                        ? this.find({ page: page++, dates: params.dates })
                        : EMPTY;
                }),
                reduce(
                    (acc: TrainingEventApiModel[], current: { data: TrainingEventApiModel[]; totalCount: number }) =>
                        acc.concat(current.data),
                    [],
                ),
                map((events: TrainingEventApiModel[]) => {
                    const simple: TrainingEventApiModel[] = [];
                    const complex = new Map<string, TrainingEventApiModel[]>();

                    events.forEach((event: TrainingEventApiModel) => {
                        if (event.resourceUniqueId === null) {
                            simple.push(event);
                        } else {
                            const { resourceUniqueId } = event;

                            const prevEvents = complex.get(resourceUniqueId) || [];

                            complex.set(resourceUniqueId, [...prevEvents, event]);
                        }
                    });

                    return [
                        ...simple,
                        ...Array.from(complex.values())
                            .map((value: TrainingEventApiModel[]) => {
                                if (!value[0]) return null;

                                const event = value[0];

                                return {
                                    ...event,
                                    projectIds: value
                                        .map((e: TrainingEventApiModel) => {
                                            return e.projectIds[0] || null;
                                        })
                                        .filter(Boolean),
                                };
                            })
                            .filter(Boolean),
                    ];
                }),
            ),
        );
    }

    async upsert({
        events,
        shouldRemovePrevItems,
    }: {
        events: TrainingEventApiModel[];
        shouldRemovePrevItems: boolean;
    }): Promise<unknown> {
        let body: TrainingEventUpsertBody[];

        const resourceUniqueIds = events.map((event: TrainingEventApiModel) => event.resourceUniqueId).filter(Boolean);

        if (shouldRemovePrevItems) {
            try {
                await Promise.all(
                    resourceUniqueIds.map((id: string | number) => {
                        return this.deleteByResourceUniqueId(id);
                    }),
                );
            } catch (error: unknown) {
                if (error instanceof Error) {
                    throw new Error(error.message);
                }
            }

            body = events.reduce((acc: TrainingEventUpsertBody[], event: TrainingEventApiModel) => {
                if (event.projectIds.length < 2) {
                    return acc.concat(createUpsertData(event, event.projectIds[0] || null));
                }

                return acc.concat(
                    event.projectIds.map((pId: string) => {
                        return createUpsertData(
                            {
                                ...event,
                                id: undefined,
                            },
                            pId,
                        );
                    }),
                );
            }, []);
        } else {
            const eventsIds = await Promise.all(
                resourceUniqueIds.map((id: string | number) => {
                    return this.findEventsIdsByResourceUniqueId(id);
                }),
            );

            const flattenEventsIds = eventsIds.flat();

            body = events.reduce((acc: TrainingEventUpsertBody[], event: TrainingEventApiModel) => {
                if (event.projectIds.length < 2) {
                    return acc.concat(createUpsertData(event, event.projectIds[0] || null));
                }

                return acc.concat(
                    event.projectIds.map((pId: string) => {
                        const prevId = flattenEventsIds.find(({ projectId }: { projectId: string | null }) => {
                            return pId === projectId;
                        })?.id;

                        return createUpsertData(
                            {
                                ...event,
                                id: prevId,
                            },
                            pId,
                        );
                    }),
                );
            }, []);
        }

        const url = `/${config.TRAINING_EVENTS_TABLE_NAME}/upsert.json`;

        type Response = [
            | {
                  status: 200; // updated
                  id: number;
                  key: string;
              }
            | {
                  status: 400; // error
                  id: number;
                  key: string;
                  errors: [
                      {
                          error: 409;
                          source?: string;
                          message: string;
                      },
                  ];
              }
            | {
                  status: 201; // created
                  id: number;
                  key: string;
              },
        ];

        return lastValueFrom(
            from(this.client.post(url, body))
                .pipe(
                    tap((response: AxiosResponse<Response>) => {
                        const data = response.data[0];

                        if (data.status === 400) {
                            console.error(data);

                            if (data.errors[0]) {
                                const error = data.errors[0];

                                throw new Error(
                                    error.message + (error.source ? "\nSource [" + error.source + "]" : ""),
                                );
                            } else {
                                throw new Error("Unexpected error");
                            }
                        }
                    }),
                )
                .pipe(
                    catchError((error: unknown) => {
                        throwApiError(error, "Can't create/update training event: ");
                    }),
                ),
        );
    }

    async deleteById(id: string | number): Promise<unknown> {
        const url = `/${config.TRAINING_EVENTS_TABLE_NAME}/delete.json?key=${id}`;

        type Response = [
            | {
                  status: 200;
                  id: number;
              }
            | {
                  status: 403;
                  id: number;
                  error: {
                      error: 403;
                      code: 4000;
                      message: string;
                  };
              },
        ];

        return lastValueFrom(
            from(this.client.get(url))
                .pipe(
                    tap((response: AxiosResponse<Response>) => {
                        const data = response.data[0];

                        if (data.status !== 200) {
                            console.error(data);

                            throw new Error(data.error.message);
                        }
                    }),
                )
                .pipe(
                    catchError((error: unknown) => {
                        throwApiError(error, "Can't delete training event: ");
                    }),
                ),
        );
    }

    async deleteByResourceUniqueId(resourceUniqueId: number | string): Promise<void> {
        const ids = await this.findEventsIdsByResourceUniqueId(resourceUniqueId);

        if (ids.length === 0) return;

        await Promise.all(
            ids.map(({ id }: { id: string | number }) => {
                return this.deleteById(id);
            }),
        );
    }

    private async find(
        params: FindParams,
        signal?: AbortSignal,
    ): Promise<{ data: TrainingEventApiModel[]; totalCount: number }> {
        const { page = 1, dates } = params;

        const skip = MAX_TOTAL_RECORDS * (page - 1);
        const urlParams = new URLSearchParams();

        urlParams.append("column", "Id");
        urlParams.append("column", "Type");
        urlParams.append("column", "Sub Type");
        urlParams.append("column", "Status");
        urlParams.append("column", "Start Date");
        urlParams.append("column", "End Date");
        urlParams.append("column", "Duration (days)");
        urlParams.append("column", "Location");
        urlParams.append("column", "Title");
        urlParams.append("column", "Description");
        urlParams.append("column", "Project");

        urlParams.append("column", "Unassigned");
        urlParams.append("column", "Internal");
        urlParams.append("column", "External");
        urlParams.append("column", "External Trainer Name");
        urlParams.append("column", "External Trainer Email");
        urlParams.append("column", "Internal Trainer 1");
        urlParams.append("column", "Internal Trainer 2");
        urlParams.append("column", "Internal Trainer 3");
        urlParams.append("column", "Internal Trainer 4");
        urlParams.append("column", "Internal Trainer 5");
        urlParams.append("column", "Internal Trainer 6");
        urlParams.append("column", "Internal Trainer 7");
        urlParams.append("column", "Internal Trainer 8");

        urlParams.append("column", "Resource Unique ID");

        urlParams.append("skip", skip.toString());

        urlParams.append(
            "filter",
            [
                `([Start Date]>=#${dates.from}# and [Start Date]<=#${dates.to}#)`, //
                `([End Date]>=#${dates.from}# and [End Date]<=#${dates.to}#)`,
                `([Start Date]<=#${dates.from}# and [End Date]>=#${dates.to}#)`,
            ].join(" or "),
        );

        return lastValueFrom<{ data: TrainingEventApiModel[]; totalCount: number }>(
            from(
                this.client.get(`/${config.TRAINING_EVENTS_TABLE_NAME}/select.json?${urlParams.toString()}`, {
                    signal,
                }),
            )
                .pipe(
                    map((response: AxiosResponse<SchemaInput[]>) => {
                        return {
                            data: response.data
                                .map((item: SchemaInput) => {
                                    const parsed = schema.safeParse(item);

                                    if (parsed.success) {
                                        return parsed.data;
                                    }

                                    return null;
                                })
                                .filter(Boolean)
                                .map<TrainingEventApiModel>((item: SchemaOutput) => {
                                    const trainerType = item["Internal"]
                                        ? TrainingTrainerType.INTERNAL
                                        : item["External"]
                                        ? TrainingTrainerType.EXTERNAL
                                        : null;

                                    const localStartDate = item["Start Date"];
                                    const localEndDate = DateHelper.add(
                                        item["Start Date"],
                                        Math.ceil(item["Duration (days)"] / DURATION_DIVIDER),
                                        "day",
                                    );

                                    const { startDate, endDate } = removeTimezoneOffset(
                                        localStartDate,
                                        localEndDate,
                                        1,
                                    );

                                    return {
                                        id: item.Id,
                                        name: item.Title || "",
                                        type: trainingEventTypesMapper.valueToType(item["Type"]),
                                        subType: trainingEventTypesMapper.valueToSubType(item["Sub Type"]),
                                        isStatusConfirmed: item.Status === "Confirmed",
                                        startDate,
                                        endDate,
                                        location: item.Location || "",
                                        trainerType,
                                        trainerName: item["External Trainer Name"] || "",
                                        trainerEmail: item["External Trainer Email"] || "",
                                        description: item.Description || "",
                                        projectIds: [item.Project].filter(Boolean),
                                        resourceEmails: [
                                            item["Internal Trainer 1"],
                                            item["Internal Trainer 2"],
                                            item["Internal Trainer 3"],
                                            item["Internal Trainer 4"],
                                            item["Internal Trainer 5"],
                                            item["Internal Trainer 6"],
                                            item["Internal Trainer 7"],
                                            item["Internal Trainer 8"],
                                        ]
                                            .filter((resource: string | null | undefined) => !!resource)
                                            .map((resource: string | null | undefined) => {
                                                return resource?.match(/<(.*)>/)?.[1] || "";
                                            }),
                                        resourceUniqueId: item["Resource Unique ID"],
                                    };
                                }),
                            totalCount: response.data.length,
                        };
                    }),
                )
                .pipe(
                    catchError((error: unknown) => {
                        throwApiError(error, "Can't get training events: ");
                    }),
                ),
        );
    }

    private async findEventsIdsByResourceUniqueId(resourceUniqueId: number | string): Promise<
        {
            id: string | number;
            projectId: string | null;
        }[]
    > {
        const urlParams = new URLSearchParams();

        urlParams.append("filter", `[Resource Unique ID]="${resourceUniqueId}"`);

        return lastValueFrom(
            from(this.client.get(`/${config.TRAINING_EVENTS_TABLE_NAME}/select.json?${urlParams.toString()}`))
                .pipe(
                    map((response: AxiosResponse<SchemaInput[]>) => {
                        return response.data.map((item: SchemaInput) => {
                            return {
                                id: item.Id,
                                projectId: item.Project,
                            };
                        });
                    }),
                )
                .pipe(
                    catchError((error: unknown) => {
                        throwApiError(error, "Can't find training events by resource unique id: ");
                    }),
                ),
        );
    }
}
