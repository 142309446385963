import React, {
    ForwardedRef,
    RefObject,
    useCallback,
    useImperativeHandle,
    useLayoutEffect,
    useMemo,
    useRef,
} from "react";

import classnames from "classnames/bind";

import { DateHelper } from "@bryntum/schedulerpro";
import { zodResolver } from "@hookform/resolvers/zod";
import { FieldErrors, useController, useForm } from "react-hook-form";
import { RefinementCtx, z } from "zod";

import { LocationApiModel, UserApiModel } from "@API/services";

import { EventFormWrapper } from "@INTEGRATIONS/scheduler/components/forms/elements";
import {
    DatesSchema,
    LocationSchema,
    ProjectSchema,
    ResourceSchema,
    UserSchema,
} from "@INTEGRATIONS/scheduler/components/forms/schemas";
import { useSchedulerInstance } from "@INTEGRATIONS/scheduler/hooks";
import { calendarEntryProjectMappers, calendarResourceMappers, locationMappers } from "@INTEGRATIONS/scheduler/mappers";
import {
    AppLocation,
    CalendarEntryModel,
    CalendarEntryNonOpeningProject,
    CalendarEntryOpeningProject,
    CalendarResource,
    CalendarResourceModel,
    UNASSIGNED_RESOURCE_ID,
} from "@INTEGRATIONS/scheduler/models";
import {
    CalendarEntryProject,
    OnAfterEventSaveExtended,
    OnBeforeEventDelete,
    ProjectType,
} from "@INTEGRATIONS/scheduler/types";
import { excludeResourceGroupHeaderModel, getNextDay, getPreviousDay } from "@INTEGRATIONS/scheduler/utils";
import {
    getAllResourceRecords,
    getExcludedDateIntervals,
    renderSlideLabel,
    resetEvent,
} from "@INTEGRATIONS/scheduler/utils/helpers";

import SlideCheckbox from "@VIEW/components/basic/inputs/checkbox/SlideCheckbox";
import { DatePicker } from "@VIEW/components/basic/inputs/date";
import { BasicInput } from "@VIEW/components/basic/inputs/input";
import LocationsSelect from "@VIEW/components/basic/inputs/select/LocationsSelect";
import ProjectsSelect from "@VIEW/components/basic/inputs/select/ProjectsSelect";
import ResourcePicker from "@VIEW/components/basic/inputs/select/ResourcePicker";
import { BasicTextarea } from "@VIEW/components/basic/inputs/textarea";

import { CustomErrorMessage } from "@VIEW/components/common";

import styles from "./BasicEventForm.module.scss";

const cx: CX = classnames.bind(styles);

type FormRef = {
    confirmCancel: () => void;
};

type ProjectTypeOption = {
    value: ProjectType;
    label: string;
};

const schema = z
    .object({
        projectType: z.nativeEnum(ProjectType),
        projects: z.array(ProjectSchema),
        location: LocationSchema.nullable(),
        resources: z.array(ResourceSchema),
        isUnassigned: z.boolean(),
        isTentative: z.boolean(),
        label: z.string().trim().min(1, "Label is not allowed to be empty"),
        description: z.string().trim().min(1, "Description is not allowed to be empty"),
        dates: DatesSchema,
        shouldNotifyAssignees: z.boolean(),

        // extra
        excludeDateIntervals: z.array(
            z.object({
                start: z.date(),
                end: z.date(),
            }),
        ),
        user: UserSchema,
    })
    .superRefine(
        (
            arg: {
                projectType: ProjectType;
                projects: CalendarEntryProject[];
            },
            ctx: RefinementCtx,
        ) => {
            const { projectType, projects } = arg;

            if (projectType !== ProjectType.NOT_PROJECT_RELATED) {
                if (projects.length === 0) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: "At least one project must be selected",
                        path: ["projects"],
                    });

                    return z.NEVER;
                }

                if (projects.length > 4) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: "Max 4 projects can be selected",
                        path: ["projects"],
                    });

                    return z.NEVER;
                }
            }

            return true;
        },
    )
    .superRefine(
        (
            arg: {
                projectType: ProjectType;
                location: AppLocation | null;
            },
            ctx: RefinementCtx,
        ) => {
            const { projectType, location } = arg;

            if (projectType !== ProjectType.NOT_PROJECT_RELATED && location === null) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: "Location is not allowed to be empty",
                    path: ["location"],
                });

                return z.NEVER;
            }

            return true;
        },
    )
    .superRefine(
        (
            arg: {
                isUnassigned: boolean;
                resources: CalendarResource[];
                user: UserApiModel;
            },
            ctx: RefinementCtx,
        ) => {
            const { isUnassigned, resources, user } = arg;

            if (isUnassigned) return true;

            if (resources.length === 0) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: "At least one assignee must be selected",
                    path: ["resources"],
                });

                return z.NEVER;
            }

            if (resources.length > 8) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: "Max 8 assignees can be selected",
                    path: ["resources"],
                });

                return z.NEVER;
            }

            if (user.role === "USER") {
                const hasCurrentDepartment = resources.some((resource: CalendarResource) => {
                    return user.departments.includes(resource.role);
                });

                if (!hasCurrentDepartment) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: `At least one assignee should be selected from:\n${user.departments.join(", ")}`,
                        path: ["resources"],
                    });

                    return z.NEVER;
                }
            }

            return true;
        },
    )
    .superRefine(
        (
            arg: {
                isUnassigned: boolean;
                dates: {
                    endDate: Date | null;
                    startDate: Date | null;
                };
                excludeDateIntervals: {
                    start: Date;
                    end: Date;
                }[];
            },
            ctx: RefinementCtx,
        ) => {
            const {
                isUnassigned,
                dates: { startDate, endDate },
                excludeDateIntervals,
            } = arg;

            if (startDate && endDate) {
                if (endDate < startDate) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: "End date must be greater than or equal start date",
                        path: ["dates.endDate"],
                    });

                    return z.NEVER;
                }

                if (!isUnassigned) {
                    const someOverlap = excludeDateIntervals.some((dates: { start: Date; end: Date }) => {
                        return DateHelper.intersectSpans(
                            DateHelper.add(startDate, -1, "day"), //
                            DateHelper.add(endDate, 1, "day"),
                            dates.start,
                            dates.end,
                        );
                    });

                    if (someOverlap) {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message: "Dates overlap blocked period",
                            path: ["dates.endDate"],
                        });

                        return z.NEVER;
                    }
                }
            }

            return true;
        },
    );

type SchemaInput = z.input<typeof schema>;
type SchemaOutput = z.output<typeof schema>;

function checkUnsavedChanges(
    oldValues: Omit<SchemaInput, "shouldNotifyAssignees" | "excludeDateIntervals" | "user">,
    newValues: Omit<SchemaInput, "shouldNotifyAssignees" | "excludeDateIntervals" | "user">,
) {
    const newProjectsIds = newValues.projects.map((p: CalendarEntryProject) => p.id);
    const oldProjectsIds = oldValues.projects.map((p: CalendarEntryProject) => p.id);

    const newResourcesIds = newValues.resources.map((r: CalendarResource) => r.id);
    const oldResourcesIds = oldValues.resources.map((r: CalendarResource) => r.id);

    return [
        oldValues.projectType !== newValues.projectType,

        newProjectsIds.length !== oldProjectsIds.length,
        !newProjectsIds.every((newProjectId: string | number) => {
            return oldProjectsIds.includes(newProjectId);
        }),

        oldValues.location?.custom !== newValues.location?.custom,
        oldValues.location?.city !== newValues.location?.city,
        oldValues.location?.country !== newValues.location?.country,

        newResourcesIds.length !== oldResourcesIds.length,
        !newResourcesIds.every((newResourceId: string | number) => {
            return oldResourcesIds.includes(newResourceId);
        }),

        oldValues.isTentative !== newValues.isTentative,
        oldValues.isUnassigned !== newValues.isUnassigned,
        oldValues.label !== newValues.label,
        oldValues.description !== newValues.description,
        oldValues.dates?.startDate?.toString() !== newValues.dates.startDate?.toString(),
        oldValues.dates?.endDate?.toString() !== newValues.dates.endDate?.toString(),
    ].some(Boolean);
}

function useBasicEventFormData({
    eventRecord,
    projectType,
    user,
}: {
    eventRecord: CalendarEntryModel;
    projectType: ProjectTypeOption;
    user: UserApiModel;
}) {
    const { ref, services: schedulerServices } = useSchedulerInstance();

    function getInstance() {
        return ref.current!.instance;
    }

    function getCurrentProjectsList(type?: ProjectType): CalendarEntryProject[] {
        if (type === ProjectType.OPENING) {
            return schedulerServices.getOpeningProjects();
        }

        return schedulerServices.getNonOpeningProjects();
    }

    const allResources = useMemo(() => {
        const instance = getInstance();

        if (!instance) return [];

        const allRecords = getAllResourceRecords(instance);

        return allRecords
            .filter((resourceModel: CalendarResourceModel) => {
                return resourceModel.id !== UNASSIGNED_RESOURCE_ID;
            })
            .map((resourceModel: CalendarResourceModel) => {
                return calendarResourceMappers.modelToValue(resourceModel);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const excludeDateIntervals = useMemo(() => {
        const instance = getInstance();

        return getExcludedDateIntervals(instance);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const defaultAllProjects = getCurrentProjectsList(projectType.value);

    return {
        defaults: {
            projectType: projectType.value,
            projects: defaultAllProjects.filter((project: CalendarEntryProject) => {
                return eventRecord.projects.find((p: CalendarEntryProject) => p.id === project.id);
            }),
            location: eventRecord.location ? locationMappers.modelToValue(eventRecord.location) : null,
            resources: eventRecord.isUnassigned
                ? []
                : allResources.filter((resource: CalendarResource) => {
                      return excludeResourceGroupHeaderModel(eventRecord.resources)
                          .map((model: CalendarResourceModel) => model.id)
                          .includes(resource.id);
                  }),
            isUnassigned: eventRecord.isUnassigned,
            isTentative: eventRecord.isUnassigned ? true : eventRecord.isTentative,
            label: eventRecord.name,
            description: eventRecord.description,
            dates: {
                startDate: eventRecord.startDate,
                endDate: getPreviousDay(eventRecord.endDate),
            },
            shouldNotifyAssignees: false,

            // extra
            excludeDateIntervals,
            user,
        },
        extra: {
            allResources,
            excludeDateIntervals,
        },
        fn: {
            getCurrentProjectsList,
        },
    };
}

function useBasicEventForm({ eventRecord, onClose }: { eventRecord: CalendarEntryModel; onClose: () => void }) {
    const { ref } = useSchedulerInstance();

    function getInstance() {
        return ref.current!.instance;
    }

    async function handleSaveUnassignedEvent(values: SchemaOutput) {
        const source = getInstance();

        await setEventData(values, UNASSIGNED_RESOURCE_ID);

        const triggerParam = {
            eventRecord,
            shouldNotifyAssignees: values.shouldNotifyAssignees,
        } satisfies OnAfterEventSaveExtended;

        await source.trigger("afterEventSave", triggerParam);

        onClose();
    }

    async function handleSaveAssignedEvent(values: SchemaOutput) {
        const source = getInstance();

        const allRecords = getAllResourceRecords(source);

        const newResources = allRecords.filter(
            (resourceModel: CalendarResourceModel) => {
                return values.resources.map((resource: CalendarResource) => resource.id).includes(resourceModel.id);
            }, //
        );

        await setEventData(values, newResources);

        const triggerParam = {
            eventRecord,
            shouldNotifyAssignees: values.shouldNotifyAssignees,
        } satisfies OnAfterEventSaveExtended;

        await source.trigger("afterEventSave", triggerParam);

        onClose();
    }

    async function setEventData(values: SchemaOutput, newResources: string | CalendarResourceModel[]) {
        const source = getInstance();

        source.assignmentStore.suspendAutoCommit();
        source.suspendRefresh();

        await eventRecord.setStartDate(values.dates.startDate);
        await eventRecord.setEndDate(getNextDay(values.dates.endDate));

        eventRecord.beginBatch();

        eventRecord.update({
            entryType: "RESOURCE_ACTIVITY",
            projectType: values.projectType,
            projects:
                values.projectType === ProjectType.OPENING
                    ? values.projects.map((project: CalendarEntryOpeningProject) =>
                          calendarEntryProjectMappers.opening.valueToModel(project),
                      )
                    : values.projects.map((project: CalendarEntryNonOpeningProject) =>
                          calendarEntryProjectMappers.nonOpening.valueToModel(project),
                      ),
            name: values.label,
            isUnassigned: values.isUnassigned,
            location: values.location ? locationMappers.valueToModel(values.location) : null,
            description: values.description,
            isTentative: values.isTentative,

            // holiday
            isCreatedFromSchedulerHoliday: false,

            // training
            isTrainingEvent: false,
            trainingLocation: "",
            trainingTrainerType: null,
            trainingTrainerName: "",
            trainingTrainerEmail: "",
            trainingIsStatusConfirmed: false,
            trainingEventType: null,
            trainingEventSubType: null,
            trainingResourceUniqueId: null,
        });

        source.eventStore.assignEventToResource(eventRecord, newResources, true);

        eventRecord.endBatch();

        eventRecord.isCreating = false; // eslint-disable-line no-param-reassign

        source.assignmentStore.resumeAutoCommit();
        await source.resumeRefresh(true);
    }

    async function handleDelete() {
        const source = getInstance();

        const triggerData = {
            eventRecords: [eventRecord],
            isConfirmed: true,
        } satisfies OnBeforeEventDelete;

        await source.trigger("beforeEventDelete", triggerData);

        eventRecord.remove();

        onClose();
    }

    function handleCancel() {
        resetEvent(eventRecord);

        onClose();
    }

    function onValid(values: SchemaOutput) {
        console.log(values);

        if (values.isUnassigned) {
            void handleSaveUnassignedEvent({
                ...values,
                isTentative: true,
                shouldNotifyAssignees: false,
            });
        } else {
            void handleSaveAssignedEvent(values);
        }
    }

    function onInvalid(errors: FieldErrors<SchemaOutput>) {
        console.log("Validation Error", errors);
    }

    return {
        onValid,
        onInvalid,
        handleDelete,
        handleCancel,
    };
}

const BasicEventForm = React.forwardRef(
    (
        {
            eventRecord, //
            projectType: outerProjectType,
            user,
            onClose,
        }: Props,
        ref: ForwardedRef<FormRef>,
    ) => {
        const { services: schedulerServices } = useSchedulerInstance();

        const {
            onValid, //
            onInvalid,
            handleDelete,
            handleCancel,
        } = useBasicEventForm({
            eventRecord,
            onClose,
        });

        const {
            defaults, //
            extra: { allResources, excludeDateIntervals },
            fn: { getCurrentProjectsList },
        } = useBasicEventFormData({
            eventRecord,
            projectType: outerProjectType,
            user,
        });

        const formRef: RefObject<{
            confirmCancel: (canCancel: () => boolean) => void;
        }> = useRef(null);

        useImperativeHandle(ref, () => ({
            confirmCancel: () =>
                formRef.current?.confirmCancel(() =>
                    checkUnsavedChanges(defaults, {
                        projects: projects.value,
                        resources: resources.value,
                        projectType: projectType.value,
                        location: location.value,
                        isTentative: isTentative.value,
                        isUnassigned: isUnassigned.value,
                        label: label.value,
                        description: description.value,
                        dates: {
                            startDate: startDate.value,
                            endDate: endDate.value,
                        },
                    }),
                ),
        }));

        const { handleSubmit, control, trigger } = useForm<SchemaInput, unknown, SchemaOutput>({
            defaultValues: defaults,
            resolver: zodResolver(schema),
            mode: "onChange",
        });

        const {
            field: projectType, //
        } = useController({
            name: "projectType",
            control,
        });

        const {
            field: projects, //
            fieldState: { error: projectsError },
        } = useController({
            name: "projects",
            control,
        });

        const {
            field: location, //
            fieldState: { error: locationError },
        } = useController({
            name: "location",
            control,
        });

        const {
            field: resources, //
            fieldState: { error: resourcesError },
        } = useController({
            name: "resources",
            control,
        });

        const {
            field: isUnassigned, //
        } = useController({
            name: "isUnassigned",
            control,
        });

        const {
            field: isTentative, //
        } = useController({
            name: "isTentative",
            control,
        });

        const {
            field: label, //
            fieldState: { error: labelError },
        } = useController({
            name: "label",
            control,
        });

        const {
            field: description, //
            fieldState: { error: descriptionError },
        } = useController({
            name: "description",
            control,
        });

        const {
            field: startDate, //
            fieldState: { error: startDateError },
        } = useController({
            name: "dates.startDate",
            control,
        });

        const {
            field: endDate, //
            fieldState: { error: endDateError },
        } = useController({
            name: "dates.endDate",
            control,
        });

        const {
            field: shouldNotifyAssignees, //
        } = useController({
            name: "shouldNotifyAssignees",
            control,
        });

        useLayoutEffect(() => {
            if (outerProjectType.value !== projectType.value) {
                projectType.onChange(outerProjectType.value);
                projects.onChange([]);
                location.onChange(null);
            }
        }, [outerProjectType, projectType, projects, location]);

        const onLocationQueryChange = useCallback(
            async (query: string | undefined) => {
                const locations = await schedulerServices.getLocations(query || "");

                return locations.map((loc: LocationApiModel) => ({
                    custom: null,
                    city: loc.city,
                    country: loc.country,
                }));
            },
            [schedulerServices],
        );

        const allProjects = getCurrentProjectsList(projectType.value);

        return (
            <div className={cx("basic-event-form")}>
                <EventFormWrapper
                    ref={formRef}
                    onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();

                        void handleSubmit(onValid, onInvalid)(event);
                    }}
                    isCreating={eventRecord.isCreating}
                    onDelete={() => void handleDelete()}
                    onCancel={handleCancel}
                >
                    {projectType.value !== ProjectType.NOT_PROJECT_RELATED && (
                        <div className={cx("projects-wrapper")}>
                            <ProjectsSelect
                                selectedProjects={projects.value}
                                type={projectsError ? "error" : "default"}
                                projects={allProjects}
                                onChange={(newProjects: CalendarEntryProject[]) => {
                                    projects.onChange(newProjects);
                                }}
                            />
                            <CustomErrorMessage
                                error={{
                                    name: "projects",
                                    errors: {
                                        projects: projectsError,
                                    },
                                }}
                            />
                        </div>
                    )}
                    <div className={cx("location-wrapper")}>
                        <LocationsSelect
                            onLoad={onLocationQueryChange}
                            type={locationError ? "error" : "default"}
                            onChange={(newValue: AppLocation | null) => {
                                location.onChange(newValue);
                            }}
                            value={location.value}
                        />
                        <CustomErrorMessage
                            error={{
                                name: "location",
                                errors: {
                                    location: locationError,
                                },
                            }}
                        />
                    </div>
                    {isUnassigned.value === false && (
                        <div className={cx("assigners-wrapper")}>
                            <div className={cx("dropdown")}>
                                <div className={cx("title")}>Assigned to</div>

                                <ResourcePicker<CalendarResource>
                                    resources={allResources}
                                    selectedResources={resources.value}
                                    onChange={(newResources: CalendarResource[]) => {
                                        resources.onChange(newResources);
                                    }}
                                />
                            </div>
                            <CustomErrorMessage
                                error={{
                                    name: "resources",
                                    errors: {
                                        resources: resourcesError,
                                    },
                                }}
                            />
                        </div>
                    )}
                    <div className={cx("unassigned-wrapper")}>
                        <div className={cx("title")}>Unassigned</div>
                        <div className={cx("checkbox-wrapper")}>
                            <SlideCheckbox
                                checked={isUnassigned.value}
                                onChange={(val: boolean) => {
                                    isUnassigned.onChange(val);

                                    void trigger("resources");
                                    void trigger("dates");

                                    if (eventRecord.isUnassigned && !val) {
                                        shouldNotifyAssignees.onChange(true);
                                    }
                                }}
                                renderLabel={renderSlideLabel}
                            />
                        </div>
                    </div>
                    {isUnassigned.value === false && (
                        <div className={cx("tentative-wrapper")}>
                            <div className={cx("title")}>Tentative</div>
                            <div className={cx("checkbox-wrapper")}>
                                <SlideCheckbox
                                    checked={isTentative.value}
                                    onChange={(val: boolean) => {
                                        isTentative.onChange(val);
                                    }}
                                    renderLabel={renderSlideLabel}
                                />
                            </div>
                        </div>
                    )}
                    <div className={cx("label-wrapper")}>
                        <BasicInput
                            label="Label"
                            placeholder="Label"
                            value={label.value}
                            type={labelError ? "error" : "default"}
                            onChange={(val: string) => {
                                label.onChange(val);
                            }}
                        />
                        <CustomErrorMessage
                            error={{
                                name: "label",
                                errors: {
                                    label: labelError,
                                },
                            }}
                        />
                    </div>
                    <div className={cx("description-wrapper")}>
                        <BasicTextarea
                            value={description.value}
                            label="Description"
                            placeholder="Description"
                            type={descriptionError ? "error" : "default"}
                            onChange={(val: string) => {
                                description.onChange(val);
                            }}
                        />
                        <CustomErrorMessage
                            error={{
                                name: "description",
                                errors: {
                                    description: descriptionError,
                                },
                            }}
                        />
                    </div>
                    <div className={cx("date-range-wrapper")}>
                        <DatePicker
                            startDate={startDate.value}
                            endDate={endDate.value}
                            type={startDateError || endDateError ? "error" : "default"}
                            excludeDateIntervals={isUnassigned.value ? [] : excludeDateIntervals}
                            fixedHeight
                            disabledKeyboardNavigation
                            popperPlacement="top"
                            onChange={(newDates: [Date | null, Date | null]) => {
                                startDate.onChange(newDates[0]);
                                endDate.onChange(newDates[1]);
                            }}
                        />
                        <CustomErrorMessage
                            error={[
                                startDateError && {
                                    name: "dates.startDate",
                                    errors: {
                                        "dates.startDate": startDateError,
                                    },
                                },
                                endDateError && {
                                    name: "dates.endDate",
                                    errors: {
                                        "dates.endDate": endDateError,
                                    },
                                },
                            ]}
                        />
                    </div>
                    {isUnassigned.value === false && (
                        <div className={cx("notification-wrapper")}>
                            <div className={cx("title")}>Notify assignees</div>
                            <div className={cx("checkbox-wrapper")}>
                                <SlideCheckbox
                                    checked={shouldNotifyAssignees.value}
                                    onChange={(val: boolean) => {
                                        shouldNotifyAssignees.onChange(val);
                                    }}
                                    renderLabel={renderSlideLabel}
                                />
                            </div>
                        </div>
                    )}
                </EventFormWrapper>
            </div>
        );
    },
);

BasicEventForm.displayName = "BasicEventForm";

interface Props {
    eventRecord: CalendarEntryModel;
    projectType: ProjectTypeOption;
    user: UserApiModel;
    onClose: () => void;
}

export default BasicEventForm;
