/* eslint-disable no-param-reassign */
import React from "react";

import ReactDOMServer from "react-dom/server";
import { MenuItemConfig, ScheduleMenuConfig } from "@bryntum/schedulerpro";

import { ContextMenuItemView } from "@INTEGRATIONS/scheduler/components/common";
import { CalendarResourceModel } from "@INTEGRATIONS/scheduler/models";

export const scheduleMenuFeature = {
    processItems(original: {
        resourceRecord: CalendarResourceModel | false;
        items: Record<string, Partial<MenuItemConfig>>;
    }) {
        if (original.resourceRecord === false) {
            return false;
        }

        if (original.items.pasteEvent) {
            original.items.pasteEvent.icon = "";
            original.items.pasteEvent.disabled = false;
            original.items.pasteEvent.content = ReactDOMServer.renderToString(
                <ContextMenuItemView
                    eventType="paste"
                    title="Paste entry"
                />,
            );
        }
    },
    items: {
        addEvent: {
            icon: "",
            disabled: false,
            content: ReactDOMServer.renderToString(
                <ContextMenuItemView
                    eventType="add"
                    title="Add custom entry"
                />,
            ),
        },
    },
} satisfies Partial<ScheduleMenuConfig>;
