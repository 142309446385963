import React, { useEffect } from "react";

import { CancelledException } from "@CORE/exceptions";
import { z } from "zod";

import { useApiContext, useAuthContext } from "@CONTEXT/hooks";

import { inIframe } from "@UTILS/helpers";

import { PageLoader } from "@VIEW/components/common";

import { useNotifications } from "@VIEW/hooks";


const schema = z.object({
    name: z.literal("scheduler-current-user"),
    email: z.string().trim(),
});

function Authorization(props: ChildrenProps) {
    const {
        state: { user },
        actions: authActions,
    } = useAuthContext();

    const { api } = useApiContext();

    const { notify } = useNotifications();

    useEffect(() => {
        async function init(email: string) {
            try {
                const foundUser = await api.resources.findOne(email);

                authActions.setUser(foundUser);
            } catch (error: unknown) {
                if (error instanceof Error && !(error instanceof CancelledException)) {
                    notify.error(error.message);
                }

                authActions.setUser({
                    role: "ADMIN",
                });
            }
        }

        function handler(event: MessageEvent<unknown>) {
            const parsed = schema.safeParse(event.data);

            if (parsed.success) {
                void init(parsed.data.email);
            }
        }

        const isIframe = inIframe();

        if (isIframe) {
            window.addEventListener("message", handler, false);
            window.parent.postMessage("scheduler-request-current-user", {
                targetOrigin: "*",
            });

            return () => {
                window.removeEventListener("message", handler, false);
            };
        } else {
            authActions.setUser({
                role: "ADMIN",
            });
        }
    }, [authActions, api, notify]);

    if (!user) return <PageLoader />;

    return <>{props.children}</>;
}

export default Authorization;
