import { Model, ModelConfig } from "@bryntum/schedulerpro";

export type AppLocation =
    | {
          custom: null;
          city: string;
          country: string;
      }
    | {
          custom: string;
          city: null;
          country: null;
      };

type ConstructorParams = AppLocation & Partial<ModelConfig>;

export class AppLocationModel extends Model {
    custom: string | null;
    city: string | null;
    country: string | null;

    constructor(params: ConstructorParams) {
        super(params);

        this.custom = params.custom;
        this.city = params.city;
        this.country = params.country;
    }

    static get $name(): string {
        return "AppLocationModel";
    }

    static get fields(): any {
        return [
            { name: "custom", type: "string" },
            { name: "city", type: "string" },
            { name: "country", type: "string" },
        ];
    }
}
