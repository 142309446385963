import { EventCopyPaste, EventCopyPasteConfig } from "@bryntum/schedulerpro";

import { CalendarEntryModel } from "@INTEGRATIONS/scheduler/models";

export const eventCopyPasteFeature = {
    useNativeClipboard: false,
    generateNewName(eventRecord: CalendarEntryModel) {
        if (eventRecord.name.trim() === "") {
            return "";
        }

        return eventRecord.name;
    },
} satisfies Partial<EventCopyPasteConfig> | EventCopyPaste;
