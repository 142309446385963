import { EventStore } from "@bryntum/schedulerpro";

import { CalendarEntryModel } from "@INTEGRATIONS/scheduler/models";

export class AppEventStore extends EventStore {
    createRecord(
        data: {
            startDate: Date; //
            endDate: Date;
        },
    ): CalendarEntryModel {
        return new CalendarEntryModel({
            entryType: "RESOURCE_ACTIVITY",
            startDate: data.startDate,
            endDate: data.endDate,
        });
    }
}

export const appEventStore = new AppEventStore({
    modelClass: CalendarEntryModel,
});
