import { Draft, produce } from "immer";

import { UserApiModel } from "@API/services";

import {
    AUTH_CLEAR_USER_ACTION_TYPE,
    AUTH_SET_USER_ACTION_TYPE,
    AuthClearUserAction,
    AuthSetUserAction,
} from "@CONTEXT/action-creators";

export interface AuthState {
    user: UserApiModel | null;
}

export type AuthAction = AuthSetUserAction | AuthClearUserAction;

export function authReducer(state: AuthState, action: AuthAction): AuthState {
    switch (action.type) {
        case AUTH_SET_USER_ACTION_TYPE:
            return produce(state, (draft: Draft<AuthState>) => {
                draft.user = action.payload;
            });

        case AUTH_CLEAR_USER_ACTION_TYPE:
            return produce(state, (draft: Draft<AuthState>) => {
                draft.user = null;
            });
    }
}
