import React from "react";

import classnames from "classnames/bind";

import { COLOR } from "@CORE/constants";

import DownloadIcon from "@VIEW/components/icons/DownloadIcon/DownloadIcon.com";
import TableIcon from "@VIEW/components/icons/TableIcon/TableIcon";

import styles from "./BasicButton.module.scss";


const cx: CX = classnames.bind(styles);

const icons = {
    "download-icon": () => (
        <DownloadIcon
            color={COLOR.white}
            size={19}
        />
    ),
    "table-icon": () => (
        <TableIcon
            color={COLOR.white}
            size={19}
        />
    ),
} satisfies Record<Icon, React.ElementType>;

type Icon = "download-icon" | "table-icon";

function BasicButton({
    label, //
    disabled,
    onClick,
    icon,
    ...attributes
}: Props) {
    function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
        if (!disabled) {
            onClick(event);
        }
    }

    const IconComponent = icon ? icons[icon] : null;

    return (
        <button
            type="button"
            className={cx("basic-button")}
            disabled={disabled}
            onClick={handleClick}
            {...attributes}
        >
            {IconComponent ? (
                <div className={cx("icon-wrapper")}>
                    <IconComponent />
                </div>
            ) : null}
            {label}
        </button>
    );
}

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    label: string;
    icon?: Icon;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export default BasicButton;
