import React, { useCallback, useState } from "react";

import classNames from "classnames/bind";

import { useFiltersContext } from "@CONTEXT/hooks";

import Scheduler from "@INTEGRATIONS/scheduler";
import { useSchedulerInstance } from "@INTEGRATIONS/scheduler/hooks";

import {
    Header,
    PageLoader,
    SchedulerDataLoader,
    SchedulerEventsSubscriber,
    SchedulerTimeRangeControls,
} from "@VIEW/components/common";

import styles from "./Main.module.scss";

const cx: CX = classNames.bind(styles);

function Main() {
    const [isLoading, setLoading] = useState(true);

    const {
        filters: { dates },
    } = useFiltersContext();

    const {
        services: schedulerServices, //
    } = useSchedulerInstance();

    const onLoad = useCallback(() => {
        setLoading(false);
    }, []);

    const handlePDFExport = useCallback(() => {
        void schedulerServices.exportPDF();
    }, [schedulerServices]);

    const handleExcelExport = useCallback(() => {
        schedulerServices.exportExcel();
    }, [schedulerServices]);

    return (
        <>
            <SchedulerDataLoader onLoad={onLoad} />
            <SchedulerEventsSubscriber />
            <SchedulerTimeRangeControls />

            <div className={cx("main")}>
                <div
                    id="header"
                    className={cx("header-wrapper")}
                >
                    <Header
                        onExcelExport={handleExcelExport}
                        onPDFExport={handlePDFExport}
                    />
                </div>
                <div className={cx("scheduler-wrapper")}>
                    <Scheduler />
                </div>

                {isLoading && <PageLoader />}
            </div>
        </>
    );
}

export default Main;
