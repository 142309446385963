import { useMemo } from "react";

import { CancelledException } from "@CORE/exceptions";

import { HolidayApiModel } from "@API/services";

import { useApiContext } from "@CONTEXT/hooks";

import { useNotifications } from "@VIEW/hooks";

type FindAllParams = {
    provinceCodes: string[];
    dates: {
        from: string;
        to: string;
    };
};

function createHook() {
    return function useHolidaysApi() {
        const { serviceApi } = useApiContext();

        const { notify } = useNotifications();

        const queries = useMemo(
            () => ({
                findAll: async (params: FindAllParams, signal?: AbortSignal): Promise<HolidayApiModel[]> => {
                    try {
                        return await serviceApi.holidays.findAll(
                            {
                                provinceCodes: params.provinceCodes,
                                startDate: params.dates.from,
                                endDate: params.dates.to,
                            },
                            signal,
                        );
                    } catch (error: unknown) {
                        if (error instanceof Error && !(error instanceof CancelledException)) {
                            notify.error(error.message);
                        }

                        return [];
                    }
                },
            }),
            [serviceApi, notify],
        );

        return {
            queries,
        };
    };
}

export const useHolidaysApi = createHook();
