import React, { CSSProperties } from "react";

import classnames from "classnames/bind";

import { COLOR } from "@CORE/constants";

import styles from "./Legend.module.scss";

const cx: CX = classnames.bind(styles);

interface Item {
    title: string;
    colors: [CSSProperties["color"], CSSProperties["opacity"]];
}

const items: Item[] = [
    {
        title: "Opening",
        colors: [COLOR["entry-openings"], 1],
    },
    {
        title: "Travel",
        colors: [COLOR["entry-travel"], 1],
    },
    {
        title: "Blocked",
        colors: [COLOR["entry-resource-blocked-period"], 1],
    },
    {
        title: "Activity",
        colors: [COLOR["entry-resource-activity"], 0.3],
    },
    {
        title: "Holiday",
        colors: [COLOR["entry-holidays"], 0.3],
    },
    {
        title: "Training",
        colors: [COLOR["entry-training-event"], 0.3],
    },
];

function Legend() {
    return (
        <div className={cx("legend")}>
            {items.map((item: Item) => {
                const key = item.title;

                return (
                    <div
                        key={key}
                        className={cx("item")}
                    >
                        <div className={cx("color-box")}>
                            <div
                                style={{
                                    backgroundColor: item.colors[0],
                                }}
                                className={cx("top")}
                            />
                            <div
                                style={{
                                    backgroundColor: item.colors[0],
                                    opacity: item.colors[1],
                                }}
                                className={cx("bottom")}
                            />
                        </div>
                        <div className={cx("title")}>{item.title}</div>
                    </div>
                );
            })}
        </div>
    );
}

export default React.memo(Legend);
