import React from "react";

import ReactDOMServer from "react-dom/server";

import { CalendarEntryType, ProjectType } from "@INTEGRATIONS/scheduler/types";

import { locationToString } from "@UTILS/helpers";

type AbstractResource = {
    id: string | number;
    name: string;
    logo: string;
    abbreviation: string;
};

type AbstractProject = {
    id: string | number;
    name: string;
    projectType: ProjectType;
    marsha?: string;
    gdNumber?: string;
    code?: string;
};

type AbstractLocation =
    | {
          custom: null;
          city: string;
          country: string;
      }
    | {
          custom: string;
          city: null;
          country: null;
      };

type AbstractEntry = {
    name: string;
    resources: AbstractResource[];
    startDate: Date;
    endDate: Date;
    entryType: CalendarEntryType;
    projectType: ProjectType;
    projects: AbstractProject[];
    location: AbstractLocation | null;
    description: string;
    isTentative: boolean;
};

const projectMap = {
    [ProjectType.OPENING]: ["opening", "Opening Project"],
    [ProjectType.NON_OPENING]: ["non-opening", "Non-Opening Project"],
    [ProjectType.NOT_PROJECT_RELATED]: ["not-project-related", "Not Project Related"],
    [ProjectType.TRAINING_EVENT]: ["training-event", "Training event"],
    [ProjectType.HOLIDAY]: ["holiday", "Holiday"],
} satisfies Record<ProjectType, [string, string]>;

function formatDate(date: Date) {
    return `${`0${date.getDate()}`.slice(-2)}.${`0${date.getMonth() + 1}`.slice(-2)}.${date.getFullYear()}`;
}

function Avatar({
    logo, //
}: AvatarProps) {
    return (
        <div
            style={{
                position: "relative",
                width: 60,
                height: 60,
                borderRadius: "50%",
                margin: "0 auto",
                overflow: "hidden",
                backgroundColor: "#EFF4FA",
                marginBottom: 8,
            }}
        >
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${logo})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                }}
            />
        </div>
    );
}

interface AvatarProps {
    logo: string;
}

function CalendarEntryInfo({
    calendarEntry, //
}: CalendarEntryProps) {
    return (
        <div
            style={{
                width: 290,
                background:
                    "linear-gradient(90deg, rgba(216, 238, 255, 0) 0%, #E4F0FA 52.37%, rgba(209, 235, 255, 0) 100%)",
                margin: "0 auto 24px",
                padding: "10px 30px",
                boxSizing: "border-box",
            }}
        >
            <div
                style={{
                    borderRadius: 8,
                    backgroundColor: "#3E8EF4",
                    height: "100%",
                    boxSizing: "border-box",
                    padding: "4px 18px",
                }}
            >
                <div
                    style={{
                        fontWeight: 700,
                        lineHeight: "18px",
                        color: "#074CA4",
                    }}
                >
                    Resource Activity
                </div>
                <div
                    style={{
                        fontSize: 12,
                        lineHeight: "20px",
                        color: "#074CA4",
                    }}
                >
                    {calendarEntry.projects
                        .map((project: AbstractProject) => {
                            if (calendarEntry.projectType === ProjectType.OPENING) {
                                return project.marsha || project.gdNumber || null;
                            }

                            return project.code || "";
                        })
                        .filter(Boolean)
                        .join(", ")}
                </div>
            </div>
        </div>
    );
}

interface CalendarEntryProps {
    calendarEntry: AbstractEntry;
}

function InfoBlock({
    title, //
    value,
}: InfoBlockProps) {
    return (
        <div
            style={{
                boxSizing: "border-box",
                padding: "8px 0",
                width: 400,
                margin: "0 auto 4px",
                backgroundColor: "#F6FAFD",
                borderRadius: 8,
            }}
        >
            <div
                style={{
                    boxSizing: "border-box",
                    color: "#202020",
                    marginBottom: 6,
                    padding: "0 16px",
                }}
            >
                {title}
            </div>
            <div
                style={{
                    boxSizing: "border-box",
                    color: "#808080",
                    whiteSpace: "pre-line",
                    padding: "0 16px",
                }}
            >
                {value}
            </div>
        </div>
    );
}

interface InfoBlockProps {
    title: string;
    value: string;
}

function AssigneesInfoBlock({
    assignees, //
}: AssigneesInfoBlockProps) {
    return (
        <div
            style={{
                boxSizing: "border-box",
                padding: "8px 0",
                width: 400,
                margin: "4px 0",
                backgroundColor: "#F6FAFD",
                borderRadius: 8,
                display: "flex",
            }}
        >
            <div
                style={{
                    boxSizing: "border-box",
                    color: "#202020",
                    margin: "8px 0",
                    whiteSpace: "nowrap",
                    paddingLeft: 16,
                }}
            >
                Assigned to
            </div>
            <div
                style={{
                    boxSizing: "border-box",
                    display: "flex",
                    marginLeft: "auto",
                    padding: "0 16px 0 8px",
                }}
            >
                {assignees.map((assignee: AbstractResource) => {
                    const key = assignee.id;

                    return (
                        <div
                            key={key}
                            style={{
                                width: 33,
                                height: 33,
                                backgroundColor: "gainsboro",
                                borderRadius: "50%",
                                overflow: "hidden",
                                marginLeft: 2,
                            }}
                            title={assignee.name}
                        >
                            <div
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    backgroundImage: `url(${assignee.logo})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                }}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

interface AssigneesInfoBlockProps {
    assignees: AbstractResource[];
}

function ProjectsInfoBlock({
    projects, //
}: ProjectsInfoBlockProps) {
    return (
        <div
            style={{
                boxSizing: "border-box",
                padding: "8px 0",
                width: 400,
                margin: "0 auto 4px",
                backgroundColor: "#F6FAFD",
                borderRadius: 8,
            }}
        >
            <div
                style={{
                    boxSizing: "border-box",
                    color: "#202020",
                    marginBottom: 10,
                    padding: "0 16px",
                }}
            >
                Project(s)
            </div>
            {projects.map((project: string, index: number) => {
                const key = project + index.toString();

                return (
                    <div
                        key={key}
                        style={{
                            boxSizing: "border-box",
                            color: "#808080",
                            whiteSpace: "pre-line",
                            backgroundColor: "white",
                            padding: "6px 10px",
                            borderRadius: 5,
                            margin: "0 16px",
                            fontSize: 12,
                            lineHeight: "20px",
                            marginBottom: index < projects.length - 1 ? 8 : 0,
                        }}
                    >
                        {project}
                    </div>
                );
            })}
        </div>
    );
}

interface ProjectsInfoBlockProps {
    projects: string[];
}

function UserNotificationTemplate({
    calendarEntry, //
    calendarResource,
}: Props) {
    return (
        <div
            style={{
                width: 650,
                margin: "0 auto",
                paddingTop: 30,
                backgroundImage: "url('https://mt-emea-oasis.apexdigital.online/media/email-dots-left.png')",
                backgroundRepeat: "no-repeat",
            }}
        >
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    paddingBottom: 30,
                    backgroundImage: "url('https://mt-emea-oasis.apexdigital.online/media/email-dots-right.png')",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "100% 100%",
                }}
            >
                <div
                    style={{
                        width: 400,
                        margin: "0 auto",
                    }}
                >
                    <Avatar logo={calendarResource.logo} />

                    {/* User name */}
                    <div
                        style={{
                            textAlign: "center",
                            color: "#202020",
                            marginBottom: 8,
                        }}
                    >
                        By {calendarResource.name}
                    </div>

                    {/* Assignment message */}
                    <div
                        style={{
                            fontSize: 20,
                            fontWeight: 700,
                            lineHeight: "30px",
                            textAlign: "center",
                            whiteSpace: "pre-line",
                            color: "#454A54",
                            marginBottom: 16,
                        }}
                    >
                        {`You were assigned to new\n ${projectMap[calendarEntry.projectType][0]} project`}
                    </div>

                    <CalendarEntryInfo calendarEntry={calendarEntry} />

                    <InfoBlock
                        title="Label"
                        value={calendarEntry.name}
                    />

                    <InfoBlock
                        title="Project type"
                        value={projectMap[calendarEntry.projectType][1]}
                    />

                    <ProjectsInfoBlock
                        projects={calendarEntry.projects.map((project: AbstractProject) => {
                            return project.name;
                        })}
                    />

                    <InfoBlock
                        title="Location"
                        value={locationToString(calendarEntry.location) || "N/A"}
                    />

                    <AssigneesInfoBlock assignees={calendarEntry.resources} />

                    <InfoBlock
                        title="Tentative"
                        value={calendarEntry.isTentative ? "Yes" : "No"}
                    />

                    <InfoBlock
                        title="Description"
                        value={calendarEntry.description}
                    />

                    <InfoBlock
                        title="Date"
                        value={`${formatDate(calendarEntry.startDate)} - ${formatDate(calendarEntry.endDate)}`}
                    />
                </div>
            </div>
        </div>
    );
}

export const userNotificationTemplate = (props: Props) => {
    return ReactDOMServer.renderToString(<UserNotificationTemplate {...props} />);
};

interface Props {
    calendarEntry: AbstractEntry;
    calendarResource: AbstractResource;
}
