import { AppLocation, AppLocationModel } from "@INTEGRATIONS/scheduler/models";

export const locationMappers = {
    valueToModel(value: AppLocation): AppLocationModel {
        return new AppLocationModel(value);
    },
    modelToValue(model: AppLocationModel): AppLocation {
        if (model.custom !== null) {
            return {
                custom: model.custom,
                city: null,
                country: null,
            };
        }

        if (typeof model.city === "string" && typeof model.country === "string") {
            return {
                custom: null,
                city: model.city,
                country: model.country,
            };
        }

        throw new Error("Wrong location type");
    },
};
