import React, { useLayoutEffect, useState } from "react";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { RootProvider } from "@CONTEXT/providers";

import { SchedulerRootProvider } from "@INTEGRATIONS/scheduler/context";

import { Authorization, LoginView, Main, Notificator } from "@VIEW/components/common";

// TODO install prettier 3. when @trivago/prettier-plugin-sort-imports will be ready

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: 60 * 1000,
            retry: false,
        },
    },
});

function App() {
    const [permissionAllowed, setPermissionAllowed] = useState(false);

    useLayoutEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        if (params.token === "ot0QM2AuQowbjzY74QSD0wK") {
            setPermissionAllowed(true);
        }
    }, []);

    if (!permissionAllowed) {
        return <LoginView />;
    }

    return (
        <QueryClientProvider client={queryClient}>
            <RootProvider>
                <SchedulerRootProvider>
                    <Authorization>
                        <Main />
                    </Authorization>

                    <Notificator />
                </SchedulerRootProvider>
            </RootProvider>
        </QueryClientProvider>
    );
}

export default App;
