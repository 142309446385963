import { useMemo } from "react";

import { generateICSFile } from "@UTILS/generations";
import { userNotificationTemplate } from "@VIEW/email-templates";

import { ProjectApiModel, ResourceApiModel } from "@API/services";

import { useApiContext } from "@CONTEXT/hooks";

import { CalendarEntry } from "@INTEGRATIONS/scheduler/models";
import { ProjectType } from "@INTEGRATIONS/scheduler/types";
import { getNextDay } from "@INTEGRATIONS/scheduler/utils";

import { createAbbreviation, locationToString } from "@UTILS/helpers";

import config from "../../config";

type NotifyAssigneesParams = {
    entry: CalendarEntry;
    assignees: ResourceApiModel[];
    projects: ProjectApiModel[];
};

function createSubject(entry: CalendarEntry) {
    const dates = `${formatDate(entry.startDate)} - ${formatDate(entry.endDate)}`;
    const location = locationToString(entry.location);

    const info = [entry.name, location, dates].filter(Boolean).join(", ");

    return `New Resource Activity - [${info}]`;
}

function formatDate(date: Date) {
    return `${`0${date.getDate()}`.slice(-2)}.${`0${date.getMonth() + 1}`.slice(-2)}.${date.getFullYear()}`;
}

function createEmailNotificationsHook() {
    return function useEmailNotifications() {
        const { serviceApi } = useApiContext();

        return useMemo(() => {
            return {
                notifyAssignees: async ({
                    entry, //
                    assignees,
                    projects,
                }: NotifyAssigneesParams) => {
                    const DISABLED = config.DISABLE_EMAIL_NOTIFICATIONS;

                    if (DISABLED) {
                        return Promise.resolve();
                    }

                    const file = await generateICSFile({
                        start: entry.startDate,
                        end: getNextDay(entry.endDate),
                        title: entry.name,
                        description: entry.description,
                        organizer: {
                            name: "COMPASS EMEA IT Opening Platform",
                            email: "noreply@apexdigital.online",
                        },
                        location: locationToString(entry.location) || undefined,
                        attendees: assignees.map((resource: ResourceApiModel) => ({
                            name: resource.fullName,
                            email: resource.email,
                        })),
                    });

                    return Promise.all(
                        assignees.map((assignee: ResourceApiModel) => {
                            return serviceApi.notifications.notifyByEmail({
                                recipients: [assignee.email],

                                // recipients: ["vkondariev@apexdigital.online"],
                                // recipients: ["vkgtechs@gmail.com"],
                                // recipients: ["vk.apexdigital@gmail.com"],

                                // recipients: ["free_fall@ukr.net"],
                                // recipients: ["skripka.s.1988@gmail.com"],
                                subject: createSubject(entry),
                                sender: "COMPASS EMEA IT Opening Platform",
                                content: userNotificationTemplate({
                                    calendarEntry: {
                                        name: entry.name,
                                        resources: assignees.map((resource: ResourceApiModel) => ({
                                            id: resource.id,
                                            name: resource.fullName,
                                            logo: resource.logo,
                                            abbreviation: createAbbreviation(resource.fullName),
                                        })),
                                        startDate: entry.startDate,
                                        endDate: entry.endDate,
                                        entryType: entry.entryType,
                                        projectType: entry.projectType || ProjectType.NON_OPENING,
                                        projects: projects.filter((project: ProjectApiModel) => {
                                            return entry.projectIds.includes(project.id);
                                        }),
                                        location: entry.location,
                                        description: entry.description,
                                        isTentative: entry.isTentative,
                                    },
                                    calendarResource: {
                                        id: assignee.id,
                                        name: assignee.fullName,
                                        logo: assignee.logo,
                                        abbreviation: createAbbreviation(assignee.fullName),
                                    },
                                }),
                                files: [file],
                            });
                        }),
                    );
                },
            };
        }, [serviceApi]);
    };
}

export const useEmailNotifications = createEmailNotificationsHook();
