import { z } from "zod";

export const LocationSchema = z.union([
    z.object({
        custom: z.null(),
        city: z.string(),
        country: z.string(),
    }),
    z.object({
        custom: z.string(),
        city: z.null(),
        country: z.null(),
    }),
]);
