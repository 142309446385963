import React, { ForwardedRef, RefObject, useImperativeHandle, useMemo, useRef } from "react";

import classnames from "classnames/bind";

import { DateHelper } from "@bryntum/schedulerpro";
import { zodResolver } from "@hookform/resolvers/zod";
import { nanoid } from "nanoid";
import { FieldErrors, useController, useForm } from "react-hook-form";
import { RefinementCtx, z } from "zod";

import { UserApiModel } from "@API/services";

import { EventFormWrapper } from "@INTEGRATIONS/scheduler/components/forms/elements";
import {
    DatesSchema,
    ProjectSchema,
    ResourceSchema,
    UserSchema,
} from "@INTEGRATIONS/scheduler/components/forms/schemas";
import { useSchedulerInstance } from "@INTEGRATIONS/scheduler/hooks";
import {
    calendarEntryProjectMappers,
    calendarResourceMappers,
    trainingDisplayTypeMapper,
} from "@INTEGRATIONS/scheduler/mappers";
import {
    CalendarEntryModel,
    CalendarEntryOpeningProject,
    CalendarResource,
    CalendarResourceModel,
    EXTERNAL_TRAINER_RESOURCE_ID,
    UNASSIGNED_RESOURCE_ID,
} from "@INTEGRATIONS/scheduler/models";
import {
    CalendarEntryProject,
    OnAfterEventSaveExtended,
    OnBeforeEventDelete,
    ProjectType,
    TrainingEventSubType,
    TrainingEventType,
    TrainingTrainerType,
} from "@INTEGRATIONS/scheduler/types";
import { excludeResourceGroupHeaderModel, getNextDay } from "@INTEGRATIONS/scheduler/utils";
import {
    getAllResourceRecords,
    getExcludedDateIntervals,
    renderSlideLabel,
} from "@INTEGRATIONS/scheduler/utils/helpers";

import { SlideCheckbox } from "@VIEW/components/basic/inputs/checkbox";
import { DatePicker } from "@VIEW/components/basic/inputs/date";
import { BasicInput } from "@VIEW/components/basic/inputs/input";
import DayNumber from "@VIEW/components/basic/inputs/number/DayNumber";
import { TabRadio } from "@VIEW/components/basic/inputs/radio";
import ProjectsSelect from "@VIEW/components/basic/inputs/select/ProjectsSelect";
import ResourcePicker from "@VIEW/components/basic/inputs/select/ResourcePicker";
import TrainingEventTypeSelect from "@VIEW/components/basic/inputs/select/TrainingEventTypeSelect";
import { TrainingEventTypeOption } from "@VIEW/components/basic/inputs/select/TrainingEventTypeSelect/TrainingEventTypeSelect.com";
import { BasicTextarea } from "@VIEW/components/basic/inputs/textarea";

import { CustomErrorMessage } from "@VIEW/components/common";

import styles from "./TrainingEventForm.module.scss";

const cx: CX = classnames.bind(styles);

type FormRef = {
    confirmCancel: () => void;
};

const trainingEventTypes = (Object.keys(trainingDisplayTypeMapper.types) as TrainingEventType[]).map(
    (key: TrainingEventType) => {
        return {
            value: key,
            label: trainingDisplayTypeMapper.types[key],
        };
    },
) satisfies TrainingEventTypeOption[];

const trainingEventSubTypes = (Object.keys(trainingDisplayTypeMapper.subTypes) as TrainingEventSubType[]).map(
    (key: TrainingEventSubType) => {
        return {
            value: key,
            label: trainingDisplayTypeMapper.subTypes[key],
        };
    },
);

const trainerTypes = [
    {
        value: TrainingTrainerType.EXTERNAL,
        label: "External",
    },
    {
        value: TrainingTrainerType.INTERNAL,
        label: "Internal",
    },
] satisfies Array<{
    value: TrainingTrainerType;
    label: string;
}>;

const schema = z
    .object({
        projectType: z.literal(ProjectType.TRAINING_EVENT),
        projects: z
            .array(ProjectSchema)
            .min(1, "At least one project must be selected")
            .max(4, "Max 4 projects can be selected"),
        trainingEventType: z
            .nativeEnum(TrainingEventType)
            .nullable()
            .transform((val: TrainingEventType | null, ctx: RefinementCtx) => {
                if (val === null) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: "Type is not allowed to be empty",
                    });

                    return z.NEVER;
                }

                return val;
            }),
        trainingEventSubType: z
            .nativeEnum(TrainingEventSubType)
            .nullable()
            .transform((val: TrainingEventSubType | null, ctx: RefinementCtx) => {
                if (val === null) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: "Sub Type is not allowed to be empty",
                    });

                    return z.NEVER;
                }

                return val;
            }),
        location: z.string().trim().min(1, "Location is not allowed to be empty"),
        isUnassigned: z.boolean(),
        trainerType: z.nativeEnum(TrainingTrainerType).nullable(),
        resources: z.array(ResourceSchema),
        trainerName: z.string().trim(),
        trainerEmail: z.string().trim(),
        isStatusConfirmed: z.boolean(),
        label: z.string().trim(),
        description: z.string().trim(),
        dates: DatesSchema,
        durationDays: z.number().min(1, "Duration days must be greater than or equal to 1"),
        shouldNotifyAssignees: z.boolean(),

        // extra
        excludeDateIntervals: z.array(
            z.object({
                start: z.date(),
                end: z.date(),
            }),
        ),
        user: UserSchema,
    })
    .superRefine(
        (
            arg: {
                isUnassigned: boolean;
                trainerType: TrainingTrainerType | null;
                trainerName: string;
                trainerEmail: string;
                resources: CalendarResource[];
                user: UserApiModel;
            },
            ctx: RefinementCtx,
        ) => {
            const { isUnassigned, trainerType, trainerName, trainerEmail, resources, user } = arg;

            if (isUnassigned) return true;

            if (trainerType === null) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: "Please select Trainer type",
                    path: ["trainerType"],
                });

                return z.NEVER;
            }

            if (trainerType === TrainingTrainerType.INTERNAL) {
                if (resources.length === 0) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: "At least one trainer must be selected",
                        path: ["resources"],
                    });

                    return z.NEVER;
                }

                if (resources.length > 8) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: "Max 8 trainers can be selected",
                        path: ["resources"],
                    });

                    return z.NEVER;
                }

                if (user.role === "USER") {
                    const hasCurrentDepartment = resources.some((resource: CalendarResource) => {
                        return user.departments.includes(resource.role);
                    });

                    if (!hasCurrentDepartment) {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message: `At least one trainer should be selected from:\n${user.departments.join(", ")}`,
                            path: ["resources"],
                        });

                        return z.NEVER;
                    }
                }
            }

            if (trainerType === TrainingTrainerType.EXTERNAL) {
                const trainerSchema = z.object({
                    trainerName: z.string().min(1, "External trainer name is not allowed to be empty"),
                    trainerEmail: z
                        .string()
                        .min(1, "External trainer email is not allowed to be empty")
                        .email("External trainer email is not valid email"),
                });

                const parsedTrainer = trainerSchema.safeParse({
                    trainerName,
                    trainerEmail,
                });

                if (!parsedTrainer.success) {
                    parsedTrainer.error.issues.forEach((issue: z.ZodIssue) => {
                        ctx.addIssue(issue);
                    });

                    return z.NEVER;
                }
            }

            return true;
        },
    )
    .superRefine(
        (
            arg: {
                isUnassigned: boolean;
                trainerType: TrainingTrainerType | null;
                dates: {
                    endDate: Date | null;
                    startDate: Date | null;
                };
                excludeDateIntervals: {
                    start: Date;
                    end: Date;
                }[];
            },
            ctx: RefinementCtx,
        ) => {
            const {
                isUnassigned,
                trainerType,
                dates: { startDate, endDate },
                excludeDateIntervals,
            } = arg;

            if (startDate && endDate) {
                if (endDate < startDate) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: "End date must be greater than or equal start date",
                        path: ["dates.endDate"],
                    });

                    return z.NEVER;
                }

                if (!isUnassigned && trainerType !== TrainingTrainerType.EXTERNAL) {
                    const someOverlap = excludeDateIntervals.some((dates: { start: Date; end: Date }) => {
                        return DateHelper.intersectSpans(
                            DateHelper.add(startDate, -1, "day"), //
                            DateHelper.add(endDate, 1, "day"),
                            dates.start,
                            dates.end,
                        );
                    });

                    if (someOverlap) {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message: "Dates overlap blocked period",
                            path: ["dates.endDate"],
                        });

                        return z.NEVER;
                    }
                }
            }

            return true;
        },
    );

type SchemaInput = z.input<typeof schema>;
type SchemaOutput = z.output<typeof schema>;

function resetEvent(eventRecord: CalendarEntryModel) {
    if (eventRecord.isCreating) {
        eventRecord.remove();
    } else {
        eventRecord.clearChanges();
    }
}

function checkUnsavedChanges(
    oldValues: Omit<SchemaInput, "shouldNotifyAssignees" | "excludeDateIntervals" | "user">,
    newValues: Omit<SchemaInput, "shouldNotifyAssignees" | "excludeDateIntervals" | "user">,
) {
    const newProjectsIds = newValues.projects.map((p: CalendarEntryProject) => p.id);
    const oldProjectsIds = oldValues.projects.map((p: CalendarEntryProject) => p.id);

    const newResourcesIds = newValues.resources.map((r: CalendarResource) => r.id);
    const oldResourcesIds = oldValues.resources.map((r: CalendarResource) => r.id);

    return [
        newProjectsIds.length !== oldProjectsIds.length,
        !newProjectsIds.every((newProjectId: string | number) => {
            return oldProjectsIds.includes(newProjectId);
        }),

        newResourcesIds.length !== oldResourcesIds.length,
        !newResourcesIds.every((newResourceId: string | number) => {
            return oldResourcesIds.includes(newResourceId);
        }),

        oldValues.projectType !== newValues.projectType, //
        oldValues.trainingEventType !== newValues.trainingEventType, //
        oldValues.trainingEventSubType !== newValues.trainingEventSubType, //
        oldValues.location !== newValues.location,
        oldValues.isUnassigned !== newValues.isUnassigned,
        oldValues.trainerType !== newValues.trainerType,
        oldValues.trainerName !== newValues.trainerName,
        oldValues.trainerEmail !== newValues.trainerEmail,
        oldValues.isStatusConfirmed !== newValues.isStatusConfirmed,
        oldValues.label !== newValues.label,
        oldValues.description !== newValues.description,
        oldValues.dates?.startDate?.toString() !== newValues.dates.startDate?.toString(),
        oldValues.dates?.endDate?.toString() !== newValues.dates.endDate?.toString(),
    ].some(Boolean);
}

function useTrainingEventFormData({
    eventRecord, //
    defaultTrainerType,
    user,
}: {
    eventRecord: CalendarEntryModel;
    defaultTrainerType: TrainingTrainerType | null;
    user: UserApiModel;
}) {
    const { ref, services: schedulerServices } = useSchedulerInstance();

    function getInstance() {
        return ref.current!.instance;
    }

    const allResources = useMemo(() => {
        const instance = getInstance();

        if (!instance) return [];

        const allRecords = getAllResourceRecords(instance);

        return allRecords
            .filter((resourceModel: CalendarResourceModel) => {
                return ![
                    UNASSIGNED_RESOURCE_ID, //
                    EXTERNAL_TRAINER_RESOURCE_ID,
                ].includes(resourceModel.id.toString());
            })
            .map((resourceModel: CalendarResourceModel) => {
                return calendarResourceMappers.modelToValue(resourceModel);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const excludeDateIntervals = useMemo(() => {
        const instance = getInstance();

        return getExcludedDateIntervals(instance);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const allProjects = schedulerServices.getOpeningProjects().filter((project: CalendarEntryOpeningProject) => {
        return project.type === "Project";
    });

    const durationDays =
        eventRecord.startDate && eventRecord.endDate
            ? DateHelper.diff(eventRecord.startDate, eventRecord.endDate, "day")
            : 1;

    return {
        defaults: {
            trainingEventType: eventRecord.trainingEventType,
            trainingEventSubType: eventRecord.trainingEventSubType,
            projectType: ProjectType.TRAINING_EVENT,
            projects: allProjects.filter((project: CalendarEntryProject) => {
                return eventRecord.projects.find((p: CalendarEntryProject) => p.id === project.id);
            }),
            location: eventRecord.trainingLocation,
            isUnassigned: eventRecord.isUnassigned,
            trainerType: defaultTrainerType,
            resources: eventRecord.isUnassigned
                ? []
                : allResources.filter((resource: CalendarResource) => {
                      return excludeResourceGroupHeaderModel(eventRecord.resources)
                          .map((model: CalendarResourceModel) => model.id)
                          .includes(resource.id);
                  }),
            trainerName: eventRecord.trainingTrainerName,
            trainerEmail: eventRecord.trainingTrainerEmail,
            isStatusConfirmed: eventRecord.trainingIsStatusConfirmed,
            label: eventRecord.name,
            description: eventRecord.description,
            dates: {
                startDate: eventRecord.startDate,
                endDate: DateHelper.add(eventRecord.startDate, durationDays - 1, "day"),
            },
            durationDays,
            shouldNotifyAssignees: false,
            excludeDateIntervals,
            user,
        },
        extra: {
            allProjects,
            allResources,
            excludeDateIntervals,
        },
    };
}

function useTrainingEventForm({
    eventRecord, //
    onClose,
}: {
    eventRecord: CalendarEntryModel;
    onClose: () => void;
}) {
    const { ref } = useSchedulerInstance();

    function getInstance() {
        return ref.current!.instance;
    }

    async function handleSaveUnassignedEvent(values: SchemaOutput) {
        const source = getInstance();

        await setEventData(
            {
                ...values,
                trainerType: null,
                resources: [],
                trainerName: "",
                trainerEmail: "",
            },
            UNASSIGNED_RESOURCE_ID,
        );

        const triggerParam = {
            eventRecord,
            shouldNotifyAssignees: false,
        } satisfies OnAfterEventSaveExtended;

        await source.trigger("afterEventSave", triggerParam);

        onClose();
    }

    async function handleSaveInternalAssignedEvent(values: SchemaOutput) {
        const source = getInstance();

        const shouldNotifyAssignees =
            (!eventRecord.trainingIsStatusConfirmed && values.isStatusConfirmed) || //
            values.shouldNotifyAssignees;

        const allRecords = getAllResourceRecords(source);

        const newResources = allRecords.filter((resourceModel: CalendarResourceModel) => {
            return values.resources.map((resource: CalendarResource) => resource.id).includes(resourceModel.id);
        });

        await setEventData(
            {
                ...values,
                trainerName: "",
                trainerEmail: "",
            },
            newResources,
        );

        const triggerParam = {
            eventRecord,
            shouldNotifyAssignees,
        } satisfies OnAfterEventSaveExtended;

        await source.trigger("afterEventSave", triggerParam);

        onClose();
    }

    async function handleSaveExternalAssignedEvent(values: SchemaOutput) {
        const source = getInstance();

        await setEventData(
            {
                ...values,
                resources: [],
            },
            EXTERNAL_TRAINER_RESOURCE_ID,
        );

        const triggerParam = {
            eventRecord,
            shouldNotifyAssignees: false,
        } satisfies OnAfterEventSaveExtended;

        await source.trigger("afterEventSave", triggerParam);

        onClose();
    }

    async function setEventData(values: SchemaOutput, newResources: string | CalendarResourceModel[]) {
        console.log(values);

        const source = getInstance();

        source.assignmentStore.suspendAutoCommit();
        source.suspendRefresh();

        await eventRecord.setStartDate(values.dates.startDate);
        await eventRecord.setEndDate(getNextDay(values.dates.endDate));

        eventRecord.beginBatch();

        const trainingResourceUniqueId = eventRecord.trainingResourceUniqueId || nanoid();

        eventRecord.update({
            entryType: "RESOURCE_ACTIVITY",
            projectType: values.projectType,
            projects: values.projects.map((project: CalendarEntryOpeningProject) =>
                calendarEntryProjectMappers.opening.valueToModel(project),
            ),
            name: values.label,
            isUnassigned: values.isUnassigned,
            location: null,
            description: values.description,
            isTentative: true,

            // holiday
            isCreatedFromSchedulerHoliday: false,

            // training
            isTrainingEvent: true,
            trainingLocation: values.location,
            trainingTrainerType: values.trainerType,
            trainingTrainerName: values.trainerName,
            trainingTrainerEmail: values.trainerEmail,
            trainingIsStatusConfirmed: values.isStatusConfirmed,
            trainingEventType: values.trainingEventType,
            trainingEventSubType: values.trainingEventSubType,
            trainingResourceUniqueId,
        });

        source.eventStore.assignEventToResource(eventRecord, newResources, true);

        eventRecord.endBatch();

        eventRecord.isCreating = false; // eslint-disable-line no-param-reassign

        source.assignmentStore.resumeAutoCommit();
        await source.resumeRefresh(true);
    }

    async function handleDelete() {
        const source = getInstance();

        const triggerData = {
            eventRecords: [eventRecord],
            isConfirmed: true,
        } satisfies OnBeforeEventDelete;

        await source.trigger("beforeEventDelete", triggerData);

        eventRecord.remove();

        onClose();
    }

    function handleCancel() {
        resetEvent(eventRecord);

        onClose();
    }

    function onValid(values: SchemaOutput) {
        if (values.isUnassigned) {
            void handleSaveUnassignedEvent(values);

            return;
        }

        if (values.trainerType === TrainingTrainerType.INTERNAL) {
            void handleSaveInternalAssignedEvent(values);

            return;
        }

        if (values.trainerType === TrainingTrainerType.EXTERNAL) {
            void handleSaveExternalAssignedEvent(values);

            return;
        }

        throw new Error("Trainer type not specified");
    }

    function onInvalid(errors: FieldErrors<SchemaOutput>) {
        console.log("Validation Error", errors);
    }

    return {
        onValid,
        onInvalid,
        handleDelete,
        handleCancel,
    };
}

const TrainingEventForm = React.forwardRef(
    (
        {
            eventRecord, //
            defaultTrainerType,
            user,
            onClose,
        }: Props,
        ref: ForwardedRef<FormRef>,
    ) => {
        const formRef: RefObject<{
            confirmCancel: (canCancel: () => boolean) => void;
        }> = useRef(null);

        useImperativeHandle(ref, () => ({
            confirmCancel: () =>
                formRef.current?.confirmCancel(() =>
                    checkUnsavedChanges(defaults, {
                        projectType: defaults.projectType,
                        trainingEventType: trainingEventType.value,
                        trainingEventSubType: trainingEventSubType.value,
                        projects: projects.value,
                        location: location.value,
                        isUnassigned: isUnassigned.value,
                        trainerType: trainerType.value,
                        resources: resources.value,
                        trainerName: trainerName.value,
                        trainerEmail: trainerEmail.value,
                        isStatusConfirmed: isStatusConfirmed.value,
                        label: label.value,
                        description: description.value,
                        dates: {
                            startDate: startDate.value,
                            endDate: endDate.value,
                        },
                        durationDays: durationDays.value,
                    }),
                ),
        }));

        const {
            onValid, //
            onInvalid,
            handleDelete,
            handleCancel,
        } = useTrainingEventForm({
            eventRecord,
            onClose,
        });

        const {
            defaults, //
            extra: {
                allProjects, //
                allResources,
                excludeDateIntervals,
            },
        } = useTrainingEventFormData({
            eventRecord,
            defaultTrainerType,
            user,
        });

        const { handleSubmit, control, trigger } = useForm<SchemaInput, undefined, SchemaOutput>({
            defaultValues: defaults,
            resolver: zodResolver(schema),
            mode: "onChange",
        });

        const {
            field: trainingEventType, //
            fieldState: { error: trainingEventTypeError },
        } = useController({
            name: "trainingEventType",
            control,
        });

        const {
            field: trainingEventSubType, //
            fieldState: { error: trainingEventSubTypeError },
        } = useController({
            name: "trainingEventSubType",
            control,
        });

        const {
            field: projects, //
            fieldState: { error: projectsError },
        } = useController({
            name: "projects",
            control,
        });

        const {
            field: location, //
            fieldState: { error: locationError },
        } = useController({
            name: "location",
            control,
        });

        const {
            field: isUnassigned, //
        } = useController({
            name: "isUnassigned",
            control,
        });

        const {
            field: trainerType, //
            fieldState: { error: trainerTypeError },
        } = useController({
            name: "trainerType",
            control,
        });

        const {
            field: resources, //
            fieldState: { error: resourcesError },
        } = useController({
            name: "resources",
            control,
        });

        const {
            field: trainerName, //
            fieldState: { error: trainerNameError },
        } = useController({
            name: "trainerName",
            control,
        });

        const {
            field: trainerEmail, //
            fieldState: { error: trainerEmailError },
        } = useController({
            name: "trainerEmail",
            control,
        });

        const {
            field: isStatusConfirmed, //
        } = useController({
            name: "isStatusConfirmed",
            control,
        });

        const {
            field: label, //
            fieldState: { error: labelError },
        } = useController({
            name: "label",
            control,
        });

        const {
            field: description, //
            fieldState: { error: descriptionError },
        } = useController({
            name: "description",
            control,
        });

        const {
            field: startDate, //
            fieldState: { error: startDateError },
        } = useController({
            name: "dates.startDate",
            control,
        });

        const {
            field: endDate, //
            fieldState: { error: endDateError },
        } = useController({
            name: "dates.endDate",
            control,
        });

        const {
            field: durationDays, //
            fieldState: { error: durationDaysError },
        } = useController({
            name: "durationDays",
            control,
        });

        const {
            field: shouldNotifyAssignees, //
        } = useController({
            name: "shouldNotifyAssignees",
            control,
        });

        const notificationRequired = !eventRecord.trainingIsStatusConfirmed && isStatusConfirmed.value;

        return (
            <div className={cx("training-event-form")}>
                <EventFormWrapper
                    ref={formRef}
                    onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();

                        void handleSubmit(onValid, onInvalid)(event);
                    }}
                    isCreating={eventRecord.isCreating}
                    onDelete={() => void handleDelete()}
                    onCancel={handleCancel}
                >
                    <div className={cx("training-event-type-wrapper")}>
                        <TrainingEventTypeSelect
                            trainingEventTypeOptions={trainingEventTypes}
                            value={trainingEventType.value}
                            placeholder="Select Type"
                            type={trainingEventTypeError ? "error" : "default"}
                            onChange={(option: TrainingEventTypeOption | null) => {
                                trainingEventType.onChange(option?.value || null);
                            }}
                        />
                        <CustomErrorMessage
                            error={{
                                name: "trainingEventType",
                                errors: {
                                    trainingEventType: trainingEventTypeError,
                                },
                            }}
                        />
                    </div>
                    <div className={cx("training-event-sub-type-wrapper")}>
                        <TrainingEventTypeSelect
                            trainingEventTypeOptions={trainingEventSubTypes}
                            value={trainingEventSubType.value}
                            placeholder="Select Sub Type"
                            type={trainingEventSubTypeError ? "error" : "default"}
                            onChange={(option: TrainingEventTypeOption | null) => {
                                trainingEventSubType.onChange(option?.value || null);
                            }}
                        />
                        <CustomErrorMessage
                            error={{
                                name: "trainingEventSubType",
                                errors: {
                                    trainingEventSubType: trainingEventSubTypeError,
                                },
                            }}
                        />
                    </div>
                    <div className={cx("projects-wrapper")}>
                        <ProjectsSelect
                            selectedProjects={projects.value}
                            type={projectsError ? "error" : "default"}
                            projects={allProjects}
                            onChange={(newProjects: CalendarEntryProject[]) => {
                                projects.onChange(newProjects);
                            }}
                        />
                        <CustomErrorMessage
                            error={{
                                name: "projects",
                                errors: {
                                    projects: projectsError,
                                },
                            }}
                        />
                    </div>
                    <div className={cx("location-wrapper")}>
                        <BasicInput
                            label="Location"
                            placeholder="Location (free text)"
                            value={location.value}
                            type={locationError ? "error" : "default"}
                            onChange={(val: string) => {
                                location.onChange(val);
                            }}
                        />
                        <CustomErrorMessage
                            error={{
                                name: "location",
                                errors: {
                                    location: locationError,
                                },
                            }}
                        />
                    </div>
                    <div className={cx("unassigned-wrapper")}>
                        <div className={cx("title")}>Unassigned</div>
                        <div className={cx("checkbox-wrapper")}>
                            <SlideCheckbox
                                checked={isUnassigned.value}
                                onChange={(val: boolean) => {
                                    isUnassigned.onChange(val);

                                    void trigger("resources");
                                    void trigger("dates");
                                }}
                                renderLabel={renderSlideLabel}
                            />
                        </div>
                    </div>
                    {isUnassigned.value === false && (
                        <>
                            <div className={cx("trainer-type-wrapper")}>
                                <TabRadio
                                    options={trainerTypes}
                                    value={trainerType.value}
                                    type={trainerTypeError ? "error" : "default"}
                                    onChange={(val: string | null) => {
                                        trainerType.onChange(val);

                                        void trigger("dates");
                                    }}
                                />
                                <CustomErrorMessage
                                    error={{
                                        name: "trainerType",
                                        errors: {
                                            trainerType: trainerTypeError,
                                        },
                                    }}
                                />
                            </div>

                            {trainerType.value === TrainingTrainerType.INTERNAL && (
                                <div className={cx("marriott-trainer-wrapper")}>
                                    <div className={cx("dropdown")}>
                                        <div className={cx("title")}>Marriott trainer</div>

                                        <ResourcePicker<CalendarResource>
                                            resources={allResources}
                                            selectedResources={resources.value}
                                            onChange={(newResources: CalendarResource[]) => {
                                                resources.onChange(newResources);
                                            }}
                                        />
                                    </div>
                                    <CustomErrorMessage
                                        error={{
                                            name: "resources",
                                            errors: {
                                                resources: resourcesError,
                                            },
                                        }}
                                    />
                                </div>
                            )}

                            {trainerType.value === TrainingTrainerType.EXTERNAL && (
                                <>
                                    <div className={cx("trainer-name-wrapper")}>
                                        <BasicInput
                                            label="External trainer name"
                                            placeholder="Name"
                                            value={trainerName.value}
                                            type={trainerNameError ? "error" : "default"}
                                            onChange={(val: string) => {
                                                trainerName.onChange(val);
                                            }}
                                        />
                                        <CustomErrorMessage
                                            error={{
                                                name: "trainerName",
                                                errors: {
                                                    trainerName: trainerNameError,
                                                },
                                            }}
                                        />
                                    </div>
                                    <div className={cx("trainer-email-wrapper")}>
                                        <BasicInput
                                            label="External trainer email"
                                            placeholder="Email address"
                                            value={trainerEmail.value}
                                            type={trainerEmailError ? "error" : "default"}
                                            onChange={(val: string) => {
                                                trainerEmail.onChange(val);
                                            }}
                                        />
                                        <CustomErrorMessage
                                            error={{
                                                name: "trainerEmail",
                                                errors: {
                                                    trainerEmail: trainerEmailError,
                                                },
                                            }}
                                        />
                                    </div>
                                </>
                            )}
                        </>
                    )}
                    <div className={cx("status-confirmed-wrapper")}>
                        <div className={cx("title")}>Status confirmed</div>
                        <div className={cx("checkbox-wrapper")}>
                            <SlideCheckbox
                                checked={isStatusConfirmed.value}
                                onChange={(val: boolean) => {
                                    isStatusConfirmed.onChange(val);
                                }}
                                renderLabel={renderSlideLabel}
                            />
                        </div>
                    </div>
                    <div className={cx("label-wrapper")}>
                        <BasicInput
                            label="Label"
                            placeholder="Label"
                            value={label.value}
                            type={labelError ? "error" : "default"}
                            onChange={(val: string) => {
                                label.onChange(val);
                            }}
                        />
                        <CustomErrorMessage
                            error={{
                                name: "label",
                                errors: {
                                    label: labelError,
                                },
                            }}
                        />
                    </div>
                    <div className={cx("description-wrapper")}>
                        <BasicTextarea
                            value={description.value}
                            label="Description"
                            placeholder="Description"
                            type={descriptionError ? "error" : "default"}
                            onChange={(val: string) => {
                                description.onChange(val);
                            }}
                        />
                        <CustomErrorMessage
                            error={{
                                name: "description",
                                errors: {
                                    description: descriptionError,
                                },
                            }}
                        />
                    </div>
                    <div className={cx("date-range-wrapper")}>
                        <DatePicker
                            startDate={startDate.value}
                            endDate={endDate.value}
                            type={startDateError || endDateError ? "error" : "default"}
                            excludeDateIntervals={
                                isUnassigned.value || trainerType.value === TrainingTrainerType.EXTERNAL
                                    ? []
                                    : excludeDateIntervals
                            }
                            fixedHeight
                            disabledKeyboardNavigation
                            popperPlacement="top"
                            onChange={([newStartDate, newEndDate]: [Date | null, Date | null]) => {
                                startDate.onChange(newStartDate);
                                endDate.onChange(newEndDate);

                                if (newStartDate && newEndDate) {
                                    const diff = DateHelper.diff(newStartDate, newEndDate, "day") + 1;

                                    durationDays.onChange(diff);
                                }
                            }}
                        />
                        <CustomErrorMessage
                            error={[
                                startDateError && {
                                    name: "dates.startDate",
                                    errors: {
                                        "dates.startDate": startDateError,
                                    },
                                },
                                endDateError && {
                                    name: "dates.endDate",
                                    errors: {
                                        "dates.endDate": endDateError,
                                    },
                                },
                            ]}
                        />
                    </div>
                    <div className={cx("duration-days-wrapper")}>
                        <div className={cx("input-wrapper")}>
                            <div className={cx("title")}>Duration (days)</div>
                            <div className={cx("input")}>
                                <DayNumber
                                    value={durationDays.value}
                                    min={1}
                                    type={durationDaysError ? "error" : "default"}
                                    onChange={(val: number) => {
                                        durationDays.onChange(val);

                                        if (startDate.value) {
                                            const newEndDate = DateHelper.add(startDate.value, val - 1, "day");

                                            endDate.onChange(newEndDate);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <CustomErrorMessage
                            error={{
                                name: "durationDays",
                                errors: {
                                    durationDays: durationDaysError,
                                },
                            }}
                        />
                    </div>
                    {isUnassigned.value === false && trainerType.value === TrainingTrainerType.INTERNAL && (
                        <div
                            className={cx("notification-wrapper", {
                                "notification-required": notificationRequired,
                            })}
                        >
                            <div className={cx("title")}>Notify assignees</div>
                            <div className={cx("checkbox-wrapper")}>
                                <SlideCheckbox
                                    checked={notificationRequired || shouldNotifyAssignees.value}
                                    onChange={(val: boolean) => {
                                        if (!notificationRequired) {
                                            shouldNotifyAssignees.onChange(val);
                                        }
                                    }}
                                    renderLabel={renderSlideLabel}
                                />
                            </div>
                        </div>
                    )}
                </EventFormWrapper>
            </div>
        );
    },
);

TrainingEventForm.displayName = "TrainingEventForm";

interface Props {
    eventRecord: CalendarEntryModel;
    defaultTrainerType: TrainingTrainerType | null;
    user: UserApiModel;
    onClose: () => void;
}

export default TrainingEventForm;
