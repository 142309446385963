import React from "react";

import { Period } from "@CORE/enums";

import Select from "@VIEW/components/basic/inputs/select/BasicSelect";

type PeriodOption = {
    value: Period;
    label: string;
};

const periodOptions = [
    {
        value: Period.TODAY,
        label: "Today",
    },
    {
        value: Period.CURRENT_WEEK,
        label: "Current Week",
    },
    {
        value: Period.CURRENT_MONTH,
        label: "Current Month",
    },
    {
        value: Period.NEXT_4_WEEKS,
        label: "Next 4 weeks",
    },
    {
        value: Period.WEEK,
        label: "Week",
    },
    {
        value: Period.MONTH,
        label: "Month",
    },
] satisfies Array<PeriodOption>;

function PeriodSelect({ selectedPeriod, onChange }: Props) {
    const handleChange = (newValue: PeriodOption | PeriodOption[] | null) => {
        if (newValue && !Array.isArray(newValue)) {
            onChange(newValue.value);
        }
    };

    const value = selectedPeriod
        ? periodOptions.find((item: { value: string; label: string }) => item.value === selectedPeriod)
        : "";

    return (
        <Select<PeriodOption>
            isClearable={false}
            options={periodOptions}
            value={value}
            onChange={handleChange}
            placeholder="Week/Month"
        />
    );
}

type Props = {
    selectedPeriod: Period | null;
    onChange: (newPeriod: Period) => void;
};

export default PeriodSelect;
