import { useContext, useMemo } from "react";

import invariant from "tiny-invariant";

import { AuthSetUserAction, authClearUser, authSetUser } from "@CONTEXT/action-creators";

import { AuthContext } from "@CONTEXT/providers";

function createAuthHook() {
    return function useAuthContext() {
        const [state, dispatch] = useContext(AuthContext);

        invariant(dispatch, "Dispatch must be specified");

        const actions = useMemo(
            () => ({
                setUser: (payload: AuthSetUserAction["payload"]) => {
                    dispatch(authSetUser(payload));
                },
                clearUser: () => {
                    dispatch(authClearUser());
                },
            }),
            [dispatch],
        );

        return {
            state,
            actions,
        };
    };
}

export const useAuthContext = createAuthHook();
