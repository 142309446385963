import React, { useCallback, useMemo, useState } from "react";

import classnames from "classnames/bind";

import { CancelledException } from "@CORE/exceptions";
import debounce from "lodash.debounce";
import {
    CSSObjectWithLabel,
    ControlProps,
    GroupBase,
    OptionProps,
    SingleValue,
    StylesConfig,
    ValueContainerProps,
    components,
} from "react-select";
import AsyncSelect from "react-select/async";

import { COLOR } from "@CORE/constants";

import { locationToString } from "@UTILS/helpers";

import { DotSpinner } from "@VIEW/components/basic/spinners";

import { SearchIcon } from "@VIEW/components/icons";

import { useNotifications } from "@VIEW/hooks";

import { getStyles as getBasicStyles } from "../BasicSelect/Select.com";
import styles from "./LocationsSelect.module.scss";

const cx: CX = classnames.bind(styles);

function getStyles<T extends AbstractLocation>(error: boolean): StylesConfig<T, boolean, GroupBase<T>> {
    return {
        ...getBasicStyles<T>(error),
        valueContainer: (base: CSSObjectWithLabel) => ({
            ...base,
            padding: "0 16px 0 26px",
        }),
        menu: () => ({
            ...(getBasicStyles<T>(error)?.menu || {}),
            position: "absolute",
            width: "100%",
            marginTop: 4,
            backgroundColor: COLOR["white"],
            borderRadius: "8px",
            border: "none",
        }),
    };
}

function ValueContainer<T extends AbstractLocation>(props: ValueContainerProps<T>) {
    const { children, ...rest } = props;

    return (
        <components.ValueContainer {...rest}>
            {!!children && (
                <span className={cx("search-icon")}>
                    <SearchIcon
                        size={16}
                        color={COLOR["blue-dark"]}
                    />
                </span>
            )}
            {children}
        </components.ValueContainer>
    );
}

function Option<T extends AbstractLocation>(props: OptionProps<T>) {
    const { children, data, ...rest } = props;

    const isCustom = data.custom !== null;

    if (isCustom) {
        return (
            <components.Option
                data={data}
                className={cx("custom-location")}
                {...rest}
            >
                <div className={cx("custom-location-option-message")}>Custom</div>

                <div className={cx("custom-location-option")}>{children}</div>
            </components.Option>
        );
    }

    return (
        <components.Option
            data={data}
            {...rest}
        >
            {children}
        </components.Option>
    );
}

function LoadingMessage() {
    return (
        <div className={cx("loading-message")}>
            <DotSpinner
                size="medium"
                color={COLOR["grey"]}
            />
        </div>
    );
}

function LocationsSelect<T extends AbstractLocation>({
    onLoad, //
    onChange,
    value,
    type,
}: Props<T>) {
    const [isOpen, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState("");

    const { notify } = useNotifications();

    const onLoadData = useCallback(
        (query: string, callback: (data: T[]) => void) => {
            const trimmedQuery = query.trim();

            const customLocation = {
                custom: trimmedQuery,
                city: null,
                country: null,
            } as T;

            if (trimmedQuery.length >= 3) {
                onLoad(trimmedQuery)
                    .then((result: T[]) => {
                        void callback([
                            customLocation,
                            ...result.map(
                                (loc: T) =>
                                    ({
                                        custom: null,
                                        city: loc.city!,
                                        country: loc.country!,
                                    } as T),
                            ),
                        ]);
                    })
                    .catch((error: unknown) => {
                        if (error instanceof Error && !(error instanceof CancelledException)) {
                            notify.error(error.message);
                        }

                        callback([]);
                    });
            } else if (trimmedQuery.length > 0) {
                callback([customLocation]);
            } else {
                callback([]);
            }
        },
        [onLoad, notify],
    );

    const onLoadDebounced = useMemo(() => debounce(onLoadData, 250), [onLoadData]);

    const getLabel = (option: T) => {
        return locationToString(option) || "";
    };

    return (
        <AsyncSelect<T>
            className={cx("location-select", {
                "is-open": isOpen,
            })}
            placeholder="Select Location"
            loadOptions={onLoadDebounced}
            onChange={(newValue: SingleValue<T>) => {
                onChange(newValue);
            }}
            onFocus={() => {
                if (value && value.custom !== null) {
                    setInputValue(value.custom);
                }
            }}
            onBlur={() => {
                if (value?.custom === null) {
                    setInputValue("");
                }
            }}
            inputValue={inputValue}
            onInputChange={(newValue: string) => {
                setInputValue(newValue);
            }}
            value={value}
            getOptionLabel={getLabel}
            blurInputOnSelect
            getOptionValue={(option: T) => (option.custom !== null ? option.custom : option.country)}
            onMenuOpen={() => {
                setOpen(true);
            }}
            onMenuClose={() => {
                setOpen(false);
            }}
            components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                LoadingIndicator: () => null,
                ValueContainer,
                LoadingMessage,
                Option,
            }}
            classNames={{
                control: (controlProps: ControlProps<T, false, GroupBase<T>>) =>
                    cx({ "control-focused": controlProps.isFocused }),
                singleValue: () => cx("single-value"),
            }}
            styles={getStyles(type === "error")}
        />
    );
}

type AbstractLocation =
    | {
          custom: null;
          city: string;
          country: string;
      }
    | {
          custom: string;
          city: null;
          country: null;
      };

interface Props<T extends AbstractLocation> {
    onLoad: (city?: string) => Promise<T[]>;
    onChange: (newValue: SingleValue<T>) => void;
    value: SingleValue<T>;
    type?: "error" | "default";
}

export default LocationsSelect;
