import React from "react";

import classnames from "classnames/bind";

import { ErrorMessage, Props as ErrorMessageProps } from "@hookform/error-message";
import { FieldErrors } from "react-hook-form";

import styles from "./CustomErrorMessage.module.scss";

const cx: CX = classnames.bind(styles);

function Parent({ children }: ChildrenProps) {
    return <div className={cx("custom-error-message")}>{children}</div>;
}

function CustomErrorMessage<
    TFieldErrors extends FieldErrors,
    TAs extends undefined | React.ReactElement | React.ComponentType<any | keyof JSX.IntrinsicElements>,
>(props: Props<TFieldErrors, TAs>) {
    if (Array.isArray(props.error)) {
        const errors = props.error.filter(Boolean);

        if (errors.length === 0) {
            return <Parent>{null}</Parent>;
        }

        return (
            <Parent>
                <ErrorMessage {...errors[0]!} />
            </Parent>
        );
    }

    return (
        <Parent>
            <ErrorMessage {...props.error} />
        </Parent>
    );
}

interface Props<
    TFieldErrors extends FieldErrors,
    TAs extends undefined | React.ReactElement | React.ComponentType<any | keyof JSX.IntrinsicElements>,
> {
    error: ErrorMessageProps<TFieldErrors, TAs> | Array<ErrorMessageProps<TFieldErrors, TAs> | undefined>;
}

export default CustomErrorMessage;
