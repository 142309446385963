import { Draft, produce } from "immer";

import { ResourceApiModel } from "@API/services";

import { FILTERS_APPLY_ACTION_TYPE, FiltersApplyAction } from "@CONTEXT/action-creators";

import { Period } from "@CORE/enums";

import { CalendarEntryTypeExtended } from "@INTEGRATIONS/scheduler/types";

type DateRange = {
    from: Date | null;
    to: Date | null;
};

export interface FiltersState {
    dates: DateRange;
    period: Period | null;
    resources: ResourceApiModel[];
    eventTypes: CalendarEntryTypeExtended[];
    teamLead: ResourceApiModel | null;
    search: string;
}

export type FiltersAction = FiltersApplyAction;

export function filtersReducer(state: FiltersState, action: FiltersApplyAction): FiltersState {
    switch (action.type) {
        case FILTERS_APPLY_ACTION_TYPE: {
            return produce(state, (draft: Draft<FiltersState>) => {
                Object.assign(draft, action.payload);

                return draft;
            });
        }
    }
}
