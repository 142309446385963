import React, { useState } from "react";

import classnames from "classnames/bind";

import ReactPicker, { ReactDatePickerCustomHeaderProps, ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { COLOR } from "@CORE/constants";

import { ArrowIcon, CalendarIcon } from "@VIEW/components/icons";

import "./DatePicker.global.scss";
import styles from "./DatePicker.module.scss";

const cx: CX = classnames.bind(styles);

const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "long" };
const intlInstance = new Intl.DateTimeFormat("en-US", options);

function datesAreEqual(start: Date, end: Date) {
    const date1 = new Date(start);
    const date2 = new Date(end);

    date1.setHours(0, 0, 0, 0);
    date2.setHours(0, 0, 0, 0);

    return date1.getTime() === date2.getTime();
}

const CustomInput = React.forwardRef(
    (
        { ...attributes }: React.InputHTMLAttributes<HTMLInputElement>, //
        ref: React.ForwardedRef<HTMLInputElement>,
    ) => {
        return (
            <div className={cx("input")}>
                <label>
                    <span className={cx("icon")}>
                        <CalendarIcon color={COLOR["blue-dark"]} />
                    </span>
                    <input
                        ref={ref}
                        type="text"
                        {...attributes}
                    />
                </label>
            </div>
        );
    },
);

CustomInput.displayName = "CustomInput";

function Header({ date, onNextMonth, onPreviousMonth }: HeaderProps) {
    return (
        <div className={cx("header")}>
            <ArrowIcon
                size="24px"
                onClick={onPreviousMonth}
                className={cx("month-control-icon", "month-control-icon-left")}
            />
            <ArrowIcon
                size="24px"
                onClick={onNextMonth}
                className={cx("month-control-icon", "month-control-icon-right")}
            />
            <span className={cx("current-date")}>{intlInstance.format(date)}</span>
        </div>
    );
}

interface HeaderProps {
    date: Date;
    onNextMonth: () => void;
    onPreviousMonth: () => void;
}

function DatePicker({
    startDate, //
    endDate,
    onChange,
    type = "default",
    excludeDateIntervals,
    inline,
    ...attributes
}: Props) {
    const [isOpen, setOpen] = useState(false);

    function dayClassName(date: Date) {
        if (!excludeDateIntervals || excludeDateIntervals.length === 0) return "";

        const classes = new Set<string>();

        excludeDateIntervals.forEach((interval: { start: Date; end: Date }) => {
            if (datesAreEqual(date, interval.start)) {
                classes.add("react-datepicker__day--excluded-start");
            }

            if (datesAreEqual(date, interval.end)) {
                classes.add("react-datepicker__day--excluded-end");
            }
        });

        return Array.from(classes).join(" ");
    }

    return (
        <div
            className={cx("date-picker", {
                "is-open": isOpen,
            })}
        >
            <ReactPicker
                renderCustomHeader={(params: ReactDatePickerCustomHeaderProps) => {
                    return (
                        <Header
                            date={params.monthDate}
                            onNextMonth={() => params.increaseMonth()}
                            onPreviousMonth={() => params.decreaseMonth()}
                        />
                    );
                }}
                customInput={<CustomInput />}
                selectsRange
                startDate={startDate}
                endDate={endDate}
                excludeDateIntervals={excludeDateIntervals}
                dateFormat="dd.MM.yyyy"
                calendarStartDay={1}
                formatWeekDay={(day: string) => {
                    return day.slice(0, 3);
                }}
                placeholderText="Select Date"
                showPopperArrow={false}
                className={cx({
                    error: type === "error",
                })}
                calendarClassName={cx("calendar", {
                    inline,
                })}
                dayClassName={dayClassName}
                onChange={onChange}
                onCalendarOpen={() => {
                    setOpen(true);
                }}
                onCalendarClose={() => {
                    setOpen(false);
                }}
                onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                    event.preventDefault();
                }}
                inline={inline}
                {...attributes}
            />
        </div>
    );
}

interface Props extends Omit<ReactDatePickerProps, "onChange"> {
    onChange: (newDates: [Date | null, Date | null]) => void;
    type?: "default" | "error";
}

export default DatePicker;
