import { CalendarResourceModel } from "@INTEGRATIONS/scheduler/models";

export function excludeResourceGroupHeaderModel(models: unknown[]): CalendarResourceModel[] {
    return models
        .map((model: unknown) => {
            if (model instanceof CalendarResourceModel && !model.isGroupHeader) {
                return model;
            }

            return null;
        })
        .filter(Boolean);
}
