import { ResourceStore } from "@bryntum/schedulerpro";

import {
    CalendarResourceModel,
    EXTERNAL_TRAINER_RESOURCE_ROLE,
    UNASSIGNED_RESOURCE_ROLE,
} from "@INTEGRATIONS/scheduler/models";

export class AppResourceStore extends ResourceStore {
    async sort(): Promise<string> {
        return Promise.resolve("name");
    }
}

export const appResourceStore = new AppResourceStore({
    modelClass: CalendarResourceModel,
    groupers: [
        {
            field: "role",
            fn: (recordA: CalendarResourceModel, recordB: CalendarResourceModel) => {
                if (recordB.role === UNASSIGNED_RESOURCE_ROLE) {
                    return 1;
                }

                if (recordB.role === EXTERNAL_TRAINER_RESOURCE_ROLE) {
                    return 1;
                }

                return recordA.role < recordB.role ? -1 : 1;
            },
        },
    ],
});
