import React, { useCallback, useEffect } from "react";

import { calendarEntriesApiMappers, trainingEventsApiMappers } from "@UTILS/mappers";
import { QueryFunctionContext, useQuery } from "@tanstack/react-query";

import { ProjectApiModel, ResourceApiModel } from "@API/services";

import { useSchedulerListeners } from "@INTEGRATIONS/scheduler/hooks";
import { CalendarEntry, CalendarResource } from "@INTEGRATIONS/scheduler/models";
import { ProjectType } from "@INTEGRATIONS/scheduler/types";

import { useEmailNotifications, useNotifications } from "@VIEW/hooks";
import {
    useCalendarEntriesApi,
    useCalendarEntriesProjectsApi,
    useCalendarResourcesApi,
    useTrainingEventsApi,
} from "@VIEW/hooks/api";

function logEvent(entries: CalendarEntry[], message: string) {
    entries.forEach((entry: CalendarEntry) => {
        console.log(message, entry);
    });
}

const DEFAULT_CALENDAR_RESOURCES: ResourceApiModel[] = [];
const DEFAULT_PROJECTS: ProjectApiModel[] = [];

function SchedulerEventsSubscriber() {
    const listeners = useSchedulerListeners();

    const { queries: calendarResourcesQueries } = useCalendarResourcesApi();
    const { queries: calendarEntriesProjectsQueries } = useCalendarEntriesProjectsApi();
    const { mutations: calendarEntriesMutations } = useCalendarEntriesApi();
    const { mutations: trainingEventsMutations } = useTrainingEventsApi();

    const { notifyAssignees } = useEmailNotifications();

    const { notify } = useNotifications();

    const { data: calendarResources = DEFAULT_CALENDAR_RESOURCES } = useQuery({
        queryKey: ["resources"],
        queryFn: async (ctx: QueryFunctionContext) => {
            return calendarResourcesQueries.findAll(ctx.signal);
        },
    });

    const { data: openingProjects = DEFAULT_PROJECTS } = useQuery({
        queryKey: ["projects-opening"],
        queryFn: async (ctx: QueryFunctionContext) => {
            return calendarEntriesProjectsQueries.openings.findAll(ctx.signal);
        },
    });

    const { data: nonOpeningProjects = DEFAULT_PROJECTS } = useQuery({
        queryKey: ["projects-non-opening"],
        queryFn: async (ctx: QueryFunctionContext) => {
            return calendarEntriesProjectsQueries.nonOpenings.findAll(ctx.signal);
        },
    });

    const onNotifyAssignees = useCallback(
        (entries: CalendarEntry[]) => {
            entries.forEach((entry: CalendarEntry) => {
                const assignees = calendarResources.filter((resource: ResourceApiModel) => {
                    return entry.resourceIds.includes(resource.id);
                }, []);

                const projects = [ProjectType.OPENING, ProjectType.TRAINING_EVENT].includes(entry.projectType)
                    ? openingProjects
                    : nonOpeningProjects;

                notifyAssignees({
                    entry,
                    assignees,
                    projects,
                })
                    .then(() => {
                        console.log("Notification success");
                    })
                    .catch((error: unknown) => {
                        if (error instanceof Error) {
                            notify.error(error.message);
                        }

                        console.error("Notification error");
                    });
            });
        },
        [
            calendarResources, //
            openingProjects,
            nonOpeningProjects,
            notifyAssignees,
            notify,
        ],
    );

    /**
     * Resource activities and holidays
     */
    useEffect(() => {
        /**
         * Scheduler "onEventDrop" event
         */
        const eventDrop$ = listeners.onEventDrop.subscribe(
            (item: { entries: CalendarEntry[]; shouldNotifyAssignees: boolean }) => {
                logEvent(item.entries, "onEventDrop");

                const payload = calendarEntriesApiMappers.valuesToApi(item.entries, calendarResources);

                void calendarEntriesMutations.upsert(payload);

                if (item.shouldNotifyAssignees) {
                    onNotifyAssignees(item.entries);
                }
            },
            "event",
        );

        /**
         * Scheduler "onEventResizeEnd" event
         */
        const eventResizeEnd$ = listeners.onEventResizeEnd.subscribe((entry: CalendarEntry) => {
            logEvent([entry], "onEventResizeEnd");

            const payload = calendarEntriesApiMappers.valuesToApi([entry], calendarResources);

            void calendarEntriesMutations.upsert(payload);
        }, "event");

        /**
         * Scheduler "onPaste" event
         */
        const eventPaste$ = listeners.onEventPaste.subscribe(
            (item: { entries: CalendarEntry[]; shouldNotifyAssignees: boolean }) => {
                logEvent(item.entries, "onEventPaste");

                const payload = calendarEntriesApiMappers.valuesToApi(item.entries, calendarResources);

                void calendarEntriesMutations.upsert(payload);

                if (item.shouldNotifyAssignees) {
                    onNotifyAssignees(item.entries);
                }
            },
            "event",
        );

        /**
         * Scheduler "onBeforeEventDelete" event
         */
        const beforeEventDelete$ = listeners.onBeforeEventDelete.subscribe((entries: CalendarEntry[]) => {
            logEvent(entries, "onBeforeEventDelete");

            if (entries[0]) {
                void calendarEntriesMutations.delete([entries[0].id]);
            }
        }, "event");

        /**
         * Scheduler "onBeforeAssignmentDelete" event
         */
        const beforeAssignmentDelete$ = listeners.onBeforeAssignmentDelete.subscribe(
            (
                data: {
                    entry: CalendarEntry;
                    resource: CalendarResource;
                }[],
            ) => {
                logEvent(
                    data.map((d: { entry: CalendarEntry; resource: CalendarResource }) => d.entry),
                    "onBeforeAssignmentDelete",
                );

                const formattedItems = data.map((item: { entry: CalendarEntry; resource: CalendarResource }) => {
                    return {
                        ...item.entry,
                        resourceIds: item.entry.resourceIds.filter((id: string | number) => item.resource.id !== id),
                    };
                });

                const itemsToUpdate = formattedItems.filter((item: CalendarEntry) => item.resourceIds.length);
                const itemsToDelete = formattedItems.filter((item: CalendarEntry) => !item.resourceIds.length);

                if (itemsToUpdate.length) {
                    const payload = calendarEntriesApiMappers.valuesToApi(formattedItems, calendarResources);

                    void calendarEntriesMutations.upsert(payload);
                }

                if (itemsToDelete.length) {
                    const payload = formattedItems.map((item: CalendarEntry) => item.id);

                    void calendarEntriesMutations.delete(payload);
                }
            },
            "event",
        );

        /**
         * Scheduler "onAfterEventSave" event
         */
        const afterEventSave$ = listeners.onAfterEventSave.subscribe(
            (item: { entry: CalendarEntry; shouldNotifyAssignees: boolean }) => {
                logEvent([item.entry], "onAfterEventSave");

                const payload = calendarEntriesApiMappers.valuesToApi([item.entry], calendarResources);

                void calendarEntriesMutations.upsert(payload);

                if (item.shouldNotifyAssignees) {
                    onNotifyAssignees([item.entry]);
                }
            },
            "event",
        );

        return () => {
            eventDrop$.unsubscribe();
            eventResizeEnd$.unsubscribe();
            eventPaste$.unsubscribe();
            beforeEventDelete$.unsubscribe();
            beforeAssignmentDelete$.unsubscribe();
            afterEventSave$.unsubscribe();
        };
    }, [listeners, calendarEntriesMutations, calendarResources, onNotifyAssignees]);

    /**
     * Training events
     */
    useEffect(() => {
        /**
         * Scheduler "onEventDrop" event
         */
        const eventDrop$ = listeners.onEventDrop.subscribe(
            (item: { entries: CalendarEntry[]; shouldNotifyAssignees: boolean }) => {
                logEvent(item.entries, "TRAINING onEventDrop");

                const payload = trainingEventsApiMappers.valuesToApi(item.entries, calendarResources);

                void trainingEventsMutations.upsert({
                    items: payload,
                    openingProjects,
                    shouldRemovePrevItems: false,
                });

                if (item.shouldNotifyAssignees) {
                    onNotifyAssignees(item.entries);
                }
            },
            "training-event",
        );

        /**
         * Scheduler "onEventResizeEnd" event
         */
        const eventResizeEnd$ = listeners.onEventResizeEnd.subscribe((entry: CalendarEntry) => {
            logEvent([entry], "TRAINING onEventResizeEnd");

            const payload = trainingEventsApiMappers.valuesToApi([entry], calendarResources);

            void trainingEventsMutations.upsert({
                items: payload,
                openingProjects,
                shouldRemovePrevItems: false,
            });
        }, "training-event");

        /**
         * Scheduler "onPaste" event
         */
        const eventPaste$ = listeners.onEventPaste.subscribe(
            (item: { entries: CalendarEntry[]; shouldNotifyAssignees: boolean }) => {
                logEvent(item.entries, "TRAINING onEventPaste");

                const payload = trainingEventsApiMappers.valuesToApi(item.entries, calendarResources);

                void trainingEventsMutations.upsert({
                    items: payload,
                    openingProjects,
                    shouldRemovePrevItems: false,
                });

                if (item.shouldNotifyAssignees) {
                    onNotifyAssignees(item.entries);
                }
            },
            "training-event",
        );

        /**
         * Scheduler "onBeforeEventDelete" event
         */
        const beforeEventDelete$ = listeners.onBeforeEventDelete.subscribe((entries: CalendarEntry[]) => {
            logEvent(entries, "TRAINING onBeforeEventDelete");

            if (entries[0]) {
                void trainingEventsMutations.delete([entries[0]]);
            }
        }, "training-event");

        /**
         * Scheduler "onBeforeAssignmentDelete" event
         */
        const beforeAssignmentDelete$ = listeners.onBeforeAssignmentDelete.subscribe(
            (
                data: {
                    entry: CalendarEntry;
                    resource: CalendarResource;
                }[],
            ) => {
                logEvent(
                    data.map((d: { entry: CalendarEntry; resource: CalendarResource }) => d.entry),
                    "TRAINING onBeforeAssignmentDelete",
                );

                const formattedItems = data.map((item: { entry: CalendarEntry; resource: CalendarResource }) => {
                    return {
                        ...item.entry,
                        resourceIds: item.entry.resourceIds.filter((id: string | number) => item.resource.id !== id),
                    };
                });

                const itemsToUpdate = formattedItems.filter((item: CalendarEntry) => item.resourceIds.length);
                const itemsToDelete = formattedItems.filter((item: CalendarEntry) => !item.resourceIds.length);

                if (itemsToUpdate.length) {
                    const payload = trainingEventsApiMappers.valuesToApi(formattedItems, calendarResources);

                    void trainingEventsMutations.upsert({
                        items: payload,
                        openingProjects,
                        shouldRemovePrevItems: false,
                    });
                }

                if (itemsToDelete.length) {
                    void trainingEventsMutations.delete(formattedItems);
                }
            },
            "training-event",
        );

        /**
         * Scheduler "onAfterEventSave" event
         */
        const afterEventSave$ = listeners.onAfterEventSave.subscribe(
            (item: { entry: CalendarEntry; shouldNotifyAssignees: boolean }) => {
                logEvent([item.entry], "TRAINING onAfterEventSave");

                const payload = trainingEventsApiMappers.valuesToApi([item.entry], calendarResources);

                void trainingEventsMutations.upsert({
                    items: payload,
                    openingProjects,
                    shouldRemovePrevItems: true,
                });

                if (item.shouldNotifyAssignees) {
                    onNotifyAssignees([item.entry]);
                }
            },
            "training-event",
        );

        return () => {
            eventDrop$.unsubscribe();
            eventResizeEnd$.unsubscribe();
            eventPaste$.unsubscribe();
            beforeEventDelete$.unsubscribe();
            beforeAssignmentDelete$.unsubscribe();
            afterEventSave$.unsubscribe();
        };
    }, [listeners, trainingEventsMutations, calendarResources, onNotifyAssignees, openingProjects]);

    return null;
}

export default React.memo(SchedulerEventsSubscriber);
