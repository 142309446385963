import React from "react";

import classnames from "classnames/bind";

import { COLOR } from "@CORE/constants";

import { SidebarView } from "@INTEGRATIONS/scheduler/config/common";
import { CalendarResourceModel } from "@INTEGRATIONS/scheduler/models";

import styles from "./ResourceInfoView.module.scss";


const cx: CX = classnames.bind(styles);

function Avatar({
    logo, //
    abbreviation,
    background = "transparent",
}: AvatarProps) {
    return (
        <div
            style={{
                background,
            }}
            className={cx("avatar")}
        >
            <div
                style={{
                    backgroundImage: `url(${logo})`,
                }}
                className={cx("image-logo")}
            />
            <div className={cx("abbreviation")}>{abbreviation.trim() || "?"}</div>
        </div>
    );
}

interface AvatarProps {
    logo: string;
    abbreviation: string;
    background?: React.CSSProperties["background"];
}

function ResourceInfoView({
    resourceRecord, //
    view,
}: Props) {
    const {
        name, //
        abbreviation,
    } = resourceRecord;

    if (view === "COLLAPSED") {
        return (
            <div className={cx("resource-info-collapsed-view")}>
                <Avatar
                    logo={resourceRecord.logo}
                    abbreviation={abbreviation}
                />
            </div>
        );
    }

    return (
        <div className={cx("resource-info-full-view")}>
            <div className={cx("avatar-wrapper")}>
                <Avatar
                    logo={resourceRecord.logo}
                    abbreviation={abbreviation}
                    background={COLOR["blue-grey-light-3"]}
                />
            </div>
            <div className={cx("name")}>{name.trim() || "Unknown"}</div>
        </div>
    );
}

interface Props {
    resourceRecord: CalendarResourceModel;
    view: SidebarView;
}

export default ResourceInfoView;
