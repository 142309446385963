import { AxiosResponse } from "axios";
import { EMPTY, catchError, expand, from, lastValueFrom, map, reduce, tap } from "rxjs";
import config from "src/config";
import { z } from "zod";

import { BaseApi } from "@API/base";

import { CalendarEntryType } from "@INTEGRATIONS/scheduler/types";
import { removeTimezoneOffset } from "@INTEGRATIONS/scheduler/utils";

import { locationToString, stringToLocation, throwApiError } from "@UTILS/helpers";

const typeSchema = z.enum(["Holiday", "Opening", "Travel", "Resource Activity"]);

const generalSchema = z.object({
    "@row.id": z.number(),
    "Id": z.string().nullable(),
    "Start Date": z.coerce.date(),
    "End Date": z.coerce.date(),
    "Start Timestamp": z.coerce.date().nullable(),
    "End Timestamp": z.coerce.date().nullable(),
    "Tentative": z.boolean(),
    "Description": z.string().trim().nullable(),
    "Project 1": z.string().trim().nullable(),
    "Project 2": z.string().trim().nullable(),
    "Project 3": z.string().trim().nullable(),
    "Project 4": z.string().trim().nullable(),
    "Non-Opening Project 1": z.string().trim().nullable(),
    "Non-Opening Project 2": z.string().trim().nullable(),
    "Non-Opening Project 3": z.string().trim().nullable(),
    "Non-Opening Project 4": z.string().trim().nullable(),
    "Label": z.string().trim().nullable(),
    "Location Name": z.string().trim().nullable(),
    "Entry data URL": z.string().trim().nullable(),
    "Travel Destination": z.string().trim().nullable(),
    "Project MARSHA": z.string().trim().nullable(),
    "GD Project#": z.string().trim().nullable(),
    "Holiday Created from Scheduler": z.boolean(),
});

const assignedCalendarEntrySchema = generalSchema.extend({
    "Type": typeSchema,
    "User 1": z.string().trim(),
    "User 2": z.string().trim().nullable(),
    "User 3": z.string().trim().nullable(),
    "User 4": z.string().trim().nullable(),
    "User 5": z.string().trim().nullable(),
    "User 6": z.string().trim().nullable(),
    "User 7": z.string().trim().nullable(),
    "User 8": z.string().trim().nullable(),
});

const unassignedCalendarEntrySchema = generalSchema.extend({
    "Type": typeSchema.extract(["Resource Activity"]),
    "User 1": z.null(),
    "User 2": z.null(),
    "User 3": z.null(),
    "User 4": z.null(),
    "User 5": z.null(),
    "User 6": z.null(),
    "User 7": z.null(),
    "User 8": z.null(),
});

const calendarEntrySchema = z.union([
    assignedCalendarEntrySchema, //
    unassignedCalendarEntrySchema,
]);

type SchemaInput = z.input<typeof calendarEntrySchema>;
type SchemaOutput = z.output<typeof calendarEntrySchema>;

function checkAllowedTypeToUpsert(t: CalendarEntryType) {
    if (
        t === "RESOURCE_ACTIVITY" || //
        t === "HOLIDAYS"
    ) {
        return t;
    }

    throw new Error("Type not allowed to upsert");
}

const calendarEntryTypesMapper = {
    typeToValue(t: CalendarEntryType): SchemaOutput["Type"] {
        const _ = {
            RESOURCE_ACTIVITY: "Resource Activity",
            HOLIDAYS: "Holiday",
            OPENINGS: "Opening",
            TRAVEL: "Travel",
            RESOURCE_BLOCKED_PERIOD: null,
        } satisfies Record<CalendarEntryType, SchemaOutput["Type"] | null>;

        if (_[t] === null) {
            throw new Error("Resource blocked period not allowed");
        }

        return _[t] as SchemaOutput["Type"];
    },
    valueToType(v: SchemaOutput["Type"]): CalendarEntryType {
        const _ = {
            "Holiday": "HOLIDAYS",
            "Opening": "OPENINGS",
            "Travel": "TRAVEL",
            "Resource Activity": "RESOURCE_ACTIVITY",
        } satisfies Record<SchemaOutput["Type"], CalendarEntryType>;

        return _[v];
    },
};

export type CalendarEntryLocationApiModel =
    | {
          custom: null;
          city: string;
          country: string;
      }
    | {
          custom: string;
          city: null;
          country: null;
      };

export type CalendarEntryApiModel = {
    id: number | string;
    name: string;
    startDate: Date;
    endDate: Date;
    isTentative: boolean;
    description: string;
    type: CalendarEntryType;
    openingProjectIds: string[];
    nonOpeningProjectIds: string[];
    resourceEmails: string[];
    projectMarsha?: string;
    gdProjectId?: string;
    location: CalendarEntryLocationApiModel | null;
    url: string | null;
    travelDestination: string | null;
    isCreatedFromSchedulerHoliday: boolean;
};

type CalendarEntryUpdatePayload = Pick<CalendarEntryApiModel, "id"> & CalendarEntryApiModel;

interface FindParams {
    page: number;
    dates: {
        from: string;
        to: string;
    };
}

interface FindAllParams {
    dates: {
        from: string;
        to: string;
    };
}

const MAX_TOTAL_RECORDS = 500;
const MAX_RESOURCES = 8;
const MAX_PROJECTS = 4;

export class CalendarEntriesApi extends BaseApi {
    private async find(
        params: FindParams,
        signal?: AbortSignal,
    ): Promise<{ data: CalendarEntryApiModel[]; totalCount: number }> {
        const { page = 1, dates } = params || {};
        const skip = MAX_TOTAL_RECORDS * (page - 1);
        const urlParams = new URLSearchParams();

        urlParams.append("skip", skip.toString());
        urlParams.append(
            "filter",
            [
                "([Type]='Holiday' or [Type]='Opening' or [Type]='Travel' or [Type]='Resource Activity')",
                [
                    `([Start Date]>=#${dates.from}# and [Start Date]<=#${dates.to}#)`, //
                    `([End Date]>=#${dates.from}# and [End Date]<=#${dates.to}#)`,
                    `([Start Date]<=#${dates.from}# and [End Date]>=#${dates.to}#)`,
                ].join(" or "),
            ].join(" and "),
        );

        return lastValueFrom<{ data: CalendarEntryApiModel[]; totalCount: number }>(
            from(
                this.client.get(`/${config.CALENDAR_ENTRIES_TABLE_NAME}/API/select.json?${urlParams.toString()}`, {
                    signal,
                }),
            )
                .pipe(
                    map((response: AxiosResponse<SchemaInput[]>) => ({
                        data: response.data
                            .map((item: SchemaInput) => {
                                const parsed = calendarEntrySchema.safeParse(item);

                                if (parsed.success) {
                                    return parsed.data;
                                }

                                return null;
                            })
                            .filter(Boolean)
                            .map((item: SchemaOutput) => {
                                const localStartDate = item["Start Date"] || item["Start Timestamp"];
                                const localEndDate = item["End Date"] || item["End Timestamp"];

                                const { startDate, endDate } = removeTimezoneOffset(localStartDate, localEndDate, 1);

                                return {
                                    id: item.Id || item["@row.id"],
                                    startDate,
                                    endDate,
                                    isTentative: item.Tentative || false,
                                    description: item.Description || "",
                                    type: calendarEntryTypesMapper.valueToType(item.Type),
                                    name: item.Label || "",
                                    location: stringToLocation(item["Location Name"] || null),
                                    url: item["Entry data URL"] || "",
                                    travelDestination: item["Travel Destination"],
                                    projectMarsha: item["Project MARSHA"] || "",
                                    gdProjectId: item["GD Project#"] || "",
                                    openingProjectIds: [
                                        item["Project 1"],
                                        item["Project 2"],
                                        item["Project 3"],
                                        item["Project 4"],
                                    ].filter(Boolean),
                                    nonOpeningProjectIds: [
                                        item["Non-Opening Project 1"],
                                        item["Non-Opening Project 2"],
                                        item["Non-Opening Project 3"],
                                        item["Non-Opening Project 4"],
                                    ].filter(Boolean),
                                    resourceEmails: [
                                        item["User 1"],
                                        item["User 2"],
                                        item["User 3"],
                                        item["User 4"],
                                        item["User 5"],
                                        item["User 6"],
                                        item["User 7"],
                                        item["User 8"],
                                    ]
                                        .filter(Boolean)
                                        .map((resource: string | null) => resource?.match(/<(.*)>/)?.[1] || ""),
                                    isCreatedFromSchedulerHoliday: item["Holiday Created from Scheduler"],
                                };
                            }),
                        totalCount: response.data.length,
                    })),
                )
                .pipe(
                    catchError((error: unknown) => {
                        throwApiError(error, "Can't get calendar entries: ");
                    }),
                ),
        );
    }

    async findAll(params: FindAllParams, signal?: AbortSignal): Promise<CalendarEntryApiModel[]> {
        let page = 1;

        return lastValueFrom<CalendarEntryApiModel[]>(
            from(this.find({ page: page++, dates: params.dates }, signal)).pipe(
                expand((response: { data: CalendarEntryApiModel[]; totalCount: number }) => {
                    return response.totalCount === MAX_TOTAL_RECORDS
                        ? this.find({ page: page++, dates: params.dates })
                        : EMPTY;
                }),
                reduce(
                    (acc: CalendarEntryApiModel[], current: { data: CalendarEntryApiModel[]; totalCount: number }) =>
                        acc.concat(current.data),
                    [],
                ),
            ),
        );
    }

    async upsert(updateEntryPayloads: CalendarEntryUpdatePayload[]): Promise<unknown> {
        const body = updateEntryPayloads.map((entry: CalendarEntryUpdatePayload) => {
            const { location } = entry;

            const { startDate, endDate } = removeTimezoneOffset(entry.startDate, entry.endDate, -1);

            const extraCheck = z
                .object({
                    startDate: z.date(),
                    endDate: z.date(),
                })
                .safeParse({
                    startDate,
                    endDate,
                });

            if (!extraCheck.success) {
                throw new Error(
                    "Can't create/update calendar entry:\n'Start Date' or 'End Date' are missing or invalid",
                );
            }

            return {
                ...(startDate
                    ? {
                          ...(entry.type === "HOLIDAYS"
                              ? {
                                    Date: startDate.toISOString(),
                                }
                              : {}),
                          "Start Date": startDate.toISOString(),
                          "Start Timestamp": startDate.toISOString(),
                      }
                    : {}),
                ...(endDate
                    ? {
                          "End Date": endDate.toISOString(),
                          "End Timestamp": endDate.toISOString(),
                      }
                    : {}),
                "Type": calendarEntryTypesMapper.typeToValue(
                    checkAllowedTypeToUpsert(entry.type), //
                ),
                "Tentative": entry.isTentative,
                "Location Name": locationToString(location || null),
                ...(entry.description ? { Description: entry.description } : {}),
                ...(entry.name ? { Label: entry.name } : {}),
                "Id": entry.id,
                ...(entry.resourceEmails
                    ? [...Array(MAX_RESOURCES).keys()].reduce(
                          (acc: Record<string, string | null>, _: number, index: number) => {
                              const newAcc = { ...acc };
                              newAcc[`User ${index + 1}`] = entry?.resourceEmails?.[index] || null;
                              return newAcc;
                          },
                          {},
                      )
                    : {}),

                ...(entry.openingProjectIds
                    ? [...Array(MAX_PROJECTS).keys()].reduce(
                          (acc: Record<string, string | null>, _: number, index: number) => {
                              const newAcc = { ...acc };
                              newAcc[`Project ${index + 1}`] = entry?.openingProjectIds?.[index] || null;
                              return newAcc;
                          },
                          {},
                      )
                    : {}),

                ...(entry.nonOpeningProjectIds
                    ? [...Array(MAX_PROJECTS).keys()].reduce(
                          (acc: Record<string, string | null>, _: number, index: number) => {
                              const newAcc = { ...acc };
                              newAcc[`Non-Opening Project ${index + 1}`] = entry?.nonOpeningProjectIds?.[index] || null;
                              return newAcc;
                          },
                          {},
                      )
                    : {}),
                "Holiday Created from Scheduler": entry.isCreatedFromSchedulerHoliday,
            };
        });

        const url = `/${config.CALENDAR_ENTRIES_TABLE_NAME}/upsert.json`;

        type Response = [
            | {
                  status: 200; // updated
                  id: number;
                  key: string;
              }
            | {
                  status: 400; // error
                  id: number;
                  key: string;
                  errors: [
                      {
                          error: 409;
                          source?: string;
                          message: string;
                      },
                  ];
              }
            | {
                  status: 201; // created
                  id: number;
                  key: string;
              },
        ];

        return lastValueFrom(
            from(this.client.post(url, body))
                .pipe(
                    tap((response: AxiosResponse<Response>) => {
                        const data = response.data[0];

                        if (data.status === 400) {
                            console.error(data);

                            if (data.errors[0]) {
                                const error = data.errors[0];

                                throw new Error(
                                    error.message + (error.source ? "\nSource [" + error.source + "]" : ""),
                                );
                            } else {
                                throw new Error("Unexpected error");
                            }
                        }
                    }),
                )
                .pipe(
                    catchError((error: unknown) => {
                        throwApiError(error, "Can't create/update calendar entry: ");
                    }),
                ),
        );
    }

    async delete(id: number | string): Promise<unknown> {
        const url = `/${config.CALENDAR_ENTRIES_TABLE_NAME}/delete.json?key=${id}`;

        type Response = [
            | {
                  status: 200;
                  id: number;
              }
            | {
                  status: 403;
                  id: number;
                  error: {
                      error: 403;
                      code: 4000;
                      message: string;
                  };
              },
        ];

        return lastValueFrom(
            from(this.client.get(url))
                .pipe(
                    tap((response: AxiosResponse<Response>) => {
                        const data = response.data[0];

                        if (data.status !== 200) {
                            console.error(data);

                            throw new Error(data.error.message);
                        }
                    }),
                )
                .pipe(
                    catchError((error: unknown) => {
                        throwApiError(error, "Can't delete calendar entry: ");
                    }),
                ),
        );
    }
}
