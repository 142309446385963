import React from "react";
import { IconProps } from "@CORE/interfaces";

function IconClose({ color = "inherit", size = "24px", ...rest }: IconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            {...rest}
        >
            <g id="X">
                <path
                    id="Vector"
                    d="M18.75 5.25L5.25 18.75"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    id="Vector_2"
                    d="M18.75 18.75L5.25 5.25"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};

export default IconClose;
