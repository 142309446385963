import React, { useEffect, useState } from "react";

import ReactDOM from "react-dom";

import classnames from "classnames/bind";

import { COLOR } from "@CORE/constants";

import { BarSpinner } from "@VIEW/components/basic/spinners";

import styles from "./SchedulerDataLoadingBar.module.scss";

const cx: CX = classnames.bind(styles);

const schedulerHeaderElementId = "b-schedulerpro-1-normalSubgrid-header";
const portalId = "scheduler-data-loading-bar";

function SchedulerDataLoadingBar({
    visible, //
}: Props) {
    const [ready, setReady] = useState(false);

    useEffect(() => {
        const schedulerHeaderElement = document.getElementById(schedulerHeaderElementId);

        const portal = document.createElement("div");

        portal.setAttribute("id", portalId);

        if (schedulerHeaderElement) {
            schedulerHeaderElement.appendChild(portal);

            setReady(true);
        }

        return () => {
            portal.remove();
        };
    }, []);

    if (!ready) return null;

    return ReactDOM.createPortal(
        <div className={cx("scheduler-data-loading-bar")}>
            {visible && <BarSpinner color={COLOR["blue-main"]} />}
        </div>,
        document.getElementById(portalId)!,
    );
}

interface Props {
    visible: boolean;
}

export default React.memo(SchedulerDataLoadingBar);
