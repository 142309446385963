import { AxiosResponse } from "axios";
import { catchError, from, lastValueFrom, map } from "rxjs";

import { BaseApi } from "@API/base";

import { removeTimezoneOffset } from "@INTEGRATIONS/scheduler/utils";

import { throwApiError } from "@UTILS/helpers";

type FindAllParams = {
    provinceCodes: string[];
    startDate: string;
    endDate: string;
};

type HolidayRaw = {
    id: string;
    name: string;
    startDate: string;
    endDate: string;
    provinceCode: string;
};

export type HolidayApiModel = {
    id: string;
    name: string;
    startDate: Date;
    endDate: Date;
    provinceCode: string;
};

function formatDate(stringDate: string) {
    return new Date(stringDate);
}

export class HolidaysApi extends BaseApi {
    async findAll(params: FindAllParams, signal?: AbortSignal): Promise<HolidayApiModel[]> {
        const urlParams = new URLSearchParams();

        urlParams.append("provinceCodes", params.provinceCodes.join(","));
        urlParams.append("startDate", params.startDate);
        urlParams.append("endDate", params.endDate);

        const url = "/holidays" + "?" + urlParams.toString();

        return lastValueFrom(
            from(
                this.client.get(url, {
                    signal,
                }),
            )
                .pipe<HolidayApiModel[]>(
                    map((response: AxiosResponse<HolidayRaw[]>) => {
                        return response.data
                            .map((holiday: HolidayRaw) => {
                                const localStartDate = formatDate(holiday.startDate);
                                const localEndDate = formatDate(holiday.endDate);

                                if (
                                    localStartDate.toString() === "Invalid Date" ||
                                    localEndDate.toString() === "Invalid Date"
                                ) {
                                    return null;
                                }

                                const { startDate, endDate } = removeTimezoneOffset(localStartDate, localEndDate, 1);

                                return {
                                    id: holiday.id,
                                    name: holiday.name,
                                    startDate,
                                    endDate,
                                    provinceCode: holiday.provinceCode,
                                };
                            })
                            .filter(Boolean);
                    }),
                )
                .pipe(
                    catchError((error: unknown) => {
                        throwApiError(error, "Can't get public holidays: ");
                    }),
                ),
        );
    }
}
