import { CalendarModel } from "@bryntum/schedulerpro";

export class AppCalendarModel extends CalendarModel {
    static get $name(): string {
        return "AppCalendarModel";
    }
}

export const eventBlockedCalendar = new AppCalendarModel({
    id: "event-blocked",
    unspecifiedTimeIsWorking: true,
    intervals: [],
});
