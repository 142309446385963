import React from "react";

import { IconProps } from "@CORE/interfaces";

function WarningIcon({
    size = defaultProps.size, //
    color = defaultProps.color,
}: IconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g>
                <path
                    d="M10 10.625V6.25"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10 14.375C10.5178 14.375 10.9375 13.9553 10.9375 13.4375C10.9375 12.9197 10.5178 12.5 10 12.5C9.48223 12.5 9.0625 12.9197 9.0625 13.4375C9.0625 13.9553 9.48223 14.375 10 14.375Z"
                    fill={color}
                />
                <path
                    d="M9.56023 2.0572L2.05661 9.56082C1.81406 9.80337 1.81406 10.1966 2.05661 10.4392L9.56023 17.9428C9.80278 18.1854 10.196 18.1853 10.4386 17.9428L17.9422 10.4392C18.1848 10.1966 18.1848 9.80337 17.9422 9.56082L10.4386 2.0572C10.196 1.81465 9.80278 1.81465 9.56023 2.0572Z"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}

const defaultProps = {
    size: "1em",
    color: "currentColor",
};

export default WarningIcon;
