import { AssignmentStore } from "@bryntum/schedulerpro";

import { CalendarAssignmentModel, CalendarEntryModel, CalendarResourceModel } from "@INTEGRATIONS/scheduler/models";

export class AppAssignmentStore extends AssignmentStore {
    createRecord(data: {
        event: CalendarEntryModel; //
        resource: CalendarResourceModel;
    }): CalendarAssignmentModel {
        return new CalendarAssignmentModel({
            event: data.event,
            resource: data.resource,
        });
    }
}

export const appAssignmentStore = new AppAssignmentStore({
    modelClass: CalendarAssignmentModel,
});
