import React, { createContext, useReducer } from "react";

import { Draft, produce } from "immer";

import { CalendarEntryModel, CalendarResourceModel } from "@INTEGRATIONS/scheduler/models";


type SchedulerPopupsState = {
    eventEditPopupState: {
        isOpen: boolean;
        data: {
            eventRecord: CalendarEntryModel;
            resourceRecord: CalendarResourceModel;
        } | null;
    };
    eventDeleteConfirmPopupState: {
        isOpen: boolean;
        data: {
            eventRecords: CalendarEntryModel[];
        } | null;
    };
};

export type EventEditPopupOpenAction = {
    type: "event-edit-popup-open";
    payload: {
        eventRecord: CalendarEntryModel;
        resourceRecord: CalendarResourceModel;
    };
};

export type EventEditPopupCloseAction = {
    type: "event-edit-popup-close";
};

export type EventDeleteConfirmPopupOpenAction = {
    type: "event-delete-confirm-popup-open";
    payload: {
        eventRecords: CalendarEntryModel[];
    };
};

export type EventDeleteConfirmPopupCloseAction = {
    type: "event-delete-confirm-popup-close";
};

export type SchedulerPopupsDispatchAction =
    | EventEditPopupOpenAction
    | EventEditPopupCloseAction
    | EventDeleteConfirmPopupOpenAction
    | EventDeleteConfirmPopupCloseAction;

const initialState = {
    eventEditPopupState: {
        isOpen: false,
        data: null,
    },
    eventDeleteConfirmPopupState: {
        isOpen: false,
        data: null,
    },
} satisfies SchedulerPopupsState;

function reducer(state: SchedulerPopupsState, action: SchedulerPopupsDispatchAction): SchedulerPopupsState {
    switch (action.type) {
        case "event-edit-popup-open": {
            return produce(state, (draft: Draft<SchedulerPopupsState>) => {
                draft.eventEditPopupState.isOpen = true;
                draft.eventEditPopupState.data = action.payload;

                return draft;
            });
        }

        case "event-edit-popup-close": {
            return produce(state, (draft: Draft<SchedulerPopupsState>) => {
                draft.eventEditPopupState.isOpen = false;
                draft.eventEditPopupState.data = null;

                return draft;
            });
        }

        case "event-delete-confirm-popup-open": {
            return produce(state, (draft: Draft<SchedulerPopupsState>) => {
                draft.eventDeleteConfirmPopupState.isOpen = true;
                draft.eventDeleteConfirmPopupState.data = action.payload;

                return draft;
            });
        }

        case "event-delete-confirm-popup-close": {
            return produce(state, (draft: Draft<SchedulerPopupsState>) => {
                draft.eventDeleteConfirmPopupState.isOpen = false;
                draft.eventDeleteConfirmPopupState.data = null;

                return draft;
            });
        }
    }
}

export const SchedulerPopupsContext = createContext<
    [
        SchedulerPopupsState, //
        React.Dispatch<SchedulerPopupsDispatchAction> | null,
    ]
>([
    initialState, //
    null,
]);

export function SchedulerPopupsProvider({
    children, //
}: ChildrenProps) {
    const value = useReducer(reducer, initialState);

    return <SchedulerPopupsContext.Provider value={value}>{children}</SchedulerPopupsContext.Provider>;
}
