import { SchedulerPro } from "@bryntum/schedulerpro";

import { GlobalBlockedPeriodTimeSpanModel } from "@INTEGRATIONS/scheduler/models";
import { getPreviousDay } from "@INTEGRATIONS/scheduler/utils";
import { getTimeRangeStore } from "@INTEGRATIONS/scheduler/utils/helpers";

export function getExcludedDateIntervals(source: SchedulerPro): {
    start: Date;
    end: Date;
}[] {
    const store = getTimeRangeStore(source);

    if (!store) return [];

    return store.getBlockedPeriods().map((model: GlobalBlockedPeriodTimeSpanModel) => ({
        start: model.startDate,
        end: getPreviousDay(model.endDate),
    }));
}
