import React from "react";

import ReactDOMServer from "react-dom/server";

import { Button, DateHelper, SummaryConfig } from "@bryntum/schedulerpro";

import { COLOR } from "@CORE/constants";

import { SummaryItemView, SummaryOpeningsMenuView } from "@INTEGRATIONS/scheduler/components/common";
import { AppEventStore } from "@INTEGRATIONS/scheduler/config/stores";
import { CalendarEntryModel } from "@INTEGRATIONS/scheduler/models";
import { renderDateRange } from "@INTEGRATIONS/scheduler/utils";

class SummaryDropdownButton extends Button {
    menuIcon = "";

    construct(config: object) {
        super.construct(config);
    }

    compose() {
        return {
            tag: "button",
            class: "",
            style: "height: 100%; margin: 2px",
        };
    }
}

function createHeaderTitle(dates: [Date, Date]) {
    return (
        "Openings " +
        renderDateRange({
            startDate: dates[0],
            endDate: dates[1],
            dateFormat: "MMM DD, YYYY",
        })
    );
}

export const summaryFeature = {
    renderer: (original: {
        startDate: Date; //
        endDate: Date;
        eventStore: AppEventStore;
        element: HTMLElement;
    }) => {
        const events = original.eventStore.allRecords.filter((model: unknown): model is CalendarEntryModel => {
            if (model instanceof CalendarEntryModel) {
                return (
                    model.entryType === "OPENINGS" &&
                    DateHelper.intersectSpans(
                        original.startDate, //
                        original.endDate,
                        model.startDate,
                        model.endDate,
                    )
                );
            }

            return false;
        });

        const eventRecords = [
            ...new Map(
                events.map((item: CalendarEntryModel) => [item.id, item]), //
            ).values(),
        ];

        const count = eventRecords.length;

        if (count === 0) return null;

        void Promise.resolve().then(() => {
            const dropdown = document.createElement("div");

            dropdown.innerHTML = ReactDOMServer.renderToStaticMarkup(
                <SummaryOpeningsMenuView
                    headerTitle={createHeaderTitle([
                        original.startDate, //
                        original.endDate,
                    ])}
                    eventRecords={eventRecords}
                />,
            );

            const dataSummaryItem = "opening";

            const dropdownButton = new SummaryDropdownButton({
                content: ReactDOMServer.renderToStaticMarkup(
                    <SummaryItemView
                        eventRecords={eventRecords}
                        dataSummaryItem={dataSummaryItem}
                        color={COLOR["entry-openings"]}
                        hoverColor="#7BEC65"
                        active
                    />,
                ),
                onToggle(event: { pressed: boolean }) {
                    const item = dropdownButton.element.querySelector(`[data-summary-item="${dataSummaryItem}"]`);

                    if (!item) return;

                    if (event.pressed) {
                        item.setAttribute("data-active", "1");
                    } else {
                        item.removeAttribute("data-active");
                    }
                },
                menu: {
                    hideAnimation: false,
                    showAnimation: false,
                    cls: "custom-openings-summary-dropdown",
                    items: [
                        {
                            type: "widget",
                            element: dropdown,
                        },
                    ],
                },
            });

            const closeButton = dropdown.querySelector<HTMLButtonElement>("[data-openings-dropdown-close='1']");

            if (closeButton) {
                closeButton.onclick = () => {
                    dropdownButton.toggle(false);
                };
            }

            // eslint-disable-next-line no-param-reassign
            original.element.innerHTML = "";
            original.element.appendChild(dropdownButton.element);
        });
    },
    showTooltip: false,
} satisfies Partial<SummaryConfig>;
