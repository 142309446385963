import React from "react";

import classnames from "classnames/bind";

import { EventConfirm } from "@INTEGRATIONS/scheduler/components/common";

import styles from "./EventFormPopups.module.scss";

const cx: CX = classnames.bind(styles);

function EventFormPopups({
    showDeleteConfirmation, //
    onDeleteConfirm,
    onDeleteCancel,
    showCloseConfirmation,
    onCloseConfirm,
    onCloseCancel,
}: Props) {
    return (
        <div className={cx("event-form-popups")}>
            {showDeleteConfirmation && (
                <div className={cx("confirm-wrapper")}>
                    <EventConfirm
                        title="You are going to delete the Entry"
                        onConfirm={onDeleteConfirm}
                        onCancel={onDeleteCancel}
                    />
                </div>
            )}

            {showCloseConfirmation && (
                <div className={cx("confirm-wrapper")}>
                    <EventConfirm
                        title="You are about to leave a record with unsaved changes"
                        onConfirm={onCloseConfirm}
                        onCancel={onCloseCancel}
                    />
                </div>
            )}
        </div>
    );
}

interface Props {
    showDeleteConfirmation: boolean;
    onDeleteConfirm: () => void;
    onDeleteCancel: () => void;
    showCloseConfirmation: boolean;
    onCloseConfirm: () => void;
    onCloseCancel: () => void;
}

export default EventFormPopups;
