import React from "react";

import classnames from "classnames/bind";

import { SchedulerResourceModel } from "@bryntum/schedulerpro";
import { BryntumSchedulerPro, BryntumSchedulerProProps } from "@bryntum/schedulerpro-react";
import { nanoid } from "nanoid";

import { schedulerProConfig } from "@INTEGRATIONS/scheduler/config";
import { useSchedulerInstance, useSchedulerListeners } from "@INTEGRATIONS/scheduler/hooks";
import { useSchedulerPopups } from "@INTEGRATIONS/scheduler/hooks/internal";
import {
    CalendarAssignmentModel,
    CalendarEntryModel,
    CalendarResourceModel,
    EXTERNAL_TRAINER_RESOURCE_ID,
    UNASSIGNED_RESOURCE_ID,
} from "@INTEGRATIONS/scheduler/models";
import "@INTEGRATIONS/scheduler/styles/scheduler.scss";
import {
    OnAfterEventSaveExtended,
    OnBeforeAssignmentDelete,
    OnBeforeEventDelete,
    OnBeforeEventEdit,
    OnEventDrop,
    OnEventPaste,
    OnEventResizeEnd,
    OnPresetChange,
    ProjectType,
    TrainingTrainerType,
} from "@INTEGRATIONS/scheduler/types";

import styles from "./ShedulerInstance.module.scss";

const cx: CX = classnames.bind(styles);

function updateEventIfNeeded(model: CalendarEntryModel) {
    const isExternalTrainerResource = model.resources.find((resourceModel: SchedulerResourceModel) => {
        return resourceModel.id === EXTERNAL_TRAINER_RESOURCE_ID;
    });

    const isUnassignedResource = model.resources.find((resourceModel: SchedulerResourceModel) => {
        return resourceModel.id === UNASSIGNED_RESOURCE_ID;
    });

    // clear external trainer values if calendar entry moved away
    if (model.trainingTrainerType === TrainingTrainerType.EXTERNAL) {
        if (isUnassignedResource) {
            model.update({
                trainingTrainerType: null,
                trainingTrainerName: "",
                trainingTrainerEmail: "",
            });
        } else if (!isExternalTrainerResource) {
            model.update({
                trainingTrainerType: TrainingTrainerType.INTERNAL,
                trainingTrainerName: "",
                trainingTrainerEmail: "",
            });
        }

        // if moved back to external then do nothing
    }

    // clear internal trainer values if calendar entry moved to unassigned resource
    if (model.trainingTrainerType === TrainingTrainerType.INTERNAL) {
        if (isUnassignedResource) {
            model.update({
                trainingTrainerType: null,
                trainingTrainerName: "",
                trainingTrainerEmail: "",
            });
        }

        // if moved back to internal then do nothing
        // move to external resource is forbidden!
    }

    // move unassigned training event to internal resource
    if (model.isTrainingEvent && model.trainingTrainerType === null) {
        if (!isUnassignedResource && !isExternalTrainerResource) {
            model.update({
                trainingTrainerType: TrainingTrainerType.INTERNAL,
                trainingTrainerName: "",
                trainingTrainerEmail: "",
            });
        }

        // move to external resource is forbidden!
    }

    // update isUnassigned value
    if (isUnassignedResource && !model.isUnassigned) {
        model.eventStore.assignEventToResource(model, UNASSIGNED_RESOURCE_ID, true);

        model.update({
            isUnassigned: true,
        });
    } else if (!isUnassignedResource && model.isUnassigned) {
        model.update({
            isUnassigned: false,
        });
    }

    return model;
}

const MemoizedSchedulerInstance = React.memo(
    function MemoizedSchedulerInstance({
        onEventDrop, //
        onEventResizeEnd,
        onBeforeEventDelete,
        onAfterEventSave,
        onBeforeAssignmentDelete,
        onPaste,
        onBeforeEventEdit,
        onPresetChange,
        onEventDeleteConfirm,
    }: ConfigProps & {
        onEventDeleteConfirm: (original: OnBeforeEventDelete) => void;
    }) {
        const { ref } = useSchedulerInstance();

        const restConfig = {
            onEventDrop: (original: OnEventDrop) => {
                const isUnassigned = original.eventRecords.some((record: CalendarEntryModel) => {
                    return record.isUnassigned;
                });

                const shouldNotifyAssignees =
                    original.targetResourceRecord.id !== UNASSIGNED_RESOURCE_ID && isUnassigned;

                typeof onEventDrop === "function" &&
                    onEventDrop({
                        ...original,
                        eventRecords: original.eventRecords.map(updateEventIfNeeded),
                        shouldNotifyAssignees,
                    });
            },

            onEventResizeEnd: (original: OnEventResizeEnd) => {
                typeof onEventResizeEnd === "function" && onEventResizeEnd(original);
            },

            onBeforeEventDelete: (original: OnBeforeEventDelete) => {
                const canDelete =
                    original.eventRecords.every((record: CalendarEntryModel) => {
                        return !record.readOnly;
                    }) || false;

                if (canDelete) {
                    if (original.isConfirmed === true) {
                        typeof onBeforeEventDelete === "function" && onBeforeEventDelete(original);
                    } else {
                        onEventDeleteConfirm(original);
                    }
                }

                return canDelete && original.isConfirmed === true;
            },

            onAfterEventSave: (original: OnAfterEventSaveExtended) => {
                typeof onAfterEventSave === "function" && onAfterEventSave(original);
            },

            onBeforeAssignmentDelete: (original: OnBeforeAssignmentDelete) => {
                const canUnassign = original.assignmentRecords?.every((record: CalendarAssignmentModel) => {
                    return !record.event.readOnly;
                });

                const isLastAssigned =
                    original.assignmentRecords?.some((assignmentModel: CalendarAssignmentModel) => {
                        return assignmentModel.event.resources.length === 1;
                    }) || false;

                if (canUnassign) {
                    if (!isLastAssigned) {
                        typeof onBeforeAssignmentDelete === "function" && onBeforeAssignmentDelete(original);
                    } else {
                        const triggerData = {
                            eventRecords: original.assignmentRecords.map((assignmentModel: CalendarAssignmentModel) => {
                                return assignmentModel.event;
                            }),
                        } satisfies OnBeforeEventDelete;

                        void original.source.trigger("beforeEventDelete", triggerData);
                    }
                }

                return canUnassign && !isLastAssigned;
            },

            onPaste: (original: OnEventPaste) => {
                if (original.entityName === "event") {
                    const isUnassigned = original.pastedEventRecords.some((record: CalendarEntryModel) => {
                        return record.isUnassigned;
                    });

                    const shouldNotifyAssignees = original.resourceRecord.id !== UNASSIGNED_RESOURCE_ID && isUnassigned;

                    const pastedEventRecords = original.pastedEventRecords
                        .map(updateEventIfNeeded)
                        .map((model: CalendarEntryModel) => {
                            if (!original.isCut && model.projectType === ProjectType.TRAINING_EVENT) {
                                if (model.trainingResourceUniqueId === null) {
                                    return model;
                                }

                                model.set("trainingResourceUniqueId", nanoid());

                                return model;
                            }

                            return model;
                        });

                    typeof onPaste === "function" &&
                        onPaste({
                            ...original,
                            pastedEventRecords,
                            shouldNotifyAssignees,
                        });
                } else {
                    //
                }
            },

            onBeforeEventEdit: (original: OnBeforeEventEdit) => {
                typeof onBeforeEventEdit === "function" &&
                    onBeforeEventEdit({
                        ...original,
                        eventRecord: updateEventIfNeeded(original.eventRecord),
                    });

                return false;
            },

            onPresetChange: (original: OnPresetChange) => {
                if (original.from?.id !== original.to.id) {
                    typeof onPresetChange === "function" && onPresetChange(original);
                }
            },

            onDragCreateStart: (original: { eventRecord: CalendarEntryModel }) => {
                updateEventIfNeeded(original.eventRecord);
            },

            onEventDrag: (original: {
                context: { context: { element?: HTMLElement } };
                eventRecords: CalendarEntryModel[];
                newResource: CalendarResourceModel;
            }) => {
                const { element } = original.context.context;

                if (original.newResource.id === UNASSIGNED_RESOURCE_ID) {
                    element?.firstElementChild?.classList.add("unassigned-drag-highlight");
                } else {
                    element?.firstElementChild?.classList.remove("unassigned-drag-highlight");
                }
            },
        } satisfies ConfigProps;

        return (
            <div className={cx("scheduler-instance")}>
                <BryntumSchedulerPro
                    ref={ref}
                    {...schedulerProConfig}
                    {...restConfig}
                />
            </div>
        );
    },
    () => true,
);

type ConfigProps = Omit<BryntumSchedulerProProps, keyof typeof schedulerProConfig>;

function SchedulerInstance() {
    const {
        actions: { evenEditPopupActions, evenDeleteConfirmPopupActions },
    } = useSchedulerPopups();

    const listeners = useSchedulerListeners();

    return (
        <MemoizedSchedulerInstance
            onBeforeEventEdit={(original: OnBeforeEventEdit) => {
                evenEditPopupActions.open({
                    eventRecord: original.eventRecord,
                    resourceRecord: original.resourceRecord,
                });
            }}
            onEventDrop={listeners.onEventDrop.next}
            onEventResizeEnd={listeners.onEventResizeEnd.next}
            onPaste={listeners.onEventPaste.next}
            onAfterEventSave={listeners.onAfterEventSave.next}
            onBeforeEventDelete={listeners.onBeforeEventDelete.next}
            onBeforeAssignmentDelete={listeners.onBeforeAssignmentDelete.next}
            onPresetChange={listeners.onPresetChange.next}
            onEventDeleteConfirm={(original: OnBeforeEventDelete) => {
                evenDeleteConfirmPopupActions.open({
                    eventRecords: original.eventRecords,
                });
            }}
        />
    );
}

export default React.memo(SchedulerInstance, () => true);
