import { ResourceApiModel, TrainingEventApiModel } from "@API/services";

import { CalendarEntry, CalendarEntryOpeningProject } from "@INTEGRATIONS/scheduler/models";
import { ProjectType } from "@INTEGRATIONS/scheduler/types";

export const trainingEventsApiMappers = {
    apiToValues: (
        trainings: TrainingEventApiModel[],
        resources: ResourceApiModel[],
        projects: CalendarEntryOpeningProject[],
    ): CalendarEntry[] => {
        return trainings.map((item: TrainingEventApiModel) => {
            const resourceIds = item.resourceEmails
                .map((resourceEmail: string) => {
                    return resources.find((resource: ResourceApiModel) => resource.email === resourceEmail)
                        ?.id as number;
                })
                .filter((id: number | undefined) => !!id);

            const projectIds = item.projectIds
                .map((id: string) => {
                    return projects.find((project: CalendarEntryOpeningProject) => {
                        return project.projectId === id.toString();
                    })?.id;
                })
                .filter(Boolean);

            return {
                id: item.id,
                projectType: ProjectType.TRAINING_EVENT,
                name: item.name,
                resourceIds,
                startDate: item.startDate,
                endDate: item.endDate,
                entryType: "RESOURCE_ACTIVITY",
                description: item.description,
                projectIds,
                isTentative: false,
                location: null,
                url: null,

                // holiday
                isExternalHoliday: false,
                isCreatedFromSchedulerHoliday: false,

                // travel
                travelDestination: null,

                // opening
                projectMarsha: null,
                gdProjectId: null,

                // training
                isTrainingEvent: true,
                trainingLocation: item.location,
                trainingTrainerType: item.trainerType,
                trainingTrainerName: item.trainerName,
                trainingTrainerEmail: item.trainerEmail,
                trainingIsStatusConfirmed: item.isStatusConfirmed,
                trainingEventType: item.type,
                trainingEventSubType: item.subType,
                trainingResourceUniqueId: item.resourceUniqueId,

                // blocked
                blockedDisallowRoles: null,
                blockedDisallowUsers: null,
            } satisfies CalendarEntry;
        });
    },
    valuesToApi: (entries: CalendarEntry[], resources: ResourceApiModel[]): TrainingEventApiModel[] => {
        return entries.map((item: CalendarEntry) => {
            const resourceEmails = item.resourceIds
                .map((resourceId: string | number) => {
                    return resources.find((resource: ResourceApiModel) => resource.id === resourceId)?.email;
                })
                .filter((resource: string | undefined): resource is string => !!resource);

            return {
                id: item.id,
                name: item.name,
                type: item.trainingEventType,
                subType: item.trainingEventSubType,
                isStatusConfirmed: item.trainingIsStatusConfirmed,
                startDate: item.startDate,
                endDate: item.endDate,
                location: item.trainingLocation,
                trainerType: item.trainingTrainerType,
                trainerName: item.trainingTrainerName,
                trainerEmail: item.trainingTrainerEmail,
                description: item.description,
                projectIds: item.projectIds.map((id: string | number) => id.toString()),
                resourceEmails,
                resourceUniqueId: item.trainingResourceUniqueId,
            };
        });
    },
};
