import React from "react";

import { ApiProvider, AuthProvider, FiltersProvider } from "@CONTEXT/providers";

export function RootProvider(props: ChildrenProps) {
    const { children } = props;

    return (
        <AuthProvider>
            <ApiProvider>
                <FiltersProvider>{children}</FiltersProvider>
            </ApiProvider>
        </AuthProvider>
    );
}
