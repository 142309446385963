import React from "react";

import ReactDOMServer from "react-dom/server";

import {
    ColumnStore,
    EventHelper,
    GridColumnConfig,
    Model,
    ResourceInfoColumn,
    SchedulerPro,
} from "@bryntum/schedulerpro";

import {
    CollapsibleResourceGroupView,
    ResourceGroupView,
    ResourceInfoHeaderView,
    ResourceInfoView,
    SummaryOpeningsColumnView,
} from "@INTEGRATIONS/scheduler/components/common";
import {
    CalendarResourceModel,
    EXTERNAL_TRAINER_RESOURCE_ID,
    EXTERNAL_TRAINER_RESOURCE_ROLE,
    UNASSIGNED_RESOURCE_ID,
    UNASSIGNED_RESOURCE_ROLE,
} from "@INTEGRATIONS/scheduler/models";

const COLLAPSE_SIDEBAR_BUTTON_ID = "collapse-sidebar-button";

export const SidebarView = {
    FULL: "FULL",
    COLLAPSED: "COLLAPSED",
} as const;

export type SidebarView = (typeof SidebarView)[keyof typeof SidebarView];

const SidebarWidth = {
    FULL: 280,
    COLLAPSED: 80,
} satisfies Record<SidebarView, number>;

const columns = [
    {
        type: "resourceInfo",
        field: "role",
        id: "resource-column",
        text: SidebarView.FULL,
        width: SidebarWidth.FULL,
        autoWidth: false,
        htmlEncode: false,
        sortable: false,
        resizable: false,
        editor: null,
        draggable: false,
        enableCellContextMenu: false,
        enableHeaderContextMenu: false,
        cls: "custom-resource-column-header",
        cellCls: "custom-resource-column-cell",
        showEventCount: false,
        sum: "count",
        summaryRenderer() {
            const sidebar = document.getElementById("b-schedulerpro-1-lockedSubgrid");

            const view = sidebar!.style.width === `${SidebarWidth.FULL}px` ? SidebarView.FULL : SidebarView.COLLAPSED;

            return ReactDOMServer.renderToString(<SummaryOpeningsColumnView view={view} />);
        },
        groupRenderer(original: {
            groupRowFor: string;
            size: { height: number | null };
            column: ResourceInfoColumn;
            record: { meta?: { collapsed?: boolean } };
        }) {
            const view = original.column.text as SidebarView;

            if (original.groupRowFor === UNASSIGNED_RESOURCE_ROLE) {
                return ReactDOMServer.renderToString(
                    <CollapsibleResourceGroupView //
                        view={view}
                        title="Unassigned"
                        collapsed={original.record?.meta?.collapsed || false}
                    />,
                );
            }

            if (original.groupRowFor === EXTERNAL_TRAINER_RESOURCE_ROLE) {
                return ReactDOMServer.renderToString(
                    <CollapsibleResourceGroupView //
                        view={view}
                        title="External Trainers"
                        collapsed={original.record?.meta?.collapsed || false}
                    />,
                );
            }

            return ReactDOMServer.renderToString(
                <ResourceGroupView
                    title={original.groupRowFor}
                    view={view}
                />,
            );
        },
        headerRenderer(original: { column: ResourceInfoColumn }) {
            const view = original.column.text as SidebarView;

            return ReactDOMServer.renderToString(
                <ResourceInfoHeaderView
                    buttonId={COLLAPSE_SIDEBAR_BUTTON_ID}
                    view={view}
                />,
            );
        },
        renderer: (original: { record: Model; column: ResourceInfoColumn }) => {
            if (original.record instanceof CalendarResourceModel && !original.record.isGroupHeader) {
                const view = original.column.text as SidebarView;

                if (original.record.id === UNASSIGNED_RESOURCE_ID) {
                    return "";
                }

                if (original.record.id === EXTERNAL_TRAINER_RESOURCE_ID) {
                    return "";
                }

                return ReactDOMServer.renderToString(
                    <ResourceInfoView
                        resourceRecord={original.record}
                        view={view}
                    />,
                );
            }
            return null;
        },
    },
] satisfies Partial<GridColumnConfig>[];

const addListeners = (source: SchedulerPro) => {
    EventHelper.on({
        element: source.element, //
        delegate: `#${COLLAPSE_SIDEBAR_BUTTON_ID}`,
        capture: true,
        autoDetach: true,
        click(event: PointerEvent) {
            event.stopPropagation();

            const columnStore = source.columns as ColumnStore;

            if (columnStore) {
                const column = columnStore.getById("resource-column");

                // TODO find normal solution !
                const sidebarHeader = document.getElementById("b-schedulerpro-1-lockedSubgrid-header");
                const sidebar = document.getElementById("b-schedulerpro-1-lockedSubgrid");
                const openingsSummary = document.getElementById("b-schedulerpro-1-lockedSubgrid-footer");
                const summaryOpeningsView = openingsSummary?.querySelector("[data-summary-openings-view='1']");

                if (sidebarHeader && sidebar && openingsSummary && summaryOpeningsView) {
                    if (column.text === SidebarView.FULL) {
                        column.text = SidebarView.COLLAPSED;
                        column.width = SidebarWidth.COLLAPSED;

                        sidebarHeader.style.width = `${SidebarWidth.COLLAPSED}px`;
                        sidebar.style.width = `${SidebarWidth.COLLAPSED}px`;
                        openingsSummary.style.width = `${SidebarWidth.COLLAPSED}px`;
                        summaryOpeningsView.setAttribute("data-summary-openings-view-mode", SidebarView.COLLAPSED);
                    } else {
                        column.text = SidebarView.FULL;
                        column.width = SidebarWidth.FULL;

                        sidebarHeader.style.width = `${SidebarWidth.FULL}px`;
                        sidebar.style.width = `${SidebarWidth.FULL}px`;
                        openingsSummary.style.width = `${SidebarWidth.FULL}px`;
                        summaryOpeningsView.setAttribute("data-summary-openings-view-mode", SidebarView.FULL);
                    }

                    source.refreshColumn(column);
                }
            }
        },
    });
};

export const columnsConfig = {
    columns,
    addListeners,
};
