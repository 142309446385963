import React from "react";

import { IconProps } from "@CORE/interfaces";

function CircleIcon({
    size = defaultProps.size, //
    color = defaultProps.color,
}: IconProps) {
    return (
        <svg
            stroke={color}
            fill="none"
            strokeWidth="0"
            viewBox="0 0 15 15"
            height={size}
            width={size}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.875 7.5C9.875 8.81168 8.81168 9.875 7.5 9.875C6.18832 9.875 5.125 8.81168 5.125 7.5C5.125 6.18832 6.18832 5.125 7.5 5.125C8.81168 5.125 9.875 6.18832 9.875 7.5Z"
                fill={color}
            />
        </svg>
    );
}

const defaultProps = {
    size: "1em",
    color: "currentColor",
};

export default CircleIcon;
