import React, { createContext } from "react";

import { createApi, createServiceApi } from "@API/api.client";

const api = createApi();
const serviceApi = createServiceApi();

export const ApiContext = createContext({
    api,
    serviceApi,
});

export function ApiProvider(props: ChildrenProps) {
    const { children } = props;

    return (
        <ApiContext.Provider
            value={{
                api,
                serviceApi,
            }}
        >
            {children}
        </ApiContext.Provider>
    );
}
