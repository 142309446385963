import { LOGO_MARRIOT_BASE_64 } from "@CORE/constants/image.constants";
import * as XLSX from "xlsx";
import { CalendarEntryModel, CalendarEntryOpeningProject, CalendarResourceModel } from "../models";
import { CalendarEntryProjectModel } from "../types";
import { locationToString } from "@UTILS/helpers";

function doDateRangesOverlap(range1Start: Date, range1End: Date, range2Start: Date, range2End: Date) {
    return (range1Start <= range2End && range1End >= range2Start);
}

export function createCSVDataset({
    resources,
    entries,
    startDate,
    endDate,
}: {
    resources: CalendarResourceModel[],
    entries: CalendarEntryModel[],
    startDate: Date,
    endDate: Date
}): string[][] {

    const headerRow = [
        "User",
        "Event Type",
        "Label",
        "ProjectType",
        "Project Codes",
        "Location",
        "Start Date",
        "End Date",
        "Tentative",
        "Description",
        "URL",
    ];
    const rowData: string[][] = [];
    resources
        .forEach((item: CalendarResourceModel) => {
            if (item.groupChildren?.length) {
                rowData.push([item.id.toString().replace("group-header-", "")]);
            } else {
                const resourceEntries = entries
                    .filter((calendarEntry: CalendarEntryModel) => {
                        const isOnTheVisibleResources = (calendarEntry.resourceIds as number[])
                            .includes(item.id as number);

                        return isOnTheVisibleResources &&
                            doDateRangesOverlap(
                                startDate,
                                endDate,
                                calendarEntry.startDate,
                                calendarEntry.endDate,
                            );
                    });

                if (!resourceEntries.length) rowData.push([item.name]);
                else resourceEntries.forEach((calendarEntry: CalendarEntryModel) => {
                    rowData.push([
                        item.name.toString(),
                        calendarEntry.entryType,
                        calendarEntry.name,
                        calendarEntry.projectType,
                        calendarEntry.projects.map((project: CalendarEntryProjectModel) => {
                            const calendarEntryProject = project as CalendarEntryOpeningProject;
                            return calendarEntryProject?.marsha || calendarEntryProject.gdNumber || "";
                        }).join(" "),
                        locationToString(calendarEntry.location) || "",
                        new Intl.DateTimeFormat("en-GB").format(calendarEntry.startDate),
                        new Intl.DateTimeFormat("en-GB").format(calendarEntry.endDate),
                        calendarEntry.isTentative.toString(),
                        `"${calendarEntry.description.split("\r\n").join("\n")}"`,
                        "",
                    ]);
                });

            }
        });

    return [headerRow, ...rowData];
}

export function createExcelDataset({
    resources,
    entries,
    startDate,
    endDate,
}: {
    resources: CalendarResourceModel[],
    entries: CalendarEntryModel[],
    startDate: Date,
    endDate: Date
}): XLSX.WorkBook {

    const headerRow = [
        "User",
        "Event Type",
        "Label",
        "ProjectType",
        "Project Codes",
        "Location",
        "Start Date",
        "End Date",
        "Tentative",
        "Description",
        "URL",
    ];
    const rowData: string[][] = [];
    resources
        .forEach((item: CalendarResourceModel) => {
            if (item.groupChildren?.length) {
                rowData.push([item.id.toString().replace("group-header-", "")]);
            } else {
                const resourceEntries = entries
                    .filter((calendarEntry: CalendarEntryModel) => {
                        const isOnTheVisibleResources = (calendarEntry.resourceIds as number[])
                            .includes(item.id as number);

                        return isOnTheVisibleResources &&
                            doDateRangesOverlap(
                                startDate,
                                endDate,
                                calendarEntry.startDate,
                                calendarEntry.endDate,
                            );
                    });

                if (!resourceEntries.length) rowData.push([item.name]);
                else resourceEntries.forEach((calendarEntry: CalendarEntryModel) => {
                    rowData.push([
                        item.name.toString(),
                        calendarEntry.entryType,
                        calendarEntry.name,
                        calendarEntry.projectType,
                        calendarEntry.projects.map((project: CalendarEntryProjectModel) => {
                            const calendarEntryProject = project as CalendarEntryOpeningProject;
                            return calendarEntryProject?.marsha || calendarEntryProject.gdNumber || "";
                        }).join(" "),
                        locationToString(calendarEntry.location) || "",
                        new Intl.DateTimeFormat("en-GB").format(calendarEntry.startDate),
                        new Intl.DateTimeFormat("en-GB").format(calendarEntry.endDate),
                        calendarEntry.isTentative.toString(),
                        `${calendarEntry.description.split("\r\n").join("\n")}`,
                        "",
                    ]);
                });

            }
        });

    const worksheet = XLSX.utils.aoa_to_sheet([headerRow, ...rowData]);
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    return workbook;
}


export function getHeaderTemplate({ currentPage, currentPart, totalPages, totalParts }: { currentPage: number, totalPages: number, totalParts: number, currentPart: number }): string | null {
    const dateTimeFormat = new Intl.DateTimeFormat(
        "en-GB", {
        dateStyle: "short",
        timeStyle: "medium",
        hour12: false,
    });


    const pagesHtml = totalPages > 1 ? `<div style="font-size: 20px; font-weight: 700">${currentPage + 1} of ${totalPages} page</div>` : "";

    const partsHtml = totalParts > 1 ? `<div style="font-size: 20px; font-weight: 700">${currentPart} of ${totalParts} part</div>` : "";

    return (
        `<div style="padding: 15px 15px 0; transform: translateY(15px); display: flex; justify-content: space-between; width: 100%">
            <div style="display: flex;">
                <div  style="margin-right:24px;" >
                    <img style="max-width: 161px" src=${LOGO_MARRIOT_BASE_64} alt=""/>
                </div>
                <div style="display: flex; flex-direction: column;">
                <span style="font-size:24px; font-weight: 700; margin-bottom: 8px">Resource Scheduling Report</span>
                <span style="font-size: 16px; font-weight: 700; color: #4EB0C2">Created ${dateTimeFormat.format(new Date)}</span>
            </div>
        </div>
            <div>
            ${partsHtml}
            ${pagesHtml}
            </div>
        </div>
        <div style="width:100%">
            ${document.getElementById("header")?.outerHTML || ""}
            <div></
        </div>`
    );
}
