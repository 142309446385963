export const Period = {
    TODAY: "TODAY",
    WEEK: "WEEK",
    MONTH: "MONTH",
    CURRENT_WEEK: "CURRENT_WEEK",
    CURRENT_MONTH: "CURRENT_MONTH",
    NEXT_4_WEEKS: "NEXT_4_WEEKS",
} as const;

export type Period = (typeof Period)[keyof typeof Period];
