import axios, { AxiosInstance } from "axios";

import {
    CalendarEntriesApi,
    HolidaysApi,
    LocationsApi,
    NotificationsApi,
    ProjectsApi,
    ResourcesApi,
    BlockedPeriodsApi,
    TrainingEventsApi,
} from "@API/services";

const apiClient = axios.create({
    baseURL: "https://compass.apexdigital.online/secure/api/v2/90539/21010A68F2704355A624B6D0CE776A2D",
    headers: {
        "Content-type": "application/json",
    },
});

const basicApiClient = axios.create({
    baseURL: "https://mt-emea-oasis.apexdigital.online/scheduler-api/v1/",
    headers: {
        "Content-type": "application/json",
    },
});

const serviceApiClient = axios.create({
    baseURL: "https://mt-emea-oasis.apexdigital.online/api/v1/",
});

export function createApi(client: AxiosInstance = apiClient) {
    return {
        resources: new ResourcesApi(client),
        projects: new ProjectsApi(client),
        calendarEntries: new CalendarEntriesApi(client),
        trainingEvents: new TrainingEventsApi(client),
        blockedPeriods: new BlockedPeriodsApi(client),
        locations: new LocationsApi(client),
    };
}

export function createServiceApi() {
    return {
        notifications: new NotificationsApi(serviceApiClient),
        holidays: new HolidaysApi(basicApiClient),
    };
}
