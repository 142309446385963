import React from "react";

import classnames from "classnames/bind";

import { SidebarView } from "@INTEGRATIONS/scheduler/config/common";

import { SidebarArrowIcon } from "@VIEW/components/icons";

import styles from "./ResourceInfoHeaderView.module.scss";


const cx: CX = classnames.bind(styles);

function ResourceInfoHeaderView({
    buttonId, //
    view,
}: Props) {
    const collapsed = view === "COLLAPSED";

    return (
        <div
            className={cx("resource-info-header-view", {
                collapsed,
            })}
        >
            {!collapsed && <div className={cx("title")}>All Users</div>}
            <button
                id={buttonId}
                type="button"
            >
                <div className={cx("icon-wrapper")}>
                    <SidebarArrowIcon size={20} />
                </div>
            </button>
        </div>
    );
}

interface Props {
    buttonId: string;
    view: SidebarView;
}

export default ResourceInfoHeaderView;
