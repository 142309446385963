import { useContext, useMemo } from "react";

import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import invariant from "tiny-invariant";

import { ResourceApiModel } from "@API/services";

import { FiltersApplyAction, filtersApply } from "@CONTEXT/action-creators";

import { FiltersContext } from "@CONTEXT/providers";

import { useCalendarResourcesApi } from "@VIEW/hooks/api";

const DEFAULT_CALENDAR_RESOURCES: ResourceApiModel[] = [];

function createFiltersHook() {
    return function useFiltersContext() {
        const [filters, dispatch] = useContext(FiltersContext);

        const { queries: calendarResourcesQueries } = useCalendarResourcesApi();

        const { data: calendarResources = DEFAULT_CALENDAR_RESOURCES } = useQuery({
            queryKey: ["resources"],
            queryFn: async (ctx: QueryFunctionContext) => {
                return calendarResourcesQueries.findAll(ctx.signal);
            },
        });

        invariant(dispatch, "Dispatch must be specified");

        const actions = useMemo(() => {
            const onChange = (payload: FiltersApplyAction["payload"]) => {
                return dispatch(filtersApply(payload));
            };

            return {
                onChange,

                onRolesResourcesChange: (newResources: ResourceApiModel[]) => {
                    onChange({
                        resources: newResources,
                        teamLead: null,
                    });
                },

                onTeamLeadChange: (newTeamLead: ResourceApiModel | null) => {
                    const teamLeadResources =
                        calendarResources?.filter(
                            (resource: ResourceApiModel) => resource.teamLeadId === newTeamLead?.id,
                        ) || [];

                    onChange({
                        resources: [
                            ...teamLeadResources,
                            ...(newTeamLead && newTeamLead.teamLeadId !== newTeamLead.id ? [newTeamLead] : []),
                        ],
                        teamLead: newTeamLead,
                    });
                },
            };
        }, [calendarResources, dispatch]);

        return {
            filters,
            actions,
        };
    };
}

export const useFiltersContext = createFiltersHook();
