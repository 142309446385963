import React from "react";

import ReactDOMServer from "react-dom/server";

import { CellTooltip, CellTooltipConfig, ResourceInfoColumn } from "@bryntum/schedulerpro";

import { CellTooltipView } from "@INTEGRATIONS/scheduler/components/common";
import { SidebarView } from "@INTEGRATIONS/scheduler/config/common";
import { CalendarResourceModel } from "@INTEGRATIONS/scheduler/models";

export const cellTooltipFeature = {
    hoverDelay: 0,
    hideDelay: 0,
    trackMouse: true,
    anchor: false,
    header: false,
    tooltipRenderer(original: { column: ResourceInfoColumn; record: CalendarResourceModel }) {
        if (original.column.text === SidebarView.FULL) {
            return null;
        }

        return ReactDOMServer.renderToString(
            <CellTooltipView resourceRecord={original.record} />, //
        );
    },
} satisfies Partial<CellTooltipConfig> | CellTooltip;
