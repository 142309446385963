import React from "react";

import { IconProps } from "@CORE/interfaces";

function ZoomOutIcon({
    size = defaultProps.size, //
    color = defaultProps.color,
}: IconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="1 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g>
                <path
                    d="M9.5 11.5H15.5"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.5 19C16.6421 19 20 15.6421 20 11.5C20 7.35786 16.6421 4 12.5 4C8.35786 4 5 7.35786 5 11.5C5 15.6421 8.35786 19 12.5 19Z"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M17.8035 16.8035L23 22"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}

const defaultProps = {
    size: "1em",
    color: "currentColor",
};

export default ZoomOutIcon;
