/* eslint-disable no-param-reassign */
import { ScheduleContext, ScheduleContextConfig } from "@bryntum/schedulerpro";

import { COLOR } from "@CORE/constants";

export const scheduleContextFeature = {
    triggerEvent: "hover",
    renderer(_: any, element: HTMLDivElement) {
        element.style.backgroundColor = COLOR["white"];
    },
} satisfies Partial<ScheduleContextConfig> | ScheduleContext;
