/* eslint-disable no-param-reassign */
import React from "react";

import ReactDOMServer from "react-dom/server";

import { EventMenu, EventMenuConfig, MenuItemConfig, SchedulerPro } from "@bryntum/schedulerpro";

import { ContextMenuItemView } from "@INTEGRATIONS/scheduler/components/common";
import { CalendarAssignmentModel, CalendarEntryModel } from "@INTEGRATIONS/scheduler/models";


export const eventMenuFeature = {
    processItems(originalEvent: {
        eventRecord: CalendarEntryModel; //
        items: Record<string, Partial<MenuItemConfig>>;
    }) {
        if (originalEvent.items.unassignEvent) {
            if (originalEvent.eventRecord.resources.length > 1) {
                originalEvent.items.unassignEvent.icon = "";
                originalEvent.items.unassignEvent.hidden = false;
                originalEvent.items.unassignEvent.content = ReactDOMServer.renderToString(
                    <ContextMenuItemView
                        eventType="unassign"
                        title="Unassign entry"
                    />,
                );
                originalEvent.items.unassignEvent.onItem = async (original: {
                    assignmentRecord: CalendarAssignmentModel;
                    source: SchedulerPro;
                }) => {
                    await original.source.trigger("beforeAssignmentDelete", {
                        assignmentRecords: [original.assignmentRecord],
                    });

                    original.assignmentRecord.remove();
                };
            } else {
                originalEvent.items.unassignEvent.hidden = true;
            }
        }

        return !originalEvent.eventRecord.readOnly;
    },
    items: {
        editEvent: {
            icon: "",
            content: ReactDOMServer.renderToString(
                <ContextMenuItemView
                    eventType="edit"
                    title="Edit entry"
                />,
            ),
        },
        copyEvent: {
            icon: "",
            content: ReactDOMServer.renderToString(
                <ContextMenuItemView
                    eventType="copy"
                    title="Copy entry"
                />,
            ),
        },
        cutEvent: {
            icon: "",
            content: ReactDOMServer.renderToString(
                <ContextMenuItemView
                    eventType="cut"
                    title="Cut entry"
                />,
            ),
        },
        deleteEvent: {
            icon: "",
            content: ReactDOMServer.renderToString(
                <ContextMenuItemView
                    eventType="delete"
                    title="Delete entry"
                />,
            ),
        },
        splitEvent: null,
    },
} satisfies Partial<EventMenuConfig> | EventMenu;
