import React from "react";

import ReactDOMServer from "react-dom/server";

import { EventDragCreate, EventDragCreateConfig } from "@bryntum/schedulerpro";

import { EventTimeRangeTooltipView } from "@INTEGRATIONS/scheduler/components/common";
import { AppTimeRangeStore } from "@INTEGRATIONS/scheduler/config/stores";
import {
    CalendarEntryModel,
    CalendarResourceModel,
    EXTERNAL_TRAINER_RESOURCE_ID,
    UNASSIGNED_RESOURCE_ID,
} from "@INTEGRATIONS/scheduler/models";
import { formatDate, getPreviousDay } from "@INTEGRATIONS/scheduler/utils";

export const eventDragCreateFeature = {
    allowResizeToZero: false,
    tip: {
        header: false,
        anchor: false,
        hoverDelay: 0,
        hideDelay: 0,
        margin: -2,
    },
    showExactResizePosition: true,
    tooltipTemplate(original: {
        startDate: Date; //
        endDate: Date;
        valid: boolean;
        message: string;
    }) {
        const startDate = formatDate(original.startDate);
        const endDate = formatDate(getPreviousDay(original.endDate));

        return ReactDOMServer.renderToString(
            <EventTimeRangeTooltipView
                startDate={startDate}
                endDate={endDate}
                isInvalid={!original.valid}
                message={original.message}
            />,
        );
    },

    validatorFn(original: {
        startDate: Date; //
        endDate: Date;
        eventRecord: CalendarEntryModel;
        resourceRecord: CalendarResourceModel;
    }) {
        // #BLOCKED

        // allow unassigned and external trainer
        if (
            [
                UNASSIGNED_RESOURCE_ID, //
                EXTERNAL_TRAINER_RESOURCE_ID,
            ].includes(original.resourceRecord.id.toString())
        ) {
            return true;
        }

        // allow outside blocked periods
        const { timeRangeStore } = original.eventRecord.project;

        if (timeRangeStore instanceof AppTimeRangeStore) {
            const isIntersect = timeRangeStore.isIntersectBlockedPeriods(
                original.startDate, //
                original.endDate,
            );

            if (isIntersect) {
                return {
                    valid: false,
                    message: "Calendar entry overlaps blocked period",
                };
            }
        }
    },
} satisfies Partial<EventDragCreateConfig> | EventDragCreate;
