import React from "react";

import classnames from "classnames/bind";

import { COLOR } from "@CORE/constants";

import { BasePopup } from "@INTEGRATIONS/scheduler/components/popups";
import { useSchedulerInstance } from "@INTEGRATIONS/scheduler/hooks";
import { useSchedulerPopups } from "@INTEGRATIONS/scheduler/hooks/internal";
import { CalendarEntryModel } from "@INTEGRATIONS/scheduler/models";
import { OnBeforeEventDelete } from "@INTEGRATIONS/scheduler/types";

import { BasicButton, GhostButton } from "@VIEW/components/basic/buttons";

import { ThrashIcon } from "@VIEW/components/icons";

import styles from "./EventDeleteConfirmPopup.module.scss";


const cx: CX = classnames.bind(styles);

function EventDeleteConfirmPopup() {
    const {
        state: {
            eventDeleteConfirmPopupState: { data },
        },
        actions: { evenDeleteConfirmPopupActions },
    } = useSchedulerPopups();

    const { services } = useSchedulerInstance();

    const {
        eventRecords, //
    } = data!;

    async function handleDelete() {
        const instance = services.getInstance();

        if (instance) {
            const triggerData = {
                eventRecords,
                isConfirmed: true,
            } satisfies OnBeforeEventDelete;

            await instance.trigger("beforeEventDelete", triggerData);

            eventRecords.forEach((eventRecord: CalendarEntryModel) => {
                eventRecord.remove();
            });

            evenDeleteConfirmPopupActions.close();
        }
    }

    return (
        <BasePopup
            onClose={() => {
                evenDeleteConfirmPopupActions.close();
            }}
        >
            <div className={cx("event-delete-confirm-popup")}>
                <div className={cx("icon-wrapper")}>
                    <ThrashIcon
                        color={COLOR["blue-dark"]}
                        size={40}
                    />
                </div>
                <div className={cx("title")}>{"You are going\n to delete the Entry"}</div>
                <div className={cx("question")}>Click Yes to proceed, or No to cancel</div>
                <div className={cx("buttons-wrapper")}>
                    <div className={cx("button-item")}>
                        <BasicButton
                            label="Yes"
                            onClick={() => void handleDelete()}
                        />
                    </div>
                    <div className={cx("button-item")}>
                        <GhostButton
                            label="No"
                            onClick={() => {
                                evenDeleteConfirmPopupActions.close();
                            }}
                        />
                    </div>
                </div>
            </div>
        </BasePopup>
    );
}

export default EventDeleteConfirmPopup;
