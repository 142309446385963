import { useMemo } from "react";

import { Observer, Subject, forkJoin, map } from "rxjs";

import { calendarEntryMappers, calendarResourceMappers } from "@INTEGRATIONS/scheduler/mappers";
import {
    CalendarAssignmentModel,
    CalendarEntry,
    CalendarEntryModel,
    CalendarResource,
} from "@INTEGRATIONS/scheduler/models";
import {
    OnAfterEventSaveExtended,
    OnBeforeAssignmentDelete,
    OnBeforeEventDelete,
    OnDatesRangeTranslate,
    OnEventDropExtended,
    OnEventPasteExtended,
    OnEventResizeEnd,
    OnPresetChange,
} from "@INTEGRATIONS/scheduler/types";
import { getPreviousDay } from "@INTEGRATIONS/scheduler/utils";

type ObserverOrNext<T> = Partial<Observer<T>> | ((value: T) => void);

type EventSubscriberType = "event" | "training-event";

function IsTraining(entry: CalendarEntryModel) {
    return entry.isTrainingEvent;
}

function getEntryWithoutLastDay(entry: CalendarEntry) {
    return {
        ...entry,
        endDate: getPreviousDay(entry.endDate),
    };
}

function formatEntry(model: CalendarEntryModel) {
    return getEntryWithoutLastDay(
        calendarEntryMappers.modelToValue(model), //
    );
}

function createSchedulerListenersHook() {
    const eventDrop$ = new Subject<OnEventDropExtended>();
    const eventResizeEnd$ = new Subject<OnEventResizeEnd>();
    const pasteEvent$ = new Subject<OnEventPasteExtended>();
    const afterEventSave$ = new Subject<OnAfterEventSaveExtended>();
    const beforeDeleteEvent$ = new Subject<OnBeforeEventDelete>();
    const beforeAssignmentDelete$ = new Subject<OnBeforeAssignmentDelete>();
    const presetChange$ = new Subject<OnPresetChange>();
    const datesRangeTranslate$ = new Subject<OnDatesRangeTranslate>();

    // trainings
    const trainingEventDrop$ = new Subject<OnEventDropExtended>();
    const trainingEventResizeEnd$ = new Subject<OnEventResizeEnd>();
    const pasteTrainingEvent$ = new Subject<OnEventPasteExtended>();
    const afterTrainingEventSave$ = new Subject<OnAfterEventSaveExtended>();
    const beforeDeleteTrainingEvent$ = new Subject<OnBeforeEventDelete>();
    const beforeTrainingEventAssignmentDelete$ = new Subject<OnBeforeAssignmentDelete>();

    return function useSchedulerListeners() {
        return useMemo(
            () => ({
                onEventDrop: {
                    next: (original: OnEventDropExtended) => {
                        const isTraining = original.eventRecords.some(IsTraining);

                        (isTraining ? trainingEventDrop$ : eventDrop$).next(original);
                    },
                    subscribe: (
                        observerOrNext: ObserverOrNext<{
                            entries: CalendarEntry[];
                            shouldNotifyAssignees: boolean;
                        }>,
                        type: EventSubscriberType,
                    ) => {
                        const isTraining = type === "training-event";

                        return (isTraining ? trainingEventDrop$ : eventDrop$)
                            .pipe(
                                map((original: OnEventDropExtended) => {
                                    return {
                                        entries: original.eventRecords.map((entryModel: CalendarEntryModel) => {
                                            return formatEntry(entryModel);
                                        }),
                                        shouldNotifyAssignees: original.shouldNotifyAssignees,
                                    };
                                }),
                            )
                            .subscribe(observerOrNext);
                    },
                },
                onEventResizeEnd: {
                    next: (original: OnEventResizeEnd) => {
                        const isTraining = IsTraining(original.eventRecord);

                        (isTraining ? trainingEventResizeEnd$ : eventResizeEnd$).next(original);
                    },
                    subscribe: (
                        observerOrNext: ObserverOrNext<CalendarEntry>, //
                        type: EventSubscriberType,
                    ) => {
                        const isTraining = type === "training-event";

                        return (isTraining ? trainingEventResizeEnd$ : eventResizeEnd$)
                            .pipe(
                                map((original: OnEventResizeEnd) => {
                                    return formatEntry(original.eventRecord);
                                }),
                            )
                            .subscribe(observerOrNext);
                    },
                },
                onEventPaste: {
                    next: (original: OnEventPasteExtended) => {
                        const isTraining = original.pastedEventRecords.some(IsTraining);

                        forkJoin(
                            original.pastedEventRecords.map((entryModel: CalendarEntryModel) => {
                                return entryModel.project.commitAsync();
                            }),
                        ).subscribe(() => {
                            (isTraining ? pasteTrainingEvent$ : pasteEvent$).next(original);
                        });
                    },
                    subscribe: (
                        observerOrNext: ObserverOrNext<{
                            entries: CalendarEntry[];
                            shouldNotifyAssignees: boolean;
                            isCut: boolean;
                        }>,
                        type: EventSubscriberType,
                    ) => {
                        const isTraining = type === "training-event";

                        return (isTraining ? pasteTrainingEvent$ : pasteEvent$)
                            .pipe(
                                map((original: OnEventPasteExtended) => {
                                    return {
                                        entries: original.pastedEventRecords.map((entryModel: CalendarEntryModel) => {
                                            return formatEntry(entryModel);
                                        }),
                                        shouldNotifyAssignees: original.shouldNotifyAssignees,
                                        isCut: original.isCut,
                                    };
                                }),
                            )
                            .subscribe(observerOrNext);
                    },
                },
                onAfterEventSave: {
                    next: (original: OnAfterEventSaveExtended) => {
                        const isTraining = IsTraining(original.eventRecord);

                        (isTraining ? afterTrainingEventSave$ : afterEventSave$).next(original);
                    },
                    subscribe: (
                        observerOrNext: ObserverOrNext<{
                            entry: CalendarEntry;
                            shouldNotifyAssignees: boolean;
                        }>,
                        type: EventSubscriberType,
                    ) => {
                        const isTraining = type === "training-event";

                        return (isTraining ? afterTrainingEventSave$ : afterEventSave$)
                            .pipe(
                                map((original: OnAfterEventSaveExtended) => {
                                    return {
                                        entry: formatEntry(original.eventRecord),
                                        shouldNotifyAssignees: original.shouldNotifyAssignees,
                                    };
                                }),
                            )
                            .subscribe(observerOrNext);
                    },
                },
                onBeforeEventDelete: {
                    next: (original: OnBeforeEventDelete) => {
                        const isTraining = original.eventRecords.some(IsTraining);

                        (isTraining ? beforeDeleteTrainingEvent$ : beforeDeleteEvent$).next(original);
                    },
                    subscribe: (
                        observerOrNext: ObserverOrNext<CalendarEntry[]>, //
                        type: EventSubscriberType,
                    ) => {
                        const isTraining = type === "training-event";

                        return (isTraining ? beforeDeleteTrainingEvent$ : beforeDeleteEvent$)
                            .pipe(
                                map((original: OnBeforeEventDelete) => {
                                    return original.eventRecords.map((entryModel: CalendarEntryModel) => {
                                        return formatEntry(entryModel);
                                    });
                                }),
                            )
                            .subscribe(observerOrNext);
                    },
                },
                onBeforeAssignmentDelete: {
                    next: (original: OnBeforeAssignmentDelete) => {
                        const isTraining = original.assignmentRecords.some(
                            (assignmentModel: CalendarAssignmentModel) => {
                                return IsTraining(assignmentModel.event);
                            },
                        );

                        (isTraining ? beforeTrainingEventAssignmentDelete$ : beforeAssignmentDelete$).next(original);
                    },
                    subscribe: (
                        observerOrNext: ObserverOrNext<
                            {
                                entry: CalendarEntry;
                                resource: CalendarResource;
                            }[]
                        >,
                        type: EventSubscriberType,
                    ) => {
                        const isTraining = type === "training-event";

                        return (isTraining ? beforeTrainingEventAssignmentDelete$ : beforeAssignmentDelete$)
                            .pipe(
                                map((original: OnBeforeAssignmentDelete) => {
                                    return original.assignmentRecords.map(
                                        (assignmentModel: CalendarAssignmentModel) => ({
                                            entry: formatEntry(assignmentModel.event),
                                            resource: calendarResourceMappers.modelToValue(assignmentModel.resource),
                                        }),
                                    );
                                }),
                            )
                            .subscribe(observerOrNext);
                    },
                },
                onPresetChange: {
                    next: (original: OnPresetChange) => {
                        presetChange$.next(original);
                    },
                    subscribe: (
                        observerOrNext: ObserverOrNext<{
                            startDate: Date | null;
                            endDate: Date | null;
                            preset: string | number;
                        }>,
                    ) => {
                        return presetChange$
                            .pipe(
                                map((original: OnPresetChange) => {
                                    return {
                                        startDate: original.startDate || null,
                                        endDate: original.endDate ? getPreviousDay(original.endDate) : null,
                                        preset: original.preset.id,
                                    };
                                }),
                            )
                            .subscribe(observerOrNext);
                    },
                },

                onDatesRangeTranslate: {
                    next: (original: OnDatesRangeTranslate) => {
                        datesRangeTranslate$.next(original);
                    },
                    subscribe: (observerOrNext: ObserverOrNext<OnDatesRangeTranslate>) => {
                        return datesRangeTranslate$.subscribe(observerOrNext);
                    },
                },
            }),
            [],
        );
    };
}

export const useSchedulerListeners = createSchedulerListenersHook();
