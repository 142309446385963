import React from "react";

import ReactDOMServer from "react-dom/server";

import { EventTooltip, EventTooltipConfig } from "@bryntum/schedulerpro";

import { EventTooltipView } from "@INTEGRATIONS/scheduler/components/common";
import { CalendarEntryModel } from "@INTEGRATIONS/scheduler/models";


export const eventTooltipFeature = {
    header: false,
    anchor: false,
    hoverDelay: 250,
    hideDelay: 0,
    margin: -2,
    cls: "custom-event-tooltip",
    template: (original: { eventRecord: CalendarEntryModel }) => {
        return ReactDOMServer.renderToString(<EventTooltipView eventRecord={original.eventRecord} />);
    },
} satisfies Partial<EventTooltipConfig> | EventTooltip;
