import React from "react";

import ReactDOM from "react-dom";

import classnames from "classnames/bind";

import { BasicButton, GhostButton } from "@VIEW/components/basic/buttons";

import styles from "./EventConfirm.module.scss";

const cx: CX = classnames.bind(styles);

function EventConfirm({
    title, //
    onConfirm,
    onCancel,
}: Props) {
    const portal = document.getElementById("event-confirm-dialog");

    return (
        <>
            <div
                role="button"
                tabIndex={0}
                className={cx("curtain")}
                onClick={onCancel}
            />
            {ReactDOM.createPortal(
                <div className={cx("event-confirm")}>
                    <div className={cx("title")}>{title}</div>
                    <div className={cx("question")}>Click Yes to proceed, or No to cancel</div>
                    <div className={cx("buttons-wrapper")}>
                        <div className={cx("button-item")}>
                            <BasicButton
                                label="Yes"
                                onClick={onConfirm}
                            />
                        </div>
                        <div className={cx("button-item")}>
                            <GhostButton
                                label="No"
                                onClick={onCancel}
                            />
                        </div>
                    </div>
                </div>,
                portal!,
            )}
        </>
    );
}

interface Props {
    title: string;
    onConfirm: () => void;
    onCancel: () => void;
}

export default EventConfirm;
