import { AxiosResponse } from "axios";
import { catchError, from, lastValueFrom, map } from "rxjs";
import { z } from "zod";

import { BaseApi } from "@API/base";

import { throwApiError } from "@UTILS/helpers";

const userSchema = z.object({
    "E-mail": z.string().trim(),
    "Full Name": z.string().trim().nullable(),
    "Can see Resource Scheduler Entries of": z.string().trim().nullable(),
});

type UserSchemaInput = z.input<typeof userSchema>;

const resourceSchema = z.object({
    "@row.id": z.number(),
    "Role": z.string().trim(),
    "Full Name": z.string().trim().min(1),
    "E-mail": z.string().trim(),
    "Holiday Approval First Instance": z.string().trim(),
    "Province": z.string().nullable(),
    "Photo": z.string().nullable(),
    "Allow to add Holidays in Resource Scheduler": z.boolean(),
});

type ResourceSchemaInput = z.input<typeof resourceSchema>;
type ResourceSchemaOutput = z.output<typeof resourceSchema>;

export type UserApiModel =
    | {
          role: "USER";
          email: string;
          fullName: string | null;
          departments: string[];
      }
    | {
          role: "ADMIN";
      };

export type ResourceApiModel = {
    id: number;
    role: string;
    fullName: string;
    email: string;
    teamLeadId: number | null;
    province: string;
    logo: string;
    allowToAddHolidays: boolean;
};

export class ResourcesApi extends BaseApi {
    async findOne(email: string, signal?: AbortSignal): Promise<UserApiModel> {
        const urlParams = new URLSearchParams();

        urlParams.append("filter", `[Show in Resource Scheduler]=true and [E-mail]='${email}'`);

        urlParams.append("column", "Full Name");
        urlParams.append("column", "E-mail");
        urlParams.append("column", "Can see Resource Scheduler Entries of");

        return lastValueFrom<UserApiModel>(
            from(
                this.client.get(`/User/select.json?${urlParams.toString()}`, {
                    signal,
                }),
            )
                .pipe(
                    map((response: AxiosResponse<UserSchemaInput[]>) => {
                        const admin: UserApiModel = {
                            role: "ADMIN",
                        };

                        if (response.data.length === 0) return admin;

                        const parsed = userSchema.safeParse(response.data[0]);

                        if (parsed.success) {
                            const { data } = parsed;

                            const departments =
                                data["Can see Resource Scheduler Entries of"]?.split(",").map((val: string) => {
                                    return val.trim();
                                }) || [];

                            if (departments.length === 0) return admin;

                            const user: UserApiModel = {
                                role: "USER",
                                email: data["E-mail"],
                                fullName: data["Full Name"],
                                departments,
                            };

                            return user;
                        }

                        return admin;
                    }),
                )
                .pipe(
                    catchError((error: unknown) => {
                        throwApiError(error, "Can't get current user: ");
                    }),
                ),
        );
    }

    async findAll(signal?: AbortSignal): Promise<ResourceApiModel[]> {
        const urlParams = new URLSearchParams();

        urlParams.append("filter", "[Show in Resource Scheduler]=true");

        urlParams.append("column", "Role");
        urlParams.append("column", "Full Name");
        urlParams.append("column", "E-mail");
        urlParams.append("column", "Holiday Approval First Instance");
        urlParams.append("column", "Province");
        urlParams.append("column", "Photo");
        urlParams.append("column", "Allow to add Holidays in Resource Scheduler");

        return lastValueFrom<ResourceApiModel[]>(
            from(
                this.client.get(`/User/select.json?${urlParams.toString()}`, {
                    signal,
                }),
            )
                .pipe(
                    map((response: AxiosResponse<ResourceSchemaInput[]>) =>
                        response.data
                            .map((item: ResourceSchemaInput) => {
                                const parsed = resourceSchema.safeParse(item);

                                if (parsed.success) {
                                    return parsed.data;
                                }

                                return null;
                            })
                            .filter(Boolean)
                            .map((item: ResourceSchemaOutput): ResourceApiModel => {
                                const teamLeadEmail = item["Holiday Approval First Instance"].match(/<(.*)>/)?.[1];
                                const imagePath = item["Photo"] ? item["Photo"].split(";")?.[2] : null;
                                const logo = imagePath
                                    ? `https://compass.apexdigital.online/secure/api/v2/90539/User/Photo/attachment/${imagePath}`
                                    : "";

                                return {
                                    id: item["@row.id"],
                                    role: item.Role,
                                    fullName: item["Full Name"],
                                    email: item["E-mail"],
                                    teamLeadId:
                                        response.data.find(
                                            (resource: ResourceSchemaOutput) => resource["E-mail"] === teamLeadEmail,
                                        )?.["@row.id"] || null,
                                    province: item.Province || "",
                                    logo,
                                    allowToAddHolidays: item["Allow to add Holidays in Resource Scheduler"],
                                };
                            }),
                    ),
                )
                .pipe(
                    catchError((error: unknown) => {
                        throwApiError(error, "Can't get users: ");
                    }),
                ),
        );
    }
}
