import React from "react";

import { SchedulerInstance } from "@INTEGRATIONS/scheduler/components/common";
import { PopupsContainer } from "@INTEGRATIONS/scheduler/components/popups";
import { SchedulerPopupsProvider } from "@INTEGRATIONS/scheduler/context/internal";

function Scheduler() {
    return (
        <SchedulerPopupsProvider>
            <SchedulerInstance />

            <PopupsContainer />
        </SchedulerPopupsProvider>
    );
}

export default Scheduler;
