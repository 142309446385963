import React, { useEffect, useLayoutEffect, useMemo } from "react";

import classnames from "classnames/bind";

import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import invariant from "tiny-invariant";

import { ResourceApiModel } from "@API/services";

import { useAuthContext, useFiltersContext } from "@CONTEXT/hooks";

import { Period } from "@CORE/enums";

import { useSchedulerInstance, useSchedulerListeners } from "@INTEGRATIONS/scheduler/hooks";
import { CalendarEntryTypeExtended } from "@INTEGRATIONS/scheduler/types";

import { BasicButton, IconButton } from "@VIEW/components/basic/buttons";
import { DatePicker } from "@VIEW/components/basic/inputs/date";
import { SearchInput } from "@VIEW/components/basic/inputs/input";
import EventTypesSelect from "@VIEW/components/basic/inputs/select/EventTypesSelect";
import PeriodSelect from "@VIEW/components/basic/inputs/select/PeriodSelect";
import RolesPersonsSelect from "@VIEW/components/basic/inputs/select/RolesPersonsSelect";
import TeamLeadSelect from "@VIEW/components/basic/inputs/select/TeamLeadSelect";

import { Legend } from "@VIEW/components/common";

import { useCalendarResourcesApi } from "@VIEW/hooks/api";

import styles from "./Header.module.scss";

const cx: CX = classnames.bind(styles);

function Header({ onExcelExport, onPDFExport }: Props) {
    const { queries: calendarResourcesQueries } = useCalendarResourcesApi();

    const { data: calendarResources = [] } = useQuery({
        queryKey: ["resources"],
        queryFn: async (ctx: QueryFunctionContext) => {
            return calendarResourcesQueries.findAll(ctx.signal);
        },
    });

    const { filters, actions: filtersActions } = useFiltersContext();

    const listeners = useSchedulerListeners();

    const { actions: schedulerActions, services: schedulerServices } = useSchedulerInstance();

    const {
        state: { user },
    } = useAuthContext();

    invariant(user, "User must be specified");

    useEffect(() => {
        const period = Period.CURRENT_MONTH;

        schedulerActions.setPeriod(period);

        const dates = schedulerServices.getDatesRange();

        filtersActions.onChange({
            dates: {
                from: dates.startDate,
                to: dates.endDate,
            },
            period,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useLayoutEffect(() => {
        const presetChange$ = listeners.onPresetChange.subscribe(
            (newDates: {
                startDate: Date | null; //
                endDate: Date | null;
                preset: string | number;
            }) => {
                const { startDate, endDate } = newDates;

                filtersActions.onChange({
                    dates: {
                        from: startDate,
                        to: endDate,
                    },
                    period: null,
                });
            },
        );

        const datesRangeTranslate$ = listeners.onDatesRangeTranslate.subscribe(
            (newDates: {
                startDate: Date; //
                endDate: Date;
            }) => {
                const { startDate, endDate } = newDates;

                filtersActions.onChange({
                    dates: {
                        from: startDate,
                        to: endDate,
                    },
                });
            },
        );

        return () => {
            presetChange$.unsubscribe();
            datesRangeTranslate$.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        schedulerActions.filterEntriesByTypes(filters.eventTypes);
    }, [filters.eventTypes, schedulerActions]);

    const currentUserResources = useMemo(() => {
        if (calendarResources.length === 0) {
            return [];
        }

        if (user.role === "ADMIN") return calendarResources;

        return calendarResources.filter((resource: ResourceApiModel) => {
            return user.departments.includes(resource.role);
        });
    }, [calendarResources, user]);

    return (
        <div className={cx("header")}>
            <div className={cx("top")}>
                <div className={cx("title")} />

                <div className={cx("legend-wrapper")}>
                    <Legend />
                </div>

                <div className={cx("export")}>
                    <BasicButton
                        label="PDF"
                        onClick={onPDFExport}
                        icon="download-icon"
                    />
                    <BasicButton
                        icon="table-icon"
                        label="Excel"
                        onClick={onExcelExport}
                    />
                </div>
            </div>
            <div className={cx("filters")}>
                <div className={cx("left")}>
                    <div className={cx("dates")}>
                        <DatePicker
                            startDate={filters.dates.from}
                            endDate={filters.dates.to}
                            disabledKeyboardNavigation
                            fixedHeight
                            onChange={(dates: [Date | null, Date | null]) => {
                                if (dates[0] && dates[1]) {
                                    schedulerActions.setDatesRange(dates[0], dates[1]);

                                    filtersActions.onChange({
                                        period: null,
                                    });
                                }

                                filtersActions.onChange({
                                    dates: {
                                        from: dates[0],
                                        to: dates[1],
                                    },
                                });
                            }}
                        />
                    </div>
                    <div className={cx("period")}>
                        <PeriodSelect
                            selectedPeriod={filters.period}
                            onChange={(newPeriod: Period | null) => {
                                if (newPeriod) {
                                    schedulerActions.setPeriod(newPeriod);
                                }

                                const dates = schedulerServices.getDatesRange();

                                filtersActions.onChange({
                                    dates: {
                                        from: dates.startDate,
                                        to: dates.endDate,
                                    },
                                    period: newPeriod,
                                });
                            }}
                        />
                    </div>
                    <div className={cx("roles-people")}>
                        <RolesPersonsSelect
                            resources={currentUserResources}
                            selectedResources={filters.resources}
                            onChange={filtersActions.onRolesResourcesChange}
                        />
                    </div>
                    <div className={cx("team-lead")}>
                        <TeamLeadSelect
                            resources={currentUserResources}
                            selectedTeamLead={filters.teamLead}
                            onChange={filtersActions.onTeamLeadChange}
                        />
                    </div>
                </div>
                <div className={cx("right")}>
                    <div className={cx("zoom-buttons")}>
                        <IconButton
                            icon="zoom-in"
                            disabled={schedulerServices.checkZoomEnd("in")}
                            onClick={() => {
                                schedulerActions.zoomIn();
                            }}
                        />
                        <IconButton
                            icon="zoom-out"
                            disabled={schedulerServices.checkZoomEnd("out")}
                            onClick={() => {
                                schedulerActions.zoomOut();
                            }}
                        />
                    </div>
                    <div className={cx("event-types")}>
                        <EventTypesSelect
                            onChange={(newEventTypes: CalendarEntryTypeExtended[]) =>
                                filtersActions.onChange({
                                    eventTypes: newEventTypes,
                                })
                            }
                            selectedEventTypes={filters.eventTypes}
                        />
                    </div>
                    <div className={cx("search")}>
                        <SearchInput
                            placeholder="Search"
                            value={filters.search}
                            onChange={(value: string) => filtersActions.onChange({ search: value })}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

type Props = {
    onExcelExport: () => void;
    onPDFExport: () => void;
};

export default React.memo(Header);
