import { AxiosResponse } from "axios";
import { EMPTY, catchError, expand, from, lastValueFrom, map, reduce } from "rxjs";
import { RefinementCtx, z } from "zod";

import { BaseApi } from "@API/base";

import { removeTimezoneOffset } from "@INTEGRATIONS/scheduler/utils";

import { throwApiError } from "@UTILS/helpers";

const EVERYONE = "EVERYONE";

const schema = z
    .object({
        "@row.id": z.number(),
        "Start Date": z.coerce.date(),
        "End Date": z.coerce.date(),
        "Disallow Holidays for these Roles": z
            .string()
            .nullable()
            .transform((val: string | null) => {
                if (val === null || val.trim() === "") return null;

                if (val.trim().toUpperCase() === "EVERYONE") return EVERYONE;

                return val.split(",").map((name: string) => name.trim());
            }),
        "Disallow Holidays for these Users": z
            .string()
            .nullable()
            .transform((val: string | null) => {
                if (val === null || val.trim() === "") return null;

                return val.split(",").map((name: string) => name.trim());
            }),
        "Description": z
            .string()
            .nullable()
            .transform((val: string | null) => {
                if (val === null) return "";

                return val.trim();
            }),
    })
    .superRefine(
        (
            data: {
                "Disallow Holidays for these Roles": typeof EVERYONE | string[] | null;
                "Disallow Holidays for these Users": string[] | null;
            },
            ctx: RefinementCtx,
        ) => {
            if (
                data["Disallow Holidays for these Roles"] === null &&
                data["Disallow Holidays for these Users"] === null
            ) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: "Roles or Users must be presented",
                    path: [
                        "Disallow Holidays for these Roles", //
                        "Disallow Holidays for these Users",
                    ],
                });

                return z.NEVER;
            }

            return data;
        },
    );

type SchemaInput = z.input<typeof schema>;
type SchemaOutput = z.output<typeof schema>;

export type BlockedPeriodApiModel = {
    id: number;
    startDate: Date;
    endDate: Date;
    disallowRoles: typeof EVERYONE | string[] | null;
    disallowUsers: string[] | null;
    description: string;
};

interface FindParams {
    page: number;
    dates: {
        from: string;
        to: string;
    };
}

interface FindAllParams {
    dates: {
        from: string;
        to: string;
    };
}

const MAX_TOTAL_RECORDS = 500;

export class BlockedPeriodsApi extends BaseApi {
    async findAll(params: FindAllParams, signal?: AbortSignal): Promise<BlockedPeriodApiModel[]> {
        let page = 1;

        return lastValueFrom(
            from(this.findPage({ page: page++, dates: params.dates }, signal)).pipe(
                expand((response: { data: BlockedPeriodApiModel[]; totalCount: number }) => {
                    return response.totalCount === MAX_TOTAL_RECORDS
                        ? this.findPage({ page: page++, dates: params.dates })
                        : EMPTY;
                }),
                reduce(
                    (acc: BlockedPeriodApiModel[], current: { data: BlockedPeriodApiModel[]; totalCount: number }) =>
                        acc.concat(current.data),
                    [],
                ),
            ),
        );
    }

    private async findPage(
        params: FindParams,
        signal?: AbortSignal,
    ): Promise<{ data: BlockedPeriodApiModel[]; totalCount: number }> {
        const { page = 1, dates } = params;

        const skip = MAX_TOTAL_RECORDS * (page - 1);
        const urlParams = new URLSearchParams();

        urlParams.append("skip", skip.toString());

        urlParams.append(
            "filter",
            [
                `([Start Date]>=#${dates.from}# and [Start Date]<=#${dates.to}#)`, //
                `([End Date]>=#${dates.from}# and [End Date]<=#${dates.to}#)`,
                `([Start Date]<=#${dates.from}# and [End Date]>=#${dates.to}#)`,
            ].join(" or "),
        );

        return lastValueFrom(
            from(
                this.client.get(`/Blocked Period/API/select.json?${urlParams.toString()}`, {
                    signal,
                }),
            )
                .pipe(
                    map((response: AxiosResponse<SchemaInput[]>) => {
                        return {
                            data: response.data
                                .map((item: SchemaInput) => {
                                    const parsed = schema.safeParse(item);

                                    if (parsed.success) {
                                        return parsed.data;
                                    }

                                    return null;
                                })
                                .filter(Boolean)
                                .map((item: SchemaOutput) => {
                                    const { startDate, endDate } = removeTimezoneOffset(
                                        item["Start Date"],
                                        item["End Date"],
                                        1,
                                    );

                                    return {
                                        id: item["@row.id"],
                                        startDate,
                                        endDate,
                                        disallowRoles: item["Disallow Holidays for these Roles"],
                                        disallowUsers: item["Disallow Holidays for these Users"],
                                        description: item.Description,
                                    };
                                }),
                            totalCount: response.data.length,
                        };
                    }),
                )
                .pipe(
                    catchError((error: unknown) => {
                        throwApiError(error, "Can't get resources blocked periods: ");
                    }),
                ),
        );
    }
}
