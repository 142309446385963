import { DateHelper, Model, RecurringTimeSpan, RecurringTimeSpansMixin, Store, TimeSpan } from "@bryntum/schedulerpro";

import { GlobalBlockedPeriodTimeSpanModel } from "@INTEGRATIONS/scheduler/models";

const weekends = {
    startDate: "2000-01-01T00:00",
    endDate: "2000-01-03T00:00",
    recurrenceRule: "FREQ=WEEKLY",
};

class RecurringTimeRange extends RecurringTimeSpan(TimeSpan) {}

export class AppTimeRangeStore extends RecurringTimeSpansMixin(Store) {
    data: Model[] | object[] = [];

    removeAll(silent?: boolean): boolean {
        const result = super.removeAll(silent);

        this.add(weekends);

        return result;
    }

    getBlockedPeriods(): GlobalBlockedPeriodTimeSpanModel[] {
        return this.records
            .map((model: unknown) => {
                if (model instanceof GlobalBlockedPeriodTimeSpanModel) {
                    return model;
                }

                return null;
            })
            .filter(Boolean);
    }

    isIntersectBlockedPeriods(startDate: Date, endDate: Date): boolean {
        const blockedPeriods = this.getBlockedPeriods();

        return blockedPeriods.some((model: GlobalBlockedPeriodTimeSpanModel) => {
            return DateHelper.intersectSpans(
                startDate, //
                endDate,
                model.startDate,
                model.endDate,
            );
        });
    }

    static get defaultConfig(): any {
        return {
            modelClass: RecurringTimeRange,
            storeId: "timeRanges",
        };
    }
}

export const appTimeRangeStore = new AppTimeRangeStore({
    data: [weekends],
});
