import React from "react";

import classNames from "classnames/bind";

import { Tooltip as ReactTooltip } from "react-tooltip";

import styles from "./Tooltip.module.scss";

const cx: CX = classNames.bind(styles);

function Tooltip({ id }: Props) {
    return (
        <ReactTooltip
            id={id}
            noArrow
            className={cx("tooltip")}
            place="top-end"
        />
    );
}

type Props = {
    id: string;
};

export default Tooltip;
