import React, { FormEventHandler, ForwardedRef, RefObject, useImperativeHandle, useRef, useState } from "react";

import classnames from "classnames/bind";

import { EventFormButtons, EventFormPopups } from "@INTEGRATIONS/scheduler/components/forms/elements";

import { HiddenFormButton } from "@VIEW/components/basic/buttons";

import styles from "./EventFormWrapper.module.scss";

const cx: CX = classnames.bind(styles);

type FormRef = {
    confirmCancel: (canCancel: () => boolean) => void;
};

const EventFormWrapper = React.forwardRef(
    (
        {
            isCreating, //
            onSubmit,
            onDelete,
            onCancel,
            children,
        }: Props,
        ref: ForwardedRef<FormRef>,
    ) => {
        const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
        const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);

        const formButtonRef: RefObject<HTMLButtonElement> = useRef(null);

        useImperativeHandle(
            ref,
            () => {
                function confirmCancel(canCancel: () => boolean) {
                    const showConfirmation = canCancel();

                    if (showConfirmation) {
                        return setShowCloseConfirmation(true);
                    }

                    onCancel();
                }

                return {
                    confirmCancel,
                };
            },
            [onCancel],
        );

        return (
            <>
                <div className={cx("event-form-wrapper")}>
                    <form onSubmit={onSubmit}>
                        {children}
                        <HiddenFormButton ref={formButtonRef} />
                    </form>
                </div>
                <EventFormPopups
                    showDeleteConfirmation={showDeleteConfirmation}
                    onDeleteConfirm={onDelete}
                    onDeleteCancel={() => setShowDeleteConfirmation(false)}
                    showCloseConfirmation={showCloseConfirmation}
                    onCloseConfirm={onCancel}
                    onCloseCancel={() => setShowCloseConfirmation(false)}
                />
                <EventFormButtons
                    isCreating={isCreating}
                    disabled={showDeleteConfirmation || showCloseConfirmation}
                    onSave={() => formButtonRef.current?.click()}
                    onDelete={() => setShowDeleteConfirmation(true)}
                    onCancel={onCancel}
                />
            </>
        );
    },
);

EventFormWrapper.displayName = "EventFormWrapper";

interface Props extends ChildrenProps {
    isCreating: boolean;
    onSubmit: FormEventHandler<HTMLFormElement>;
    onCancel: () => void;
    onDelete: () => void;
}

export default EventFormWrapper;
