import React, { useMemo } from "react";

import { ResourceApiModel } from "@API/services";

import Select from "@VIEW/components/basic/inputs/select/BasicSelect";
import { ChangeAction, Group, Option } from "@VIEW/components/basic/inputs/select/BasicSelect/Select.com";

import { Person } from "@VIEW/components/common";

function RolesPersonsSelect({ resources, selectedResources, onChange }: Props) {
    const roles = useMemo(
        () => [...new Set<string>(resources?.map((item: ResourceApiModel) => item.role)).values()],
        [resources],
    );

    const resourcesByRole = useMemo(() => {
        return roles.reduce((acc: Record<string, ResourceApiModel[]>, item: string) => {
            const newAcc = { ...acc };
            newAcc[item] = resources.filter((resource: ResourceApiModel) => resource.role === item);
            return newAcc;
        }, {});
    }, [roles, resources]);

    const roleOptions = useMemo(
        () =>
            roles.map((item: string) => ({
                value: item,
                label: item,
            })),
        [roles],
    );

    const resourcesOptions = useMemo(
        () =>
            resources?.map(
                (item: ResourceApiModel) =>
                    ({
                        label: item.fullName,
                        value: item.id.toString(),
                        logoSrc: item.logo,
                        role: item.role,
                    } || []),
            ),
        [resources],
    );

    const rolesResourcesOptions: Group<Option>[] = useMemo(() => {
        return [
            {
                label: "Roles",
                key: "role",
                options: roleOptions,
            },
            {
                label: "Persons",
                key: "persons",
                options: resourcesOptions,
            },
        ];
    }, [roleOptions, resourcesOptions]);

    const selectedResourcesOptions = useMemo(
        () =>
            resourcesOptions.filter((item: PersonOption) =>
                selectedResources.find((selectedResource: ResourceApiModel) => selectedResource.id.toString() === item.value),
            ),
        [selectedResources, resourcesOptions],
    );

    const selectedRolesOptions = useMemo(
        () =>
            roleOptions
                .filter((item: Option) =>
                    selectedResources.find((selectedResource: ResourceApiModel) =>
                        resourcesByRole[item.value]?.find(
                            (roleResource: ResourceApiModel) => roleResource.id === selectedResource.id,
                        ),
                    ),
                )
                .map((item: Option) => ({
                    ...item,
                    halfSelected:
                        !resourcesByRole[item.value]?.every((resource: ResourceApiModel) =>
                            selectedResources.find((selectedResource: ResourceApiModel) => selectedResource.id === resource.id),
                        ) || false,
                })),
        [resourcesByRole, selectedResources, roleOptions],
    );

    const handleChange = (
        value: PersonOption | PersonOption[] | null,
        { action, option }: ChangeAction<PersonOption>,
    ) => {
        if (option?.value === "all") {
            if (selectedResources.length === resources.length) return onChange([]);
            return onChange(resources);
        }

        const changedRole = roleOptions.find((item: Option) => item.value === option?.value);

        if (changedRole) {
            const roleResources = resourcesByRole[changedRole.value];
            if (action === "select-option") {
                const newRoleResources =
                    roleResources?.filter(
                        (item: ResourceApiModel) =>
                            !selectedResources.find((selectedResource: ResourceApiModel) => item.id === selectedResource.id),
                    ) || [];

                return onChange([...selectedResources, ...newRoleResources]);
            } else {
                const newResources = selectedResources.filter(
                    (item: ResourceApiModel) => !roleResources?.find((roleResource: ResourceApiModel) => item.id === roleResource.id),
                );

                return onChange(newResources);
            }
        }

        return onChange(
            resources.filter((item: ResourceApiModel) =>
                (value as PersonOption[]).find(
                    (selectedOption: PersonOption) => item.id.toString() === selectedOption.value,
                ),
            ),
        );
    };

    const getLabel = () => {
        let label = `${selectedResources.length} Persons`;

        const fullSelectedRoles = selectedRolesOptions.filter((item: Option) => !item.halfSelected);

        if (fullSelectedRoles.length) {
            label += `, ${fullSelectedRoles.length} Roles`;
        }

        return label;
    };

    return (
        <Select<PersonOption & RoleOption>
            options={rolesResourcesOptions}
            placeholder="Role & Persons"
            isMultiple
            value={[...selectedResourcesOptions, ...selectedRolesOptions]}
            onChange={handleChange}
            Option={CustomOption}
            getLabel={getLabel}
        />
    );
}

function CustomOption({ value: optionValue, groupKey }: OptionProps) {
    if (optionValue.value == "all") return <span>{optionValue.label}</span>;

    return groupKey === "persons" ? (
        <Person
            name={optionValue.label}
            logo={optionValue.logoSrc || ""}
            role={optionValue.role || ""}
        />
    ) : (
        <div>{optionValue.label}</div>
    );
}

type Props = {
    resources: ResourceApiModel[];
    selectedResources: ResourceApiModel[];
    onChange: (newResources: ResourceApiModel[]) => void;
};

type RoleOption = Option & { halfSelected?: boolean };

type PersonOption = Option & { logoSrc?: string; role?: string };

type OptionProps = {
    value: PersonOption;
    groupKey?: string;
};

export default RolesPersonsSelect;
