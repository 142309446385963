import React from "react";

import { IconProps } from "@CORE/interfaces";

function LinkIcon2({
    size = defaultProps.size, //
    color = defaultProps.color,
}: IconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 12 12"
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M5.99997 6.5V10.5C6.00005 10.5989 5.97077 10.6957 5.91583 10.778C5.8609 10.8603 5.78278 10.9244 5.69137 10.9623C5.59996 11.0002 5.49936 11.0101 5.40232 10.9908C5.30528 10.9714 5.21615 10.9238 5.14622 10.8538L3.49997 9.20687L0.853723 11.8538C0.807268 11.9002 0.752118 11.9371 0.691421 11.9622C0.630724 11.9873 0.56567 12.0003 0.499973 12.0003C0.434275 12.0003 0.369221 11.9873 0.308525 11.9622C0.247828 11.9371 0.192678 11.9002 0.146223 11.8538C0.0997677 11.8073 0.0629176 11.7521 0.0377762 11.6914C0.0126349 11.6308 -0.000305176 11.5657 -0.000305176 11.5C-0.000305176 11.4343 0.0126349 11.3692 0.0377762 11.3086C0.0629176 11.2479 0.0997677 11.1927 0.146223 11.1462L2.7931 8.5L1.14622 6.85375C1.07622 6.78382 1.02853 6.6947 1.00921 6.59765C0.989883 6.50061 0.999785 6.40002 1.03766 6.30861C1.07554 6.21719 1.13968 6.13908 1.22198 6.08414C1.30428 6.0292 1.40102 5.99992 1.49997 6H5.49997C5.63258 6 5.75976 6.05268 5.85353 6.14645C5.94729 6.24021 5.99997 6.36739 5.99997 6.5ZM11 0H2.99997C2.73476 0 2.4804 0.105357 2.29287 0.292893C2.10533 0.48043 1.99997 0.734784 1.99997 1V4C1.99997 4.13261 2.05265 4.25979 2.14642 4.35355C2.24019 4.44732 2.36736 4.5 2.49997 4.5C2.63258 4.5 2.75976 4.44732 2.85353 4.35355C2.94729 4.25979 2.99997 4.13261 2.99997 4V1H11V9H7.99997C7.86736 9 7.74019 9.05268 7.64642 9.14645C7.55265 9.24021 7.49997 9.36739 7.49997 9.5C7.49997 9.63261 7.55265 9.75979 7.64642 9.85355C7.74019 9.94732 7.86736 10 7.99997 10H11C11.2652 10 11.5195 9.89464 11.7071 9.70711C11.8946 9.51957 12 9.26522 12 9V1C12 0.734784 11.8946 0.48043 11.7071 0.292893C11.5195 0.105357 11.2652 0 11 0Z" />
        </svg>
    );
}

const defaultProps = {
    size: "1em",
    color: "currentColor",
};

export default LinkIcon2;
