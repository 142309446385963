import React from "react";

import classnames from "classnames/bind";

import { COLOR } from "@CORE/constants";

import { CalendarEntryModel } from "@INTEGRATIONS/scheduler/models";

import { CloseIcon, LinkIcon } from "@VIEW/components/icons";

import styles from "./SummaryOpeningsMenuView.module.scss";


const cx: CX = classnames.bind(styles);

function SummaryOpeningsMenuView({
    headerTitle, //
    eventRecords,
}: Props) {
    return (
        <div className={cx("summary-openings-menu-view")}>
            <div className={cx("header")}>
                <div className={cx("header-title")}>{headerTitle}</div>
                <button
                    data-openings-dropdown-close="1"
                    type="button"
                    className={cx("close")}
                >
                    <CloseIcon
                        size={15}
                        color={COLOR["white"]}
                    />
                </button>
            </div>
            <div className={cx("openings-list")}>
                {eventRecords.map((eventRecord: CalendarEntryModel) => {
                    const key = eventRecord.id;

                    const code = eventRecord.projectMarsha || eventRecord.gdProjectId;

                    return (
                        <a
                            key={key}
                            className={cx("opening-item")}
                            href={eventRecord.url || "#"}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {code ? (
                                <div className={cx("code")}>{code}</div>
                            ) : (
                                <div className={cx("unknown")}>Missing MARSHA</div>
                            )}
                            <div className={cx("icon-wrapper")}>
                                <LinkIcon
                                    color={code ? COLOR["white"] : COLOR["orange"]}
                                    size={16}
                                />
                            </div>
                        </a>
                    );
                })}
            </div>
        </div>
    );
}

interface Props {
    headerTitle: string;
    eventRecords: CalendarEntryModel[];
}

export default SummaryOpeningsMenuView;
