import React from "react";

import classNames from "classnames/bind";

import { createAbbreviation } from "@UTILS/helpers";

import { Tooltip } from "@VIEW/components/basic/tooltips";

import { UserLogo } from "@VIEW/components/common";

import { CloseIcon } from "@VIEW/components/icons";

import styles from "./SelectedResource.module.scss";


const cx: CX = classNames.bind(styles);

function SelectedResource<T extends AbstractResource>({
    resource, //
    onRemove,
}: Props<T>) {
    const abbreviation = createAbbreviation(resource.name);

    return (
        <div
            data-tooltip-id={`user-id-${resource.id}`}
            data-tooltip-content={resource.name}
            className={cx("resource-wrapper")}
        >
            <button
                type="button"
                className={cx("remove-button")}
                onClick={() => onRemove()}
            >
                <CloseIcon
                    color="white"
                    size="9px"
                />
            </button>
            <UserLogo
                title={abbreviation}
                src={resource.logo}
            />
            <Tooltip id={`user-id-${resource.id}`} />
        </div>
    );
}

type Props<T extends AbstractResource> = {
    resource: T;
    onRemove: () => void;
};

type AbstractResource = {
    id: string | number;
    name: string;
    logo: string;
};

export default SelectedResource;
