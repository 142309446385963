import React from "react";

import classnames from "classnames/bind";

import { COLOR } from "@CORE/constants";

import { AddIcon, CopyIcon, CutIcon, DeleteIcon, EditIcon, PasteIcon, UnassignIcon } from "@VIEW/components/icons";

import styles from "./ContextMenuItemView.module.scss";

const cx: CX = classnames.bind(styles);

type MenuItemType = "add" | "edit" | "copy" | "cut" | "paste" | "delete" | "unassign";

const iconsMap = {
    add: (props: { color: string }) => (
        <AddIcon
            color={props.color}
            size={21}
        />
    ),
    edit: (props: { color: string }) => (
        <EditIcon
            color={props.color}
            size={21}
        />
    ),
    copy: (props: { color: string }) => (
        <CopyIcon
            color={props.color}
            size={21}
        />
    ),
    cut: (props: { color: string }) => (
        <CutIcon
            color={props.color}
            size={21}
        />
    ),
    paste: (props: { color: string }) => (
        <PasteIcon
            color={props.color}
            size={21}
        />
    ),
    delete: (props: { color: string }) => (
        <DeleteIcon
            color={props.color}
            size={21}
        />
    ),
    unassign: (props: { color: string }) => (
        <UnassignIcon
            color={props.color}
            size={22}
        />
    ),
} satisfies Record<MenuItemType, React.ElementType>;

function ContextMenuItemView({
    eventType, //
    title,
}: Props) {
    const Icon = iconsMap[eventType];

    return (
        <div className={cx("context-menu-item-view")}>
            <div className={cx("icon-wrapper")}>
                <Icon color={COLOR["white"]} />
            </div>
            <div className={cx("title")}>{title}</div>
        </div>
    );
}

interface Props {
    eventType: MenuItemType;
    title: string;
}

export default ContextMenuItemView;
