import { catchError, from, lastValueFrom, map } from "rxjs";

import { BaseApi } from "@API/base";

import { throwApiError } from "@UTILS/helpers";

type NotifyAssigneesApiParams = {
    recipients: string[];
    subject: string;
    sender: string;
    content: string;
    files: File[];
};

type Response = {
    message: string;
};

export class NotificationsApi extends BaseApi {
    notifyByEmail(
        {
            recipients, //
            subject,
            sender,
            content,
            files,
        }: NotifyAssigneesApiParams,
        signal?: AbortSignal,
    ): Promise<Response> {
        const formData = new FormData();

        formData.append("recipients", recipients.join(";"));
        formData.append("subject", subject);
        formData.append("sender", sender);
        formData.append("content", content);

        files.forEach((file: File) => {
            formData.append("files", file, file.name);
        });

        return lastValueFrom<Response>(
            from(
                this.client.post("/send_files", formData, {
                    signal,
                }),
            )
                .pipe(
                    map(() => ({
                        message: "Success",
                    })),
                )
                .pipe(
                    catchError((error: unknown) => {
                        throwApiError(error, "Can't send email notification: ");
                    }),
                ),
        );
    }
}
