import React from "react";

import { IconProps } from "@CORE/interfaces";

function LevelUpIcon({
    size = defaultProps.size, //
    color = defaultProps.color,
}: IconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.4618 7.69128C14.424 7.78265 14.3599 7.86075 14.2777 7.91571C14.1955 7.97066 14.0988 8.00001 13.9999 8.00003H11.4999V9.50003C11.4999 9.63264 11.4472 9.75981 11.3535 9.85358C11.2597 9.94735 11.1325 10 10.9999 10H4.99991C4.8673 10 4.74012 9.94735 4.64635 9.85358C4.55258 9.75981 4.49991 9.63264 4.49991 9.50003V8.00003H1.99991C1.90096 8.0001 1.80421 7.97082 1.72191 7.91589C1.63962 7.86095 1.57547 7.78283 1.53759 7.69142C1.49972 7.60001 1.48982 7.49942 1.50914 7.40237C1.52847 7.30533 1.57615 7.2162 1.64616 7.14628L7.64616 1.14628C7.69259 1.09979 7.74774 1.06291 7.80844 1.03775C7.86913 1.01259 7.9342 0.999634 7.99991 0.999634C8.06561 0.999634 8.13068 1.01259 8.19138 1.03775C8.25207 1.06291 8.30722 1.09979 8.35366 1.14628L14.3537 7.14628C14.4236 7.21624 14.4711 7.30537 14.4904 7.40238C14.5096 7.49939 14.4997 7.59993 14.4618 7.69128ZM10.9999 13H4.99991C4.8673 13 4.74012 13.0527 4.64635 13.1465C4.55258 13.2402 4.49991 13.3674 4.49991 13.5C4.49991 13.6326 4.55258 13.7598 4.64635 13.8536C4.74012 13.9473 4.8673 14 4.99991 14H10.9999C11.1325 14 11.2597 13.9473 11.3535 13.8536C11.4472 13.7598 11.4999 13.6326 11.4999 13.5C11.4999 13.3674 11.4472 13.2402 11.3535 13.1465C11.2597 13.0527 11.1325 13 10.9999 13ZM10.9999 11H4.99991C4.8673 11 4.74012 11.0527 4.64635 11.1465C4.55258 11.2402 4.49991 11.3674 4.49991 11.5C4.49991 11.6326 4.55258 11.7598 4.64635 11.8536C4.74012 11.9473 4.8673 12 4.99991 12H10.9999C11.1325 12 11.2597 11.9473 11.3535 11.8536C11.4472 11.7598 11.4999 11.6326 11.4999 11.5C11.4999 11.3674 11.4472 11.2402 11.3535 11.1465C11.2597 11.0527 11.1325 11 10.9999 11Z"
                fill={color}
            />
        </svg>
    );
}

const defaultProps = {
    size: "1em",
    color: "currentColor",
};

export default LevelUpIcon;
