import { useMemo } from "react";

import { CancelledException } from "@CORE/exceptions";

import { TrainingEventApiModel } from "@API/services";

import { useApiContext } from "@CONTEXT/hooks";

import { CalendarEntry, CalendarEntryOpeningProject } from "@INTEGRATIONS/scheduler/models";

import { useNotifications } from "@VIEW/hooks";

interface FindAllParams {
    dates: {
        from: string;
        to: string;
    };
}

function createHook() {
    return function useTrainingEventsApi() {
        const { api } = useApiContext();

        const { notify } = useNotifications();

        const queries = useMemo(
            () => ({
                findAll: async (params: FindAllParams, signal?: AbortSignal) => {
                    try {
                        return await api.trainingEvents.findAll(params, signal);
                    } catch (error: unknown) {
                        if (error instanceof Error && !(error instanceof CancelledException)) {
                            notify.error(error.message);
                        }

                        return [];
                    }
                },
            }),
            [api, notify],
        );

        const mutations = useMemo(
            () => ({
                upsert: async ({
                    items,
                    openingProjects,
                    shouldRemovePrevItems,
                }: {
                    items: TrainingEventApiModel[];
                    openingProjects: CalendarEntryOpeningProject[];
                    shouldRemovePrevItems: boolean;
                }) => {
                    const transformedItems = items.map((item: TrainingEventApiModel) => {
                        const projectIds = item.projectIds
                            .map((id: string) => {
                                return openingProjects.find((project: CalendarEntryOpeningProject) => {
                                    return project.id.toString() === id;
                                })?.projectId;
                            })
                            .filter(Boolean);

                        return {
                            ...item,
                            projectIds,
                        };
                    });

                    try {
                        await api.trainingEvents.upsert({
                            events: transformedItems,
                            shouldRemovePrevItems,
                        });
                    } catch (error: unknown) {
                        if (error instanceof Error) {
                            notify.error(error.message);
                        }
                    }
                },

                delete: async (entries: CalendarEntry[]) => {
                    try {
                        await Promise.all(
                            entries.map((entry: CalendarEntry) => {
                                if (entry.trainingResourceUniqueId) {
                                    return api.trainingEvents.deleteByResourceUniqueId(entry.trainingResourceUniqueId);
                                }

                                return api.trainingEvents.deleteById(entry.id);
                            }),
                        );
                    } catch (error: unknown) {
                        if (error instanceof Error) {
                            notify.error(error.message);
                        }
                    }
                },
            }),
            [api, notify],
        );

        return {
            queries,
            mutations,
        };
    };
}

export const useTrainingEventsApi = createHook();
