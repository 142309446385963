import { useMemo } from "react";

import { CancelledException } from "@CORE/exceptions";

import { useApiContext } from "@CONTEXT/hooks";

import { useNotifications } from "@VIEW/hooks";

function createHook() {
    return function useCalendarResourcesApi() {
        const { api } = useApiContext();

        const { notify } = useNotifications();

        const queries = useMemo(
            () => ({
                findAll: async (signal?: AbortSignal) => {
                    try {
                        return await api.resources.findAll(signal);
                    } catch (error: unknown) {
                        if (error instanceof Error && !(error instanceof CancelledException)) {
                            notify.error(error.message);
                        }

                        return [];
                    }
                },
            }),
            [api, notify],
        );

        return {
            queries,
        };
    };
}

export const useCalendarResourcesApi = createHook();
